//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
//import { addNotification } from "../notifications";
// import { idText } from "typescript";
import * as actions from "./actions";
import XLSX from "xlsx";

var db = firebase.firestore();

export function addSubmission(activation: string, data: any, submitter: any) {
  return db
    .collection("submissions")
    .doc()
    .set(
      {
        activation,
        data,
        submitter,
        created: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.log(error);
      return error;
    });
}

export function updateSubmission(
  submission: string,
  data: any,
  submitter: any
) {
  // console.log(submission);
  console.log(submission, data, submitter);
  return db
    .collection("submissions")
    .doc(submission)
    .set(
      {
        data,
        submitter,
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )
    .then(function (res: any) {
      return res;
    })
    .catch(function (error: any) {
      console.log(error);
      return error;
    });
}

export function deleteSubmission(id: string) {
  return db
    .collection("submissions")
    .doc(id)
    .delete()
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.log(error);
      return error;
    });
}

export function getSubmissions(id: string) {
  return db
    .collection("submissions")
    .where("activation", "==", id)
    .get()
    .then((snapshot: any) => {
      const data: any = {};
      snapshot.docs.map(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      return data;
    });

  //return data;
}

export function deleteActivationSubmissions(id: string) {
  return db
    .collection("submissions")
    .where("activation", "==", id)
    .get()
    .then((snapshot: any) => {
      snapshot.docs.forEach((doc: any) => {
        //console.log("deleting submission ", doc.id);
        deleteSubmission(doc.id);
      });
      return true;
    });
}

export function getAdminSubmissions() {
  return (dispatch: any, data: any) => {
    db.collection("submissions").onSnapshot((docs: any) => {
      const data: any = {};
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getSubmissionsSuccess(data));
      return data;
    });
  };
}

export const getActivations = () => {
  return (dispatch: any, data: any) => {
    db.collection("activations").onSnapshot((docs: any) => {
      const data: any = {};
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getActivations(data));
    });
  };
};

export const getActivation = (id: any) => {
  return db
    .collection("activations")
    .doc(id)
    .get()
    .then((doc: any) => {
      if (doc.exists) {
        return doc.data();
      }
    })
    .catch((error: any) => {
      console.log(error);
      return false;
    });
};

export function addActivation(payload: any) {
  const date = Date.now();
  const today = new Date(date);
  return db
    .collection("activations")
    .add({ ...payload, created: today.toISOString() })
    .then(function (res: any) {
      return res.id;
    })
    .catch(function (error: any) {
      return false;
    });
}

export function updateActivation(payload: any, id: string) {
  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("activations")
    .doc(id)
    .update({ ...payload, updated: today.toISOString() })
    .then(() => {
      return id;
    })
    .catch((error: any) => {
      return false;
    });
}

export function saveWinners(payload: any, id: string) {
  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("activations")
    .doc(id)
    .update({ winners: payload, updated: today.toISOString() })
    .then(() => {
      return true;
    })
    .catch((error: any) => {
      return false;
    });
}

export function deleteActivation(id: any) {
  return deleteActivationSubmissions(id).then(() => {
    return db
      .collection("activations")
      .doc(id)
      .delete()
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        console.log(error);
        return false;
      });
  });
}

export const setFeaturedActivation = async (id: any = null) => {
  //find post with featured status and resave as not featured

  return db
    .collection("activations")
    .where("featured", "==", true)
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        db.collection("activations")
          .doc(doc.id)
          .update({ featured: false })
          .then(() => {
            return true;
          });
        // console.log("setting false: ", doc.id);
      });
    })
    .then(() => {
      if (id) {
        db.collection("activations")
          .doc(id)
          .update({ featured: true })
          .then(() => {
            return true;
          });
        // console.log("setting true: ", id);
      }
    });
};

export const fanActivationAlert = (payload: any) => {
  console.log(payload.message);
  // addNotification("all", payload);
};

export const setSubmissionStatus = (payload: any) => {
  return db
    .collection("submissions")
    .doc(payload.id)
    .set(
      {
        status: payload.status,
      },
      { merge: true }
    )
    .then(function (res: any) {
      return res;
    })
    .catch(function (error: any) {
      console.log(error);
      return error;
    });
};

export const downloadSubmitters = (activation: any, submissions: any) => {
  updateActivation({ downloaded: true }, activation.id);
  let title = activation.title.replace(/ /g, "_").replace(/'/g, "");

  //set up data
  let data: any = _setupSubmissionData(submissions);
  //debugger;
  /* convert state to workbook */
  const wb = XLSX.utils.book_new();

  Object.keys(data).forEach((el: any, idx: any) => {
    const ws = XLSX.utils.aoa_to_sheet(data[el]);
    XLSX.utils.book_append_sheet(wb, ws, el);
  });

  /* generate XLSX file and send to client */
  let TS = new Date().toISOString();
  XLSX.writeFile(wb, `${title}-${TS}.xlsx`);
};

const _setupSubmissionData = (submissions: any) => {
  let headers = ["fname", "lname", "email", "phone"];

  let data = [headers];
  Object.values(submissions).forEach((en: any, idx: any) => {
    let res = [
      en.submitter.fname ? en.submitter.fname : "NA",
      en.submitter.lname ? en.submitter.lname : "NA",
      en.submitter.email ? en.submitter.email : "NA",
      en.submitter.phoneNumber ? en.submitter.phoneNumber : "NA",
    ];
    data.push(res);
  });

  let unique: any = [];
  data.forEach((x, i, a) => {
    let index: any = unique.find((element: any) => element[2] === x[2]);
    if (!index || index === "undefined") unique.push(x);
  });

  return { unique };
};
