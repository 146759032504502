import { IonCard, IonCardContent, IonButton } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import TeamIcon from "../../../Admin/Schedules/Teams/components/TeamIcon";

export interface RouteParams {
  id: string;
}

interface Props {
  ladder: any;
  teams: any;
}

const Ladder: React.FC<Props> = ({ ladder, teams }) => {
  const [ladderTeams, setLadderTeams] = useState<any>({});
  const [ladderData, setLadderData] = useState<any>({});

  const { officialURL } = ladder;

  useEffect(() => {
    if (ladder) {
      setLadderData({
        ...ladder,
        data: Object.values(ladder.data).sort(sortByPoints),
      });
      setLadderTeams(ladder.data);
    }
  }, [ladder]);

  const sortByPoints = (a: any, b: any) => {
    return b.points - a.points;
  };

  return (
    <div className="ladder-page">
      <IonCard>
        <h2 style={{ textAlign: "center" }}>{ladder.title}</h2>
        <IonCardContent>
          {ladderData && Object.keys(ladderTeams).length > 0 && (
            <table className="ladder">
              <thead>
                <tr>
                  <th className="posCol header"></th>
                  <th className="teamCol header">TEAM</th>
                  <th className="gamesCol header">GP</th>
                  <th className="winsCol header">W</th>
                  <th className="lossCol header">L</th>
                  {/* <th className="forCol header">FOR</th>
									<th className="againstCol header">AGST</th> */}
                  <th className="pointsCol header">PTS</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(ladderData.data)
                  .sort(sortByPoints)
                  .map((data: any, idx: any) => (
                    <Fragment key={idx}>
                      <tr>
                        <td className="posCol">{idx + 1}</td>
                        <td className="teamCol">
                          <div className="icon">
                            <TeamIcon team={teams[data.teamID]} />
                          </div>
                          <span className="teamName">
                            {teams[data.teamID].name}
                          </span>
                        </td>
                        <td className="gamesCol">{data.played}</td>
                        <td className="winsCol">{data.won}</td>
                        <td className="lossCol">{data.lost}</td>
                        {/* <td className="forCol">{data.for}</td>
	<td className="againstCol">{data.against}</td> */}
                        <td className="pointsCol">{data.points}</td>
                      </tr>
                    </Fragment>
                  ))}
              </tbody>
            </table>
          )}
        </IonCardContent>
      </IonCard>
      <div className="link_button_wrapper">
        <IonButton fill="outline" href={officialURL} color="light">
          View the full ladder
        </IonButton>
      </div>
      <div className="spacer60" />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(Ladder);
