import { IonButton, IonCard, IonCardContent, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
  settings: any;
  schedules: any;
  teams: any;
}
const CurrentMatchDashboardModule: React.FC<Props> = ({
  settings,
  schedules,
  teams,
}) => {
  const history = useHistory();

  const [imageURL, setImageURL] = useState<any>("");
  const [currentMatch, setCurrentMatch] = useState<any>("");
  const [BGImage, setBGImage] = useState<any>("");

  useEffect(() => {
    if (settings["schedule-current-match"]) {
      setCurrentMatch(settings["schedule-current-match"].value);
    }
    if (settings["current-match-dashboard-background-image"]) {
      getImageURL(
        settings["current-match-dashboard-background-image"].value
      ).then((url: any) => {
        setBGImage(url);
      });
    }
  }, [settings]);

  useEffect(() => {
    if (schedules[currentMatch]) {
      getImageURL(schedules[currentMatch].img).then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [currentMatch, schedules]);

  // useEffect(() => {
  // 	console.log(currentMatch);
  // }, [currentMatch]);

  return (
    <Fragment>
      {currentMatch && (
        <div
          className="current-dashboard-section"
          style={{ backgroundImage: `url("${BGImage}")` }}
        >
          <h1 className="section-header">Match Programme</h1>
          <p>
            Find out more about the match up including live stats, team rosters,
            and more.
          </p>
          <div className="card-wrapper">
            <IonCard
              onClick={() => history.push(`/schedule/${currentMatch}`)}
              className="programmeCard image-only"
              button
            >
              {imageURL && <IonImg src={imageURL} alt="match programme" />}
              <IonCardContent></IonCardContent>
            </IonCard>
            <div className="card-button-wrapper">
              <IonButton
                onClick={() => history.push(`/schedule/${currentMatch}`)}
              >
                Find out more
              </IonButton>
            </div>
          </div>
          <div className="spacer30" />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
  schedules: state.schedules.schedule,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(CurrentMatchDashboardModule);
