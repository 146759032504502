// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../services/util";

interface Props {
	request: any;
	settings: any;
}

const RequestSlide: React.FC<Props> = ({ request, settings }) => {
	const [bg, setBG] = useState<any>("");
	const { name, img, body, song } = request;

	useEffect(() => {
		if (settings["admin-request-default-slide-image"]) {
			let image = settings["admin-request-default-slide-image"].value;
			getImageURL(image, "1920").then((url: any) => {
				setBG(url);
			});
		}
		//eslint-disable-next-line
	}, [settings, img]);

	return (
		<div
			style={{ backgroundImage: `url(${bg})` }}
			className="slide-wrapper request"
		>
			<div className="slide">
				<div className="content-wrapper">
					<div className="content">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Flogos%2Ffanatical-imagery_DJ-text.png?alt=media&token=6505f294-bcea-4746-9fd2-cc7201e08166"
							className="title"
							alt="you're the dj"
						/>
						{name && (
							<div className="name-wrapper">
								<span className="name">{name}</span>
							</div>
						)}
						<div className="song-wrapper">
							<span className="song">{song}</span>
						</div>
						{body && (
							<Fragment>
								<div className="spacer60" />
								<span className="message">"{body}"</span>
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateTopProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateTopProps, {})(RequestSlide);
