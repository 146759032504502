import { IonContent, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";
import SendRequest from "./components/SendRequest";

interface Props {
	settings: any;
}

const Requests: React.FC<Props> = ({ settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");
	const [footerImage, setFooterImage] = useState<any>("");
	const [footerImageURL, setFooterImageURL] = useState<any>("");
	const [active, setActive] = useState<any>("");

	useEffect(() => {
		if (settings["request-header-image"]) {
			getImageURL(settings["request-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
		if (settings["request-footer-image"]) {
			getImageURL(settings["request-footer-image"].value).then((url: any) => {
				setFooterImage(url);
			});
		}
		if (settings["request-footer-image-URL"]) {
			setFooterImageURL(settings["request-footer-image-URL"].value);
		}
		if (settings["request-active"]) {
			setActive(settings["request-active"].value);
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="Request a Song" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<h1 className="section-header">Send a Request</h1>
				{active ? (
					<SendRequest />
				) : (
					<p className="center-text">
						Sorry, song requests is currently unavailable. Please check back
						again soon.
					</p>
				)}
				<div className="spacer60" />
				{footerImage && (
					<Fragment>
						{footerImageURL ? (
							<a href={footerImageURL}>
								<IonImg src={footerImage} className="full-width-image footer" />
							</a>
						) : (
							<IonImg src={footerImage} className="full-width-image footer" />
						)}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(Requests);
