import { IonContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";
import ScheduleLadderModule from "./components/ScheduleLadderModule";

interface Props {
	settings: any;
}

const ScheduleLadder: React.FC<Props> = ({ settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");

	useEffect(() => {
		if (settings["schedule-header-image"]) {
			getImageURL(settings["schedule-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="Schedule & Ladder" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}

				<ScheduleLadderModule />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(ScheduleLadder);
