import { IonContent, IonList, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";
import PromotionCard from "./components/PromotionCard";

interface Props {
	promotions: any;
	settings: any;
}

const Promotions: React.FC<Props> = ({ promotions, settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");
	const [footerImage, setFooterImage] = useState<any>("");
	const [footerImageURL, setFooterImageURL] = useState<any>("");

	useEffect(() => {
		if (settings["promotions-header-image"]) {
			getImageURL(settings["promotions-header-image"].value).then(
				(url: any) => {
					setHeaderImage(url);
				}
			);
		}
		if (settings["promotions-footer-image"]) {
			getImageURL(settings["promotions-footer-image"].value).then(
				(url: any) => {
					setFooterImage(url);
				}
			);
		}
		if (settings["promotions-footer-image-URL"]) {
			setFooterImageURL(settings["promotions-footer-image-URL"].value);
		}
	}, [settings]);

	const activePromotions = Object.values(promotions).filter(
		(promotion: any) => promotion.active === true
	);

	useEffect(() => {
		//eslint-disable-next-line
	}, [promotions]);

	return (
		<>
			<AppHeader title="Fan Deals" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<IonList>
					{activePromotions.length > 0 ? (
						Object.values(activePromotions).map((promotion: any, idx: any) => (
							<PromotionCard key={idx} promotion={promotion} />
						))
					) : (
						<p>No promotions are available right now. Check back again soon.</p>
					)}
				</IonList>
				{footerImage && (
					<Fragment>
						{footerImageURL ? (
							<a href={footerImageURL}>
								<IonImg src={footerImage} className="full-width-image footer" />
							</a>
						) : (
							<IonImg src={footerImage} className="full-width-image footer" />
						)}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	promotions: state.promotions,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(Promotions);
