import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonToggle,
	IonItemGroup,
	IonItemDivider,
	IonIcon,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	addProduct,
	deleteProduct,
	getProducts,
	updateProduct,
} from "../../../../services/products";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { trash } from "ionicons/icons";

export interface RouteParams {
	id: string;
}

interface Props {
	products: any;
	getProducts: any;
}

const AdminProduct: React.FC<Props> = ({ products, getProducts }) => {
	const history = useHistory();

	const defaultState = {
		name: "",
		active: false,
		img: "",
		shortDesc: "",
		longDesc: "",
		price: "",
		category: "",
		salePrice: "",
		stockStatus: "in",
		inventory: "",
	};

	let { id } = useParams<RouteParams>();
	const [product, setProduct] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
	const [category, setCategory] = useState<any>(defaultState);

	const {
		name,
		active,
		img,
		shortDesc,
		longDesc,
		// category,
		price,
		salePrice,
	} = product;

	useEffect(() => {
		if (products && products[id]) {
			setProduct(products[id]);
			setCategory(products[id].category);
		}
		//eslint-disable-next-line
	}, [id, products]);

	const _onChange = (e: any) => {
		setProduct({ ...product, [e.target.name]: e.target.value });
	};

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// if (product.name.trim() === "" || product.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a message and an image");
		// }
		if (id === "new") {
			addProduct({ ...product, category }).then((res: any) => {
				if (res !== false) {
					history.push(`/admin/product/${res}`);
					toast("Product saved");
				}
			});
		} else {
			updateProduct({ ...product, category }, id).then((res) => {
				if (res) {
					toast("Product updated");
				}
			});
		}
		getProducts();
		setDisableSubmit(false);
	};

	const _back = () => {
		history.push("/admin/products");
	};

	const _onToggle = (option: any) => {
		setProduct({ ...product, [option]: !product[option] });
	};

	const _onPhotoChange = (e: any) => {
		setProduct({ ...product, img: e.image.fullPath });
	};

	const _delete = () => {
		deleteProduct(id).then(() => {
			history.push(`/admin/products`);
		});
	};

	const _onSelect = (e: any) => {
		var name = e.target.name;
		if (name === "category") setCategory(e.target.value);
		// else if (name === "awayTeam") setAwayTeam(e.target.value);
		// else if (name === "status") setStatus(e.target.value);
	};

	const _compareCategory = (o1: any, o2: any) => {
		// console.log(o1);
		// console.log(o2);
		return o1 === category;
	};

	return (
		<>
			<AppHeader title={name} />
			<IonContent className="ion-padding admin">
				<IonTitle>{id === "new" ? "Add" : "Edit"} product </IonTitle>

				<form onSubmit={_submit}>
					<IonItemGroup>
						<IonItem>
							<IonLabel>Active</IonLabel>
							<IonToggle
								checked={active}
								onClick={() => _onToggle("active")}
								name="active"
								id="active"
							/>
						</IonItem>
						<IonItem>
							<IonLabel>Cateogory</IonLabel>
							<IonSelect
								compareWith={_compareCategory}
								value={category}
								name="category"
								onIonChange={_onSelect}
								okText="Okay"
								cancelText="Dismiss"
								placeholder="Select One"
							>
								<IonSelectOption value="drinks">Drinks</IonSelectOption>
								<IonSelectOption value="food">Food</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItemDivider>
							<IonLabel>PRODUCT COPY</IonLabel>
						</IonItemDivider>
						<IonItem>
							<IonLabel position="floating">Name</IonLabel>
							<IonInput
								type="text"
								id="name"
								name="name"
								value={name}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>

						<IonItem>
							<IonLabel position="stacked">Image</IonLabel>
							<div className="spacer5" />
							<PhotoUploadInput
								name="img"
								id="img"
								value={img}
								onChange={_onPhotoChange}
							/>
						</IonItem>

						<IonItem>
							<IonLabel position="floating">Short Description</IonLabel>
							<IonTextarea
								id="shortDesc"
								name="shortDesc"
								value={shortDesc}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Long Description</IonLabel>
							<IonTextarea
								id="longDesc"
								name="longDesc"
								value={longDesc}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Price</IonLabel>
							<IonInput
								type="text"
								id="price"
								name="price"
								value={price}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Sale Price</IonLabel>
							<IonInput
								type="text"
								id="salePrice"
								name="salePrice"
								value={salePrice}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
					</IonItemGroup>

					<IonButton type="submit" color="primary" disabled={disableSubmit}>
						Save
					</IonButton>
					<IonButton onClick={_back} color="danger">
						Back
					</IonButton>
					<IonButton onClick={_delete} color="danger">
						<IonIcon icon={trash} slot="start"></IonIcon>
						Delete
					</IonButton>
				</form>
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
});

export default connect(mapStateToProps, { getProducts })(AdminProduct);
