import firebase from "firebase/app";
//import admin from "firebase-admin";
import "firebase/auth";
import "firebase/firestore";
// import "firebase/functions";
import "@codetrix-studio/capacitor-google-auth";

const config = {
	apiKey: "AIzaSyD7msSzDCAQVJlWirL4JBrGbcr1AY4RRJo",
	authDomain: "rams.fanaticalapp.com",
	projectId: "rams-app-22bcc",
	storageBucket: "rams-app-22bcc.appspot.com",
	messagingSenderId: "214109026165",
	appId: "1:214109026165:web:ad5c8972930818d435453c",
	measurementId: "G-1JTW5CR3EY",
};

firebase.initializeApp(config);

/**
 * Emulator config
 */
//firebase.functions().useEmulator("localhost", 5001);
//var db = firebase.firestore();
// //eslint-disable-next-line no-restricted-globals
// if (location.hostname === "localhost") {
// 	db.useEmulator("localhost", 8080);
// }
