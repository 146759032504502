import {
  IonButton,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonProgressBar,
  IonToast,
} from "@ionic/react";

import firebase from "firebase/app";
import "firebase/storage";

import React, { useEffect, useState } from "react";
// custom hook that will upload to firebase
import useFirebaseUpload from "../services/useFirebaseUpload";
import { CameraResultType } from "@capacitor/core";
import { useCamera, availableFeatures } from "@ionic/react-hooks/camera";
import { trash } from "ionicons/icons";

interface Props {
  name: any;
  id: any;
  value: any;
  onChange: any;
}

const PhotoUploadInput: React.FC<Props> = ({ value, onChange, id, name }) => {
  // const [image, setImage] = useState<any>("");
  const [imageURL, setImageURL] = useState<any>("");

  // setting up the hook to upload file and track its progress
  const [
    { dataResponse, isLoading, isError, progress },
    setFileData,
    clearError,
  ] = useFirebaseUpload();

  const { photo, getPhoto } = useCamera();

  const _handleTakePhoto = () => {
    if (availableFeatures.getPhoto) {
      getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      });
    }
  };

  const _removePhoto = () => {
    onChange({ image: { fullPath: "" }, id, name });
    setImageURL("");
  };

  const getImageURL = async (path: any, size: any) => {
    if (path) {
      let refPath = path;
      var storage = firebase.storage();
      if (size !== "full") {
        const splitString = path.split(".");
        refPath =
          splitString[0] + "_" + size + "x" + size + "." + splitString[1];
      }

      var storageRef = storage.ref(refPath);
      if (storageRef) {
        return storageRef
          .getDownloadURL()
          .then((url: any) => {
            return url;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else return "";
  };

  // when the photo state changes, then call setFileData to upload
  // the image using firebase-hook
  useEffect(() => {
    setFileData(photo);
  }, [photo, setFileData]);

  useEffect(() => {
    if (dataResponse && value !== dataResponse.metaData.fullPath) {
      onChange({ image: { ...dataResponse.metaData }, id, name });
    }
    //eslint-disable-next-line
  }, [dataResponse]);

  useEffect(() => {
    // console.log(imageURL);
    if (value) {
      setImageURL("");
      getImageURL(value, "600").then((url: any) => {
        if (url) {
          setImageURL(url);
        } else {
          getImageURL(value, "full").then((url: any) => {
            setImageURL(url);
          });
        }
      });
    } else setImageURL("");
    //eslint-disable-next-line
  }, [value]);

  // useEffect(() => {
  // 	console.log(imageURL);
  // 	//eslint-disable-next-line
  // }, [imageURL]);

  return (
    <div className="imageUploader">
      {isLoading && progress && (
        <IonProgressBar value={progress.value}></IonProgressBar>
      )}
      <IonList lines="none">
        {(imageURL || dataResponse) && (
          <IonItem>
            {imageURL && (
              <IonImg
                src={imageURL}
                alt="uploaded image"
                className="uploadedImage"
              />
            )}
            {availableFeatures.getPhoto ? null : (
              <input
                type="file"
                onChange={(e: any) => {
                  setFileData(e.target.files[0]);
                }}
              />
            )}
          </IonItem>
        )}
        <IonItem>
          <div>
            <IonButton fill="outline" onClick={_handleTakePhoto}>
              Upload Photo
            </IonButton>

            {(imageURL || dataResponse) && (
              <IonButton fill="outline" onClick={_removePhoto}>
                <IonIcon icon={trash} />
              </IonButton>
            )}
          </div>
        </IonItem>
      </IonList>
      <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError(false)}
        message={isError && isError.message}
        color="danger"
        position="bottom"
        buttons={[
          {
            text: "Done",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      />
    </div>
  );
};

export default PhotoUploadInput;
