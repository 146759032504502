import { GET_PRODUCTS_SUCCESS } from "../actionTypes";
const defaultState = {};

const products = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_PRODUCTS_SUCCESS:
			//console.log(payload);
			return payload;

		default:
			return state;
	}
};

export default products;
