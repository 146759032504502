import React, { useEffect } from "react";
import { IonApp } from "@ionic/react";
import { connect } from "react-redux";

import firebase from "firebase/app";
import "firebase/auth";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Theme CSS */
import "./theme/styles.css";
import "./theme/global.scss";

/* Plugin CSS */
import "react-slideshow-image/dist/styles.css";

import { getCurrentUser, saveToken } from "./services/auth";
import {
  setUser,
  checkLoggedIn,
  monitorUser,
  getUserVotes,
  getUserNotifications,
} from "./services/users";
import { getActivations, getAdminSubmissions } from "./services/activations";
import { getPromotions } from "./services/promotions";
import { getSchedule, getTeams } from "./services/schedules";
import { getLadders } from "./services/ladders";
import { getSettings } from "./services/settings";
import { getProducts } from "./services/products";
import { getOrders } from "./services/orders";
import { getPosts } from "./services/news";
import { getMessages } from "./services/messages";
import { getSlides } from "./services/slides";
import { getNotifications } from "./services/notifications";
import { getPlayers } from "./services/players";
import { getRequests } from "./services/requests";

import { setLoading } from "./services/loading";
import RoutingSystem from "./routes";

interface AppProps {
  user: any;
  setUser: any;
  getActivations: any;
  getPromotions: any;
  getSchedule: any;
  getTeams: any;
  getLadders: any;
  getSettings: any;
  getProducts: any;
  getOrders: any;
  getPlayers: any;
  getPosts: any;
  setLoading: any;
  checkLoggedIn: any;
  monitorUser: any;
  getMessages: any;
  getUserVotes: any;
  getNotifications: any;
  getUserNotifications: any;
  getSlides: any;
  getAdminSubmissions: any;
  getRequests: any;
}

const App: React.FC<AppProps> = ({
  user,
  setUser,
  getActivations,
  getPromotions,
  getSchedule,
  getTeams,
  getLadders,
  getSettings,
  getProducts,
  getOrders,
  getPlayers,
  getPosts,
  setLoading,
  monitorUser,
  getMessages,
  getUserVotes,
  getNotifications,
  getUserNotifications,
  getSlides,
  getAdminSubmissions,
  getRequests,
}) => {
  const getData = () => {
    getActivations();
    getPromotions();
    getSchedule();
    getTeams();
    getLadders();
    getSettings();
    getProducts();
    getPlayers();
    getPosts();
    getMessages();
    getNotifications();
    getSlides();
  };

  useEffect(() => {
    getData();
    /**
     * TODO
     * Calling getData before logged in causes a few issues because of firebase storage permissions
     * But waiting until inside the onAuthStateChanged below creates issues with the data not loading
     * when logging-in in some situations. E.g. Anonymous signIn.
     *
     * We should devise a better strategy for this.
     * */

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let uid = user.uid;
        setUser(user);
        getData();
        saveToken(user.uid);
        monitorUser(uid);
        getOrders(uid);
        getUserVotes(uid);
        getUserNotifications(uid);
      }
    });
    setLoading(true);
    getCurrentUser().then((userData: any) => {
      if (userData) {
        setUser(userData);
        //monitorUser and getOrders may be able to be removed here because we're triggering these on login
        monitorUser(userData.uid);
        getData();
        getOrders(userData.uid);
        if (userData.isAdmin) {
          console.log("isAdmin");
          getAdminSubmissions();
          getRequests();
        }
      }
    });
    setLoading(false);
    //eslint-disable-next-line
  }, []);

  return (
    <IonApp>
      <RoutingSystem />
    </IonApp>
  );
};

const mapStateToProps = (state: any) => ({ user: state.user });

export default connect(mapStateToProps, {
  setUser,
  getActivations,
  getPromotions,
  getSchedule,
  getTeams,
  getLadders,
  getSettings,
  getProducts,
  getOrders,
  getPlayers,
  getPosts,
  setLoading,
  checkLoggedIn,
  monitorUser,
  getMessages,
  getUserVotes,
  getNotifications,
  getUserNotifications,
  getSlides,
  getAdminSubmissions,
  getRequests,
})(App);
