import {
	IonLabel,
	IonItem,
	IonList,
	IonIcon,
	IonButton,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { getActivations } from "../../../../services/activations";

import { add } from "ionicons/icons";
import { connect } from "react-redux";
import ActivationCard from "./ActivationCard";
import { useHistory } from "react-router-dom";
import { sortByCreated } from "../../../../services/util";

interface Props {
	activations: any;
}

const ActivationsView: React.FC<Props> = ({ activations }) => {
	const history = useHistory();

	const [segment, setSegment] = useState<any>("current");
	const [allDocs, setAllDocs] = useState<any>([]);
	const [draft, setDraft] = useState<any>([]);
	const [current, setCurrent] = useState<any>([]);

	useEffect(() => {
		setAllDocs(
			Object.values(activations).sort((a, b) => sortByCreated(a, b, "DESC"))
		);
	}, [activations]);

	useEffect(() => {
		if (allDocs) {
			setDraft(allDocs.filter((el: any) => el.status === "draft"));
			setCurrent(allDocs.filter((el: any) => el.status !== "draft"));
		}
	}, [allDocs]);

	return (
		<Fragment>
			<IonToolbar>
				<IonSegment
					onIonChange={(e) => setSegment(e.detail.value)}
					value={segment}
				>
					<IonSegmentButton value="current">Current</IonSegmentButton>
					<IonSegmentButton value="draft">Draft</IonSegmentButton>
				</IonSegment>
			</IonToolbar>
			{segment === "draft" && (
				<IonList>
					{draft.length > 0 ? (
						<Fragment>
							{draft.map((activation: any, idx: any) => (
								<ActivationCard key={idx} activation={activation} />
							))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">
							No activations available
						</IonLabel>
					)}
					<IonItem>
						<IonButton
							onClick={() => history.push("/admin/activation/new")}
							color="primary"
						>
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			)}
			{segment === "current" && (
				<IonList>
					{current.length > 0 ? (
						<Fragment>
							{current.map((activation: any, idx: any) => (
								<ActivationCard key={idx} activation={activation} />
							))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">
							No activations available
						</IonLabel>
					)}
					<IonItem>
						<IonButton
							onClick={() => history.push("/admin/activation/new")}
							color="primary"
						>
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, { getActivations })(ActivationsView);
