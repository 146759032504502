import { IonContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";

import ActivationsModule from "./components/ActivationsModule";

interface Props {
	activations: any;
	settings: any;
}

const Activations: React.FC<Props> = ({ activations, settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");

	useEffect(() => {
		if (settings["activation-header-image"]) {
			getImageURL(settings["activation-header-image"].value).then(
				(url: any) => {
					setHeaderImage(url);
				}
			);
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="Competitions" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<ActivationsModule featured={true} />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(Activations);
