import {
  IonContent,
  IonImg,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonInput,
  IonCard,
  IonCardContent,
  IonTextarea,
  IonCheckbox,
  IonCol,
  IonRow,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  addSubmission,
  updateSubmission,
  getSubmissions,
} from "../../../../services/activations";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { connect } from "react-redux";
import SubmissionResults from "../components/SubmissionResults";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { toast } from "../../../../components/Toast/toast";
import { getImageURL } from "../../../../services/util";
import { saveUserDisplayInfo } from "../../../../services/users";

export interface RouteParams {
  id: string;
}

export interface Props {
  user: any;
  activations: any;
}

const Activation: React.FC<Props> = ({ user, activations }) => {
  let { id } = useParams<RouteParams>();

  const defaultState = {
    title: "",
    featured: false,
    img: "",
    shortDesc: "",
    longDesc: "",
    singleEntry: "",
    canChange: false,
    canSeeSubmission: false,
    showResultsOnSubmit: false,
    callToAction: "",
    submitText: "",
    type: "",
    options: "",
    displayFnameField: "",
    displayLnameField: "",
    displayEmailField: "",
    displayPhoneField: "",
    reqFname: false,
    reqLname: false,
    reqEmail: false,
    reqPhone: false,
    textLabel: "",
    additionalText: false,
    additionalTextReq: false,
    textareaLabel: "",
    additionalTextarea: false,
    additionalTextareaReq: false,
    numberLabel: "",
    additionalNumber: false,
    additionalNumberReq: false,
  };
  const [activation, setActivation] = useState<any>(defaultState);
  const [selected, setSelected] = useState<string>("");
  const [completed, setCompleted] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [recentlySubmitted, setRecentlySubmitted] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [allSubmissions, setAllSubmissions] = useState<any>([]);
  const [submissionId, setSubmissionId] = useState<any>("");
  const [score, setScore] = useState<any>({});
  const [additionalFields, setAdditionalFields] = useState<any>({});
  const [submissionData, setSubmissionData] = useState<any>({});

  const { uid, email, fname, lname, phoneNumber } = userDetails;

  const [imageURL, setImageURL] = useState<any>("");
  const [saveInfo, setSaveInfo] = useState<any>(true);

  const {
    title,
    img,
    shortDesc,
    longDesc,
    singleEntry,
    canChange,
    showResultsOnSubmit,
    callToAction,
    submitText,
    type,
    options,
    displayFnameField,
    displayLnameField,
    displayEmailField,
    displayPhoneField,
    reqFname,
    reqLname,
    reqEmail,
    reqPhone,
    homeName,
    oppositionName,
    textLabel,
    additionalText,
    additionalTextReq,
    textareaLabel,
    additionalTextarea,
    additionalTextareaReq,
    numberLabel,
    additionalNumber,
    additionalNumberReq,
  } = activation;

  useEffect(() => {
    if (img) {
      getImageURL(img).then((url: any) => {
        setImageURL(url);
      });
    }
  }, [img]);

  useEffect(() => {
    setActivation({ ...activations[id] });
    setUpdated(false);
    setCompleted(false);
    setRecentlySubmitted(false);
  }, [id, activations]);

  const validateEmail = (email: any) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateSubmission = (suppress: boolean = true) => {
    let pass = true;
    let msg = "";
    // Validate userdetails
    if (displayFnameField && reqFname && fname === "") {
      pass = false;
      msg = "First Name is a required field";
    } else if (displayLnameField && reqLname && lname === "") {
      pass = false;
      msg = "Last Name is a required field";
    } else if (displayEmailField && reqEmail && email === "") {
      pass = false;
      msg = "Email is a required field";
    } else if (email !== "" && !validateEmail(email)) {
      pass = false;
      msg = "Email appears to be invalid";
    } else if (displayPhoneField && reqPhone && phoneNumber === "") {
      pass = false;
      msg = "Phone is a required field";
    }

    //Validate activation specific fields
    if (type === "score") {
      //Score fields
      if (
        !score.homeScore ||
        score.homeScore === "" ||
        !score.awayScore ||
        score.awayScore === ""
      ) {
        pass = false;
        msg = "You must enter a score for each team";
      }
    } else if (type === "simple") {
      //Additional fields
      if (
        additionalText &&
        additionalTextReq &&
        (!additionalFields.text || additionalFields.text === "")
      ) {
        pass = false;
        msg = `${textLabel} is a required field`;
      } else if (
        additionalTextarea &&
        additionalTextareaReq &&
        (!additionalFields.textarea || additionalFields.textarea === "")
      ) {
        pass = false;
        msg = `${textareaLabel} is a required field`;
      } else if (
        additionalNumber &&
        additionalNumberReq &&
        (!additionalFields.number || additionalFields.number === "")
      ) {
        pass = false;
        msg = `${numberLabel} is a required field`;
      }
    } else if (type === "select") {
      if (selected === "") {
        pass = false;
        msg = "You must select an option";
      }
    }

    if (pass) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
      if (!suppress && msg !== "") toast(msg);
    }

    return pass;
  };

  const _onSubmit = () => {
    if (validateSubmission()) {
      if (!submissionId) {
        addSubmission(
          id,
          { selected, score, additionalFields, status: "pending" },
          userDetails
        ).then(() => {
          setCompleted(true);
          if (saveInfo) {
            saveUserDisplayInfo(user.uid, { fname, lname, phoneNumber, email });
          }
        });
      } else {
        updateSubmission(
          submissionId,
          { selected, score, additionalFields },
          userDetails
        );
        setUpdated(true);
        if (saveInfo) {
          saveUserDisplayInfo(user.uid, { fname, lname, phoneNumber, email });
        }
      }
      setRecentlySubmitted(true);
      getData();
    }
  };

  const getData = () => {
    /**
     * TODO do something smarter with submissions. Maybe there should be a cloud function
     * which aggregates submissions and updates the activation. The activation would then
     * only need to retrieve the users submissions if/when necessary.
     */
    getSubmissions(id).then((res: any) => {
      if (res) {
        try {
          setAllSubmissions(res);
          let submissions: any = Object.values(res).find((item: any) => {
            return item.submitter.uid === uid;
          });

          if (submissions !== undefined) {
            if (singleEntry) {
              setSubmissionId(submissions.id);
              setUserDetails({ ...submissions.submitter });
              if (allSubmissions[submissions.id]) {
                setSubmissionData(allSubmissions[submissions.id].data);
              }
            }
            setCompleted(true);
            setSelected(submissions.data.selected);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  useEffect(() => {
    if (user.uid !== "") {
      setUserDetails({
        email:
          user.persona && user.persona.email
            ? user.persona.email
            : user.email
            ? user.email
            : "",
        fname:
          user.persona && user.persona.fname
            ? user.persona.fname
            : user.fname
            ? user.fname
            : "",
        lname:
          user.persona && user.persona.lname
            ? user.persona.lname
            : user.lname
            ? user.lname
            : "",
        phoneNumber:
          user.persona && user.persona.phoneNumber
            ? user.persona.phoneNumber
            : user.phoneNumber
            ? user.phoneNumber
            : "",
        uid: user.uid,
      });
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (uid) {
      getData();
    }
    //eslint-disable-next-line
  }, [uid, completed, updated]);

  useEffect(() => {
    validateSubmission(true);
    //eslint-disable-next-line
  }, [additionalFields, userDetails, score]);

  const _onChange = (e: any) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const _onChangeSubmission = (e: any) => {
    if (["homeScore", "awayScore"].includes(e.target.name)) {
      setScore({ ...score, [e.target.name]: e.target.value });
    } else if (["text", "textarea", "number"].includes(e.target.name)) {
      setAdditionalFields({
        ...additionalFields,
        [e.target.name]: e.target.value,
      });
    }
  };

  const _setOptionSelect = (e: any) => {
    setSelected(e.detail.value);
    //TODO if(check if required fields have been completed)
    setSubmitDisabled(false);
  };

  const _onCheckboxChange = (e: any) => {
    if (e.target.name === "saveInfo") {
      setSaveInfo(!saveInfo);
    }
  };

  return (
    <>
      <AppHeader title={title} />
      <IonContent className="ion-padding">
        {imageURL && (
          <IonImg src={imageURL} alt="" className="full-width-image" />
        )}
        {title && <h2>{title}</h2>}

        {shortDesc && (
          <div
            dangerouslySetInnerHTML={{
              __html: shortDesc,
            }}
          />
        )}
        {longDesc && (
          <div
            dangerouslySetInnerHTML={{
              __html: longDesc,
            }}
          />
        )}
        {showResultsOnSubmit && completed && (
          <IonCard id="results-card">
            <IonCardContent>
              <SubmissionResults type={type} submissions={allSubmissions} />
            </IonCardContent>
          </IonCard>
        )}
        {(completed || updated) && (
          <IonCard id="message-card">
            <IonCardContent>
              {completed && singleEntry && !canChange && (
                <p>You have already completed this activation</p>
              )}
              {completed && !singleEntry && (
                <p>
                  You have already completed this activation, but this
                  activation accepts multiple entries. Refresh to enter again.
                </p>
              )}
              {updated && (
                <p>
                  Your submission for this activation has been updated. Refresh
                  the page and you can update it again.
                </p>
              )}
              {!updated && completed && singleEntry && canChange && (
                <p>
                  You have already completed this activation, but your
                  submission can be updated.
                </p>
              )}
            </IonCardContent>
          </IonCard>
        )}
        {(completed || updated) && type === "score" && submissionData.score && (
          <IonCard id="submission-data-card">
            <IonCardContent>
              {completed && singleEntry && !canChange && (
                <>
                  {submissionData && submissionData.score && (
                    <Fragment>
                      <h2 className="activation-section-header">
                        Your Prediction
                      </h2>
                      <div className="spacer5" />
                      <p>
                        {homeName} Score: {submissionData.score.homeScore}
                      </p>
                      <p>
                        {oppositionName} Score: {submissionData.score.awayScore}
                      </p>
                    </Fragment>
                  )}
                </>
              )}
            </IonCardContent>
          </IonCard>
        )}

        {!recentlySubmitted && (
          <Fragment>
            {(!completed ||
              !singleEntry ||
              (!updated && completed && singleEntry && canChange)) && (
              <Fragment>
                {type === "select" && (
                  <IonCard id="submission-card">
                    <IonCardContent>
                      <IonList className="full-width">
                        <IonListHeader>
                          <h2 className="activation-section-header">Options</h2>
                        </IonListHeader>
                        <IonItem lines="none">
                          <IonRadioGroup
                            onIonChange={_setOptionSelect}
                            value={selected}
                          >
                            <IonListHeader>
                              <IonLabel>{callToAction}</IonLabel>
                            </IonListHeader>
                            {options.map((elem: any, index: any) => (
                              <IonItem key={index} lines="none">
                                <IonLabel>{elem.olabel}</IonLabel>
                                <IonRadio
                                  mode="md"
                                  slot="start"
                                  value={elem.oid}
                                  className="md"
                                />
                              </IonItem>
                            ))}
                          </IonRadioGroup>
                        </IonItem>
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                )}
                {type === "score" && (
                  <IonCard id="submission-card">
                    <IonCardContent>
                      <IonList className="full-width">
                        <IonListHeader>
                          <h2 className="activation-section-header">
                            Your Prediction
                          </h2>
                        </IonListHeader>

                        <IonItem>
                          <IonLabel position="floating">
                            {homeName || "Home"} Score
                          </IonLabel>
                          <IonInput
                            type="number"
                            id="homeScore"
                            name="homeScore"
                            value={score.homeScore ? score.homeScore : ""}
                            onIonChange={_onChangeSubmission}
                            required={true}
                          ></IonInput>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating">
                            {oppositionName || "Away"} Score
                          </IonLabel>
                          <IonInput
                            type="number"
                            id="awayScore"
                            name="awayScore"
                            value={score.awayScore ? score.awayScore : ""}
                            onIonChange={_onChangeSubmission}
                            required={true}
                          ></IonInput>
                        </IonItem>
                      </IonList>
                    </IonCardContent>
                  </IonCard>
                )}
                {type === "simple" &&
                  (additionalNumber ||
                    additionalText ||
                    additionalTextarea) && (
                    <IonCard id="additional-fields">
                      <IonCardContent>
                        <IonList className="full-width">
                          <IonListHeader>
                            <h2 className="activation-section-header">
                              Your Submission
                            </h2>
                          </IonListHeader>

                          {additionalText && (
                            <IonItem>
                              <IonLabel position="floating">
                                {textLabel}
                              </IonLabel>
                              <IonInput
                                type="text"
                                id="text"
                                name="text"
                                value={
                                  additionalFields.text
                                    ? additionalFields.text
                                    : ""
                                }
                                onIonChange={_onChangeSubmission}
                                required={additionalTextReq}
                              ></IonInput>
                            </IonItem>
                          )}
                          {additionalTextarea && (
                            <IonItem>
                              <IonLabel position="floating">
                                {textareaLabel}
                              </IonLabel>
                              <IonTextarea
                                id="textarea"
                                name="textarea"
                                value={
                                  additionalFields.textarea
                                    ? additionalFields.textarea
                                    : ""
                                }
                                onIonChange={_onChangeSubmission}
                                required={additionalTextareaReq}
                              ></IonTextarea>
                            </IonItem>
                          )}
                          {additionalNumber && (
                            <IonItem>
                              <IonLabel position="floating">
                                {numberLabel}
                              </IonLabel>
                              <IonInput
                                type="number"
                                id="number"
                                name="number"
                                value={
                                  additionalFields.number
                                    ? additionalFields.number
                                    : ""
                                }
                                onIonChange={_onChangeSubmission}
                                required={additionalNumberReq}
                              ></IonInput>
                            </IonItem>
                          )}
                        </IonList>
                      </IonCardContent>
                    </IonCard>
                  )}
              </Fragment>
            )}
            {(!completed ||
              !singleEntry ||
              (!updated && completed && singleEntry && canChange)) && (
              <IonCard id="user-card">
                <IonCardContent>
                  <IonList className="full-width" lines="none">
                    <IonListHeader>
                      <h2 className="activation-section-header">
                        Your Details
                      </h2>
                    </IonListHeader>
                    <IonRow>
                      {displayFnameField && (
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">First Name</IonLabel>
                            <IonInput
                              type="text"
                              id="fname"
                              name="fname"
                              value={fname}
                              onIonChange={_onChange}
                              required={reqFname && displayFnameField}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      )}
                      {displayLnameField && (
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Last Name</IonLabel>
                            <IonInput
                              type="text"
                              id="lname"
                              name="lname"
                              value={lname}
                              onIonChange={_onChange}
                              required={reqLname && displayLnameField}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      )}
                      {displayEmailField && (
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput
                              type="email"
                              id="email"
                              name="email"
                              value={email}
                              onIonChange={_onChange}
                              required={reqEmail && displayEmailField}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      )}
                      {displayPhoneField && (
                        <IonCol size="12">
                          <IonItem>
                            <IonLabel position="floating">Phone</IonLabel>
                            <IonInput
                              type="tel"
                              id="phoneNumber"
                              name="phoneNumber"
                              value={phoneNumber}
                              onIonChange={_onChange}
                              required={reqPhone && displayPhoneField}
                            ></IonInput>
                          </IonItem>
                        </IonCol>
                      )}{" "}
                      <IonCol size="12">
                        <IonItem>
                          <IonCheckbox
                            slot="start"
                            name="saveInfo"
                            value={saveInfo}
                            checked={saveInfo}
                            onIonChange={_onCheckboxChange}
                          />
                          <IonLabel>Save these details?</IonLabel>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonList>
                </IonCardContent>
              </IonCard>
            )}
          </Fragment>
        )}

        {(!completed || !singleEntry) && !recentlySubmitted && (
          <IonButton
            type="submit"
            onClick={_onSubmit}
            disabled={submitDisabled}
          >
            {submitText}
          </IonButton>
        )}
        {!updated &&
          completed &&
          singleEntry &&
          canChange &&
          !recentlySubmitted && (
            <IonButton type="submit" onClick={_onSubmit}>
              Update
            </IonButton>
          )}
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
  activations: state.activations.activations,
  //submissions: state.submissions
});

export default connect(mapStateToProps, {})(Activation);
