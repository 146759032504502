import React from "react";
import { IonToolbar, IonFooter, IonButtons } from "@ionic/react";
import { connect } from "react-redux";

// import { arrowBack } from "ionicons/icons";

interface Props {
	slides: any;
}

export const AdminFooter: React.FC<Props> = ({ slides }) => {
	return (
		<IonFooter>
			<IonToolbar>
				<IonButtons></IonButtons>
			</IonToolbar>
		</IonFooter>
	);
};

const mapStateToProps = (state: any) => ({
	slides: state.slides,
});

export default connect(mapStateToProps, {})(AdminFooter);
