import * as types from "../actionTypes";

export function getActivations(payload) {
	//console.log(payload);
	return { type: types.GET_ACTIVATIONS, payload };
}

export function getSubmissionsSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_SUBMISSIONS_SUCCESS, payload };
}
