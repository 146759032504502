import {
	IonContent,
	IonLabel,
	IonItem,
	IonList,
	IonIcon,
	IonButton,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { add } from "ionicons/icons";
import { connect } from "react-redux";
import { AppHeader } from "../../../components/Header/AppHeader";
import PromotionCard from "./components/PromotionCard";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
	promotions: any;
}

const AdminPromotions: React.FC<Props> = ({ promotions }) => {
	const [dataLength, setDataLength] = useState<any>(0);

	useEffect(() => {
		setDataLength(Object.keys(promotions).length);
		//eslint-disable-next-line
	}, [promotions]);

	return (
		<>
			<AppHeader title="Promotions" />
			<IonContent fullscreen className="ion-padding admin">
				<IonList>
					{dataLength > 0 ? (
						<Fragment>
							{Object.values(promotions).map((promotion: any, idx: any) => (
								<PromotionCard key={idx} promotion={promotion} />
							))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">No promotions available</IonLabel>
					)}
					<IonItem>
						<IonButton href={"/admin/promotion/new"} color="primary">
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	promotions: state.promotions,
	// client: state.client,
	// current: state.client.current,
});

export default connect(mapStateToProps, {})(AdminPromotions);
