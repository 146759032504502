import {
	IonBadge,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCol,
	IonIcon,
	IonRow,
} from "@ionic/react";
import {
	checkmark,
	close,
	eyeOffOutline,
	eyeOutline,
	trash,
} from "ionicons/icons";
import React from "react";

import {
	changeStatus,
	deleteRequest,
	setPlayed,
} from "../../../../services/requests";
import { pushToScreen } from "../../../../services/settings";
import { formatDateTime } from "../../../../services/util";

interface Props {
	request: any;
}

const AdminRequestCard: React.FC<Props> = ({ request }) => {
	const { id, name, song, body, status, created, played } = request;

	const _changeStatus = (status: string) => {
		changeStatus(id, status);
	};

	const _setPlayed = (status: boolean) => {
		setPlayed(id, status);
	};

	const _delete = () => {
		deleteRequest(id);
	};

	const _push = () => {
		_changeStatus("approved");
		_setPlayed(true);
		pushToScreen({ type: "request", id });
	};

	//TODO Add sorting segments here
	//TODO Add ability for admin to delete a request
	return (
		<IonCard>
			<IonCardContent onClick={_push}>
				<IonRow>
					<IonCol sizeSm="2" size="12">
						{played ? (
							<IonBadge color="light">Played</IonBadge>
						) : (
							<IonBadge color="success">Unplayed</IonBadge>
						)}
					</IonCol>
					<IonCol sizeSm="2" size="12">
						{song}
					</IonCol>
					<IonCol sizeSm="4" size="12">
						{body}
					</IonCol>
					<IonCol sizeSm="2" size="12">
						{name}
					</IonCol>
					<IonCol sizeSm="2" size="12">
						{formatDateTime(created)}
					</IonCol>
				</IonRow>
			</IonCardContent>
			<IonRow>
				<IonCol sizeSm="11" size="10">
					<IonButtons>
						{status === "approved" && (
							<IonButton
								onClick={() => _changeStatus("unapproved")}
								color="danger"
							>
								<IonIcon icon={close} />
								<span className="buttonText hide960">Unapprove</span>
							</IonButton>
						)}
						{status === "unapproved" && (
							<IonButton
								onClick={() => _changeStatus("approved")}
								color="success"
							>
								<IonIcon icon={checkmark} />
								<span className="buttonText hide960">Approve</span>
							</IonButton>
						)}
						{status === "unapproved" && (
							<IonButton onClick={() => _changeStatus("hidden")} color="danger">
								<IonIcon icon={eyeOffOutline} />
								<span className="buttonText hide960">Hide</span>
							</IonButton>
						)}
						{status === "hidden" && (
							<IonButton
								onClick={() => _changeStatus("unapproved")}
								color="success"
							>
								<IonIcon icon={eyeOutline} />
								<span className="buttonText hide960">Unhide</span>
							</IonButton>
						)}
						<IonButton onClick={_push}>
							<span className="buttonText">Push</span>
						</IonButton>
					</IonButtons>
				</IonCol>
				<IonCol sizeSm="1" size="2">
					<IonButtons>
						<IonButton onClick={_delete} color="danger">
							<IonIcon icon={trash} />
						</IonButton>
					</IonButtons>
				</IonCol>
			</IonRow>
		</IonCard>
	);
};

export default AdminRequestCard;
