import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonReorder,
  IonReorderGroup,
  IonText,
} from "@ionic/react";
import React, { Fragment, useState, useEffect } from "react";

import AdminSlideCard from "./AdminSlideCard";
import { connect } from "react-redux";
import { add } from "ionicons/icons";
import { useHistory } from "react-router-dom";

import { updateSlideOrder, getSlides } from "../../../../services/slides";

interface Props {
  slides: any;
  settings: any;
  getSlides: Function;
}

const SlidesView: React.FC<Props> = ({ slides, settings }) => {
  const history = useHistory();

  const orderSlides = (a: any, b: any) => {
    return a.order - b.order;
  };

  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    let el = Object.values(slides).sort(orderSlides);
    setItems(el);
  }, [slides]);

  const reorderItems = (from: any, to: any) => {
    const itemMove = Object.values(items)[from];
    console.log(itemMove);
    let newItems = Object.values(items);
    newItems.splice(from, 1);
    newItems.splice(to, 0, itemMove);
    newItems.forEach((item: any, i: number) => {
      item.order = i;
    });
    console.log(newItems);
    setItems(newItems);
    return newItems;
  };

  function handleReorder(event: any) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    console.log("Before complete", items);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    console.log("Dragged from index", event.detail.from, "to", event.detail.to);
    let newOrder = reorderItems(event.detail.from, event.detail.to);
    event.detail.complete(newOrder);
    //setItems(event.detail.complete(items));

    // After complete is called the items will be in the new order
    console.log("After complete", newOrder);
    saveItemOrder();
  }

  //   useEffect(() => {
  //     //console.log("items changed");
  //     //console.log(items);
  //     saveItemOrder();
  //     //eslint-disable-next-line
  //   }, [items]);

  const saveItemOrder = () => {
    Object.values(items)
      .sort(orderSlides)
      .forEach((item: any, i: number) => {
        updateSlideOrder(item.id, i);
        //   console.log(item.id, item.order);
      });
    getSlides();
  };

  return (
    <Fragment>
      {items && Object.values(items).length > 0 ? (
        <IonList>
          <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
            {items.sort(orderSlides).map((slide: any, idx: any) => (
              <IonItem key={idx}>
                <IonText>{slide.order}</IonText>
                <AdminSlideCard message={slide} />
                <IonReorder slot="end"></IonReorder>
              </IonItem>
            ))}
          </IonReorderGroup>
        </IonList>
      ) : (
        <IonLabel className="ion-padding">No messages</IonLabel>
      )}
      <IonItem>
        <IonButton
          onClick={() => history.push("/admin/slide/new")}
          color="primary"
        >
          <IonIcon icon={add} />
          Add New
        </IonButton>
      </IonItem>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  slides: state.slides,
  settings: state.settings,
});

export default connect(mapStateToProps, { getSlides })(SlidesView);
