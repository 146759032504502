import { IonContent, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppHeader } from "../../../components/Header/AppHeader";
import FeaturedPost from "../News/components/FeaturedPost";
//import ScheduleSlider from "../Schedules/components/ScheduleSlider";
import CloudMessagesModule from "../CloudMessages/components/CloudMessagesModule";
import ActivationsModule from "../Activations/components/ActivationsModule";
//import ShopModule from "../Shop/components/ShopModule";
import FeaturedActivation from "../Activations/components/FeaturedActivation";
import { getImageURL } from "../../../services/util";
import DashboardScheduleLadderModule from "../ScheduleLadder/components/DashboardScheduleLadderModule";
import ShopDashboardModule from "../Shop/components/ShopDashboardModule";
import RequestsDashboardModule from "../Requests/components/RequestsDashboardModule";
import MessagesDashboardModule from "../Messages/components/MessagesDashboardModule";
import CurrentMatchDashboardModule from "../Schedules/components/CurrentMatchDashboardModule";

export interface Props {
  user: any;
  settings: any;
}

const Dashboard: React.FC<Props> = ({ user, settings }) => {
  // const { fname, displayName } = user;
  const [headerImage, setHeaderImage] = useState<any>("");
  const [footerImage, setFooterImage] = useState<any>("");
  const [footerImageURL, setFooterImageURL] = useState<any>("");
  const [shopVisible, setShopVisible] = useState<any>(false);
  const [newsVisible, setNewsVisible] = useState<any>(false);

  useEffect(() => {
    if (settings["dashboard-header-image"]) {
      getImageURL(settings["dashboard-header-image"].value, "full").then(
        (url: any) => {
          setHeaderImage(url);
        }
      );
    }
    if (settings["dashboard-footer-image"]) {
      getImageURL(settings["dashboard-footer-image"].value).then((url: any) => {
        setFooterImage(url);
      });
    }
    if (settings["dashboard-footer-image-URL"]) {
      setFooterImageURL(settings["dashboard-footer-image-URL"].value);
    }
    if (settings["shop-visible"]) {
      setShopVisible(settings["shop-visible"].value);
    }
    if (settings["news-visible"]) {
      setNewsVisible(settings["news-visible"].value);
    }
  }, [settings]);

  // useEffect(() => {
  // 	console.log(settings["dashboard-footer-image-URL"]);
  // }, [settings]);

  return (
    <>
      <AppHeader title="Dashboard" />

      <IonContent fullscreen className="ion-padding dashboard">
        {headerImage && (
          <IonImg src={headerImage} className="full-width-image" />
        )}
        <CloudMessagesModule />
        <FeaturedActivation />
        {shopVisible && <ShopDashboardModule />}
        <MessagesDashboardModule />
        <RequestsDashboardModule />
        <ActivationsModule featured={false} />
        <CurrentMatchDashboardModule />
        {newsVisible && (
          <>
            <h1 className="section-header">Featured News</h1>
            <FeaturedPost />
            <div className="spacer30" />
          </>
        )}
        <DashboardScheduleLadderModule />
        <div className="spacer60" />
        {footerImage && (
          <Fragment>
            {footerImageURL ? (
              <a href={footerImageURL} className="full-width-div">
                <img
                  alt="footer"
                  src={footerImage}
                  className="full-width-image footer"
                />
              </a>
            ) : (
              <img
                alt="footer"
                src={footerImage}
                className="full-width-image footer"
              />
            )}
          </Fragment>
        )}
      </IonContent>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
  settings: state.settings,
});

export default connect(mapStateToProps, {})(Dashboard);
