import firebase from "firebase/app";
import "firebase/firestore";
import * as actions from "./actions";

var db = firebase.firestore();

export const addToCart = (id: any) => {
	return (dispatch: any, data: any) => {
		dispatch(actions.addToCartSuccess(id));
	};
};

export const setCart = (payload: any) => {
	return (dispatch: any, data: any) => {
		dispatch(actions.setCartSuccess(payload));
	};
};

export function addOrder(payload: any) {
	const date = Date.now();
	const today = new Date(date);
	return db
		.collection("orders")
		.add({ ...payload, created: today.toISOString() })
		.then((res: any) => {
			if (res.id) {
				return res.id;
			}
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

export function updateOrder(id: any, payload: any) {
	return db
		.collection("orders")
		.doc(id)
		.update(payload)
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

export const getOrders = (id: any) => {
	return (dispatch: any, data: any) => {
		db.collection("orders")
			.where("userDetails.uid", "==", id)
			.onSnapshot((docs) => {
				const data: any = {};
				docs.forEach((doc) => (data[doc.id] = { ...doc.data(), id: doc.id }));
				dispatch(actions.getOrdersSuccess(data));
			});
	};
};

export const getAdminOrders = () => {
	return (dispatch: any, data: any) => {
		//console.log("getting orders");
		db.collection("orders").onSnapshot((docs) => {
			const data: any = {};
			docs.forEach(
				(doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
			);
			dispatch(actions.getAdminOrdersSuccess(data));
		});
	};
};

export async function getOrder(id: any) {
	let order;
	await db
		.collection("orders")
		.doc(id)
		.get()
		.then((doc) => {
			if (doc.exists) {
				order = doc.data();
			}
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
	return order;
}

export const setCurrentOrder = (order: any) => {
	//console.log("setCurrentOrder: ", order);
	return (dispatch: any, data: any) => {
		dispatch(actions.setCurrentOrderSuccess(order));
	};
};

export const updateOrderStatus = (id: any, status: any) => {
	db.collection("orders").doc(id).update({ status: status });
};

export const processNewCardPayment = async (payload: any) => {
	await addPaymentMethod(payload).then(async () => {
		const currency = "NZD";
		// console.log(payload.order);
		const data = {
			payment_method: payload.setupIntent.payment_method,
			currency,
			amount: formatAmountForStripe(payload.order.cartTotal, currency),
			status: "new",
			order: payload.order.id,
		};
		await addPayment({ uid: payload.uid, data });
	});
};

export const addPaymentMethod = async (payload: any) => {
	await db
		.collection("users")
		.doc(payload.uid)
		.collection("payment_methods")
		.add({ id: payload.setupIntent.payment_method })
		.catch((error) => {
			console.error("Error writing payment method: ", error);
		});
};

// Format amount for Stripe
function formatAmountForStripe(amount: any, currency: any) {
	return zeroDecimalCurrency(amount, currency)
		? amount
		: Math.round(amount * 100);
}

// Check if we have a zero decimal currency
// https://stripe.com/docs/currencies#zero-decimal
function zeroDecimalCurrency(amount: any, currency: any) {
	let numberFormat = new Intl.NumberFormat(["en-US"], {
		style: "currency",
		currency: currency,
		currencyDisplay: "symbol",
	});
	const parts = numberFormat.formatToParts(amount);
	let zeroDecimalCurrency = true;
	for (let part of parts) {
		if (part.type === "decimal") {
			zeroDecimalCurrency = false;
		}
	}
	return zeroDecimalCurrency;
}

export const addPayment = async (payload: any) => {
	db.collection("users")
		.doc(payload.uid)
		.collection("payments")
		.add(payload.data)
		.catch((error) => {
			console.error("Error writing payment: ", error);
		});
};

export const processExistingCardPayment = async (payload: any) => {
	const currency = "NZD";
	const data = {
		payment_method: payload.payment_method,
		currency,
		amount: formatAmountForStripe(payload.order.cartTotal, currency),
		status: "new",
		order: payload.order.id,
	};

	await addPayment({ uid: payload.uid, data });
};
