//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
import * as actions from "./actions";

var db = firebase.firestore();

export async function addFeedback(payload: any) {
	const date = Date.now();
	const today = new Date(date);

	await db
		.collection("feedback")
		.add({ ...payload, created: today.toISOString() })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});

	return true;
}

export const getFeedback = () => {
	return (dispatch: any, data: any) => {
		db.collection("feedback").onSnapshot((docs) => {
			const data: any = {};
			docs.forEach(
				(doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
			);
			dispatch(actions.getFeedbackSuccess(data));
		});
	};
};

export const deleteFeedback = (id: any) => {
	return db
		.collection("feedback")
		.doc(id)
		.delete()
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};

export const markComplete = (id: any, completed: any) => {
	return db
		.collection("feedback")
		.doc(id)
		.update({ complete: completed })
		.catch(function (error) {
			return false;
		});
};
