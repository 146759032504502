import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

interface Props {
	messages: any;
	onClick: any;
	active: any;
}

const DashboardSlides: React.FC<Props> = ({ messages, onClick, active }) => {
	return (
		<IonCard button color={active ? "primary" : "default"} onClick={onClick}>
			<IonCardContent>
				<h1>Messages</h1>
				<IonRow>
					<IonCol>
						Count:{" "}
						{
							Object.values(messages).filter(
								(msg: any) => msg.status === "pending"
							).length
						}
					</IonCol>
				</IonRow>
			</IonCardContent>
			{/* <IonToolbar>
				<IonButtons>
					<IonButton onClick={() => history.push("/admin/messages/slideshow")}>
						Slideshow
					</IonButton>
				</IonButtons>
			</IonToolbar> */}
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
});

export default connect(mapStateToProps, {})(DashboardSlides);
