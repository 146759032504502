import { GET_PROMOS_SUCCESS } from "../actionTypes";
const defaultState = {};

const promotions = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_PROMOS_SUCCESS:
			//console.log(payload);
			return payload;
		default:
			return state;
	}
};

export default promotions;
