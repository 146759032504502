import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

import { addSetting, updateSetting } from "../../../../services/settings";
import { formatDateTime } from "../../../../services/util";

interface Props {
  settings: any;
  schedules: any;
  teams: any;
}

const ScheduleSettings: React.FC<Props> = ({ settings, schedules, teams }) => {
  const [headerImage, setHeaderImage] = useState<any>(
    settings["schedule-header-image"]
      ? settings["schedule-header-image"].value
      : ""
  );
  const [promoImage, setPromoImage] = useState<any>(
    settings["schedule-promo-image"]
      ? settings["schedule-promo-image"].value
      : ""
  );
  const [dashboardBGImage, setDashboardBGImage] = useState<any>(
    settings["schedule-dashboard-background-image"]
      ? settings["schedule-dashboard-background-image"].value
      : ""
  );

  const [ladderBGImage, setLadderBGImage] = useState<any>(
    settings["ladder-dashboard-background-image"]
      ? settings["ladder-dashboard-background-image"].value
      : ""
  );
  const [promoImageURL, setPromoImageURL] = useState<any>(
    settings["schedule-promo-image-URL"]
      ? settings["schedule-promo-image-URL"].value
      : ""
  );
  const [currentMatch, setCurrentMatch] = useState<any>(
    settings["schedule-current-match"]
      ? settings["schedule-current-match"].value
      : ""
  );
  const [currentBGImage, setCurrentBGImage] = useState<any>(
    settings["current-match-dashboard-background-image"]
      ? settings["current-match-dashboard-background-image"].value
      : ""
  );

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  // useEffect(() => {
  // 	console.log(schedules);
  // }, [schedules]);

  useEffect(() => {
    _save("schedule-header-image", headerImage);
    //eslint-disable-next-line
  }, [headerImage]);

  useEffect(() => {
    _save("schedule-dashboard-background-image", dashboardBGImage);
    //eslint-disable-next-line
  }, [dashboardBGImage]);

  useEffect(() => {
    _save("schedule-promo-image", promoImage);
    //eslint-disable-next-line
  }, [promoImage]);

  useEffect(() => {
    _save("current-match-dashboard-background-image", currentBGImage);
    //eslint-disable-next-line
  }, [currentBGImage]);

  useEffect(() => {
    _save("ladder-dashboard-background-image", ladderBGImage);
    //eslint-disable-next-line
  }, [ladderBGImage]);

  useEffect(() => {
    _save("schedule-promo-image-URL", promoImageURL);
    //eslint-disable-next-line
  }, [promoImageURL]);

  useEffect(() => {
    _save("schedule-current-match", currentMatch);
    //eslint-disable-next-line
  }, [currentMatch]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
    else if (e.name === "promoImage") {
      console.log("setting promo image");
      setPromoImage(e.image.fullPath);
    } else if (e.name === "dashboardBGImage")
      setDashboardBGImage(e.image.fullPath);
    else if (e.name === "ladderBGImage") setLadderBGImage(e.image.fullPath);
    else if (e.name === "currentBGImage") setCurrentBGImage(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "promoImageURL") {
      setPromoImageURL(e.target.value);
    }
  };

  const _compareMatch = (o1: any, o2: any) => {
    return o1 === currentMatch;
  };

  const _onSelect = (e: any) => {
    setCurrentMatch(e.target.value);
  };

  return (
    <Fragment>
      <IonTitle className="section-header">Schedule Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Current Match</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="floating">Match</IonLabel>
            <IonSelect
              compareWith={_compareMatch}
              value={currentMatch}
              name="currentMatch"
              onIonChange={_onSelect}
              okText="Okay"
              cancelText="Dismiss"
              placeholder="Select One"
            >
              <IonSelectOption value="">None</IonSelectOption>
              {Object.values(schedules).map((match: any) => (
                <IonSelectOption key={match.id} value={match.id}>
                  {teams[match.awayTeam].name} -{" "}
                  {formatDateTime(match.matchDate)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Schedule Dashboard Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Schedule Background Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="dashboardBGImage"
              id="dashboardBGImage"
              value={dashboardBGImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Ladder Background Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="ladderBGImage"
              id="ladderBGImage"
              value={ladderBGImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Promo Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="promoImage"
              id="promoImage"
              value={promoImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Promo Image Link URL</IonLabel>
            <IonInput
              type="text"
              name="promoImageURL"
              id="promoImageURL"
              value={promoImageURL}
              onIonChange={_onChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              Current Match Background Image
            </IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="currentBGImage"
              id="currentBGImage"
              value={currentBGImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Schedule Page Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Header Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="headerImage"
              id="headerImage"
              value={headerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
  schedules: state.schedules.schedule,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(ScheduleSettings);
