import About from "../pages/Frontend/About";
import Activations from "../pages/Frontend/Activations";
import Activation from "../pages/Frontend/Activations/Activation";
import Dashboard from "../pages/Frontend/Dashboard";
import Feedback from "../pages/Frontend/Feedback";
import Ladders from "../pages/Frontend/Ladders";
import Messages from "../pages/Frontend/Messages";
import News from "../pages/Frontend/News";
import Post from "../pages/Frontend/News/Post";
import Players from "../pages/Frontend/Players";
import Player from "../pages/Frontend/Players/Player";
import Promotions from "../pages/Frontend/Promotions";
import Promotion from "../pages/Frontend/Promotions/Promotion";
import Requests from "../pages/Frontend/Requests";
import ScheduleLadder from "../pages/Frontend/ScheduleLadder";
import Schedules from "../pages/Frontend/Schedules";
import Schedule from "../pages/Frontend/Schedules/Schedule";
import Cart from "../pages/Frontend/Shop/Cart";
import Checkout from "../pages/Frontend/Shop/Checkout";
import Order from "../pages/Frontend/Shop/Order";
import Shop3 from "../pages/Frontend/Shop/Shop3";

import AdminDashboard from "../pages/Admin/Dashboard";
import DashboardActivationsView from "../pages/Admin/Activations/components/DashboardActivationsView";
import AdminSettings from "../pages/Admin/Settings";
import AdminUsers from "../pages/Admin/Users";
import AdminMessages from "../pages/Admin/Messages";
import AdminMessage from "../pages/Admin/Messages/Message";
import MessagesSlideshow from "../pages/Admin/Messages/MessagesSlideshow";
import AdminSlides from "../pages/Admin/Slides";
import AdminSlide from "../pages/Admin/Slides/Slide";
import AdminFeedback from "../pages/Admin/Feedback";
import AdminPlayers from "../pages/Admin/Players";
import AdminPlayer from "../pages/Admin/Players/Player";
import AdminActivations from "../pages/Admin/Activations";
import AdminActivation from "../pages/Admin/Activations/Activation";
import AdminSubmissions from "../pages/Admin/Activations/Activation/Submissions";
import AdminActivationResults from "../pages/Admin/Activations/Activation/Results";
import AdminActivationWinners from "../pages/Admin/Activations/Activation/Winners";
import AdminPromotions from "../pages/Admin/Promotions";
import AdminPromotion from "../pages/Admin/Promotions/Promotion";
import AdminSchedules from "../pages/Admin/Schedules";
import AdminSchedule from "../pages/Admin/Schedules/Schedule";
import AdminTeam from "../pages/Admin/Schedules/Teams/Team";
import AdminLadder from "../pages/Admin/Ladders/Ladder";
import AdminLadders from "../pages/Admin/Ladders";
import AdminProduct from "../pages/Admin/Products/Product";
import AdminProducts from "../pages/Admin/Products";
import AdminOrders from "../pages/Admin/Orders";
import AdminOrder from "../pages/Admin/Orders/Order";
import AdminNotification from "../pages/Admin/Notifications/Notification";
import AdminNotifications from "../pages/Admin/Notifications";
import AdminPost from "../pages/Admin/Posts/Post";
import AdminPosts from "../pages/Admin/Posts";
import AdminRequests from "../pages/Admin/Requests";

import { connect } from "react-redux";

import React from "react";
import { Route } from "react-router";
import DashboardSlidesView from "../pages/Admin/Slides/components/DashboardSlidesView";
import DashboardMessagesView from "../pages/Admin/Messages/components/DashboardMessagesView";
import DashboardOrdersView from "../pages/Admin/Orders/components/DashboardOrdersView";
import DashboardRequestsView from "../pages/Admin/Requests/components/DashboardRequestsView";
import DashboardFeedbackView from "../pages/Admin/Feedback/components/DashboardFeedbackView";
interface Props {}

const InternalRoutes: React.FC<Props> = () => {
	return (
		<>
			<Route path="/dashboard" component={Dashboard} exact />
			<Route path="/players" component={Players} exact />
			<Route path={"/player/:id"} component={Player} exact />
			<Route path="/messages" component={Messages} exact />
			<Route path="/requests" component={Requests} exact />
			<Route path="/feedback" component={Feedback} exact />
			<Route path={"/activations"} component={Activations} exact />
			<Route path={"/activation/:id"} component={Activation} exact />

			<Route path={"/promotions"} component={Promotions} exact />
			<Route path={"/promotion/:id"} component={Promotion} exact />
			<Route path={"/schedules"} component={Schedules} exact />
			<Route path={"/schedule/:id"} component={Schedule} exact />
			<Route path={"/scheduleladder"} component={ScheduleLadder} exact />
			<Route path={"/ladders"} component={Ladders} exact />
			<Route path={"/about"} component={About} exact />
			<Route path={"/shop"} component={Shop3} exact />
			{/* <Route path={"/shop2"} component={Shop2} exact /> */}
			<Route path={"/shop3"} component={Shop3} exact />
			<Route path={"/shop/cart"} component={Cart} exact />
			<Route path={"/shop/checkout"} component={Checkout} exact />
			<Route path={"/shop/order/:id"} component={Order} exact />
			<Route path="/news" component={News} exact />
			<Route path={"/post/:id"} component={Post} exact />

			<Route path="/admin" component={AdminDashboard} exact />
			<Route path="/admin/settings" component={AdminSettings} exact />
			<Route path="/admin/users" component={AdminUsers} exact />
			<Route
				path="/admin/dashboardmessages"
				component={DashboardMessagesView}
				exact
			/>
			<Route path="/admin/messages" component={AdminMessages} exact />
			<Route path="/admin/message/:id" component={AdminMessage} exact />
			<Route
				path="/admin/messages/slideshow"
				component={MessagesSlideshow}
				exact
			/>
			<Route
				path="/admin/dashboardslides"
				component={DashboardSlidesView}
				exact
			/>
			<Route path="/admin/slides" component={AdminSlides} exact />
			<Route path="/admin/slide/:id" component={AdminSlide} exact />
			<Route
				path="/admin/dashboardrequests"
				component={DashboardRequestsView}
				exact
			/>
			<Route path="/admin/requests" component={AdminRequests} exact />
			<Route
				path="/admin/dashboardfeedback"
				component={DashboardFeedbackView}
				exact
			/>
			<Route path="/admin/feedback" component={AdminFeedback} exact />
			<Route path="/admin/players" component={AdminPlayers} exact />
			<Route path="/admin/player/:id" component={AdminPlayer} exact />
			<Route
				path="/admin/dashboardactivations"
				component={DashboardActivationsView}
				exact
			/>
			<Route path="/admin/activations" component={AdminActivations} exact />
			<Route path={"/admin/activation/:id"} component={AdminActivation} exact />
			<Route
				path={"/admin/activation/:id/submissions"}
				component={AdminSubmissions}
				exact
			/>
			<Route
				path={"/admin/activation/:id/results"}
				component={AdminActivationResults}
				exact
			/>
			<Route
				path={"/admin/activation/:id/winners"}
				component={AdminActivationWinners}
				exact
			/>
			<Route path="/admin/promotions" component={AdminPromotions} exact />
			<Route path={"/admin/promotion/:id"} component={AdminPromotion} exact />
			<Route path="/admin/schedules" component={AdminSchedules} exact />
			<Route path={"/admin/team/:id"} component={AdminTeam} exact />
			<Route path={"/admin/schedule/:id"} component={AdminSchedule} exact />
			<Route path={"/admin/ladders"} component={AdminLadders} exact />
			<Route path={"/admin/ladder/:id"} component={AdminLadder} exact />
			<Route path={"/admin/products"} component={AdminProducts} exact />
			<Route path={"/admin/product/:id"} component={AdminProduct} exact />
			<Route
				path={"/admin/dashboardorders"}
				component={DashboardOrdersView}
				exact
			/>
			<Route path={"/admin/orders"} component={AdminOrders} exact />
			<Route path={"/admin/order/:id"} component={AdminOrder} exact />
			<Route
				path={"/admin/notifications"}
				component={AdminNotifications}
				exact
			/>
			<Route
				path={"/admin/notification/:id"}
				component={AdminNotification}
				exact
			/>
			<Route path={"/admin/posts"} component={AdminPosts} exact />
			<Route path={"/admin/post/:id"} component={AdminPost} exact />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(InternalRoutes);
