// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../services/util";

interface Props {
	settings: any;
}

const DefaultSlide: React.FC<Props> = ({ settings }) => {
	const [defaultImage, setDefaultImage] = useState<any>("");

	useEffect(() => {
		if (settings["admin-message-default-slide-image"]) {
			let image = settings["admin-message-default-slide-image"].value;
			getImageURL(image, "full").then((url: any) => {
				setDefaultImage(url);
			});
		}
		//eslint-disable-next-line
	}, [settings]);

	//TODO Make the default output screen slide configurable

	return (
		<Fragment>
			<div
				style={{ backgroundImage: `url(${defaultImage})` }}
				className="slide-wrapper defaultImage"
			>
				<div className="slide"></div>
			</div>
		</Fragment>
	);
};

const mapStateTopProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateTopProps, {})(DefaultSlide);
