import { IonLoading } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

interface Props {
	loading: any;
	message: any;
}

const Loading: React.FC<Props> = ({ loading, message = "Loading..." }) => {
	return <IonLoading isOpen={loading} message={message} spinner="lines" />;
};

const mapStateToProps = (state: any) => ({
	loading: state.loading.loading,
	message: state.loading.message,
});

export default connect(mapStateToProps, {})(Loading);
