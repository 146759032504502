import { IonButton } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import FeaturedPostCard from "./FeaturedPostCard";

interface Props {
	posts: any;
}

const FeaturedPost: React.FC<Props> = ({ posts }) => {
	const [featuredPosts, setPost] = useState<any>("");
	const history = useHistory();

	useEffect(() => {
		if (posts) {
			setPost(
				Object.values(posts).filter((post: any) => post.featured === true)
			);
		}
	}, [posts]);
	return (
		<Fragment>
			{featuredPosts &&
				featuredPosts.map((post: any, idx: any) => (
					<FeaturedPostCard key={idx} post={post} />
				))}
			<p className="center-text">
				<IonButton fill="outline" onClick={() => history.push("/news")}>
					See All News
				</IonButton>
			</p>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	posts: state.news.posts,
});

export default connect(mapStateToProps, {})(FeaturedPost);
