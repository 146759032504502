import {
  IonContent,
  IonImg,
  IonCol,
  IonGrid,
  IonRow,
  IonBadge,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { AppFooter } from "../../../../components/Header/AppFooter";
import {
  convertTimeTo12h,
  formatDateTime,
  getImageURL,
} from "../../../../services/util";
import { AppHeader } from "../../../../components/Header/AppHeader";
import PlayersModule from "../../Players/components/PlayersModule";
// import MatchDetails from "../components/MatchDetails";
import TeamSummary from "../components/TeamSummary";
import AwayTeamRoster from "../components/AwayTeamRoster";

export interface RouteParams {
  id: string;
}

interface Props {
  schedules: any;
  teams: any;
}

const Schedule: React.FC<Props> = ({ schedules, teams }) => {
  const defaultState = {
    active: false,
    matchDate: "",
    matchTime: "",
    homeTeam: "",
    awayTeam: "",
    venue: "",
    ticketsLink: "",
    recapLink: "",
    livestreamLink: "",
    previewLink: "",
    statsLink: "",
    awayScore: "",
    homeScore: "",
    status: "",
    synopsisPre: "",
    synopsisPost: "",
    img: "",
  };

  let { id } = useParams<RouteParams>();
  const [game, setGame] = useState<any>(defaultState);
  const [imageURL, setImageURL] = useState<any>("");
  const [segment, setSegment] = useState<any>("home");

  const {
    //active,
    matchDate,
    matchTime,
    venue,
    homeTeam,
    awayTeam,
    status,
    img,
    oppositionRoster,
  } = game;

  useLayoutEffect(() => {
    if (schedules[id]) {
      setGame({ ...defaultState, ...schedules[id] });
    }
    //eslint-disable-next-line
  }, [id, schedules]);

  useEffect(() => {
    //console.log(img);
    if (img !== "" && imageURL === "") {
      getImageURL(img, "600").then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [img]);

  return (
    <>
      <AppHeader
        title={
          teams[awayTeam]
            ? `${teams[awayTeam].name} @ ${teams[homeTeam].name}`
            : "Match Details"
        }
      />
      <IonContent fullscreen className="ion-padding imageTop match-page">
        {imageURL && (
          <IonImg alt="" src={imageURL} className="full-width-image" />
        )}

        <IonGrid className="ion-no-padding">
          <IonRow>
            {matchDate && matchTime && venue && (
              <IonCol size="8" sizeXs="12" className="ion-no-padding">
                <div className="matchTeams">
                  {teams[awayTeam].name} @ {teams[homeTeam].name}
                </div>
                <div className="spacer5" />
                <div className="matchDate">
                  {formatDateTime(matchDate)} @ {convertTimeTo12h(matchTime)}
                </div>
                <div className="matchVenue">{venue}</div>
              </IonCol>
            )}
            <IonCol
              className="matchStatusCol ion-no-padding"
              size="4"
              sizeXs="12"
            >
              {status === "live" && (
                <IonBadge color="success" className="matchStatus">
                  In Progress
                </IonBadge>
              )}
              {status === "post" && (
                <IonBadge color="danger" className="matchStatus">
                  Complete
                </IonBadge>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="spacer30" />

        {teams[homeTeam] && teams[awayTeam] && (
          <Fragment>
            <TeamSummary match={game} />
            <div className="spacer30" />
            <IonSegment
              onIonChange={(e) => setSegment(e.detail.value)}
              value={segment}
            >
              {/* <IonSegmentButton value="default">Match Details</IonSegmentButton> */}
              <IonSegmentButton value="home">
                {teams[homeTeam].name} Roster
              </IonSegmentButton>
              {oppositionRoster &&
                Object.values(oppositionRoster).length > 0 && (
                  <IonSegmentButton value="away">
                    {teams[awayTeam].name} Roster
                  </IonSegmentButton>
                )}
            </IonSegment>

            {/* {segment === "default" && <MatchDetails match={game} />} */}
            {segment === "home" && <PlayersModule team={teams[homeTeam].id} />}
            {segment === "away" && <AwayTeamRoster match={game} />}
          </Fragment>
        )}
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  schedules: state.schedules.schedule,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(Schedule);
