import { IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	activation: any;
}

const ActivationCard: React.FC<Props> = ({ activation }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");

	useEffect(() => {
		if (activation.cardImage) {
			getImageURL(activation.cardImage).then((url: any) => {
				setImageURL(url);
			});
		} else if (activation.img) {
			getImageURL(activation.img).then((url: any) => {
				setImageURL(url);
			});
		}
	}, [activation]);

	const _linkClick = () => {
		if (activation.status === "active")
			history.push(`/activation/${activation.id}`);
	};

	return (
		<div className="card-wrapper">
			<IonImg
				onClick={() => _linkClick()}
				alt=""
				src={imageURL}
				className={`activationCard ${activation.status} linked`}
			/>
			{/* {activation.status === "active" && (
				<div className="card-button-wrapper">
					<IonButton
						onClick={() => history.push(`/activation/${activation.id}`)}
						disabled={true}
					>
						Find Out More
					</IonButton>
				</div>
			)} */}
		</div>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ActivationCard);
