import {
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonList,
  IonButton,
  IonInput,
  IonTextarea,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonToggle,
  IonIcon,
  IonCardSubtitle,
  IonCard,
  IonCardContent,
  IonRow,
  IonGrid,
  IonCol,
  IonItemGroup,
  IonItemDivider,
  IonCheckbox,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonButtons,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
  addActivation,
  deleteActivation,
  downloadSubmitters,
  // fanActivationAlert,
  setFeaturedActivation,
  updateActivation,
} from "../../../../services/activations";
import { download, trash } from "ionicons/icons";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { getActivations } from "../../../../services/activations/actions";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
  id: string;
}

interface Props {
  subs: any;
  activations: any;
}

const AdminActivation: React.FC<Props> = ({ subs, activations }) => {
  const history = useHistory();

  const optionsInit = {
    olabel: "",
    ovalue: "",
    oid: "_" + Math.random().toString(36).substr(2, 9),
  };

  const defaultState = {
    title: "",
    featured: false,
    status: "draft",
    liveDateTime: "",
    endDateTime: "",
    img: "",
    promoSlide: "",
    cardImage: "",
    shortDesc: "",
    longDesc: "",
    singleEntry: true,
    canChange: false,
    canSeeSubmission: false,
    showResultsOnSubmit: false,
    callToAction: "",
    submitText: "",
    displayFnameField: true,
    displayLnameField: true,
    displayEmailField: true,
    displayPhoneField: true,
    reqFname: false,
    reqLname: false,
    reqEmail: false,
    reqPhone: false,
    type: "select",
    type2: "",
    options: [],
    homeName: "",
    oppositionName: "",
    finalScoreHome: 0,
    finalScoreAway: 0,
    textLabel: "",
    additionalText: false,
    additionalTextReq: false,
    textareaLabel: "",
    additionalTextarea: false,
    additionalTextareaReq: false,
    numberLabel: "",
    additionalNumber: false,
    additionalNumberReq: false,
    resultsBGImg: "",
    resultsHeaderImg: "",
    resultsFooterImg: "",
    winnersBGImg: "",
    winnersHeaderImg: "",
    winnersFooterImg: "",
    resultsLayout: "center",
    canPush: false,
    pushSlideBG: "",
  };

  let { id } = useParams<RouteParams>();
  const [activation, setActivation] = useState<any>(defaultState);
  const [status, setStatus] = useState<any>("draft");
  const [resultsLayout, setResultsLayout] = useState<any>("center");
  const [alertConfirm, setAlertConfirm] = useState<any>(false);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [submissions, setSubmissions] = useState<any>([]);

  const {
    title,
    featured,
    img,
    promoSlide,
    cardImage,
    shortDesc,
    longDesc,
    singleEntry,
    canChange,
    canSeeSubmission,
    callToAction,
    showResultsOnSubmit,
    type,
    // type2,
    submitText,
    displayFnameField,
    displayLnameField,
    displayEmailField,
    displayPhoneField,
    reqFname,
    reqLname,
    reqEmail,
    reqPhone,
    homeName,
    oppositionName,
    finalScoreHome,
    finalScoreAway,
    textLabel,
    additionalText,
    additionalTextReq,
    textareaLabel,
    additionalTextarea,
    additionalTextareaReq,
    numberLabel,
    additionalNumber,
    additionalNumberReq,
    resultsBGImg,
    resultsHeaderImg,
    resultsFooterImg,
    winnersBGImg,
    winnersHeaderImg,
    winnersFooterImg,
    canPush,
    pushSlideBG,
  } = activation;

  useEffect(() => {
    if (activations[id]) {
      setActivation({ ...defaultState, ...activations[id] });
      setStatus(activations[id].status);
      setResultsLayout(activations[id].resultsLayout);
    }
    //eslint-disable-next-line
  }, [id, activations]);

  useEffect(() => {
    if (subs) {
      setSubmissions(
        Object.values(subs).filter((doc: any) => doc.activation === id)
      );
    }
    //eslint-disable-next-line
  }, [id, subs]);

  // useEffect(() => {
  // 	console.log(longDesc);
  // }, [activation]);

  const _onChange = (e: any) => {
    if (["olabel", "ovalue", "oid"].includes(e.target.name)) {
      let options = activation.options;
      options[e.target.dataset.id][e.target.name] = e.target.value;
      if (activation.options !== options) {
        setActivation({ ...activation, options });
      }
    } else {
      setActivation({ ...activation, [e.target.name]: e.target.value });
    }
  };

  const _submit = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();

    // if (activation.name.trim() === "" || activation.pos.trim() === "") {
    // 	setDisableSubmit(false);
    // 	return toast("You must include a message and an image");
    // }
    //console.log(activation.longDesc);
    if (id === "new") {
      addActivation({ ...activation, status, resultsLayout }).then(
        (res: any) => {
          if (res !== false) {
            history.push(`/admin/activation/${res}`);
            toast("Activation saved");
            if (featured) {
              setFeaturedActivation(res);
            }
          }
        }
      );
    } else {
      updateActivation({ ...activation, status, resultsLayout }, id).then(
        (res: any) => {
          if (res) {
            history.push(`/admin/activations`);
            toast("Activation updated");
            if (featured) {
              setFeaturedActivation(res);
            }
          }
        }
      );
    }
    setDisableSubmit(false);
    // if (status === "active") {
    // 	setAlertConfirm(true);
    // }
  };

  const _alertFans = () => {
    // const payload = {
    // 	id,
    // 	title: "Live Now!",
    // 	message: "X activation is live now",
    // 	CTA: "Get into it",
    // };
    // fanActivationAlert(payload);
  };

  const _dismissAlertConfirm = () => {
    setAlertConfirm(false);
  };

  const _back = () => {
    history.push("/admin/activations");
  };

  const _delete = () => {
    if (!activation.downloaded) {
      downloadSubmitters(activation, submissions);
    }
    deleteActivation(id).then(() => {
      getActivations();
      history.push(`/admin/activations`);
    });
  };

  const _addOption = (e: any) => {
    let options = activation.options;
    options.push(optionsInit);
    setActivation({ ...activation, options });
  };

  const _removeOption = (i: number) => {
    let options = activation.options;
    options.splice(i, 1);
    setActivation({ ...activation, options });
  };

  const _onToggle = (option: any) => {
    setActivation({ ...activation, [option]: !activation[option] });
  };

  const _onRadio = (option: any, value: any) => {
    setActivation({ ...activation, [option]: value });
  };

  const _onPhotoChange = (e: any) => {
    setActivation({ ...activation, [e.name]: e.image.fullPath });
  };

  const _compareStatus = (o1: any, o2: any) => {
    return o1 === status;
  };

  const _compareResultsLayout = (o1: any, o2: any) => {
    return o1 === resultsLayout;
  };

  const _onSelect = (e: any) => {
    if (e.target.name === "status") setStatus(e.target.value);
    else if (e.target.name === "resultsLayout")
      setResultsLayout(e.target.value);
    //setActivation({ ...activation, status: e.target.value });
  };

  const _downloadSubmissions = () => {
    downloadSubmitters(activation, submissions);
  };

  return (
    <>
      <AppHeader title={title} />
      <IonContent className="ion-padding admin">
        <IonAlert
          isOpen={alertConfirm}
          onDidDismiss={_dismissAlertConfirm}
          header={"Alert"}
          subHeader={"subtitle"}
          message={"This is an alert message."}
          buttons={[
            {
              text: "Dismiss",
              role: "dismiss",
              cssClass: "secondary",
              handler: () => {
                _dismissAlertConfirm();
              },
            },
            {
              text: "Ok",
              handler: () => {
                _alertFans();
              },
            },
          ]}
        />
        <IonTitle>{id === "new" ? "Add" : "Edit"} Activation </IonTitle>
        <form onSubmit={_submit}>
          <IonButtons>
            <IonButton type="submit" color="success" disabled={disableSubmit}>
              Save
            </IonButton>
            <IonButton onClick={_back} color="warning">
              Cancel
            </IonButton>
            <IonButton onClick={_delete} color="danger">
              <IonIcon icon={trash} slot="start"></IonIcon>
              Delete
            </IonButton>
            <IonButton onClick={_downloadSubmissions} color="success">
              <IonIcon icon={download} slot="start"></IonIcon>
              Download Submissions
            </IonButton>
          </IonButtons>
          <IonItemGroup>
            <IonItem>
              <IonLabel>Status</IonLabel>
              <IonSelect
                compareWith={_compareStatus}
                value={status}
                name="status"
                onIonChange={_onSelect}
                okText="Okay"
                cancelText="Dismiss"
                placeholder="Select One"
              >
                <IonSelectOption value="draft">Draft</IonSelectOption>
                <IonSelectOption value="inactive">Inactive</IonSelectOption>
                <IonSelectOption value="active">Active</IonSelectOption>
                <IonSelectOption value="expired">Expired</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel>Featured?</IonLabel>
              <IonToggle
                checked={featured}
                onClick={() => _onToggle("featured")}
                name="featured"
                id="featured"
              />
            </IonItem>
            <IonItemDivider>
              <IonLabel>ACTIVATION COPY</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel position="floating">Title</IonLabel>
              <IonInput
                type="text"
                id="title"
                name="title"
                value={title}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Header Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="img"
                id="img"
                value={img}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Card Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="cardImage"
                id="cardImage"
                value={cardImage}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Promo Slide Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="promoSlide"
                id="promoSlide"
                value={promoSlide}
                onChange={_onPhotoChange}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Short Description</IonLabel>
              <IonTextarea
                id="shortDesc"
                name="shortDesc"
                value={shortDesc}
                onIonChange={_onChange}
              ></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Long Description</IonLabel>
              <IonTextarea
                id="longDesc"
                name="longDesc"
                value={longDesc}
                onIonChange={_onChange}
                debounce={500}
                enterkeyhint="next"
                inputmode="text"
                rows={12}
              ></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Call To Action</IonLabel>
              <IonInput
                type="text"
                id="callToAction"
                name="callToAction"
                value={callToAction}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Submit Text</IonLabel>
              <IonInput
                type="text"
                id="submitText"
                name="submitText"
                value={submitText}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItemDivider>
              <IonLabel>OTHER SETTINGS</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Single Entry</IonLabel>
              <IonToggle
                checked={singleEntry}
                onClick={() => _onToggle("singleEntry")}
                name="singleEntry"
                id="singleEntry"
              />
            </IonItem>
            {singleEntry && (
              <Fragment>
                <IonItem>
                  <IonLabel>Fan can see their submission?</IonLabel>
                  <IonToggle
                    checked={canSeeSubmission}
                    onClick={() => _onToggle("canSeeSubmission")}
                    name="canSeeSubmission"
                    id="canSeeSubmission"
                  />
                </IonItem>
                <IonItem>
                  <IonLabel>Fan can change submission?</IonLabel>
                  <IonToggle
                    checked={canChange}
                    onClick={() => _onToggle("canChange")}
                    name="canChange"
                    id="canChange"
                  />
                </IonItem>
              </Fragment>
            )}
            <IonItem>
              <IonLabel>Show results on submit?</IonLabel>
              <IonToggle
                checked={showResultsOnSubmit}
                onClick={() => _onToggle("showResultsOnSubmit")}
                name="showResultsOnSubmit"
                id="showResultsOnSubmit"
              />
            </IonItem>
            <IonItem>
              <IonLabel>Can push submissions to output?</IonLabel>
              <IonToggle
                checked={canPush}
                onClick={() => _onToggle("canPush")}
                name="canPush"
                id="canPush"
              />
            </IonItem>
            {canPush && (
              <IonItem>
                <IonLabel position="stacked">Push slide BG</IonLabel>
                <div className="spacer5" />
                <PhotoUploadInput
                  name="pushSlideBG"
                  id="pushSlideBG"
                  value={pushSlideBG}
                  onChange={_onPhotoChange}
                />
              </IonItem>
            )}
            <IonItemDivider>
              <IonLabel>REQUIRED FIELDS</IonLabel>
            </IonItemDivider>
            <IonItemDivider>
              <IonLabel>First Name</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Display First Name Field</IonLabel>
              <IonToggle
                checked={displayFnameField}
                onClick={() => _onToggle("displayFnameField")}
                name="displayFnameField"
                id="displayFnameField"
              />
            </IonItem>
            {displayFnameField && (
              <IonItem>
                <IonLabel>Require First Name Field</IonLabel>
                <IonToggle
                  checked={reqFname}
                  onClick={() => _onToggle("reqFname")}
                  name="reqFname"
                  id="reqFname"
                />
              </IonItem>
            )}
            <IonItemDivider>
              <IonLabel>Last Name</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Display Last Name Field</IonLabel>
              <IonToggle
                checked={displayLnameField}
                onClick={() => _onToggle("displayLnameField")}
                name="displayLnameField"
                id="displayLnameField"
              />
            </IonItem>
            {displayLnameField && (
              <IonItem>
                <IonLabel>Require Last Name Field</IonLabel>
                <IonToggle
                  checked={reqLname}
                  onClick={() => _onToggle("reqLname")}
                  name="reqLname"
                  id="reqLname"
                />
              </IonItem>
            )}
            <IonItemDivider>
              <IonLabel>Email</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Display Email Field</IonLabel>
              <IonToggle
                checked={displayEmailField}
                onClick={() => _onToggle("displayEmailField")}
                name="displayEmailField"
                id="displayEmailField"
              />
            </IonItem>
            {displayEmailField && (
              <IonItem>
                <IonLabel>Require Email Field</IonLabel>
                <IonToggle
                  checked={reqEmail}
                  onClick={() => _onToggle("reqEmail")}
                  name="reqEmail"
                  id="reqEmail"
                />
              </IonItem>
            )}
            <IonItemDivider>
              <IonLabel>Phone</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Display Phone Field</IonLabel>
              <IonToggle
                checked={displayPhoneField}
                onClick={() => _onToggle("displayPhoneField")}
                name="displayPhoneField"
                id="displayPhoneField"
              />
            </IonItem>
            {displayPhoneField && (
              <IonItem>
                <IonLabel>Require Phone Field</IonLabel>
                <IonToggle
                  checked={reqPhone}
                  onClick={() => _onToggle("reqPhone")}
                  name="reqPhone"
                  id="reqPhone"
                />
              </IonItem>
            )}
            <IonItemDivider>
              <IonLabel>ACTIVATION TYPE</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonList>
                <IonRadioGroup
                  value={type}
                  onChange={_onChange}
                  id="type"
                  name="type"
                >
                  <IonListHeader>
                    <IonLabel>Activation Type</IonLabel>
                  </IonListHeader>

                  <IonItem>
                    <IonLabel>Select</IonLabel>
                    <IonRadio
                      slot="start"
                      value="select"
                      onClick={() => _onRadio("type", "select")}
                    />
                  </IonItem>

                  {/* <IonItem>
										<IonLabel>Vote</IonLabel>
										<IonRadio
											slot="start"
											value="vote"
											onClick={() => _onRadio("type", "vote")}
										/>
									</IonItem> */}

                  <IonItem>
                    <IonLabel>Simple</IonLabel>
                    <IonRadio
                      slot="start"
                      value="simple"
                      onClick={() => _onRadio("type", "simple")}
                    />
                  </IonItem>
                  {/* <IonItem>
										<IonLabel>Reorder</IonLabel>
										<IonRadio
											slot="start"
											value="reorder"
											onClick={() => _onRadio("type", "reorder")}
										/>
									</IonItem> */}
                  {/* <IonItem>
										<IonLabel>Quiz/Survey</IonLabel>
										<IonRadio
											slot="start"
											value="quiz"
											onClick={() => _onRadio("type", "quiz")}
										/>
									</IonItem> */}
                  <IonItem>
                    <IonLabel>Pick the Score</IonLabel>
                    <IonRadio
                      slot="start"
                      value="score"
                      onClick={() => _onRadio("type", "score")}
                    />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
            </IonItem>
            {/* {["simple"].includes(type) && (
							<IonItem>
								<IonList>
									<IonRadioGroup
										value={type2}
										onChange={_onChange}
										id="type2"
										name="type2"
									>
										<IonListHeader>
											<IonLabel>Type Variant</IonLabel>
										</IonListHeader>

										<IonItem>
											<IonLabel>None</IonLabel>
											<IonRadio
												slot="start"
												value="none"
												onClick={() => _onRadio("type2", "none")}
											/>
										</IonItem>

										<IonItem>
											<IonLabel>Text</IonLabel>
											<IonRadio
												slot="start"
												value="text"
												onClick={() => _onRadio("type2", "text")}
											/>
										</IonItem>

										<IonItem>
											<IonLabel>Image</IonLabel>
											<IonRadio
												slot="start"
												value="image"
												onClick={() => _onRadio("type2", "image")}
											/>
										</IonItem>

										<IonItem>
											<IonLabel>Reorder</IonLabel>
											<IonRadio
												slot="start"
												value="reorder"
												onClick={() => _onRadio("type2", "reorder")}
											/>
										</IonItem>
									</IonRadioGroup>
								</IonList>
							</IonItem>
						)} */}
            {["simple"].includes(type) && (
              <IonItem>
                <IonList className="full-width">
                  <IonListHeader>
                    <IonLabel>Additional Fields</IonLabel>
                  </IonListHeader>

                  <IonItem>
                    <IonLabel>Text</IonLabel>
                    <IonCheckbox
                      slot="start"
                      checked={additionalText}
                      onClick={() => _onToggle("additionalText")}
                    />{" "}
                  </IonItem>

                  {additionalText && (
                    <Fragment>
                      <IonItem>
                        <IonLabel>Text field required</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={additionalTextReq}
                          onClick={() => _onToggle("additionalTextReq")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">
                          Text Field Label
                        </IonLabel>
                        <IonInput
                          type="text"
                          id="textLabel"
                          name="textLabel"
                          value={textLabel}
                          onIonChange={_onChange}
                        />
                      </IonItem>
                    </Fragment>
                  )}
                  <IonItem>
                    <IonLabel>Textarea</IonLabel>
                    <IonCheckbox
                      slot="start"
                      checked={additionalTextarea}
                      onClick={() => _onToggle("additionalTextarea")}
                    />{" "}
                  </IonItem>

                  {additionalTextarea && (
                    <Fragment>
                      <IonItem>
                        <IonLabel>Textarea required</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={additionalTextareaReq}
                          onClick={() => _onToggle("additionalTextareaReq")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">
                          Textarea Field Label
                        </IonLabel>
                        <IonInput
                          type="text"
                          id="textareaLabel"
                          name="textareaLabel"
                          value={textareaLabel}
                          onIonChange={_onChange}
                        />
                      </IonItem>
                    </Fragment>
                  )}
                  <IonItem>
                    <IonLabel>Number</IonLabel>
                    <IonCheckbox
                      slot="start"
                      checked={additionalNumber}
                      onClick={() => _onToggle("additionalNumber")}
                    />{" "}
                  </IonItem>

                  {additionalNumber && (
                    <Fragment>
                      <IonItem>
                        <IonLabel>Number field required</IonLabel>
                        <IonCheckbox
                          slot="start"
                          checked={additionalNumberReq}
                          onClick={() => _onToggle("additionalNumberReq")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">
                          Number Field Label
                        </IonLabel>
                        <IonInput
                          type="text"
                          id="numberLabel"
                          name="numberLabel"
                          value={numberLabel}
                          onIonChange={_onChange}
                        />
                      </IonItem>
                    </Fragment>
                  )}
                </IonList>
              </IonItem>
            )}
            {type === "select" && (
              <Fragment>
                <IonItemDivider>
                  <IonLabel>OPTIONS</IonLabel>
                </IonItemDivider>

                <IonItem>
                  <IonList className="full-width">
                    {Object.keys(activation.options).map(
                      (option: any, idx: any) => {
                        //console.log(idx);
                        let labelId = `label-${idx}`,
                          // valueId = `value-${idx}`,
                          optionId = `option-${idx}`;
                        return (
                          <Fragment key={idx}>
                            <IonCard className="repeater-card">
                              <IonCardContent>
                                <IonCardSubtitle>
                                  {`Option #${idx + 1}`}
                                </IonCardSubtitle>
                                <IonGrid>
                                  <IonRow>
                                    <IonCol>
                                      <IonItem className="no-padding">
                                        <IonLabel position="floating">
                                          Label
                                        </IonLabel>
                                        <IonInput
                                          type="text"
                                          id={labelId}
                                          name="olabel"
                                          data-id={`${idx}`}
                                          value={
                                            activation.options[`${idx}`].olabel
                                          }
                                          onIonChange={_onChange}
                                        ></IonInput>
                                      </IonItem>
                                      {/* <IonItem>
																					<IonLabel position="floating">
																						Value
																					</IonLabel>
																					<IonInput
																						type="text"
																						id={valueId}
																						name="ovalue"
																						data-id={`${idx}`}
																						value={
																							activation.options[`${idx}`]
																								.ovalue
																						}
																						onIonChange={_onChange}
																					></IonInput>
																				</IonItem> */}

                                      <IonInput
                                        type="text"
                                        id={optionId}
                                        name="oid"
                                        data-id={`${idx}`}
                                        value={activation.options[`${idx}`].oid}
                                        readonly
                                        hidden
                                        onIonChange={_onChange}
                                      ></IonInput>
                                    </IonCol>
                                    <IonCol size="1">
                                      <IonIcon
                                        onClick={() => _removeOption(idx)}
                                        icon={trash}
                                        className="trash linked"
                                      />
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonCardContent>
                            </IonCard>
                          </Fragment>
                        );
                      }
                    )}
                    <IonItem>
                      <IonButton onClick={_addOption}>Add option</IonButton>
                    </IonItem>
                  </IonList>
                </IonItem>
              </Fragment>
            )}
            {type === "score" && (
              <Fragment>
                <IonItem>
                  <IonLabel position="floating">Home Team Name</IonLabel>
                  <IonInput
                    type="text"
                    id="homeName"
                    name="homeName"
                    value={homeName}
                    onIonChange={_onChange}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Opposition Team Name</IonLabel>
                  <IonInput
                    type="text"
                    id="oppositionName"
                    name="oppositionName"
                    value={oppositionName}
                    onIonChange={_onChange}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Home Team Score</IonLabel>
                  <IonInput
                    type="text"
                    id="finalScoreHome"
                    name="finalScoreHome"
                    value={finalScoreHome}
                    onIonChange={_onChange}
                  ></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Away Team Score</IonLabel>
                  <IonInput
                    type="text"
                    id="finalScoreAway"
                    name="finalScoreAway"
                    value={finalScoreAway}
                    onIonChange={_onChange}
                  ></IonInput>
                </IonItem>
              </Fragment>
            )}
            <IonItem>
              <IonLabel position="stacked">Results BG Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="resultsBGImg"
                id="resultsBGImg"
                value={resultsBGImg}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Results Header Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="resultsHeaderImg"
                id="resultsHeaderImg"
                value={resultsHeaderImg}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Results Footer Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="resultsFooterImg"
                id="resultsFooterImg"
                value={resultsFooterImg}
                onChange={_onPhotoChange}
              />
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Winners BG Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="winnersBGImg"
                id="winnersBGImg"
                value={winnersBGImg}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Winners Header Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="winnersHeaderImg"
                id="winnersHeaderImg"
                value={winnersHeaderImg}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Winners Footer Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="winnersFooterImg"
                id="winnersFooterImg"
                value={winnersFooterImg}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel>Results Layout</IonLabel>
              <IonSelect
                compareWith={_compareResultsLayout}
                value={resultsLayout}
                name="resultsLayout"
                onIonChange={_onSelect}
                okText="Okay"
                cancelText="Dismiss"
                placeholder="Select One"
              >
                <IonSelectOption value="left">Left</IonSelectOption>
                <IonSelectOption value="center">Center</IonSelectOption>
                <IonSelectOption value="right">Right</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonItemGroup>
          <IonButtons>
            <IonButton type="submit" color="success" disabled={disableSubmit}>
              Save
            </IonButton>
            <IonButton onClick={_back} color="warning">
              Cancel
            </IonButton>
            <IonButton onClick={_delete} color="danger">
              <IonIcon icon={trash} slot="start"></IonIcon>
              Delete
            </IonButton>
          </IonButtons>
        </form>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  subs: state.activations.submissions,
  activations: state.activations.activations,
});

export default connect(mapStateToProps, {})(AdminActivation);
