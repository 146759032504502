import React, { useEffect, useState } from "react";
import { IonPage, IonSplitPane } from "@ionic/react";
import InternalRoutes from "../routes/internal";

import { connect } from "react-redux";
import AppMenu from "../components/Menu/AppMenu";
import { getImageURL } from "../services/util";

interface Props {
  user: any;
  settings: any;
}

const InternalLayout: React.FC<Props> = ({ user, settings }) => {
  const [appBackgroundColor, setAppBackgroundColor] = useState<any>("");
  const [appBackgroundImage, setAppBackgroundImage] = useState<any>("");

  useEffect(() => {
    if (settings["app-background-color"]) {
      setAppBackgroundColor(settings["app-background-color"].value);
    }
    if (settings["app-background-image"]) {
      getImageURL(settings["app-background-image"].value, "full").then(
        (url: any) => {
          setAppBackgroundImage(url);
        }
      );
    }
  }, [settings]);

  return (
    <IonSplitPane id="split-pane" contentId="main-content" when="xl">
      <AppMenu />
      <IonPage
        id="main-content"
        style={{
          backgroundColor: appBackgroundColor,
          backgroundImage: `url("${appBackgroundImage}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <InternalRoutes />
      </IonPage>
    </IonSplitPane>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
  settings: state.settings,
});

export default connect(mapStateToProps, {})(InternalLayout);
