import { IonContent } from "@ionic/react";
import React from "react";

import { AppHeader } from "../../../components/Header/AppHeader";
import { connect } from "react-redux";
import AdminFooter from "../../../components/Header/AdminFooter";
import RequestsView from "./components/RequestsView";

interface Props {}

const Requests: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Requests" />
			<IonContent fullscreen className="ion-padding admin">
				<RequestsView />
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Requests);
