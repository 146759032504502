// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../../services/util";

interface Props {
	id: any;
	activations: any;
	settings: any;
}

const ActivationPromoSlide: React.FC<Props> = ({
	id,
	activations,
	settings,
}) => {
	const [img, setImage] = useState<any>("");

	useEffect(() => {
		console.log(id);
		console.log(activations[id]);
		if (activations[id]) {
			console.log(activations[id]);
			if (activations[id].promoSlide) {
				getImageURL(activations[id].promoSlide, "1920").then((url: any) => {
					setImage(url);
				});
			} else if (settings["activation-results-bg-image"]) {
				getImageURL(settings["activation-results-bg-image"].value, "1920").then(
					(url: any) => {
						setImage(url);
					}
				);
			}
		}
		//eslint-disable-next-line
	}, [activations, settings, id]);

	return (
		<Fragment>
			{img && (
				<div className="slide-wrapper customImage">
					<div style={{ backgroundImage: `url(${img})` }} className="bg-image">
						<div className="bg-overlay"></div>
					</div>
					<div
						style={{ backgroundImage: `url(${img})` }}
						className="slide"
					></div>
				</div>
			)}
		</Fragment>
	);
};

const mapStateTopProps = (state: any) => ({
	activations: state.activations.activations,
	settings: state.settings,
});

export default connect(mapStateTopProps, {})(ActivationPromoSlide);
