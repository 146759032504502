import {
	IonCardHeader,
	IonCard,
	IonCardContent,
	IonImg,
	IonCardSubtitle,
	IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDateTime, getImageURL } from "../../../../services/util";

interface Props {
	post: any;
}

const PostCard: React.FC<Props> = ({ post }) => {
	const history = useHistory();
	const [image, setImageURL] = useState("");

	const { id, title, img, date } = post;

	useEffect(() => {
		if (img !== "" && image === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [post]);

	return (
		<IonCard
			onClick={() => history.push(`/post/${id}`)}
			button
			className="post-card"
		>
			{image && <IonImg alt={title} src={image} />}
			<IonCardHeader>
				<IonCardSubtitle className="card-date">
					{formatDateTime(date)}
				</IonCardSubtitle>
				<IonCardTitle className="card-title">{title}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				{/* <div
							dangerouslySetInnerHTML={{
								__html: body.concat(excerpt.rendered.substring(0, 200), "..."),
							}}
						/> */}
				<div className="spacer5" />
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PostCard);
