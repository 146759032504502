import { IonContent, IonSegment, IonSegmentButton, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { setCart } from "../../../services/orders";
import { getImageURL, _sortByName } from "../../../services/util";
import Cart from "./components/Cart";
import OrderCard from "./components/OrderCard";
import ProductCard from "./components/ProductCard";

interface Props {
	products: any;
	cart: any;
	orders: any;
	setCart: any;
	settings: any;
}

const Shop3: React.FC<Props> = ({
	products,
	orders,
	cart,
	setCart,
	settings,
}) => {
	const [segment, setSegment] = useState<any>("shop");
	const [shopActive, setShopActive] = useState<any>("");
	const [headerImage, setHeaderImage] = useState<any>("");
	const [footerImage, setFooterImage] = useState<any>("");
	const [footerImageLink, setFooterImageLink] = useState<any>("");
	const [allowedSections, setAllowedSections] = useState<any>([]);

	useEffect(() => {
		if (settings["shop-header-image"]) {
			getImageURL(settings["shop-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
		if (settings["shop-footer-image"]) {
			getImageURL(settings["shop-footer-image"].value).then((url: any) => {
				setFooterImage(url);
			});
		}
		if (settings["shop-footer-image-URL"]) {
			setFooterImageLink(settings["shop-footer-image-URL"].value);
		}
		if (settings["shop-active"]) {
			setShopActive(settings["shop-active"].value);
		}
		if (settings["shop-allowed-sections"]) {
			let sections = Object.values(settings["shop-allowed-sections"].value)
				.map((section: any, idx: any) => {
					if (section === true) {
						return idx + 1;
					} else return "";
				})
				.filter((section: any) => {
					return section !== "";
				});
			setAllowedSections(sections.join(", "));
		}
	}, [settings]);

	const allProducts: Array<any> = Object.values(products)
		.filter((el: any) => el.active === true)
		.sort(_sortByName);
	const food = allProducts.filter((el: any) => el.category === "food");
	const drinks = allProducts.filter((el: any) => el.category === "drinks");

	const _setCartQty = (id: any, qty: any) => {
		setCart({ ...cart, [id]: qty ? qty : 0 });
	};

	const _sortByDate = (a: any, b: any) => {
		if (a.created > b.created) return -1;
		else if (a.created < b.created) return 1;
		else return 0;
	};

	return (
		<>
			<AppHeader title="Food & Drink Delivery" />
			<IonContent fullscreen className="cart-content ion-padding shop-page">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<h1 className="section-header">Food & Drink Delivery</h1>
				<p className="center-text">
					Our fans can now get food and drinks delivered to their seat
					throughout the game!*
				</p>
				<p
					className="center-text"
					style={{ fontSize: "0.8em", lineHeight: "1.2em" }}
				>
					*Food & drink delivery is currently available for section{" "}
					{allowedSections}. Sorry for any inconvenience.
				</p>
				<IonSegment
					onIonChange={(e) => setSegment(e.detail.value)}
					value={segment}
				>
					<IonSegmentButton value="shop">Shop</IonSegmentButton>
					<IonSegmentButton value="orders">My Orders</IonSegmentButton>
				</IonSegment>

				{segment === "shop" && (
					<Fragment>
						{shopActive ? (
							<Fragment>
								{food.length > 0 && (
									<Fragment>
										<h2 className="subsection-header">Food</h2>
										{food.map((item: any, idx: any) => (
											<ProductCard
												key={idx}
												type="list"
												item={item}
												setQuantity={_setCartQty}
												qty={cart[item.id]}
											/>
										))}
									</Fragment>
								)}
								{drinks.length > 0 && (
									<Fragment>
										<h2 className="subsection-header">Drinks</h2>
										{drinks.map((item: any, idx: any) => (
											<ProductCard
												key={idx}
												type="list"
												item={item}
												setQuantity={_setCartQty}
												qty={cart[item.id]}
											/>
										))}
									</Fragment>
								)}
								<h2 className="subsection-header">Summary</h2>
								{products && cart && <Cart />}
							</Fragment>
						) : (
							<Fragment>
								<div className="spacer60" />
								<p className="ion-padding center-text">
									Sorry, the shop is currently closed.
								</p>
								<div className="spacer60" />
							</Fragment>
						)}
					</Fragment>
				)}
				{segment === "orders" && (
					<Fragment>
						{Object.values(orders).filter((order: any) =>
							["processing", "complete"].includes(order.status)
						).length > 0 ? (
							<Fragment>
								{Object.values(orders)
									.sort(_sortByDate)
									.map((order: any, idx: any) => (
										<OrderCard key={idx} order={order} />
									))}
							</Fragment>
						) : (
							<Fragment>
								<div className="spacer60" />
								<p className="ion-padding center-text">No orders</p>
								<div className="spacer60" />
							</Fragment>
						)}
					</Fragment>
				)}
				{footerImage && (
					<Fragment>
						{footerImageLink ? (
							<a href={footerImageLink}>
								<IonImg src={footerImage} className="full-width-image footer" />
							</a>
						) : (
							<IonImg src={footerImage} className="full-width-image footer" />
						)}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
	cart: state.orders.cart,
	orders: state.orders.orders,
	settings: state.settings,
});

export default connect(mapStateToProps, { setCart })(Shop3);
