import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivationCard from "./ActivationCard";

interface Props {
	activations: any;
}

const FeaturedPost: React.FC<Props> = ({ activations }) => {
	const [featuredActivations, setPost] = useState<any>("");

	useEffect(() => {
		if (activations) {
			setPost(
				Object.values(activations).filter(
					(activation: any) =>
						activation.featured === true && activation.status !== "draft"
				)
			);
		}
	}, [activations]);

	return (
		<div id="activations-module-wrapper">
			{featuredActivations.length > 0 && (
				<Fragment>
					{featuredActivations.map((activation: any, idx: any) => (
						<ActivationCard key={idx} activation={activation} />
					))}
					<div className="spacer30" />
				</Fragment>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, {})(FeaturedPost);
