import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonImg,
	IonItemGroup,
	IonItemDivider,
	IonList,
	IonBadge,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	addNotification,
	getNotifications,
	updateNotification,
} from "../../../../services/notifications";

import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { useHistory, useParams } from "react-router-dom";

export interface RouteParams {
	id: string;
}

interface Props {
	notifications: any;
	getNotifications: any;
}

const AdminNotification: React.FC<Props> = ({
	notifications,
	getNotifications,
}) => {
	const history = useHistory();

	const defaultState = {
		title: "",
		status: "draft",
		img: "",
		body: "",
		link: "",
		// recip: [
		// 	"f1pqnx36QB-pDDVPROmheQ:APA91bETnWA8l2ofLxOQa0UIW8yKsm4xM-6ez-_4e0obwY4iWFH0H6CdRo5YzHBmNu48_5-LViBtyvNfjLmqlr_WnL3s7a3iI2VkfJd6zzAsNNv3q7YgqBlCp7ZfKGgYZr_1XFJSqUpe",
		// ],
		recip: ["ezbRVJt9AQgRmvDemO1rei2ykO73"],
	};

	let { id } = useParams<RouteParams>();
	const [message, setMessage] = useState<any>(defaultState);
	const [imageURL, setImageURL] = useState<any>("");
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const { status, img, body, title, link, recip } = message;

	useEffect(() => {
		//console.log(img);
		if (img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	useEffect(() => {
		if (notifications && notifications[id]) {
			setMessage({ ...notifications[id] });
		}
		//eslint-disable-next-line
	}, [id, notifications]);

	const _onChange = (e: any) => {
		setMessage({ ...message, [e.target.name]: e.target.value });
	};

	const _saveDraft = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// if (message.name.trim() === "" || message.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a message and an image");
		// }
		if (id === "new") {
			addNotification(message).then((res: any) => {
				history.push(`/admin/notification/${res}`);
				//setMessage(defaultState);
				if (res !== false) {
					toast("message saved");
				}
			});
		} else {
			updateNotification(message, id).then((res: any) => {
				if (res) {
					toast("message updated");
					getNotifications();
				}
			});
		}
		setDisableSubmit(false);
	};

	const _sendNow = () => {
		setDisableSubmit(true);
		// if (message.name.trim() === "" || message.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a message and an image");
		// }
		let recipients = message.recip;
		if (!Array.isArray(recipients)) {
			recipients = message.recip.split(", ");
		}
		if (id === "new") {
			addNotification({
				...message,
				recip: recipients,
				status: "approved",
			}).then((res: any) => {
				history.push(`/admin/notification/${res}`);
				//setMessage(defaultState);
				if (res !== false) {
					toast("message saved");
				}
			});
		} else {
			updateNotification(
				{ ...message, recip: recipients, status: "approved" },
				id
			).then((res: any) => {
				if (res) {
					toast("message updated");
				}
			});
		}
		getNotifications();
		//change status instantly or retrieve notifications
		setDisableSubmit(false);
	};

	const _back = () => {
		setMessage(defaultState);
		history.push("/admin/notifications");
	};

	const _onPhotoChange = (e: any) => {
		setMessage({ ...message, [e.name]: e.image.fullPath });
	};

	return (
		<>
			<AppHeader title="Notification" />
			<IonContent fullscreen className="ion-padding admin">
				<IonTitle>{id === "new" ? "Add" : "Edit"} notification </IonTitle>
				{status === "draft" ? (
					<form onSubmit={_saveDraft}>
						<IonItemGroup>
							<IonItemDivider>
								<IonLabel>Notification Body</IonLabel>
							</IonItemDivider>
							<IonItem>
								<IonLabel position="floating">Title</IonLabel>
								<IonInput
									type="text"
									id="title"
									name="title"
									value={title}
									onIonChange={_onChange}
								></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Body</IonLabel>
								<IonTextarea
									id="body"
									name="body"
									value={body}
									onIonChange={_onChange}
								></IonTextarea>
							</IonItem>
							<IonItem>
								<IonLabel position="stacked">Header Image</IonLabel>
								<div className="spacer5" />
								<PhotoUploadInput
									name="img"
									id="img"
									value={img}
									onChange={_onPhotoChange}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Link</IonLabel>
								<IonInput
									type="text"
									id="link"
									name="link"
									value={link}
									onIonChange={_onChange}
								></IonInput>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Recipient</IonLabel>
								<IonInput
									type="text"
									id="recip"
									name="recip"
									value={recip}
									onIonChange={_onChange}
								></IonInput>
							</IonItem>
						</IonItemGroup>
						{status === "draft" && (
							<IonButton type="submit" color="primary" disabled={disableSubmit}>
								Save Draft
							</IonButton>
						)}
						<IonButton
							color="primary"
							disabled={disableSubmit || status !== "draft"}
							onClick={_sendNow}
						>
							Send Now
						</IonButton>
						<IonButton onClick={_back} color="danger">
							Back
						</IonButton>
					</form>
				) : (
					<Fragment>
						<IonList>
							<IonItem>
								<IonLabel>Status</IonLabel>
								{status === "draft" && (
									<IonBadge color="danger">Draft</IonBadge>
								)}
								{status === "approved" && (
									<IonBadge color="warning">Sending</IonBadge>
								)}
								{status === "sent" && <IonBadge color="success">Sent</IonBadge>}
							</IonItem>
							<IonItem>
								<IonLabel>Title</IonLabel>
								<p>{title}</p>
							</IonItem>
							<IonItem>
								<IonLabel>Body</IonLabel>
								<p>{body}</p>
							</IonItem>
							<IonItem>
								<IonLabel>Link</IonLabel>
								<p>{link}</p>
							</IonItem>
							{imageURL && (
								<Fragment>
									<IonItem>
										<IonLabel>Image</IonLabel>
									</IonItem>
									<IonItem>
										<IonImg src={imageURL} alt={title} />
									</IonItem>
								</Fragment>
							)}
							<IonItem>
								<IonLabel>Recipient</IonLabel>
								<p>{recip}</p>
							</IonItem>
						</IonList>
						<IonButton onClick={_back} color="danger">
							Back
						</IonButton>
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	notifications: state.notifications,
});

export default connect(mapStateToProps, { getNotifications })(
	AdminNotification
);
