import React from "react";
import {
	IonToolbar,
	IonIcon,
	IonButtons,
	IonButton,
	IonHeader,
	IonMenuButton,
	IonTitle,
} from "@ionic/react";
import { useHistory } from "react-router-dom";

import { arrowBack } from "ionicons/icons";

interface Props {
	title: any;
}

export const AppHeader: React.FC<Props> = ({ title }) => {
	const history = useHistory();

	const _historyBack = () => {
		history.goBack();
	};

	return (
		<IonHeader>
			<IonToolbar color="primary" id="header">
				<IonButtons slot="start">
					{history.location.pathname !== "/dashboard" && (
						<IonButton onClick={_historyBack}>
							<IonIcon icon={arrowBack}></IonIcon>
						</IonButton>
					)}
				</IonButtons>
				<IonTitle>{title}</IonTitle>
				<IonButtons slot="end">
					<IonMenuButton></IonMenuButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	);
};
