import {
	IonContent,
	IonLabel,
	IonItem,
	IonList,
	IonIcon,
	IonButton,
} from "@ionic/react";
import React, { Fragment } from "react";

import { add } from "ionicons/icons";
import { connect } from "react-redux";
import { AppHeader } from "../../../components/Header/AppHeader";
import ProductCard from "./components/ProductCard";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
	products: any;
}

const AdminProducts: React.FC<Props> = ({ products }) => {
	return (
		<>
			<AppHeader title="Products" />
			<IonContent fullscreen className="ion-padding admin">
				<IonList>
					{Object.values(products).length > 0 ? (
						<Fragment>
							{Object.values(products).map((product: any, idx: any) => (
								<ProductCard key={idx} product={product} />
							))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">No products available</IonLabel>
					)}
					<IonItem>
						<IonButton href={"/admin/product/new"} color="primary">
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
});

export default connect(mapStateToProps, {})(AdminProducts);
