//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
// import { idText } from "typescript";
import * as actions from "./actions";

var db = firebase.firestore();

// export async function addSubmission(
// 	activation: string,
// 	selected: string,
// 	submitter: any
// ) {
// 	//console.log("executing database query");

// 	await db
// 		.collection("activationSubmissions")
// 		.doc()
// 		.set(
// 			{
// 				activation,
// 				selected,
// 				submitter,
// 				created: firebase.firestore.FieldValue.serverTimestamp(),
// 			},
// 			{ merge: true }
// 		)
// 		.then(function (res) {
// 			return res;
// 		})
// 		.catch(function (error) {
// 			console.log(error);
// 			return error;
// 		});
// }

// export async function updateSubmission(
// 	submission: string,
// 	selected: string,
// 	submitter: any
// ) {
// 	console.log(submission);
// 	await db
// 		.collection("activationSubmissions")
// 		.doc(submission)
// 		.set(
// 			{
// 				selected,
// 				submitter,
// 				updated: firebase.firestore.FieldValue.serverTimestamp(),
// 			},
// 			{ merge: true }
// 		)
// 		.then(function (res) {
// 			return res;
// 		})
// 		.catch(function (error) {
// 			console.log(error);
// 			return error;
// 		});
// }

// export async function getSubmissions(id: string, uid: string) {
// 	let querySnapshot = await db
// 		.collection("activationSubmissions")
// 		.where("activation", "==", id)
// 		.get();

// 	let data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

// 	return data;
// }

export const getPromotions = () => {
	return (dispatch: any, data: any) => {
		db.collection("promotions")
			.get()
			.then((docs) => {
				const data: any = {};
				docs.forEach((doc) => (data[doc.id] = { ...doc.data(), id: doc.id }));
				dispatch(actions.getPromotionsSuccess(data));
			});
	};
};

export async function getPromotion(id: any) {
	let data;
	await db
		.collection("promotions")
		.doc(id)
		.get()
		.then((doc) => {
			if (doc.exists) {
				data = doc.data();
			}
		})
		.catch((error) => {
			console.log(error);
			return false;
		});

	return data;
}

export async function addPromotion(payload: any) {
	const date = Date.now();
	const today = new Date(date);
	await db
		.collection("promotions")
		.add({ ...payload, created: today.toISOString() })
		.then(function (res) {
			return res.id;
		})
		.catch(function (error) {
			return false;
		});
}

export async function updatePromotion(payload: any, id: string) {
	const date = Date.now();
	const today = new Date(date);

	await db
		.collection("promotions")
		.doc(id)
		.update({ ...payload, updated: today.toISOString() })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});

	return true;
}

export async function deletePromotion(id: any) {
	await db
		.collection("promotions")
		.doc(id)
		.delete()
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}
