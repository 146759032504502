import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getImageURL } from "../../../../../services/util";
import ResultsView from "./ResultsView";
import ScoreResultsView from "./ScoreResultsView";

export interface RouteParams {
	id: string;
}

interface Props {
	id: any;
	settings: any;
	activations: any;
}

const ResultsSlide: React.FC<Props> = ({ id, settings, activations }) => {
	const [BG, setBG] = useState<any>("");
	const [header, setHeader] = useState<any>("");
	const [footer, setFooter] = useState<any>("");

	useEffect(() => {
		if (activations[id]) {
			//Slide background
			if (activations[id].resultsBGImg) {
				getImageURL(activations[id].resultsBGImg, "1920").then((url: any) => {
					setBG(url);
				});
			} else if (activations[id].winnersBGImg) {
				getImageURL(activations[id].winnersBGImg, "1920").then((url: any) => {
					setBG(url);
				});
			} else {
				setBG("");
			}

			//slide header image
			if (activations[id].resultsHeaderImg) {
				getImageURL(activations[id].resultsHeaderImg, "1920").then(
					(url: any) => {
						setHeader(url);
					}
				);
			} else if (activations[id].winnersHeaderImg) {
				getImageURL(activations[id].winnersHeaderImg, "1920").then(
					(url: any) => {
						setHeader(url);
					}
				);
			}

			//slide footer image
			if (activations[id].resultsFooterImg) {
				getImageURL(activations[id].resultsFooterImg, "1920").then(
					(url: any) => {
						setFooter(url);
					}
				);
			} else if (activations[id].winnersFooterImg) {
				getImageURL(activations[id].winnersFooterImg, "1920").then(
					(url: any) => {
						setFooter(url);
					}
				);
			}
		}
	}, [id, activations, settings]);

	return (
		<div
			style={{ backgroundImage: `url(${BG})` }}
			className="slide-wrapper results"
		>
			<div className="slide">
				<div className="content-wrapper">
					<div className="content">
						<div className="ion-padding results-page">
							<div
								className="headerImage"
								style={{ backgroundImage: `url(${header})` }}
							/>
							{activations[id] &&
								["vote", "select"].includes(activations[id].type) && (
									<ResultsView activation={activations[id]} />
								)}
							{activations[id] && activations[id].type === "score" && (
								<ScoreResultsView activation={activations[id]} />
							)}
							<div
								className="footerImage"
								style={{ backgroundImage: `url(${footer})` }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(ResultsSlide);
