import {
	IonBadge,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCol,
	IonIcon,
	IonRow,
	// IonIcon,
	IonToolbar,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";

// import { star, starOutline } from "ionicons/icons";
import { deleteFeedback, markComplete } from "../../../../services/feedback";
import { formatDateTime, getImageURL } from "../../../../services/util";

interface Props {
	feedback: any;
}

const FeedbackCard: React.FC<Props> = ({ feedback }) => {
	const { id, name, email, phoneNumber, img, seat, complete, created } =
		feedback;

	const [imageURL, setImageURL] = useState<any>("");

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [feedback]);

	const _delete = () => {
		deleteFeedback(id);
	};

	return (
		<IonCard>
			<IonCardContent>
				<IonRow>
					<IonCol size="12" sizeMd="4">
						{formatDateTime(created, {})}
					</IonCol>
					<IonCol size="12" sizeMd="4">
						<h1>{name}</h1>
						<div>
							<a href={`mailto:${email}`}>{email}</a>
						</div>
						<div>
							<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
						</div>
						{seat && <div>Seat: {seat}</div>}
					</IonCol>
					<IonCol size="12" sizeMd="4">
						{feedback.body}
						<img alt="" src={imageURL} />
					</IonCol>
				</IonRow>

				<IonToolbar>
					<IonButtons>
						{complete ? (
							<IonBadge color="success">Completed</IonBadge>
						) : (
							<IonBadge color="warning">Incomplete</IonBadge>
						)}
						{complete ? (
							<Fragment>
								{/* <IonIcon icon={star}></IonIcon> */}
								<IonButton onClick={() => markComplete(id, false)}>
									Mark Incomplete
								</IonButton>
							</Fragment>
						) : (
							<Fragment>
								{/* <IonIcon icon={starOutline}></IonIcon> */}
								<IonButton onClick={() => markComplete(id, true)}>
									Mark Complete
								</IonButton>
							</Fragment>
						)}
						<IonButton onClick={_delete}>
							<IonIcon icon={trash} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonCardContent>
		</IonCard>
	);
};

export default FeedbackCard;
