import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonContent,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonSelect,
	IonSelectOption,
	IonPopover,
	IonText,
	IonTextarea,
	IonCheckbox,
	IonListHeader,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { connect } from "react-redux";

import CardForm from "../components/CardForm";
import { updateOrder } from "../../../../services/orders";
import { AppHeader } from "../../../../components/Header/AppHeader";

import { useHistory } from "react-router-dom";
import OrderSummary from "../components/OrderSummary";
import { updateUserDetails } from "../../../../services/users";

interface Props {
	user: any;
	order: any;
	settings: any;
}

const Checkout: React.FC<Props> = ({ user, order, settings }) => {
	const STRIPE_PUBLISHABLE_KEY = settings["shop-stripe-public-key"]
		? settings["shop-stripe-public-key"].value
		: "";
	//eslint-disable-next-line
	const [stripePromise, setStripePromise] = useState(() =>
		loadStripe(STRIPE_PUBLISHABLE_KEY)
	);

	const history = useHistory();
	const defaultProfile = {
		fname: "",
		lname: "",
		email: "",
		phoneNumber: "",
		uid: "",
		seat: {
			section: "",
			row: "",
			number: "",
		},
	};

	const [userDetails, setUserDetails] = useState<any>(defaultProfile);
	const [notes, setOrderNotes] = useState<any>("");
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
	const [seatSection, setSeatSection] = useState<any>("");
	const [seatRow, setSeatRow] = useState<any>("");
	const [seatNumber, setSeatNumber] = useState<any>("");

	const [seatWarningPopover, setSeatWarningPopover] = useState<boolean>(false);
	const [seatWarning, setSeatWarning] = useState<boolean>(false);
	const [saveInfo, setSaveInfo] = useState<any>(true);

	const { fname, lname, email, phoneNumber } = userDetails;
	useEffect(() => {
		if (user.uid !== "") {
			setUserDetails({
				email: user.email ? user.email : "",
				fname: user.fname ? user.fname : "",
				lname: user.lname ? user.lname : "",
				phoneNumber: user.phoneNumber ? user.phoneNumber : "",
				uid: user.uid,
				seat: user.seat ? user.seat : "",
			});

			if (user.seat) {
				setSeatSection(user.seat.section);
				setSeatRow(user.seat.row);
				setSeatNumber(user.seat.number);
			}
		}
		//eslint-disable-next-line
	}, [user]);

	const _checkSeatAllowed = () => {
		if (!_seatAllowed() && seatSection !== "") {
			setSeatWarningPopover(true);
			setSeatWarning(true);
		} else {
			setSeatWarningPopover(false);
			setSeatWarning(false);
		}
	};

	const _seatAllowed = () => {
		if (
			settings["shop-allowed-sections"] &&
			settings["shop-allowed-sections"].value[seatSection] === true
		)
			return true;
		else return false;
	};

	const _isSeatAllowed = (section: any) => {
		if (
			settings["shop-allowed-sections"] &&
			settings["shop-allowed-sections"].value[section] === true
		)
			return true;
		else return false;
	};

	const validateUserDetails = () => {
		if (
			fname !== "" &&
			lname !== "" &&
			email !== "" &&
			phoneNumber !== "" &&
			seatSection !== "" &&
			seatRow !== "" &&
			seatNumber !== "" &&
			_seatAllowed()
		) {
			setSubmitDisabled(false);
			return false;
		} else {
			setSubmitDisabled(true);
			return true;
		}
	};

	const _submit = async () => {
		let seat = { section: seatSection, row: seatRow, number: seatNumber };
		updateOrder(order, { notes, userDetails: { ...userDetails, seat } }).then(
			(res) => {
				if (res === true && saveInfo) {
					updateUserDetails(userDetails.uid, { ...userDetails, seat });
				}
			}
		);
	};

	const _onChange = (e: any) => {
		if (["fname", "lname", "phoneNumber", "email"].includes(e.target.name)) {
			setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
			validateUserDetails();
		} else if (e.target.name === "notes") {
			setOrderNotes(e.target.value);
		}
	};

	useEffect(() => {
		validateUserDetails();
		if (order) {
			let seat = { section: seatSection, row: seatRow, number: seatNumber };
			updateOrder(order, { notes, userDetails: { ...userDetails, seat } });
		}
		//eslint-disable-next-line
	}, [userDetails, seatSection, seatRow, seatNumber, notes]);

	useEffect(() => {
		_checkSeatAllowed();
		//eslint-disable-next-line
	}, [seatSection, seatRow, seatNumber]);

	// useEffect(() => {
	// 	if (user && user.uid) {
	// 		getUserPayments(user.uid);
	// 	}
	// 	//eslint-disable-next-line
	// }, [user.uid]);

	const _onSelect = (e: any) => {
		var name = e.target.name;
		if (name === "seatSection") setSeatSection(e.target.value);
		else if (name === "seatRow") setSeatRow(e.target.value);
		else if (name === "seatNumber") setSeatNumber(e.target.value);
	};

	const _compareSection = (o1: any, o2: any) => {
		return o1 === seatSection;
	};
	const _compareRow = (o1: any, o2: any) => {
		return o1 === seatRow;
	};
	const _compareSeatNumber = (o1: any, o2: any) => {
		return o1 === seatNumber;
	};

	const _onCheckboxChange = (e: any) => {
		if (e.target.name === "saveInfo") {
			setSaveInfo(!saveInfo);
		}
	};

	return (
		<>
			<AppHeader title="Checkout" />
			<IonPopover
				cssClass="checkout popover"
				isOpen={seatWarningPopover}
				onDidDismiss={() => setSeatWarningPopover(false)}
			>
				<IonItem>
					<IonText>
						<h2>Sorry!</h2>
						<p>
							Ordering via the app is currently unavailable for your section
						</p>
					</IonText>
				</IonItem>
				<IonItem button onClick={() => setSeatWarningPopover(false)}>
					DISMISS
				</IonItem>
			</IonPopover>
			{order ? (
				<IonContent className="cart-content checkout">
					{/* <IonButton onClick={() => setSeatWarningPopover(true)}>
						Click this
					</IonButton> */}
					<OrderSummary />
					<IonCard>
						<IonCardContent>
							<IonCardTitle>Order Notes</IonCardTitle>
							<IonList>
								<IonItem>
									<IonTextarea
										id="notes"
										name="notes"
										value={notes}
										onIonChange={_onChange}
										placeholder="Any notes about your order?"
									></IonTextarea>
								</IonItem>
							</IonList>
						</IonCardContent>
					</IonCard>
					<IonCard>
						<IonCardContent>
							<IonCardTitle>Your Details</IonCardTitle>

							<IonList>
								<IonItem>
									<IonLabel position="floating">First Name</IonLabel>
									<IonInput
										type="text"
										id="fname"
										name="fname"
										value={fname}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position="floating">Last Name</IonLabel>
									<IonInput
										type="text"
										id="lname"
										name="lname"
										value={lname}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position="floating">Phone</IonLabel>
									<IonInput
										type="tel"
										id="phoneNumber"
										name="phoneNumber"
										value={phoneNumber}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position="floating">Email</IonLabel>
									<IonInput
										type="email"
										id="email"
										name="email"
										value={email}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
								<IonItem lines="none">
									<IonList lines="none">
										<IonListHeader>Your Seat</IonListHeader>
										<IonItem>
											<IonLabel position="floating">Section</IonLabel>
											<IonSelect
												compareWith={_compareSection}
												value={seatSection}
												name="seatSection"
												onIonChange={_onSelect}
												okText="Okay"
												cancelText="Dismiss"
												placeholder="Section"
											>
												{_isSeatAllowed(1) && (
													<IonSelectOption value="1">1</IonSelectOption>
												)}
												{_isSeatAllowed(2) && (
													<IonSelectOption value="2">2</IonSelectOption>
												)}
												{_isSeatAllowed(3) && (
													<IonSelectOption value="3">3</IonSelectOption>
												)}
												{_isSeatAllowed(4) && (
													<IonSelectOption value="4">4</IonSelectOption>
												)}
												{_isSeatAllowed(5) && (
													<IonSelectOption value="5">5</IonSelectOption>
												)}
												{_isSeatAllowed(6) && (
													<IonSelectOption value="6">6</IonSelectOption>
												)}
												{_isSeatAllowed(7) && (
													<IonSelectOption value="7">7</IonSelectOption>
												)}
												{_isSeatAllowed(8) && (
													<IonSelectOption value="8">8</IonSelectOption>
												)}
												{_isSeatAllowed(9) && (
													<IonSelectOption value="9">9</IonSelectOption>
												)}
												{_isSeatAllowed(10) && (
													<IonSelectOption value="10">10</IonSelectOption>
												)}
												{_isSeatAllowed(11) && (
													<IonSelectOption value="11">11</IonSelectOption>
												)}
												{_isSeatAllowed(12) && (
													<IonSelectOption value="12">12</IonSelectOption>
												)}
											</IonSelect>
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Row</IonLabel>
											<IonSelect
												compareWith={_compareRow}
												value={seatRow}
												name="seatRow"
												onIonChange={_onSelect}
												okText="Okay"
												cancelText="Dismiss"
												placeholder="Row"
											>
												<IonSelectOption value="A">A</IonSelectOption>
												<IonSelectOption value="B">B</IonSelectOption>
												<IonSelectOption value="C">C</IonSelectOption>
												<IonSelectOption value="D">D</IonSelectOption>
												<IonSelectOption value="E">E</IonSelectOption>
												<IonSelectOption value="F">F</IonSelectOption>
												<IonSelectOption value="G">G</IonSelectOption>
												<IonSelectOption value="H">H</IonSelectOption>
												<IonSelectOption value="I">I</IonSelectOption>
												<IonSelectOption value="J">J</IonSelectOption>
											</IonSelect>
										</IonItem>
										<IonItem>
											<IonLabel position="floating">Seat</IonLabel>
											<IonSelect
												compareWith={_compareSeatNumber}
												value={seatNumber}
												name="seatNumber"
												onIonChange={_onSelect}
												okText="Okay"
												cancelText="Dismiss"
												placeholder="Seat"
											>
												<IonSelectOption value="1">1</IonSelectOption>
												<IonSelectOption value="2">2</IonSelectOption>
												<IonSelectOption value="4">4</IonSelectOption>
												<IonSelectOption value="5">5</IonSelectOption>
												<IonSelectOption value="6">6</IonSelectOption>
												<IonSelectOption value="7">7</IonSelectOption>
												<IonSelectOption value="8">8</IonSelectOption>
												<IonSelectOption value="9">9</IonSelectOption>
												<IonSelectOption value="10">10</IonSelectOption>
												<IonSelectOption value="11">11</IonSelectOption>
												<IonSelectOption value="12">12</IonSelectOption>
												<IonSelectOption value="14">14</IonSelectOption>
												<IonSelectOption value="15">15</IonSelectOption>
												<IonSelectOption value="16">16</IonSelectOption>
												<IonSelectOption value="17">17</IonSelectOption>
												<IonSelectOption value="18">18</IonSelectOption>
												<IonSelectOption value="19">19</IonSelectOption>
												<IonSelectOption value="20">20</IonSelectOption>
												<IonSelectOption value="21">21</IonSelectOption>
											</IonSelect>
										</IonItem>
										<IonItem>
											<p>Only sections available for delivery are visible.</p>
											{seatWarning && (
												<span className="seat-error">
													Online ordering is not available for your seat
												</span>
											)}
										</IonItem>
									</IonList>
								</IonItem>

								<IonItem lines="none">
									<IonCheckbox
										slot="start"
										name="saveInfo"
										value={saveInfo}
										checked={saveInfo}
										onIonChange={_onCheckboxChange}
									/>
									<IonLabel>Save these details?</IonLabel>
								</IonItem>
							</IonList>
						</IonCardContent>
					</IonCard>
					{stripePromise && (
						<Elements stripe={stripePromise}>
							<CardForm
								checkoutSubmit={_submit}
								checkoutSubmitDisabled={submitDisabled}
							/>
						</Elements>
					)}
				</IonContent>
			) : (
				<IonContent>
					<div className="page-content">
						<IonCard>
							<IonCardContent>
								<p>Your cart is currently empty</p>
								<IonButton onClick={() => history.push("/shop")} fill="clear">
									Return to shop
								</IonButton>
							</IonCardContent>
						</IonCard>
					</div>
				</IonContent>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user.userDetails,
	order: state.orders.order,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(Checkout);
