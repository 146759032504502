import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteActivationSubmissions,
  downloadSubmitters,
  saveWinners,
  updateActivation,
} from "../../../../../services/activations";
import { pushToScreen } from "../../../../../services/settings";

interface Props {
  subs: any;
  activation: any;
}

const PickWinner: React.FC<Props> = ({ subs, activation }) => {
  const history = useHistory();
  const [submissions, setSubmissions] = useState<any>([]);
  const [winners, setWinners] = useState<any>([]);
  const [scores, setScores] = useState<any>([]);

  const [popoverState, setShowPopover] = useState(false);

  const { id, finalScoreHome, finalScoreAway } = activation;

  useEffect(() => {
    if (activation) {
      setScores({
        finalScoreHome: activation.finalScoreHome,
        finalScoreAway: activation.finalScoreAway,
      });
    }
    //eslint-disable-next-line
  }, [activation, finalScoreHome, finalScoreAway]);

  useEffect(() => {
    if (subs) {
      setSubmissions(
        Object.values(subs).filter((doc: any) => doc.activation === id)
      );
    }
    //eslint-disable-next-line
  }, [id, subs]);

  const _sortByTotalDiffDESC = (a: any, b: any) => {
    return a.total - b.total;
  };

  const _findWinners = () => {
    //create an array of the submissions with the differences calculated
    let results = submissions.map((sub: any, idx: any) => {
      let homeDiff = sub.data.score.homeScore - activation.finalScoreHome;
      let awayDiff = sub.data.score.awayScore - activation.finalScoreAway;
      let differences = {
        id: sub.id,
        home: homeDiff,
        away: awayDiff,
        total: Math.abs(homeDiff) + Math.abs(awayDiff),
      };
      return differences;
    });

    //find the smallest differences
    let minDiff = 0;
    let winningSubs: any = [];
    results.sort(_sortByTotalDiffDESC).forEach((res: any) => {
      if (minDiff === 0) minDiff = res.total;
      if (res.total === minDiff)
        winningSubs.push({
          ...submissions.find((sub: any) => sub.id === res.id),
          results: res,
        });
    });

    //set them as the winners
    setWinners(winningSubs);
  };

  useEffect(() => {
    if (activation) {
      if (activation.winners) {
        setWinners(activation.winners);
      }
    }
  }, [activation]);

  const _remove = (idx: any) => {
    let data = winners;
    data.splice(idx, 1);
    setWinners([...data]);
  };

  const _saveWinners = () => {
    saveWinners(winners, activation.id);
  };

  const _push = (type: any) => {
    pushToScreen({ type, id });
  };

  const _openPopover = (e: any) => {
    setShowPopover(true);
  };

  const _dismissPopover = () => {
    setShowPopover(false);
    setScores({
      finalScoreHome: activation.finalScoreHome,
      finalScoreAway: activation.finalScoreAway,
    });
  };

  const _saveScore = () => {
    updateActivation(scores, id).then(() => {
      setShowPopover(false);
    });
  };

  const _onChange = (e: any) => {
    setScores({ ...scores, [e.target.name]: e.target.value });
  };

  const _export = () => {
    downloadSubmitters(activation, submissions);
  };

  const _deleteSubmissions = () => {
    deleteActivationSubmissions(activation.id);
  };

  return (
    <Fragment>
      <IonModal
        cssClass="my-custom-class"
        isOpen={popoverState}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonRow>
          <IonCol>
            <IonTitle>Set Scores</IonTitle>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Home Team Score</IonLabel>
              <IonInput
                type="number"
                id="finalScoreHome"
                name="finalScoreHome"
                value={scores.finalScoreHome}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Away Team Score</IonLabel>
              <IonInput
                type="number"
                id="finalScoreAway"
                name="finalScoreAway"
                value={scores.finalScoreAway}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButtons>
              <IonButton onClick={_saveScore} color="success">
                Save
              </IonButton>
              <IonButton onClick={_dismissPopover} color="danger">
                Dismiss
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
      </IonModal>
      <IonCard className="winners-wrapper">
        <IonCardContent>
          <h1>Match Score</h1>
          <p>Home: {activation.finalScoreHome}</p>
          <p>Away: {activation.finalScoreAway}</p>
        </IonCardContent>
        <IonRow>
          <IonCol>
            <IonButtons>
              <IonButton onClick={_openPopover}>Edit</IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
      </IonCard>

      <IonCard className="winners-wrapper">
        <IonCardContent>
          <h1>Winners</h1>
          <IonButtons>
            <IonButton onClick={_findWinners}>Find Winners</IonButton>
            <IonButton
              fill="outline"
              onClick={() =>
                history.push(`/admin/activation/${activation.id}/results`)
              }
            >
              Results Views
            </IonButton>
            <IonButton onClick={() => _push("results")}>Push Results</IonButton>
            <IonButton onClick={() => _push("winner")}>Push Winner</IonButton>
            <IonButton onClick={_export}>Export Entries</IonButton>
            <IonButton onClick={_deleteSubmissions}>Delete Entries</IonButton>
          </IonButtons>
          {winners.map((winner: any, idx: any) => (
            <IonCard key={idx}>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    <h2>
                      <b>
                        {winner.submitter.fname} {winner.submitter.lname}
                      </b>
                    </h2>
                    <p>{winner.submitter.phoneNumber}</p>
                    <p>{winner.submitter.email}</p>
                  </IonCol>
                  <IonCol>
                    {winner.results && winner.data.score && (
                      <Fragment>
                        <p>
                          <b>Home:</b>
                          <span>
                            {winner.data.score.homeScore}(
                            {winner.results.home >= 0 && "+"}
                            {winner.results.home})
                          </span>
                        </p>
                        <p>
                          <b>Away:</b>
                          <span>
                            {winner.data.score.awayScore}(
                            {winner.results.away >= 0 && "+"}
                            {winner.results.away})
                          </span>
                        </p>
                        <p>
                          <b>Total:</b>
                          <span>{winner.results.total}</span>
                        </p>
                      </Fragment>
                    )}
                  </IonCol>
                  <IonCol>
                    <IonButton onClick={() => _remove(idx)} fill="outline">
                      <IonIcon icon={close} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          ))}
          {winners !== activation.winners && (
            <IonButton onClick={_saveWinners}>Save Winners</IonButton>
          )}
        </IonCardContent>
      </IonCard>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  subs: state.activations.submissions,
});

export default connect(mapStateToProps, {})(PickWinner);
