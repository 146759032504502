import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import {
  addCircleOutline,
  checkmark,
  closeOutline,
  downloadOutline,
  eyeOffOutline,
  eyeOutline,
  logInOutline,
  removeCircleOutline,
  star,
  trash,
} from "ionicons/icons";
import {
  addToPlayList,
  changeStatus,
  deleteMessage,
  removeFromPlayList,
  setPlayed,
} from "../../../../services/messages";
import { formatDateTime, getImageURL } from "../../../../services/util";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { pushToScreen } from "../../../../services/settings";

interface Props {
  message: any;
  settings: any;
  format?: any;
  index?: any;
}

const AdminMessageCard: React.FC<Props> = ({
  message,
  settings,
  format = "default",
  index = "",
}) => {
  const history = useHistory();
  const [imageURL, setImageURL] = useState<any>("");
  const [downloadURL, setDownloadURL] = useState<any>("");
  const [inPlaylist, setInPlaylist] = useState<any>(false);

  const { id, name, img, body, status, votes, created, played } = message;

  useEffect(() => {
    if (img !== "") {
      getImageURL(img, "600").then((url: any) => {
        setImageURL(url);
      });
      getImageURL(img, "full").then((url: any) => {
        setDownloadURL(url);
      });
    } else {
      setImageURL("");
      setDownloadURL("");
    }
    //eslint-disable-next-line
  }, [img, id]);

  useEffect(() => {
    if (settings["admin-message-playlist-default"]) {
      let playlist = settings["admin-message-playlist-default"].value;
      setInPlaylist(playlist.includes(id));
    }
    //eslint-disable-next-line
  }, [settings]);

  const _changeStatus = (status: string) => {
    changeStatus(id, status);
  };

  const _addToPlayList = () => {
    addToPlayList(id);
    changeStatus(id, "approved");
  };

  const _removeFromPlayList = () => {
    removeFromPlayList(id);
  };

  const _delete = () => {
    if (inPlaylist) {
      removeFromPlayList(id);
    }
    deleteMessage(id);
  };

  const _edit = () => {
    history.push(`/admin/message/${id}`);
  };

  const _push = () => {
    setPlayed(id);
    changeStatus(id, "approved");
    pushToScreen({ type: "message", id });
  };

  return (
    <IonCard className="admin-message-card">
      <IonCardContent>
        <IonRow>
          <IonCol size="12" sizeXl="1">
            {played === true ? (
              <IonBadge color="light">Played</IonBadge>
            ) : (
              <IonBadge color="warning">Unplayed</IonBadge>
            )}
          </IonCol>
          {format === "playlist" && (
            <IonCol size="12" sizeXl="1">
              <span className="playlist-index">{index}</span>
            </IonCol>
          )}
          <IonCol size="12" sizeXl="2">
            <img alt="" src={imageURL} />
          </IonCol>
          <IonCol size="12" sizeXl="5">
            <p>{body}</p>
            <h3>
              <b>{name}</b>
            </h3>
          </IonCol>
          <IonCol size="12" sizeXl="2">
            {formatDateTime(created)}
          </IonCol>
          <IonCol size="12" sizeXl="1">
            <IonIcon icon={star} />
            <IonText>{votes}</IonText>
          </IonCol>
        </IonRow>
      </IonCardContent>
      <IonRow>
        <IonCol size="12" sizeXl="10">
          <IonButtons>
            {status === "approved" && (
              <IonButton
                onClick={() => _changeStatus("pending")}
                color="danger"
              >
                <IonIcon icon={closeOutline} />
                <span className="buttonText hide960">Unapprove</span>
                {/* Unapprove */}
              </IonButton>
            )}
            {status === "pending" && (
              <IonButton
                onClick={() => _changeStatus("approved")}
                color="success"
              >
                <IonIcon icon={checkmark} />
                <span className="buttonText hide960">Approve</span>
              </IonButton>
            )}
            {status === "pending" && (
              <IonButton onClick={() => _changeStatus("hidden")} color="danger">
                <IonIcon icon={eyeOffOutline} />
                <span className="buttonText hide960">Hide</span>
                {/* Hide */}
              </IonButton>
            )}
            {status === "hidden" && (
              <IonButton
                onClick={() => _changeStatus("pending")}
                color="success"
              >
                <IonIcon icon={eyeOutline} />
                <span className="buttonText hide960">Unhide</span>
                {/* UnHide */}
              </IonButton>
            )}
            {inPlaylist ? (
              <IonButton onClick={_removeFromPlayList} color="danger">
                <IonIcon icon={removeCircleOutline} color="danger" />
                <span className="buttonText hide960">Playlist</span>
              </IonButton>
            ) : (
              <IonButton onClick={_addToPlayList} color="success">
                <IonIcon icon={addCircleOutline} />{" "}
                <span className="buttonText hide960">Playlist</span>
              </IonButton>
            )}
            <IonButton onClick={_edit}>
              <span className="buttonText">Edit</span>
            </IonButton>
            {downloadURL && (
              <IonButton href={downloadURL} target="_blank" download="true">
                <IonIcon icon={downloadOutline} />
                <span className="buttonText hide960">Download</span>
                {/* Download */}
              </IonButton>
            )}
            <IonButton onClick={_push} target="_blank" download="true">
              <IonIcon icon={logInOutline} slot="start" />
              <span className="buttonText hide960">Push</span>
            </IonButton>
          </IonButtons>
        </IonCol>
        <IonCol size="12" sizeXl="2">
          <IonButtons>
            <IonButton onClick={_delete} target="_blank" download="true">
              <IonIcon icon={trash} />
              <span className="buttonText hide960">Delete</span>
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

const mapStateTopProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateTopProps, {})(AdminMessageCard);
