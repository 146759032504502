// Import Ionic Components
import {
	IonContent,
	IonButton,
	IonIcon,
	IonRow,
	IonCol,
	IonButtons,
	IonLabel,
	IonCard,
	IonThumbnail,
	IonItem,
	IonCardContent,
} from "@ionic/react";

// Import React Libraries
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
	addOrder,
	getOrders,
	setCurrentOrder,
	setCart,
} from "../../../../services/orders";
import { getUserPayments } from "../../../../services/users";

import { useHistory } from "react-router-dom";
import { trash } from "ionicons/icons";
import { AppHeader } from "../../../../components/Header/AppHeader";

interface Props {
	cart: any;
	products: any;
	user: any;
	setCurrentOrder: any;
	setCart: any;
}

const Cart: React.FC<Props> = ({
	cart,
	products,
	user,
	setCurrentOrder,
	setCart,
}) => {
	const history = useHistory();

	const defaultProfile = {
		fname: "",
		lname: "",
		email: "",
		phoneNumber: "",
		uid: "",
		seat: "",
	};

	const [userDetails, setUserDetails] = useState<any>(defaultProfile);

	useEffect(() => {
		if (user.uid !== "") {
			setUserDetails({
				email: user.email ? user.email : "",
				fname: user.fname ? user.fname : "",
				lname: user.lname ? user.lname : "",
				phoneNumber: user.phoneNumber ? user.phoneNumber : "",
				uid: user.uid,
				seat: "",
			});
		}
		//eslint-disable-next-line
	}, [user]);

	const _cartTotal = () => {
		return Object.entries(cart).reduce(
			(accu: any, curr: any, idx: any, arr: any) => {
				let price = parseFloat(products[curr[0]].price);
				let qty = curr[1];

				return accu + price * qty;
			},
			0
		);
	};

	const _submit = async (e: any) => {
		e.preventDefault();
		var order = await addOrder({
			cart,
			userDetails,
			cartTotal: _cartTotal(),
			status: "pending",
			orderNumber: Math.floor(Math.random() * 899999 + 100000),
		});
		//console.log(order);
		setCurrentOrder(order);
		history.push("/shop/checkout");
	};

	const _addToCart = (id: any) => {
		var currQty = cart[id] >= 0 ? cart[id] : 0;
		setCart({ ...cart, [id]: parseInt(currQty) + 1 });
	};

	const _removeFromCart = (id: any) => {
		var currQty = cart[id] >= 0 ? cart[id] : 0;
		if (currQty > 0) {
			setCart({ ...cart, [id]: parseInt(currQty) - 1 });
		}
	};

	const _onRemove = (id: any) => {
		//console.log(id);
		var currQty = cart[id] >= 0 ? cart[id] : 0;
		if (currQty > 0) {
			setCart({ ...cart, [id]: 0 });
		}
	};

	return (
		<>
			<AppHeader title="Cart" />

			<IonContent className="cart-content">
				{Object.values(cart).length > 0 ? (
					<Fragment>
						{Object.entries(cart).map((item: any, idx: any) => (
							<IonCard key={idx} className="card">
								<IonItem lines="none">
									<IonThumbnail slot="start">
										<img alt="" src={products[item[0]].img} />
									</IonThumbnail>

									<IonLabel>
										<h2>{products[item[0]].name}</h2>
										<div className="div-price">
											{item.dPrice && (
												<p className="para-d-price">
													<del>$ {item.dPrice}</del>
												</p>
											)}
											<p className="para-price">
												${parseFloat(products[item[0]].price).toFixed(2)}
											</p>
										</div>
										<div className="div-btn">
											<IonButton
												className="btn-minus"
												fill="clear"
												onClick={() => _removeFromCart(item[0])}
											>
												<p>-</p>
											</IonButton>
											<p className="para-count">{item[1]}</p>
											<IonButton
												fill="clear"
												className="btn-add"
												onClick={() => _addToCart(item[0])}
											>
												<p>+</p>
											</IonButton>
										</div>
									</IonLabel>
									<IonButtons>
										<IonButton
											onClick={() => _onRemove(item[0])}
											className="div-trash-heart"
										>
											<IonIcon icon={trash}></IonIcon>
										</IonButton>
									</IonButtons>
								</IonItem>
							</IonCard>
						))}
					</Fragment>
				) : (
					<IonCard>
						<IonCardContent>
							<p>Your cart is currently empty</p>
							<IonButton href="/shop" fill="clear">
								Return to shop
							</IonButton>
						</IonCardContent>
					</IonCard>
				)}
				{/* <IonCard className="ion-padding card">
					<IonCardHeader className="ion-no-padding">
						<IonCardSubtitle>
							<h3> List Of Coupon Codes </h3>
						</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<IonItem>
							<IonInput placeholder="coupon codes"></IonInput>
							<IonButton className="coupon-btn" slot="end">
								{" "}
								APPLY
							</IonButton>
						</IonItem>
					</IonCardContent>
				</IonCard> */}

				<IonCard className="price-card card">
					<IonCardContent>
						<IonRow>
							<IonCol size="6">
								<p>Sub Total</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>${_cartTotal().toFixed(2)}</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>Delivery Fee</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>$ 0.00</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>GST (included)</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>${((_cartTotal() / 115) * 15).toFixed(2)}</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>
									<b>Total</b>
								</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>
									<b>${_cartTotal().toFixed(2)}</b>
								</p>
							</IonCol>
						</IonRow>
					</IonCardContent>
				</IonCard>
				<div className="cart-proceed-button">
					<IonButton
						disabled={_cartTotal() === 0}
						onClick={_submit}
						expand="full"
						size="small"
					>
						Proceed To Checkout
					</IonButton>
				</div>
				<IonButton fill="clear" expand="full" href={"/shop"}>
					Continue Shopping
				</IonButton>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	cart: state.orders.cart,
	products: state.products,
	user: state.user.userDetails,
	payments: state.user.payments,
});

export default connect(mapStateToProps, {
	setCart,
	setCurrentOrder,
	getOrders,
	getUserPayments,
})(Cart);
