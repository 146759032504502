//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
import { addSetting, getSettingByName, updateSetting } from "../settings";
import * as actions from "./actions";

var db = firebase.firestore();

export function addMessage(payload: any) {
  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("messages")
    .add({ ...payload, created: today.toISOString() })
    .then(function (res: any) {
      return res.id;
    })
    .catch(function (error: any) {
      return false;
    });
}

export const getMessages = () => {
  return (dispatch: any, data: any) => {
    db.collection("messages").onSnapshot((docs: any) => {
      const data: any = {};
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getMessagesSuccess(data));
    });
  };
};

export const deleteMessage = (id: any) => {
  return db
    .collection("messages")
    .doc(id)
    .delete()
    .then(function (res: any) {
      return res;
    })
    .catch((error: any) => {
      console.log(error);
      //throw error;
    });
};

export const deleteAllMessages = () => {
  db.collection("messages")
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        deleteMessage(doc.id);
      });
    });
};

export async function updateMessage(payload: any, pid: string) {
  //console.log(payload);

  const date = Date.now();
  const today = new Date(date);

  await db
    .collection("messages")
    .doc(pid)
    .update({ ...payload, updated: today.toISOString() })
    .then(function () {
      return true;
    })
    .catch(function (error: any) {
      return false;
    });

  return true;
}

export const upvoteMessage = (id: any) => {
  const increment = firebase.firestore.FieldValue.increment(1);
  return db
    .collection("messages")
    .doc(id)
    .update({ votes: increment })
    .catch(function (error: any) {
      return false;
    });
};

// export const approveMessage = (id: any, approved: any) => {
// 	return db
// 		.collection("messages")
// 		.doc(id)
// 		.update({ approved: approved })
// 		.catch(function (error) {
// 			return false;
// 		});
// };

export const changeStatus = (id: any, status: any) => {
  db.collection("messages").doc(id).update({ status: status });
};

export const setPlayed = (id: any) => {
  db.collection("messages").doc(id).update({ played: true });
};

export const addToPlayList = (id: any, playlist: any = "default") => {
  let settingName = `admin-message-playlist-${playlist}`;

  getSettingByName(settingName).then((data) => {
    if (Object.values(data).length > 0) {
      let playlist = data[settingName].id;
      let value = data[settingName].value;
      if (!value.includes(id)) {
        value.push(id);
      }
      //console.log(value);
      updateSetting(playlist, value);
    } else {
      addSetting(settingName, [id]);
    }
  });
};

export const removeFromPlayList = (id: any, playlist: any = "default") => {
  let settingName = `admin-message-playlist-${playlist}`;

  getSettingByName(settingName).then((data) => {
    if (Object.values(data).length > 0) {
      let playlist = data[settingName].id;
      let value = data[settingName].value;
      if (value.includes(id)) {
        let idx = value.indexOf(id);
        value.splice(idx, 1);
      }
      //console.log(value);
      updateSetting(playlist, value);
    } else {
      addSetting(settingName, [id]);
    }
  });
};

export const hideAllMessages = () => {
  // Get a new write batch
  var batch = db.batch();

  //Get all messages
  db.collection("messages")
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        var data = doc.data();
        if (data.status !== "hidden") {
          batch.update(doc.ref, { status: "hidden" });
        }
      });
      batch.commit().then(() => {
        console.log("batch update complete");
      });
    });
};
