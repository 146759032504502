import { IonButton, IonCard, IonCardContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	settings: any;
}

const MessagesDashboardModule: React.FC<Props> = ({ settings }) => {
	const history = useHistory();
	const [active, setActive] = useState<any>("");
	const [dashboardImage, setDashboardImage] = useState<any>("");

	useEffect(() => {
		if (settings["message-dashboard-image"]) {
			getImageURL(settings["message-dashboard-image"].value).then(
				(url: any) => {
					setDashboardImage(url);
				}
			);
		}
		if (settings["message-active"]) {
			setActive(settings["message-active"].value);
		}
	}, [settings]);

	// useEffect(() => {
	// 	console.log(active);
	// }, [active]);

	const _linkClick = () => {
		if (active) history.push("/messages");
	};

	return (
		<div className="messages-dashboard-section">
			<h1 className="section-header">Get on the big screen</h1>
			{/* <p>
				Send us a photo or a message to put up on the bigscreen! We’ll play them
				throughout the game.
			</p> */}
			<div className="card-wrapper">
				<IonCard
					onClick={() => _linkClick()}
					className={`messagesCard ${active} image-only`}
					button={active}
				>
					{dashboardImage && (
						<IonImg src={dashboardImage} alt="Get on the big screen" />
					)}
					<IonCardContent></IonCardContent>
				</IonCard>
				{active && (
					<div className="card-button-wrapper">
						<IonButton onClick={() => history.push("/messages")}>
							Send your message now!
						</IonButton>
					</div>
				)}
			</div>
			<div className="spacer30" />
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	settings: state.settings,
});

export default connect(mapStateToProps, {})(MessagesDashboardModule);
