import React, { Fragment } from "react";
import { connect } from "react-redux";

export interface RouteParams {
	id: string;
}

interface Props {
	activation: any;
}

const ScoreResultsView: React.FC<Props> = ({ activation }) => {
	const { resultsLayout, winners, oppositionName } = activation;

	return (
		<Fragment>
			{/* <div className="spacer60" /> */}
			<div className={`results-wrapper score layout-${resultsLayout}`}>
				<div className="results">
					{Object.values(winners).length > 0 && (
						<div className="winners-wrapper">
							<div className="winnersTitle">Congratulations</div>
							{Object.values(winners).map((winner: any, idx: any) => (
								<div className="winner" key={idx}>
									<div className="name">
										{winner.submitter.fname} {winner.submitter.lname}
									</div>
									<div className="winnerScore">
										<div className="subtitle">RAMS</div>
										<div className="score">{winner.data.score.homeScore}</div>
									</div>
									<div className="winnerScore">
										<div className="subtitle">{oppositionName}</div>
										<div className="score">{winner.data.score.awayScore}</div>
									</div>
									<div className="winnerScore difference">
										<div className="subtitle">DIFF</div>
										<div className="difference">{winner.results.total}</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ScoreResultsView);
