import {
	GET_ADDTOCART_SUCCESS,
	SET_CART_SUCCESS,
	GET_ORDERS_SUCCESS,
	SET_CURRENT_ORDER_SUCCESS,
	UPDATE_ORDER_STATUS_SUCCESS,
	GET_ADMIN_ORDERS_SUCCESS,
	LOGOUT_USER,
} from "../actionTypes";
const defaultState = {
	cart: {},
	orders: {},
	order: "",
	adminOrders: {},
};

const orders = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_ADDTOCART_SUCCESS:
			return { ...state, cart: payload };
		case SET_CART_SUCCESS:
			return { ...state, cart: payload };
		case GET_ORDERS_SUCCESS:
			return { ...state, orders: payload };
		case SET_CURRENT_ORDER_SUCCESS:
			return { ...state, order: payload };
		case UPDATE_ORDER_STATUS_SUCCESS:
			return state;
		case LOGOUT_USER:
			console.log("orders user logged out");
			return defaultState;
		case GET_ADMIN_ORDERS_SUCCESS:
			return { ...state, adminOrders: payload };
		default:
			return state;
	}
};

export default orders;
