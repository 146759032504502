import { IonContent, IonLabel } from "@ionic/react";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";
import { Fade } from "react-slideshow-image";

import MessageSlide from "./components/MessageSlide";
import { removeFromPlayList } from "../../../services/messages";

interface Props {
	messages: any;
	settings: any;
}

const MessagesSlideshow: React.FC<Props> = ({ messages, settings }) => {
	const slideRef = createRef<Fade>();

	const properties = {
		autoplay: false,
		arrows: false,
	};

	const [slides, setPlaylist] = useState<any>([]);

	const _changeSlide = (e: any) => {
		if (e === "z") _back();
		else if (e === "x" || e === "space") _next();
		else {
			let int = parseInt(e);
			_goTo(int);
		}
	};

	const _goTo = (idx: any) => {
		if (slides[idx]) {
			slideRef.current?.goTo(idx);
		}
	};

	const _back = () => {
		//console.log("back");
		slideRef.current?.goBack();
	};

	const _next = () => {
		//console.log("next");
		slideRef.current?.goNext();
	};

	useEffect(() => {
		// TODO Unsure, but I think this needs to be done differently so all changes are made with 1 update.
		// Currently each change to the playlist would change the playlist, so if slide 1 was removed, the slide
		// in position 1 will change (and may also need removed) but the iterator is now at position 2.
		if (settings["admin-message-playlist-default"]) {
			let playlist = settings["admin-message-playlist-default"].value;
			playlist.forEach((msg: string) => {
				if (!messages[msg]) {
					removeFromPlayList(msg);
				}
			});
			setPlaylist(playlist);
		}
		//eslint-disable-next-line
	}, [settings]);

	return (
		<>
			<IonContent>
				<div className="fullpage-slider">
					<Hotkeys
						keyName="1,2,3,4,5,6,7,8,9,0,z,x,space"
						onKeyDown={_changeSlide}
					/>
					{slides && (
						<div className="slider-container">
							<Fade ref={slideRef} {...properties} className="slider">
								{messages && slides && slides.length > 0 ? (
									slides.map((msg: any, idx: any) => (
										<MessageSlide key={idx} message={messages[msg]} />
									))
								) : (
									<IonLabel className="ion-padding">No messages</IonLabel>
								)}
							</Fade>
						</div>
					)}
				</div>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(MessagesSlideshow);
