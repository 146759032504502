import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonTitle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
  settings: any;
}

const GeneralSettings: React.FC<Props> = ({ settings }) => {
  const [appBackgroundColor, setAppBackgroundColor] = useState<any>(
    settings["app-background-color"]
      ? settings["app-background-color"].value
      : ""
  );
  const [appBackgroundImage, setAppBackgroundImage] = useState<any>(
    settings["app-background-image"]
      ? settings["app-background-image"].value
      : ""
  );
  //   const [footerImage, setFooterImage] = useState<any>(
  //     settings["dashboard-footer-image"]
  //       ? settings["dashboard-footer-image"].value
  //       : ""
  //   );
  //   const [footerImageURL, setFooterImageURL] = useState<any>(
  //     settings["dashboard-footer-image-URL"]
  //       ? settings["dashboard-footer-image-URL"].value
  //       : ""
  //   );

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  useEffect(() => {
    _save("app-background-color", appBackgroundColor);
    //eslint-disable-next-line
  }, [appBackgroundColor]);

  useEffect(() => {
    _save("app-background-image", appBackgroundImage);
    //eslint-disable-next-line
  }, [appBackgroundImage]);

  //   useEffect(() => {
  //     _save("dashboard-footer-image", footerImage);
  //     //eslint-disable-next-line
  //   }, [footerImage]);

  //   useEffect(() => {
  //     _save("dashboard-footer-image-URL", footerImageURL);
  //     //eslint-disable-next-line
  //   }, [footerImageURL]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "appBackgroundImage")
      setAppBackgroundImage(e.image.fullPath);
    // else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "appBackgroundColor") {
      setAppBackgroundColor(e.target.value);
    }
  };

  return (
    <Fragment>
      <IonTitle className="section-header">General App Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>App Background</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">App Background Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="appBackgroundImage"
              id="appBackgroundImage"
              value={appBackgroundImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">App Background Color</IonLabel>
            <IonInput
              type="text"
              name="appBackgroundColor"
              id="appBackgroundColor"
              value={appBackgroundColor}
              onIonChange={_onChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(GeneralSettings);
