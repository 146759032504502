import { IonLabel } from "@ionic/react";
import React from "react";

import MessageCard from "./MessageCard";
import { connect } from "react-redux";

interface Props {
	messages: any;
}

const MessageFeed: React.FC<Props> = ({ messages }) => {
	const data = Object.values(messages).filter(
		(msg: any) => msg.status === "approved"
	);

	return (
		<div className="message-feed">
			{messages && data.length > 0 ? (
				data.map((msg: any, idx: any) => (
					<MessageCard key={idx} message={msg} />
				))
			) : (
				<IonLabel className="ion-padding">No messages available</IonLabel>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
});

export default connect(mapStateToProps, {})(MessageFeed);
