import {
	IonItem,
	IonImg,
	IonBadge,
	IonCard,
	IonCardContent,
	IonTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	promotion: any;
}

const PromotionCard: React.FC<Props> = ({ promotion }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");
	const { img } = promotion;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [promotion]);

	return (
		<IonCard
			button
			onClick={() => history.push(`/admin/promotion/${promotion.id}`)}
		>
			{imageURL && <IonImg alt="" src={imageURL} />}
			<IonCardContent>
				<IonItem className="ion-no-padding" lines="none">
					<IonTitle>{promotion.title}</IonTitle>
					{promotion.active ? (
						<IonBadge color="success">Active</IonBadge>
					) : (
						<IonBadge color="light">Inactive</IonBadge>
					)}
				</IonItem>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PromotionCard);
