import { IonLabel, IonList } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { _sortPlayerByNumber } from "../../../../services/util";
import PlayerCard from "./PlayerCard";

interface Props {
  team: any;
  players: any;
}

const PlayersModule: React.FC<Props> = ({ team, players }) => {
  const [filteredPlayers, setFilteredPlayers] = useState<any>([]);

  useEffect(() => {
    if (players && team) {
      setFilteredPlayers(
        Object.values(players).filter((player: any) => player.team === team)
      );
    }
    //eslint-disable-next-line
  }, [players, team]);

  return (
    <IonList lines="none" className="player_cards">
      {filteredPlayers ? (
        Object.values(filteredPlayers)
          .sort(_sortPlayerByNumber)
          .map((player: any) => <PlayerCard key={player.id} player={player} />)
      ) : (
        <IonLabel className="ion-padding">No users available</IonLabel>
      )}
    </IonList>
  );
};

const mapStateToProps = (state: any) => ({ players: state.players });

export default connect(mapStateToProps, {})(PlayersModule);
