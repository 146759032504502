import firebase from "firebase/app";
import "firebase/firestore";
import { getCurrentUser } from "../auth";
import * as actions from "./actions";

var db = firebase.firestore();

export const setUser = (payload: any) => {
	return (dispatch: any) => {
		getUser(payload.uid).then((user) => {
			if (user) {
				dispatch(
					actions.setCurrentUser({
						...user,
						emailVerified: payload.emailVerified,
						isAnonymous: payload.isAnonymous,
						uid: payload.uid,
					})
				);
			}
		});
	};
};

export const checkLoggedIn = () => {
	return (dispatch: any) => {
		getCurrentUser().then((user) => {
			if (user) {
				dispatch(actions.setLoggedIn(true));
			} else {
				dispatch(actions.setLoggedIn(false));
			}
		});
	};
};

export const logoutUser = () => {
	return (dispatch: any) => {
		dispatch(actions.logUserOut());
	};
};

export const loginUserUpdate = (user: any) => {
	const date = Date.now();
	const today = new Date(date);
	db.collection("users")
		.doc(user.uid)
		.set({ lastlogin: today.toISOString() }, { merge: true })
		.catch(function (error) {
			console.error("Error writing document: ", error);
		});
};

export const getUser = (uid: any) => {
	return db
		.collection("users")
		.doc(uid)
		.get()
		.then((doc) => {
			if (doc.exists) {
				return doc.data();
			}
		});
};

export const monitorUser = (uid: any) => {
	return (dispatch: any) => {
		db.collection("users")
			.doc(uid)
			.onSnapshot((doc) => {
				if (doc.exists) {
					dispatch(actions.monitorUserSuccess({ ...doc.data(), uid: doc.id }));
					return { ...doc.data(), uid: doc.id };
				}
			});
	};
};

export const updateUser = (payload: any) => {
	// Called when new user logins in.
	//TODO this should be refactored. We need to be able to update the user without updating last login etc.

	const date = Date.now();
	const today = new Date(date);
	const profile = payload.additionalUserInfo.profile;
	const uid = payload.user.uid;
	const providerId = payload.additionalUserInfo.providerId;
	let userData = {};

	db.collection("users")
		.doc(uid)
		.get()
		.then((doc) => {
			/* If user exists */
			if (doc.data()) {
				userData = {
					lastlogin: today.toISOString(),
				};
			} else {
				/* User does not already exist - First login */
				if (providerId === "password") {
					const displayName = payload.user.email.split("@")[0];
					userData = {
						email: payload.user.email,
						lname: "",
						fname: "",
						avatar: "",
						phoneNumber: "",
						emailVerified: false,
						displayName: displayName,
						provider: providerId,
						created: today.toISOString(),
					};
				} else if (providerId === "google.com") {
					userData = {
						email: profile.email,
						lname: profile.family_name,
						fname: profile.given_name,
						avatar: profile.picture,
						emailVerified: profile.email_verified,
						displayName: profile.given_name,
						provider: providerId,
						created: today.toISOString(),
					};
				} else if (providerId === "facebook.com") {
					userData = {
						email: profile.email,
						lname: profile.last_name,
						fname: profile.first_name,
						avatar: profile.picture.data.url ? profile.picture.data.url : "",
						emailVerified: "",
						displayName: profile.name,
						provider: providerId,
						created: today.toISOString(),
					};
				} else if (providerId === "apple.com") {
					userData = {
						email: profile.email,
						lname: "",
						fname: "",
						avatar: "",
						emailVerified: profile.email_verified,
						displayName: "",
						provider: providerId,
						created: today.toISOString(),
					};
					console.log(userData);
				} else if (providerId === null) {
					userData = {
						email: "",
						lname: "",
						fname: "",
						avatar: "",
						emailVerified: "",
						displayName: "",
						provider: "anon",
						created: today.toISOString(),
					};
				}
			}
			db.collection("users")
				.doc(uid)
				.set(userData, { merge: true })
				.catch(function (error) {
					console.error("Error writing document: ", error);
				});
		})
		.catch((error) => {
			console.log(error);
		});

	return (dispatch: any) => {
		dispatch(actions.addUser(payload));
	};
};

export const getUsers = () => {
	return db
		.collection("users")
		.get()
		.then((docs: any) => {
			const postData: any = [];
			docs.forEach((doc: any) => postData.push({ ...doc.data(), id: doc.id }));
			return postData;
		});
};

export const setAdminUser = async (uid: string, isAdmin: boolean) => {
	await db
		.collection("users")
		.doc(uid)
		.update({ isAdmin: isAdmin })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});

	return true;
};

/**
 * Get all payment methods for the logged in customer
 */
export const getPaymentMethods = (uid: string) => {
	return (dispatch: any) => {
		const methods: any = {};
		db.collection("users")
			.doc(uid)
			.collection("payment_methods")
			.onSnapshot((snapshot) => {
				if (snapshot.empty) {
					//document.querySelector('#add-new-card').open = true;
				}
				snapshot.forEach(function (doc: any) {
					const paymentMethod = doc.data();
					if (!paymentMethod.card) {
						return;
					} else {
						methods[doc.id] = { ...doc.data() };
					}
				});
				dispatch(actions.getPaymentMethodsSuccess(methods));
			});
	};
};

/**
 * Get all payments for the logged in customer
 */
export const getUserPayments = (uid: any) => {
	return (dispatch: any) => {
		const payments: any = {};
		db.collection("users")
			.doc(uid)
			.collection("payments")
			.onSnapshot(
				(snapshot) => {
					snapshot.forEach(
						(doc) =>
							(payments[doc.data().order] = { ...doc.data(), id: doc.id })
					);

					dispatch(actions.getPaymentsSuccess(payments));
				},
				(error) => {
					console.log(error);
				}
			);
	};
};

/**
 * Get all votes for the logged in customer
 */
export const getUserVotes = (uid: any) => {
	return (dispatch: any) => {
		const votes: any = {};
		db.collection("users")
			.doc(uid)
			.collection("votes")
			.onSnapshot(
				(snapshot) => {
					snapshot.forEach(
						(doc) => (votes[doc.id] = { ...doc.data(), id: doc.id })
					);

					dispatch(actions.getVotesSuccess(votes));
				},
				(error) => {
					console.log(error);
				}
			);
	};
};

export const addMessageVote = (id: any) => {
	return getCurrentUser().then((user: any) => {
		if (user) {
			return db
				.collection("users")
				.doc(user.uid)
				.collection("votes")
				.doc(id)
				.set({ status: true }, { merge: true });
			// .then(() => {
			// 	console.log("returned");
			// });
		}
	});
};

/**
 * Get all notifications for the logged in customer
 */
export const getUserNotifications = (uid: string) => {
	return (dispatch: any) => {
		const notifications: any = {};
		db.collection("users")
			.doc(uid)
			.collection("notifications")
			.onSnapshot((snapshot) => {
				snapshot.forEach(function (doc: any) {
					notifications[doc.id] = { ...doc.data(), id: doc.id };
				});
				dispatch(actions.getUserNotificationsSuccess(notifications));
			});
	};
};

export const addUserNotifications = (nid: string, payload: any) => {
	db.collection("users")
		.get()
		.then((res) => {
			res.forEach((user: any) => {
				if (payload.recip.includes(user.id) || payload.recip.length === 0) {
					addUserNotification(user.id, nid);
				}
			});
		});
};

export const addUserNotification = (uid: string, nid: string) => {
	// return (dispatch: any) => {
	//const notifications: any = {};
	db.collection("users")
		.doc(uid)
		.collection("notifications")
		.doc(nid)
		.set({ read: false });
};

export const removeUserNotification = (uid: string, nid: string) => {
	//return (dispatch: any) => {
	db.collection("users").doc(uid).collection("notifications").doc(nid).delete();
	// 	dispatch(actions.getUserNotificationsSuccess(notifications));
	// });
};

export const setUserNotificationStatus = (
	uid: any,
	nid: any,
	status: boolean
) => {
	db.collection("users")
		.doc(uid)
		.collection("notifications")
		.doc(nid)
		.update({ read: status });
};

export const saveUserDisplayInfo = (id: any, userDetails: any) => {
	return db
		.collection("users")
		.doc(id)
		.update({ persona: userDetails })
		.then((res: any) => {
			return true;
		})
		.catch((error) => {
			console.error(error);
			throw error;
		});
};

export const updateUserDetails = (id: any, userDetails: any) => {
	const { fname, lname, email, phoneNumber, seat } = userDetails;
	return db
		.collection("users")
		.doc(id)
		.update({ fname, lname, email, phoneNumber, seat })
		.then((res: any) => {
			return true;
		})
		.catch((error) => {
			console.error(error);
			throw error;
		});
};
