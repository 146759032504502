import React from "react";
import { Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import Loading from "../components/Loading";

import Home from "../pages/Frontend/Auth";
import Login from "../pages/Frontend/Auth/Login";
import Register from "../pages/Frontend/Auth/Register";
import ForgotPassword from "../pages/Frontend/Auth/ForgotPassword";
import NotificationPopOver from "../pages/Frontend/Notifications/components/NotificationPopOver";

import OutputScreen from "../pages/Admin/OutputScreen";
// import AdminLayout from "../layouts/Admin";
// import FrontendLayout from "../layouts/Frontend";
import InternalLayout from "../layouts/Internal";

interface Props {}

const RoutingSystem: React.FC<Props> = () => {
	// const [loggedIn, setLoggedIn] = useState<any>(true);

	// useEffect(() => {
	// 	getCurrentUser().then((userData: any) => {
	// 		if (userData) {
	// 			setLoggedIn(true);
	// 		} else setLoggedIn(false);

	// 		firebase.auth().onAuthStateChanged(function (userData) {
	// 			if (userData) {
	// 				setLoggedIn(true);
	// 			} else {
	// 				setLoggedIn(false);
	// 			}
	// 		});
	// 	});
	// }, []);

	return (
		<IonReactRouter>
			<Loading />
			<NotificationPopOver />
			<IonRouterOutlet id="main-content">
				<Route path="/" component={Home} exact />
				<Route path="/login" component={Login} exact />
				<Route path="/register" component={Register} exact />
				<Route path="/forgotpassword" component={ForgotPassword} exact />
				{/* <Route path="/admin" component={AdminLayout} /> */}
				<InternalLayout />
				<Route path={"/admin/output"} component={OutputScreen} exact />
			</IonRouterOutlet>
		</IonReactRouter>
	);
};

export default RoutingSystem;
