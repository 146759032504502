import {
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonList,
  IonTextarea,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

// custom hook that will upload to firebase
import { toast } from "../../../../components/Toast/toast";
import { getCurrentUser } from "../../../../services/auth";
import { addFeedback } from "../../../../services/feedback";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { connect } from "react-redux";

interface Props {
  user: any;
}

const SendFeedback: React.FC<Props> = ({ user }) => {
  const defaultState = {
    name: "",
    email: "",
    phoneNumber: "",
    seat: "",
    uid: "guest",
    body: "",
    img: "",
    user: {},
    complete: false,
  };

  //const [userDetails, setUserDetails] = useState<any>(defaultProfile);
  const [message, setMessage] = useState<any>(defaultState);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState<boolean>(false);

  const { name, email, phoneNumber, body, img, seat } = message;

  useEffect(() => {
    if (user.uid !== "") {
      setMessage({
        email: user.email ? user.email : "",
        name: user.fname && user.lname ? `${user.fname} ${user.lname}` : "",
        phoneNumber: user.phoneNumber ? user.phoneNumber : "",
        uid: user.uid,
        seat: user.seat
          ? `${user.seat.section}${user.seat.row}${user.seat.number}`
          : "",
      });
    }
    //eslint-disable-next-line
  }, [user]);

  const _onChange = (e: any) => {
    setMessage({ ...message, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getCurrentUser().then((user: any) => {
      setMessage({
        ...message,
        uid: user.uid,
        user: {
          name: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photo: user.photoURL,
        },
      });
    });
    //eslint-disable-next-line
  }, []);

  const submitMessage = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();
    if (body.trim() === "") {
      return toast("You must include a message");
    }
    addFeedback(message).then((res: any) => {
      if (res) {
        toast("Your message has been sent");
        setMessageSuccess(true);
      }
    });
  };

  const _onPhotoChange = (e: any) => {
    setMessage({ ...message, [e.name]: e.image.fullPath });
  };

  return (
    <Fragment>
      {!messageSuccess ? (
        <div>
          <h3>Tell us how we're doing</h3>
          <p>
            Please feel free to tell us what you think. Maybe you'd like to tell
            us about:
          </p>
          <ul>
            <li>something you think we could be doing better</li>
            <li>a bug in the app</li>
            <li>an issue at one of our events</li>
            <li>
              an idea you have to make the Rams' game experience even better
            </li>
            <li>how we're doing a great job</li>
            <li>you'd like your data deleted from our system</li>
          </ul>
          <p>
            Whatever it is, we'd love to hear from you. If you'd like to hear
            back from us, please include your contact details.
          </p>
          {/* get loading information from hook and display progress if necessary */}

          <form onSubmit={submitMessage}>
            <IonList>
              <PhotoUploadInput
                name="img"
                id="img"
                value={img}
                onChange={_onPhotoChange}
              />
              <IonItem>
                <IonLabel position="floating">Your Name</IonLabel>
                <IonInput
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onIonChange={_onChange}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Your Email</IonLabel>
                <IonInput
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onIonChange={_onChange}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Your Phone Number</IonLabel>
                <IonInput
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  value={phoneNumber}
                  onIonChange={_onChange}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Your Seat</IonLabel>
                <IonInput
                  id="seat"
                  name="seat"
                  type="text"
                  value={seat}
                  onIonChange={_onChange}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Your Message</IonLabel>
                <IonTextarea
                  id="body"
                  name="body"
                  value={body}
                  onIonChange={_onChange}
                />
              </IonItem>
            </IonList>
            <IonButton
              disabled={disableSubmit}
              className="ion-margin-top"
              type="submit"
            >
              Send
            </IonButton>
          </form>
        </div>
      ) : (
        <p>
          Thanks for sending in your message. We take all feedback from our fans
          seriously. If you've provided your contact details, we may be in touch
          if we need to clarify any details.
        </p>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
});

export default connect(mapStateToProps, {})(SendFeedback);
