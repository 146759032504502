import { IonImg, IonThumbnail } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getImageURL } from "../../../../../services/util";

interface Props {
	team: any;
}

const TeamIcon: React.FC<Props> = ({ team }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const { img } = team;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [team]);

	return (
		<IonThumbnail className={`center-image`}>
			<IonImg alt="" src={imageURL} />
		</IonThumbnail>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(TeamIcon);
