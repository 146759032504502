import {
	IonCardHeader,
	IonCard,
	IonCardContent,
	IonImg,
	IonCardSubtitle,
	IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatDateTime, getImageURL } from "../../../../services/util";

interface Props {
	post: any;
}

const PostCard: React.FC<Props> = ({ post }) => {
	const history = useHistory();
	const [image, setImageURL] = useState("");

	const { id, title, img, date, excerpt } = post;

	useEffect(() => {
		if (img !== "" && image === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [post]);

	return (
		<IonCard
			id="featured-post-card"
			onClick={() => history.push(`/post/${id}`)}
			button
			className="post-card"
		>
			{image && <IonImg alt={title} src={image} />}
			<IonCardHeader>
				<IonCardSubtitle>{formatDateTime(date)}</IonCardSubtitle>
				<IonCardTitle>{title}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				<p>{excerpt}</p>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PostCard);
