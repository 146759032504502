import {
	IonContent,
	IonLabel,
	IonItem,
	IonItemGroup,
	IonList,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getOrder } from "../../../../services/orders";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";

export interface RouteParams {
	id: string;
}

const Order: React.FC = () => {
	let { id } = useParams<RouteParams>();
	const [order, setOrder] = useState<any>({});

	const { userDetails, orderNumber, cartTotal, status, created } = order;

	async function getOrderData(id: any) {
		const data = await getOrder(id);
		setOrder(data);
	}

	useEffect(() => {
		getOrderData(id);
	}, [id]);

	// useEffect(() => {
	// 	console.log(order);
	// }, [order]);

	const orderDate = () => {
		var options: any = { year: "numeric", month: "long", day: "numeric" };
		var date = new Date(created);
		return (
			date.toLocaleDateString("en-NZ", options) +
			" - " +
			date.toLocaleTimeString("en-NZ")
		);
	};

	return (
		<>
			<AppHeader title={`Order ${orderNumber}`} />
			<IonContent fullscreen className="ion-padding">
				{order && userDetails && (
					<IonItemGroup>
						<IonItem>
							<IonLabel>Order #</IonLabel>
							<p>{orderNumber}</p>
						</IonItem>
						{userDetails.fname && (
							<IonItem>
								<IonLabel>First Name</IonLabel>
								<p>{userDetails.fname}</p>
							</IonItem>
						)}
						{userDetails.lname && (
							<IonItem>
								<IonLabel>Last Name</IonLabel>
								<p>{userDetails.lname}</p>
							</IonItem>
						)}
						{userDetails.seat && (
							<IonItem>
								<IonLabel>Seat</IonLabel>
								<IonList>
									<IonItem>Section: {userDetails.seat.section}</IonItem>
									<IonItem>Row: {userDetails.seat.row}</IonItem>
									<IonItem>Seat: {userDetails.seat.number}</IonItem>
								</IonList>
							</IonItem>
						)}
						{userDetails.phoneNumber && (
							<IonItem>
								<IonLabel>Phone Number</IonLabel>
								<p>{userDetails.phoneNumber}</p>
							</IonItem>
						)}
						{userDetails.email && (
							<IonItem>
								<IonLabel>Email</IonLabel>
								<p>{userDetails.email}</p>
							</IonItem>
						)}
						{cartTotal && (
							<IonItem>
								<IonLabel>Cart Total</IonLabel>
								<p>{cartTotal}</p>
							</IonItem>
						)}
						{status && (
							<IonItem>
								<IonLabel>Status</IonLabel>
								<p>{status}</p>
							</IonItem>
						)}
						{created && (
							<IonItem>
								<IonLabel>Date</IonLabel>
								<p>{orderDate()}</p>
							</IonItem>
						)}
						{/* {order.cart && (
							<IonItem>
								<IonLabel>Items</IonLabel>
								<p>{order.cart}</p>
							</IonItem>
						)} */}
					</IonItemGroup>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

export default Order;
