import { IonContent } from "@ionic/react";
import React from "react";

import { AppHeader } from "../../../components/Header/AppHeader";
import { connect } from "react-redux";
import AdminFooter from "../../../components/Header/AdminFooter";
import SlidesView from "./components/SlidesView";

interface Props {}

const Slides: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Slides" />
			<IonContent fullscreen className="ion-padding admin">
				<SlidesView />
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Slides);
