import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonToggle,
	IonItemGroup,
	IonItemDivider,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	addPromotion,
	deletePromotion,
	getPromotions,
	updatePromotion,
} from "../../../../services/promotions";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
	id: string;
}

interface Props {
	promotions: any;
	getPromotions: any;
}

const AdminPromotion: React.FC<Props> = ({ promotions, getPromotions }) => {
	const history = useHistory();

	const defaultState = {
		title: "",
		active: false,
		liveDateTime: "",
		endDateTime: "",
		img: "",
		shortDesc: "",
		longDesc: "",
		callToAction: "",
		buttonText: "Find Out More",
		buttonLink: "https://canterburyrams.basketball",
	};

	let { id } = useParams<RouteParams>();
	const [promotion, setPromotion] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const {
		title,
		active,
		img,
		shortDesc,
		longDesc,
		callToAction,
		buttonText,
		buttonLink,
	} = promotion;

	useEffect(() => {
		if (promotions[id]) {
			setPromotion(promotions[id]);
		}
		//eslint-disable-next-line
	}, [id, promotions]);

	const _onChange = (e: any) => {
		if (["olabel", "ovalue", "oid"].includes(e.target.name)) {
			let options = promotion.options;
			options[e.target.dataset.id][e.target.name] = e.target.value;
			if (promotion.options !== options) {
				setPromotion({ ...promotion, options });
			}
		} else {
			setPromotion({ ...promotion, [e.target.name]: e.target.value });
		}
	};

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// if (promotion.name.trim() === "" || promotion.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a message and an image");
		// }
		if (id === "new") {
			addPromotion(promotion).then((res: any) => {
				if (res !== false) {
					toast("Promotion saved");
				}
			});
		} else {
			updatePromotion(promotion, id).then((res) => {
				if (res) {
					toast("Promotion updated");
				}
			});
		}
		setDisableSubmit(false);
	};

	const _back = () => {
		history.push("/admin/promotions");
	};

	const _onToggle = (option: any) => {
		setPromotion({ ...promotion, [option]: !promotion[option] });
	};

	const _onPhotoChange = (e: any) => {
		setPromotion({ ...promotion, [e.name]: e.image.fullPath });
	};

	const _onDelete = () => {
		deletePromotion(id).then(() => {
			getPromotions();
		});
	};

	return (
		<>
			<AppHeader title={title} />
			<IonContent className="ion-padding admin">
				<IonTitle>{id === "new" ? "Add" : "Edit"} Promotion </IonTitle>
				<form onSubmit={_submit}>
					<IonItemGroup>
						<IonItem>
							<IonLabel>Active?</IonLabel>
							<IonToggle
								checked={active}
								onClick={() => _onToggle("active")}
								name="active"
								id="active"
							/>
						</IonItem>
						<IonItemDivider>
							<IonLabel>PROMOTION COPY</IonLabel>
						</IonItemDivider>
						<IonItem>
							<IonLabel position="floating">Title</IonLabel>
							<IonInput
								type="text"
								id="title"
								name="title"
								value={title}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<PhotoUploadInput
								name="img"
								id="img"
								value={img}
								onChange={_onPhotoChange}
							/>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Short Description</IonLabel>
							<IonTextarea
								id="shortDesc"
								name="shortDesc"
								value={shortDesc}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Long Description</IonLabel>
							<IonTextarea
								id="longDesc"
								name="longDesc"
								value={longDesc}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Call To Action</IonLabel>
							<IonInput
								type="text"
								id="callToAction"
								name="callToAction"
								value={callToAction}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Button Text</IonLabel>
							<IonInput
								type="text"
								id="buttonText"
								name="buttonText"
								value={buttonText}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Button Link</IonLabel>
							<IonInput
								type="text"
								id="buttonLink"
								name="buttonLink"
								value={buttonLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
					</IonItemGroup>

					<IonButton type="submit" color="primary" disabled={disableSubmit}>
						Save
					</IonButton>
					<IonButton onClick={_back} color="warning">
						Back
					</IonButton>
					<IonButton onClick={_onDelete} color="danger">
						Delete
					</IonButton>
				</form>
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	promotions: state.promotions,
});

export default connect(mapStateToProps, { getPromotions })(AdminPromotion);
