import { IonContent, IonPage, IonButton, IonInput } from "@ionic/react";
import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { toast } from "../../../../components/Toast/toast";
import { loginUser } from "../../../../services/auth";
import { loginUserUpdate, setUser } from "../../../../services/users";
import { connect } from "react-redux";
import { setLoading } from "../../../../services/loading";

interface LoginProps {
	loggedIn: any;
	setUser: any;
	setLoading: any;
}

const Login: React.FC<LoginProps> = ({ loggedIn, setUser, setLoading }) => {
	const history = useHistory();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	// useEffect(() => {
	// 	if (loggedIn) {
	// 		history.replace("/dashboard");
	// 	}
	// 	//eslint-disable-next-line
	// }, [loggedIn]);

	async function login() {
		setLoading(true, "Logging in");
		const res: any = await loginUser({ email, password });
		if (res) {
			//console.log(res.user);
			setUser(res.user);
			loginUserUpdate(res.user);
			history.replace("/dashboard");
			toast("You are logged in!");
		}
		setLoading(false);
	}

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding auth-screen">
				<div id="app-logo" />
				<div id="content-box">
					<IonInput
						type="email"
						placeholder="Email"
						onIonChange={(e: any) => setEmail(e.target.value)}
					/>
					<IonInput
						type="password"
						placeholder="Password"
						onIonChange={(e: any) => setPassword(e.target.value)}
					/>
					<IonButton onClick={login}>Login</IonButton>
					<p>
						New here? <Link to="/register">Register</Link>
					</p>
				</div>
				<div id="app-background" />
			</IonContent>
		</IonPage>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps, { setUser, setLoading })(Login);
