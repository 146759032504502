import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import ChartRace from "react-chart-race";

import { getAdminSubmissions } from "../../../../../services/activations";
import { reds } from "../../../../../services/util";

export interface RouteParams {
	id: string;
}

interface Props {
	activation: any;
	getAdminSubmissions: any;
	submissions: any;
}

const ResultsView: React.FC<Props> = ({
	activation,
	submissions,
	getAdminSubmissions,
}) => {
	const [subs, setSubs] = useState<any>([]);
	const [results, setResults] = useState<any>([]);
	const [options, setOptions] = useState<any>([]);

	const { resultsLayout } = activation;

	useEffect(() => {
		if (activation) {
			getAdminSubmissions();

			if (activation.options) {
				let data: any = {};
				activation.options.forEach((option: any) => {
					data[option.oid] = option;
				});
				setOptions(data);
			}
		}
		//eslint-disable-next-line
	}, [activation]);

	useEffect(() => {
		if (submissions) {
			setSubs(
				Object.values(submissions).filter(
					(doc: any) => doc.activation === activation.id
				)
			);
		}
		//eslint-disable-next-line
	}, [activation, submissions]);

	useEffect(() => {
		if (options) {
			_processData(subs);
		}
		//eslint-disable-next-line
	}, [subs, options]);

	const _sortByVotes = (a: any, b: any) => {
		return b.value - a.value;
	};

	const _processData = (subsData: any) => {
		let submissionData: any = Object.values(subsData);
		let optionsArr = Object.values(options);

		let output = optionsArr.map((option: any, idx: any) => {
			let obj = { id: idx, title: option.olabel, color: reds[idx], value: 0 };
			let filtered = submissionData.filter(
				(sub: any) => sub.data.selected === option.oid
			);
			obj.value = filtered.length;
			return obj;
		});

		let sortedAndFiltered = output
			.filter((option) => option.value > 0)
			.sort(_sortByVotes)
			.slice(0, 7); //TODO make this value setable

		setResults(sortedAndFiltered);
	};

	return (
		<Fragment>
			{/* <div className="spacer60" /> */}
			<div className={`results-wrapper vote layout-${resultsLayout}`}>
				{/* <h1 className="pageTitle">{title}</h1>
				<h4 className="pageSubtitle">{shortDesc}</h4>
				<div className="spacer60" /> */}
				<div className="results">
					<div className="raceChart">
						<ChartRace
							data={results}
							backgroundColor="rgba(0,0,0, 0)"
							width={900} //1100
							padding={5} //12
							itemHeight={40} //50
							gap={5} //12
							titleStyle={{
								font: "normal 600 25px Arial",
								color: "#000",
								paddingRight: "10px",
							}}
							valueStyle={{
								font: "normal 400 0px Arial",
								color: "rgba(0,0,0, 0.4)",
							}}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	submissions: state.activations.submissions,
});

export default connect(mapStateToProps, { getAdminSubmissions })(ResultsView);
