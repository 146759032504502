import { GET_POSTS_SUCCESS, GET_API_POSTS_SUCCESS } from "../actionTypes";
const defaultState = { posts: [], api: [] };

const news = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_POSTS_SUCCESS:
			// console.log(payload);
			return { ...state, posts: payload };
		case GET_API_POSTS_SUCCESS:
			// console.log(payload);
			return { ...state, api: payload };
		default:
			return state;
	}
};

export default news;
