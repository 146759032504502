//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
import { getSettingsSuccess } from "./actions";

var db = firebase.firestore();

export function addSetting(name: any, payload: any) {
	//console.log("adding setting: ", name);
	return db
		.collection("settings")
		.add({ name, value: payload })
		.then(function (res) {
			//console.log(res.id);
			return res.id;
		})
		.catch(function (error) {
			return false;
		});
}

export function updateSetting(id: string, payload: any) {
	return db
		.collection("settings")
		.doc(id)
		.update({ value: payload })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});
}

export const getSettings = () => {
	return (dispatch: any, data: any) => {
		db.collection("settings").onSnapshot((docs) => {
			const data: any = {};
			docs.forEach(
				(doc) => (data[doc.data().name] = { ...doc.data(), id: doc.id })
			);
			dispatch(getSettingsSuccess(data));
		});
	};
};

export async function getSetting(id: any) {
	return db
		.collection("settings")
		.doc(id)
		.get()
		.then((doc) => {
			let data;
			if (doc.exists) {
				data = doc.data();
			}
			return data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

export async function getSettingByName(name: any) {
	return db
		.collection("settings")
		.where("name", "==", name)
		.get()
		.then((docs) => {
			const data: any = {};
			docs.forEach(
				(doc: any) => (data[doc.data().name] = { ...doc.data(), id: doc.id })
			);
			return data;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

export const pushToScreen = (payload: any) => {
	let settingName = "admin-output-default";
	getSettingByName(settingName).then((data) => {
		if (Object.values(data).length > 0) {
			updateSetting(data[settingName].id, payload);
		} else {
			addSetting(settingName, payload);
		}
	});
};
