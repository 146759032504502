import { IonContent } from "@ionic/react";
import React from "react";

import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../components/Header/AppFooter";
import FeedbackView from "./FeedbackView";

interface Props {}

const DashboardFeedbackView: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Feedback" />
			<IonContent className="ion-padding contentPane admin">
				<FeedbackView />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(DashboardFeedbackView);
