import {
	IonCard,
	IonCardContent,
	IonCol,
	IonItem,
	IonLabel,
	IonRow,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

interface Props {
	activation: any;
}

const WinnersView: React.FC<Props> = ({ activation }) => {
	const [options, setOptions] = useState<any>([]);

	useEffect(() => {
		if (activation && activation.options) {
			let data: any = {};
			activation.options.forEach((option: any) => {
				data[option.oid] = option;
			});
			setOptions(data);
		}
		//eslint-disable-next-line
	}, [activation]);

	return (
		<Fragment>
			<div className="spacer60" />
			<div className="results">
				{activation.type === "score" && (
					<IonRow className="matchResult">
						<IonCol>
							<h2 className="teamName">Rams</h2>
							<span className="score">{activation.finalScoreHome}</span>
						</IonCol>
						<IonCol>
							<h2 className="teamName">{activation.oppositionName}</h2>
							<span className="score">{activation.finalScoreAway}</span>
						</IonCol>
					</IonRow>
				)}
				<div className="spacer30" />
				<h1 className="pageTitle">Winners</h1>
				<IonRow className="winners-wrapper">
					{activation.winners &&
						Object.values(activation.winners).length > 0 &&
						activation.winners.map((winner: any, idx: any) => (
							<IonCol key={idx} size="4" className="col">
								<IonCard className="card">
									<IonCardContent className="cardContent">
										<IonItem lines="none">
											<IonLabel>
												<h2 className="name">
													{winner.submitter.fname} {winner.submitter.lname}
												</h2>
											</IonLabel>
											{activation.type !== "score" &&
												Object.values(options).length > 0 &&
												winner.data &&
												winner.data.selected && (
													<h2 className="answer">
														{options[winner.data.selected].olabel}
													</h2>
												)}
											{activation.type === "score" && winner.data && (
												<Fragment>
													<IonRow>
														<IonCol className="teamCol">
															<span className="teamName">Rams</span>
															<div className="result">
																<span className="pick">
																	{winner.data.score.homeScore}
																</span>
																<span className="diff">
																	{winner.results.home >= 0 && "+"}
																	{winner.results.home}
																</span>
															</div>
														</IonCol>
														<IonCol className="teamCol">
															<span className="teamName">
																{activation.oppositionName}
															</span>
															<div className="result">
																<span className="pick">
																	{winner.data.score.awayScore}
																</span>
																<span className="diff">
																	{winner.results.away >= 0 && "+"}
																	{winner.results.away}
																</span>
															</div>
														</IonCol>
													</IonRow>
													<IonRow>
														<IonCol className="totalCol">
															<span className="diff">
																+/- {winner.results.total}
															</span>
														</IonCol>
													</IonRow>
												</Fragment>
											)}
										</IonItem>
									</IonCardContent>
								</IonCard>
							</IonCol>
						))}
				</IonRow>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, {})(WinnersView);
