import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonItem,
	IonLabel,
	IonList,
	IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
	settings: any;
}

const DashboardSettingsView: React.FC<Props> = ({ settings }) => {
	const [shopActive, setShopActive] = useState<any>(
		settings["shop-active"] ? settings["shop-active"].value : false
	);

	const [messagesActive, setMessagesActive] = useState<any>(
		settings["message-active"] ? settings["message-active"].value : false
	);

	const [requestActive, setRequestActive] = useState<any>(
		settings["request-active"] ? settings["request-active"].value : false
	);

	const _onToggle = (section: any) => {
		if (section === "shopActive") {
			setShopActive(!shopActive);
		} else if (section === "messagesActive") {
			setMessagesActive(!messagesActive);
		} else if (section === "requestActive") {
			setRequestActive(!requestActive);
		}
	};

	const _save = (name: any, data: any) => {
		if (settings[name]) {
			updateSetting(settings[name].id, data);
		} else {
			addSetting(name, "");
		}
	};

	useEffect(() => {
		_save("shop-active", shopActive);
		//eslint-disable-next-line
	}, [shopActive]);

	useEffect(() => {
		_save("message-active", messagesActive);
		//eslint-disable-next-line
	}, [messagesActive]);

	useEffect(() => {
		_save("request-active", requestActive);
		//eslint-disable-next-line
	}, [requestActive]);

	return (
		<Fragment>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Settings</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonList lines="none">
						<IonItem>
							<IonLabel>Shop Active?</IonLabel>
							<IonToggle
								checked={shopActive}
								onClick={() => _onToggle("shopActive")}
								name="shopActive"
								id="shopActive"
							/>
						</IonItem>
						<IonItem>
							<IonLabel>Messages Active?</IonLabel>
							<IonToggle
								checked={messagesActive}
								onClick={() => _onToggle("messagesActive")}
								name="messagesActive"
								id="messagesActive"
							/>
						</IonItem>
						<IonItem>
							<IonLabel>Request Active?</IonLabel>
							<IonToggle
								checked={requestActive}
								onClick={() => _onToggle("requestActive")}
								name="requestActive"
								id="requestActive"
							/>
						</IonItem>
					</IonList>
				</IonCardContent>
			</IonCard>
			<div className="spacer30" />
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(DashboardSettingsView);
