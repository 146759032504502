import * as types from "../actionTypes";

export function setSchedule(payload) {
	//console.log(payload);
	return { type: types.GET_SCHEDULE, payload };
}

export function setTeams(payload) {
	//console.log(payload);
	return { type: types.SET_TEAMS, payload };
}
