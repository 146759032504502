import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

interface Props {
	requests: any;
	onClick: any;
	active: any;
}

const DashboardRequests: React.FC<Props> = ({ requests, onClick, active }) => {
	return (
		<IonCard button color={active ? "primary" : "default"} onClick={onClick}>
			<IonCardContent>
				<h1>Requests</h1>
				<IonRow>
					<IonCol>Count: {Object.values(requests).length}</IonCol>
				</IonRow>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	requests: state.requests,
});

export default connect(mapStateToProps, {})(DashboardRequests);
