import { GET_SLIDES_SUCCESS } from "../actionTypes";
const defaultState = {};

const slides = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_SLIDES_SUCCESS:
			return payload;
		default:
			return state;
	}
};

export default slides;
