import {
	IonContent,
	IonPage,
	IonButton,
	IonInput,
	IonLabel,
	IonItem,
} from "@ionic/react";
import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "../../../../components/Toast/toast";
import { sendPasswordReset } from "../../../../services/auth";
import { connect } from "react-redux";
import { setLoading } from "../../../../services/loading";

interface Props {
	setLoading: any;
}

const ForgotPassword: React.FC<Props> = ({ setLoading }) => {
	const history = useHistory();
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");

	// useEffect(() => {
	// 	getCurrentUser().then((user: any) => {
	// 		if (user) {
	// 			history.replace("/dashboard");
	// 		}
	// 	});
	// 	//eslint-disable-next-line
	// }, []);

	const _onChange = (e: any) => {
		setError("");
		setEmail(e.target.value);
	};

	const _resetPassword = () => {
		setLoading(true, "Please wait...");
		sendPasswordReset(email)
			.then((res) => {
				//console.log(res);
				//loginUserUpdate(res.user);
				history.replace("/login");
				toast("Please check your email to reset your password.");
			})
			.catch((error) => {
				if (error.code === "auth/invalid-email") {
					setError("Please check your email address. It doesn't seem right.");
				}
			});
		setLoading(false);
	};

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding auth-screen">
				<div id="app-logo" />
				<div id="content-box">
					<p className="center-text instructions">
						Request a password reset email
					</p>
					<IonItem>
						<IonLabel position="floating">Your Email</IonLabel>
						<IonInput
							type="email"
							placeholder="Email"
							onIonChange={_onChange}
						/>
					</IonItem>
					{error && <p className="center-text error-message">{error}</p>}
					<div className="spacer15" />
					<IonButton expand="full" size="large" onClick={_resetPassword}>
						Reset Your Password
					</IonButton>
					<p
						onClick={() => history.push("/")}
						className="linked instructions small center-text"
					>
						Go back
					</p>
				</div>

				<div id="app-background" />
			</IonContent>
		</IonPage>
	);
};

const mapStateToProps = (state: any) => ({
	// account: state.account.current.id,
	// client: state.client,
	// current: state.client.current,
});

export default connect(mapStateToProps, { setLoading })(ForgotPassword);
