import {
	IonToolbar,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonSearchbar,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { getAdminOrders } from "../../../../services/orders";
import { connect } from "react-redux";
import FuzzySearch from "fuzzy-search";
import AdminOrderCard from "./AdminOrderCard";
import { sortByCreated } from "../../../../services/util";

interface Props {
	adminOrders: any;
	products: any;
	getAdminOrders: any;
}

const OrdersView: React.FC<Props> = ({
	adminOrders,
	products,
	getAdminOrders,
}) => {
	const [segment, setSegment] = useState<any>("processing");
	const [searchText, setSearchText] = useState<any>("");
	const [searchResults, setSearchResults] = useState<any>([]);

	useEffect(() => {
		getAdminOrders();
		//eslint-disable-next-line
	}, []);

	const orders: Array<any> = Object.values(adminOrders).sort((a, b) =>
		sortByCreated(a, b, "ASC")
	);
	const pending = searchResults.filter((el: any) => el.status === "pending");
	const confirmed = searchResults.filter(
		(el: any) => el.status === "confirmed"
	);
	const completed = searchResults.filter((el: any) => el.status === "complete");
	const processing = searchResults.filter(
		(el: any) => el.status === "processing"
	);

	const filterOrders = () => {
		//console.log(orders);
		const searcher = new FuzzySearch(
			orders,
			[
				"id",
				"orderNumber",
				"userDetails.fname",
				"userDetails.lname",
				"userDetails.phoneNumber",
				"userDetails.email",
			],
			{ caseSensitive: false }
		);
		//console.log("checking searchText");
		if (searchText !== "") {
			//console.log("search text is NOT empty: ", searchText);
			setSearchResults(searcher.search(searchText));
		} else {
			//console.log("search text is empty: ", searchText);
			setSearchResults(orders);
		}
	};

	useEffect(() => {
		//console.log("triggered");
		filterOrders();
		// if (searchText) {

		// }
		//eslint-disable-next-line
	}, [searchText, adminOrders]);

	return (
		<Fragment>
			{orders.length ? (
				<Fragment>
					<IonToolbar>
						<IonSegment
							onIonChange={(e) => setSegment(e.detail.value)}
							value={segment}
						>
							<IonSegmentButton value="processing">Processing</IonSegmentButton>
							<IonSegmentButton value="confirmed">Confirmed</IonSegmentButton>
							<IonSegmentButton value="complete">Complete</IonSegmentButton>
							<IonSegmentButton value="all">All</IonSegmentButton>
							<IonSegmentButton value="pending">Pending</IonSegmentButton>
						</IonSegment>
					</IonToolbar>
					<IonSearchbar
						value={searchText}
						onIonChange={(e) => setSearchText(e.detail.value!)}
					></IonSearchbar>
					{segment === "processing" && (
						<Fragment>
							<p>
								These orders have been successfully placed. They are awaiting
								confirmation and fulfillment.
							</p>
							{processing.map((elem: any, idx: any) => (
								<AdminOrderCard key={idx} order={elem} />
							))}
						</Fragment>
					)}
					{segment === "confirmed" && (
						<Fragment>
							<p>Confirmed orders are awaiting fulfilment.</p>
							{confirmed.map((elem: any, idx: any) => (
								<AdminOrderCard key={idx} order={elem} />
							))}
						</Fragment>
					)}
					{segment === "complete" && (
						<Fragment>
							<p>Complete orders have been fulfilled.</p>
							{completed.map((elem: any, idx: any) => (
								<AdminOrderCard key={idx} order={elem} />
							))}
						</Fragment>
					)}
					{segment === "all" && (
						<Fragment>
							{searchResults.map((elem: any, idx: any) => (
								<AdminOrderCard order={elem} key={idx} />
							))}
						</Fragment>
					)}
					{segment === "pending" && (
						<Fragment>
							<p>
								Pending orders are awaiting payment processing. They may be
								abandoned carts, or failed payments. For all intents and
								purposes, they should be ignored.
							</p>
							{pending.map((elem: any, idx: any) => (
								<AdminOrderCard key={idx} order={elem} />
							))}
						</Fragment>
					)}
				</Fragment>
			) : (
				<IonLabel className="ion-padding">No orders available</IonLabel>
			)}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	adminOrders: state.orders.adminOrders,
	products: state.products,
});

export default connect(mapStateToProps, { getAdminOrders })(OrdersView);
