import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import {
  downloadOutline,
  ellipsisVertical,
  logInOutline,
  pencil,
  star,
  starOutline,
  trash,
  close,
} from "ionicons/icons";
import { deleteSlide, setPrimarySlide } from "../../../../services/slides";
import { getImageURL } from "../../../../services/util";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { pushToScreen } from "../../../../services/settings";

interface Props {
  message: any;
  settings: any;
  format?: any;
  index?: any;
}

const DashboardSlideCard: React.FC<Props> = ({
  message,
  settings,
  format = "default",
  index = "",
}) => {
  const history = useHistory();
  const [imageURL, setImageURL] = useState<any>("");
  const [downloadURL, setDownloadURL] = useState<any>("");
  const [showActionSheet, setShowActionSheet] = useState(false);
  // const [inPlaylist, setInPlaylist] = useState<any>(false);

  const { id, name, img, primary } = message;

  useEffect(() => {
    if (img !== "") {
      getImageURL(img, "600").then((url: any) => {
        setImageURL(url);
      });
      getImageURL(img, "full").then((url: any) => {
        setDownloadURL(url);
      });
    } else {
      setImageURL("");
      setDownloadURL("");
    }
    //eslint-disable-next-line
  }, [img, id]);

  const _delete = () => {
    deleteSlide(id);
  };

  const _download = () => {
    window.open(downloadURL, "_blank");
  };

  const _edit = () => {
    history.push(`/admin/slide/${id}`);
  };

  const _push = () => {
    pushToScreen({ type: "slide", id });
  };

  const _togglePrimary = () => {
    if (primary === false) {
      setPrimarySlide(id);
    } else {
      setPrimarySlide();
    }
  };

  return (
    <IonCard className="admin-message-card">
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Download",
            icon: downloadOutline,
            handler: _download,
          },
          {
            text: "Edit",
            icon: pencil,
            handler: _edit,
          },
          {
            text: "Delete",
            icon: trash,
            handler: _delete,
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
          },
        ]}
      />
      {img ? (
        <img alt="" src={imageURL} onClick={_push} className="linked" />
      ) : (
        <IonCardHeader>
          <IonCardTitle>{name}</IonCardTitle>
        </IonCardHeader>
      )}
      <IonRow>
        <IonCol size="10">
          {/* <IonReorder></IonReorder> */}
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={_push} target="_blank" download="true">
                <IonIcon icon={logInOutline} slot="start" />
                PUSH
              </IonButton>
              <IonButton onClick={_togglePrimary}>
                <IonIcon
                  color="danger"
                  icon={primary ? star : starOutline}
                  className={"featuredToggle"}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCol>
        <IonCol size="2">
          <IonToolbar>
            <IonButtons>
              <IonButton
                onClick={() => setShowActionSheet(true)}
                target="_blank"
                download="true"
              >
                <IonIcon icon={ellipsisVertical} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

const mapStateTopProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateTopProps, {})(DashboardSlideCard);
