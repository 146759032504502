import { isPlatform } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { savePushToken } from "../../../../services/auth";

import {
	Plugins,
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed,
} from "@capacitor/core";
import { connect } from "react-redux";
const { PushNotifications } = Plugins;

interface Props {
	user: any;
}

const CloudMessagesModule: React.FC<Props> = ({ user }) => {
	const [notifications, setNotifications] = useState<any>([
		{
			id: "id",
			title: "Another Test Push",
			body: "This is my second push notification",
		},
	]);

	const push = (user: any) => {
		//code borrowed from here https://enappd.com/blog/firebase-push-notification-in-ionic-react-capacitor/111/

		// Register with Apple / Google to receive push via APNS/FCM
		//TODO check if android or apple. Don't do this if web I think...
		PushNotifications.register();

		// On succcess, we should be able to receive notifications
		PushNotifications.addListener(
			"registration",
			(token: PushNotificationToken) => {
				savePushToken(user, token.value);
				//alert("Push registration success, token: " + token.value);
			}
		);

		// Some issue with your setup and push will not work
		PushNotifications.addListener("registrationError", (error: any) => {
			alert("Error on registration: " + JSON.stringify(error));
		});

		// Show us the notification payload if the app is open on our device
		PushNotifications.addListener(
			"pushNotificationReceived",
			(notification: PushNotification) => {
				let notif = notifications;
				notif.push({
					id: notification.id,
					title: notification.title,
					body: notification.body,
				});
				setNotifications([...notif]);
				//alert("Push notification received success " + notification.title);
				alert(JSON.stringify(notifications));
			}
		);

		// Method called when tapping on a notification
		PushNotifications.addListener(
			"pushNotificationActionPerformed",
			(notification: PushNotificationActionPerformed) => {
				let notif = notifications;
				notif.push({
					notification,
				});
				setNotifications([...notif]);

				//alert("Some other message");
				alert(JSON.stringify(notification));

				// let notif = notifications;
				// PushNotifications.getDeliveredNotifications().then(
				// 	(notification: any) => {
				// 		notif.push({
				// 			notification,
				// 		});
				// 	}
				// );
				// setNotifications([...notif]);
			}
		);

		// const _add = () => {
		// 	let notif = notifications;
		// 	// notif.push({
		// 	// 	id: notification.id,
		// 	// 	title: notification.title,
		// 	// 	body: notification.body,
		// 	// });
		// 	notif.push({
		// 		id: "id",
		// 		title: "Another Test Push",
		// 		body: "This is my second push notification",
		// 	});
		// 	setNotifications([...notif]);
		// };
	};

	useEffect(() => {
		if (user && (isPlatform("android") || isPlatform("ios"))) push(user);

		//eslint-disable-next-line
	}, []);

	return (
		<div>
			{/* {notifications && (
    <Fragment>
      <IonTitle>Notifications</IonTitle>
      {notifications &&
        notifications.map((notif: any, idx: any) => (
          <IonCard key={idx}>
            <IonCardContent>
              <IonTitle>{notif.title}</IonTitle>
              <IonText>{notif.body}</IonText>
            </IonCardContent>
          </IonCard>
        ))}
    </Fragment>
  )}
  <IonButton onClick={() => alert(JSON.stringify(notifications))}>
    Print
  </IonButton> */}
			{/* <IonButton onClick={_add}>Add</IonButton> */}
		</div>
	);
};

const mapStateToProps = (state: any) => ({ user: state.user });

export default connect(mapStateToProps, {})(CloudMessagesModule);
