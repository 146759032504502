import { IonContent } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { AppHeader } from "../../../components/Header/AppHeader";
import AdminFooter from "../../../components/Header/AdminFooter";
import ActivationsView from "./components/ActivationsView";

interface Props {}

const AdminActivations: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Activations" />
			<IonContent className="ion-padding contentPane admin">
				<ActivationsView />
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(AdminActivations);
