import {
	IonBadge,
	IonCard,
	IonCardContent,
	IonCol,
	IonRow,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { formatDate } from "../../../../services/util";
import TeamIcon from "../Teams/components/TeamIcon";

interface Props {
	match: any;
	teams: any;
}

const ScheduleAdminCard: React.FC<Props> = ({ match, teams }) => {
	const history = useHistory();
	return (
		<IonCard button onClick={() => history.push(`/admin/schedule/${match.id}`)}>
			<IonCardContent>
				<IonRow>
					<IonCol>
						<TeamIcon team={teams[match.awayTeam]} />
					</IonCol>
					<IonCol>
						{teams[match.awayTeam].name} @ {teams[match.homeTeam].name}
					</IonCol>
					<IonCol>{formatDate(match.matchDate)}</IonCol>
					<IonCol>
						{match.active ? (
							<IonBadge color="success">Active</IonBadge>
						) : (
							<IonBadge color="light">Inactive</IonBadge>
						)}
					</IonCol>
				</IonRow>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(ScheduleAdminCard);
