import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React from "react";

import { connect } from "react-redux";
import { add } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import DashboardSlideCard from "./DashboardSlideCard";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";

interface Props {
  slides: any;
}

const DashboardSlidesView: React.FC<Props> = ({ slides }) => {
  const history = useHistory();

  const orderSlides = (a: any, b: any) => {
    return a.order - b.order;
  };

  return (
    <>
      <AppHeader title="Slides" />
      <IonContent className="ion-padding contentPane admin">
        <IonItem>
          <IonButton
            onClick={() => history.push("/admin/slide/new")}
            color="primary"
          >
            <IonIcon icon={add} />
            Add New
          </IonButton>
        </IonItem>
        <IonGrid>
          <IonRow>
            {slides && Object.values(slides).length > 0 ? (
              Object.values(slides)
                .sort(orderSlides)
                .map((msg: any, idx: any) => (
                  <IonCol key={idx} size="12" sizeSm="6" sizeMd="4" sizeLg="3">
                    <DashboardSlideCard message={msg} />
                  </IonCol>
                ))
            ) : (
              <IonLabel className="ion-padding">No messages</IonLabel>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  slides: state.slides,
});

export default connect(mapStateToProps, {})(DashboardSlidesView);
