import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonList,
	IonButtons,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import { updateMessage, getMessages } from "../../../../services/messages";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { AppHeader } from "../../../../components/Header/AppHeader";

import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
	id: string;
}

interface Props {
	messages: any;
}

const AdminMessage: React.FC<Props> = ({ messages }) => {
	const history = useHistory();

	let { id } = useParams<RouteParams>();
	const [message, setMessage] = useState<any>({});
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const { name, img, body } = message;

	useEffect(() => {
		if (messages && messages[id]) {
			setMessage({ ...messages[id] });
		}
		//eslint-disable-next-line
	}, [id, messages]);

	const _onChange = (e: any) =>
		setMessage({ ...message, [e.target.name]: e.target.value });

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// if (message.name.trim() === "" || message.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a position and a name");
		// }
		updateMessage(message, id).then((res) => {
			if (res) {
				// console.log(id);
				toast("message updated");
			}
		});
		getMessages();
		setDisableSubmit(false);
		history.push("/admin/messages");
	};

	const _back = () => {
		setMessage({});
		history.push("/admin/messages");
	};

	const _onPhotoChange = (e: any) => {
		setMessage({ ...message, img: e.image.fullPath });
	};

	return (
		<>
			<AppHeader title={name} />
			<IonContent className="ion-padding">
				<IonTitle>Message</IonTitle>
				<form onSubmit={_submit}>
					<IonList>
						<IonItem>
							<IonLabel position="stacked">Image</IonLabel>
							<div className="spacer5" />
							<PhotoUploadInput
								name="img"
								id="img"
								value={img}
								onChange={_onPhotoChange}
							/>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Name</IonLabel>
							<IonInput
								type="text"
								id="name"
								name="name"
								value={message.name}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Body</IonLabel>
							<IonTextarea
								id="body"
								name="body"
								value={body}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
					</IonList>
					<IonButtons>
						<IonButton type="submit" color="success" disabled={disableSubmit}>
							Save
						</IonButton>
						<IonButton onClick={_back} color="warning">
							Cancel
						</IonButton>
					</IonButtons>
				</form>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
});

export default connect(mapStateToProps, { getMessages })(AdminMessage);
