import {
	IonText,
	IonCol,
	IonGrid,
	IonRow,
	IonCard,
	IonCardContent,
	IonButton,
	IonButtons,
} from "@ionic/react";
import React, { Fragment } from "react";
import { getSchedule, getTeams } from "../../../../services/schedules";

import { connect } from "react-redux";
import { convertTimeTo12h, formatDateTime } from "../../../../services/util";
import { useHistory } from "react-router-dom";
import TeamIcon from "../../../Admin/Schedules/Teams/components/TeamIcon";

interface Props {
	schedules: any;
	teams: any;
	getSchedule: any;
	getTeams: any;
	match: any;
}

const MatchCard: React.FC<Props> = ({
	teams,
	match,
	// getTeams,
}) => {
	const history = useHistory();

	const dateTimeOptions = {
		weekday: "short",
		year: "numeric",
		month: "short",
		day: "numeric",
	};

	return (
		<IonCard key={match.id} className="matchCard linked">
			<IonCardContent>
				{teams[match.homeTeam] && teams[match.awayTeam] && (
					<IonGrid
						onClick={() => history.push(`/schedule/${match.id}`)}
						className="ion-no-padding"
					>
						<IonRow className="ion-text-center">
							<IonCol className="teamCol ion-no-padding" size="2">
								{teams[match.awayTeam] && (
									<Fragment>
										<TeamIcon team={teams[match.awayTeam]} />
										{match.status !== "pre" && (
											<IonText className="matchScore">
												{match.awayScore}
											</IonText>
										)}
										{match.status === "pre" && (
											<IonText className="record">
												{teams[match.awayTeam].record}
											</IonText>
										)}
									</Fragment>
								)}
							</IonCol>
							<IonCol className="matchDetails" size="8">
								<div className="matchDate">
									{formatDateTime(match.matchDate, dateTimeOptions)}
								</div>
								<div className="matchTeams">
									{teams[match.awayTeam].name} @ {teams[match.homeTeam].name}
								</div>
								{match.status === "pre" && (
									<Fragment>
										<div className="matchTime">
											{convertTimeTo12h(match.matchTime)}
										</div>
										<div className="matchVenue">{match.venue}</div>
									</Fragment>
								)}
								{match.status === "live" && (
									<IonText color="success" className="matchStatus">
										In Progress
									</IonText>
								)}
								{match.status === "post" && (
									<IonText color="danger" className="matchStatus">
										Complete
									</IonText>
								)}
							</IonCol>
							<IonCol className="teamCol" size="2">
								{teams[match.homeTeam] && (
									<Fragment>
										<TeamIcon team={teams[match.homeTeam]} />
										{match.status !== "pre" && (
											<IonText className="matchScore">
												{match.homeScore}
											</IonText>
										)}
										{match.status === "pre" && (
											<IonText className="record">
												{teams[match.homeTeam].record}
											</IonText>
										)}
									</Fragment>
								)}
							</IonCol>
						</IonRow>
						{/* <IonRow>
							{match.ticketsLink && (
								<IonButton href={match.ticketsLink}>Buy Tickets</IonButton>
							)}
						</IonRow> */}
					</IonGrid>
				)}
				<div className="spacer30" />
				<IonButtons>
					<IonButton onClick={() => history.push(`/schedule/${match.id}`)}>
						Find out more
					</IonButton>
					{match.ticketsLink && match.status === "pre" && (
						<IonButton href={match.ticketsLink}>Buy Tickets</IonButton>
					)}
				</IonButtons>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	schedules: state.schedules.schedule,
	teams: state.schedules.teams,
	// client: state.client,
	// current: state.client.current,
});

export default connect(mapStateToProps, { getSchedule, getTeams })(MatchCard);
