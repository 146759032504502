import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonIcon,
	IonText,
	IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { star } from "ionicons/icons";
import { upvoteMessage } from "../../../../services/messages";
import { addMessageVote } from "../../../../services/users";
import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";

interface Props {
	message: any;
	votes: any;
}

const MessageCard: React.FC<Props> = ({ message, votes }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const [voted, setVoted] = useState<boolean>(false);

	const { id, name, img, body } = message;

	const _upvote = () => {
		upvoteMessage(message.id).then(() => {
			addMessageVote(message.id);
			setVoted(true);
		});
	};

	useEffect(() => {
		if (votes && votes[id]) {
			setVoted(true);
		}
		//eslint-disable-next-line
	}, [votes]);

	useEffect(() => {
		if (img && img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	return (
		<IonCard className="message-card">
			<img alt="" src={imageURL} />
			<IonCardHeader>
				<IonCardTitle>{name}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>
				{body}
				<IonToolbar>
					<IonButtons>
						<IonButton onClick={_upvote} disabled={voted}>
							<IonIcon slot="start" icon={star} />
						</IonButton>
						<IonText>{message.votes}</IonText>

						{!voted && (
							<IonButton onClick={_upvote} disabled={voted}>
								Upvote
							</IonButton>
						)}
					</IonButtons>
				</IonToolbar>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	votes: state.user.votes,
});

export default connect(mapStateToProps, {})(MessageCard);
