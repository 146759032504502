import firebase from "firebase/app";
import "firebase/firestore";
import * as actions from "./actions";

var db = firebase.firestore();

export async function addRequest(payload: any) {
  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("requests")
    .add({ ...payload, created: today.toISOString() })
    .then((res: any) => {
      return res.id;
    })
    .catch(function (error: any) {
      return false;
    });
}

export const getRequests = () => {
  return (dispatch: any, data: any) => {
    db.collection("requests").onSnapshot((docs: any) => {
      const data: any = {};
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getRequestsSuccess(data));
    });
  };
};

export const deleteRequest = (id: any) => {
  return db
    .collection("requests")
    .doc(id)
    .delete()
    .then(() => {
      return true;
    })
    .catch((error: any) => {
      console.log(error);
      return false;
    });
};

export const upvoteRequest = (id: any) => {
  const increment = firebase.firestore.FieldValue.increment(1);
  return db
    .collection("requests")
    .doc(id)
    .update({ votes: increment })
    .catch(function (error: any) {
      return false;
    });
};

// export const approveRequest = (id: any, approved: any) => {
// 	return db
// 		.collection("requests")
// 		.doc(id)
// 		.update({ approved: approved })
// 		.catch(function (error) {
// 			return false;
// 		});
// };

export const changeStatus = (id: any, status: any) => {
  db.collection("requests").doc(id).update({ status: status });
};

export const setPlayed = (id: any, status: any) => {
  db.collection("requests").doc(id).update({ played: status });
};

export const hideAllRequests = () => {
  // Get a new write batch
  var batch = db.batch();

  //Get all messages
  db.collection("requests")
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        var data = doc.data();
        if (data.status !== "hidden") {
          batch.update(doc.ref, { status: "hidden" });
        }
      });
      batch.commit().then(() => {
        console.log("batch update complete");
      });
    });
};

export const deleteAllRequests = () => {
  db.collection("requests")
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        deleteRequest(doc.id);
      });
    });
};
