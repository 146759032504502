import { IonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	removeUserNotification,
	setUserNotificationStatus,
} from "../../../../services/users";

interface Props {
	user: any;
	notifications: any;
	userNotifications: any;
}

const NotificationPopOver: React.FC<Props> = ({
	user,
	notifications,
	userNotifications,
}) => {
	const defaultButtons = [
		{
			text: "Dismiss",
			role: "dismiss",
			cssClass: "secondary",
		},
	];
	const swish = new Audio(
		"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fsounds%2Fnotification.wav?alt=media&token=daf11f60-3a9b-4b4b-94a4-585087322a40"
	);
	const buzzer = new Audio(
		"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fsounds%2Fbuzzer.mp3?alt=media&token=cf477b91-2ca9-435c-bc81-4a0e6275ec3e"
	);
	// const dribble = new Audio(
	// 	"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/static-resources%2Fsounds%2Fdribble.wav?alt=media&token=d2e1f859-637a-48ec-87d8-73503e5b7223"
	// );
	const history = useHistory();
	const [message, setMessage] = useState<any>({});
	const [open, setOpen] = useState<any>("");
	const [buttons, setButtons] = useState<any>(defaultButtons);

	const { title, body } = message;

	const _onDismiss = (id: any) => {
		setOpen(false);
		setUserNotificationStatus(user.userDetails.uid, id, true);
		setMessage({});
		setButtons(defaultButtons);
	};

	useEffect(() => {
		/**
		 * TODO shouldn't pop every single notification that they haven't previous clicked.
		 * 	Otherwise someone who hasn't logged in for months could get slammed with crap tonnes.
		 * Should add a TTL to each notification.
		 *
		 * TODO Add reporting to these notifications so we can see how many people it was
		 * delivered to and how many have marked it as read.
		 *
		 * */
		// Check if there are any notifications to display
		if (userNotifications) {
			Object.values(userNotifications)
				.filter((msg: any) => msg.read !== true)
				.forEach((msg: any) => {
					if (notifications[msg.id]) {
						setMessage(notifications[msg.id]);
						if (notifications[msg.id].link) {
							buttons.push({
								text: "Go!",
								handler: () => {
									_onAcknowledge(notifications[msg.id].link, msg.id);
								},
							});
							setButtons(buttons);
						}
						setOpen(true);
						if (notifications[msg.id].recip.length === 1) {
							buzzer.play();
						} else {
							swish.play();
						}
					}
				});
		}
		//eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (userNotifications) {
			Object.values(userNotifications).forEach((notification: any) => {
				let id = notification.id;
				/**
				 * Remove use notifications if required
				 * Removed if it's been deleted on admin, the recip list has been
				 * changed and the user is no longer present or it's status has
				 * changed and it's no longer approved
				 **/
				if (
					!notifications[id] ||
					(!notifications[id].recip.includes(user.userDetails.uid) &&
						!(notifications[id].recip.length === 0)) ||
					notifications[id].status !== "approved"
				) {
					removeUserNotification(user.userDetails.uid, id);
				}
			});
		}
		//eslint-disable-next-line
	}, [notifications, userNotifications]);

	const _onAcknowledge = (redirect: any, msgid: any) => {
		let idx = redirect.indexOf("http");

		if (idx >= 0) {
			window.open(redirect);
		} else history.push(redirect);
	};

	return (
		<IonAlert
			isOpen={open}
			onDidDismiss={() => _onDismiss(message.id)}
			cssClass="my-custom-class"
			header={title}
			message={body}
			buttons={buttons}
		/>
	);
};

const mapStateToProps = (state: any) => ({
	notifications: state.notifications,
	user: state.user,
	userNotifications: state.user.notifications,
});

export default connect(mapStateToProps, {})(NotificationPopOver);
