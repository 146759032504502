import {
	IonItem,
	IonItemOption,
	IonItemOptions,
	IonItemSliding,
	IonImg,
	IonBadge,
	IonLabel,
	IonAvatar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { deleteNotification } from "../../../../services/notifications";
import { connect } from "react-redux";
import { formatDateTime, getImageURL } from "../../../../services/util";
import { useHistory } from "react-router-dom";

interface Props {
	message: any;
}

const NotificationCard: React.FC<Props> = ({ message }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");

	const { id, img, title, body, status, created } = message;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	const _delete = (actId: any) => {
		deleteNotification(actId).then(() => {
			// getNotifications();
		});
	};

	return (
		<IonItemSliding>
			<IonItem onClick={() => history.push(`/admin/notification/${id}`)}>
				{imageURL && (
					<IonAvatar slot="start">
						<IonImg alt="" src={imageURL} />
					</IonAvatar>
				)}
				<IonLabel>
					<h2>{title}</h2>
					<h3>{body}</h3>
					<p>{formatDateTime(created)}</p>
				</IonLabel>

				{status === "draft" && (
					<IonBadge color="danger" slot="end">
						Draft
					</IonBadge>
				)}
				{status === "approved" && (
					<IonBadge color="warning" slot="end">
						Sending
					</IonBadge>
				)}
				{status === "sent" && (
					<IonBadge color="success" slot="end">
						Sent
					</IonBadge>
				)}
			</IonItem>
			<IonItemOptions side="end">
				<IonItemOption onClick={() => _delete(id)} color="danger">
					Delete
				</IonItemOption>
			</IonItemOptions>
		</IonItemSliding>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(NotificationCard);
