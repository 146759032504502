import { IonContent, IonImg, IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
	// addSubmission,
	// updateSubmission,
	getPromotion,
	// getSubmissions,
} from "../../../../services/promotions";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { getImageURL } from "../../../../services/util";
// import SubmissionResults from "../components/SubmissionResults";

export interface RouteParams {
	id: string;
}

export interface Props {
	user: any;
}

const Promotion: React.FC<Props> = ({ user }) => {
	let { id } = useParams<RouteParams>();
	const [promotion, setPromotion] = useState<any>({});
	const [imageURL, setImageURL] = useState<any>("");

	const {
		title,
		img,
		shortDesc,
		longDesc,
		callToAction,
		buttonText,
		buttonLink,
	} = promotion;

	useEffect(() => {
		if (img) {
			getImageURL(img).then((url: any) => {
				setImageURL(url);
			});
		}
	}, [img]);

	useEffect(() => {
		getPromotionData(id);
	}, [id]);

	async function getPromotionData(id: any) {
		getPromotion(id).then((res) => {
			setPromotion(res);
		});
	}

	useEffect(() => {
		if (user.uid !== "") {
		}
	}, [user]);

	return (
		<>
			<AppHeader title={title} />
			<IonContent className="ion-padding imageTop">
				{imageURL && (
					<IonImg src={imageURL} alt="" className="full-width-image" />
				)}
				{title && <h1>{title}</h1>}
				{shortDesc && <p>{shortDesc}</p>}
				{longDesc && <p>{longDesc}</p>}
				{callToAction && <p>{callToAction}</p>}
				{buttonText && buttonLink && (
					<IonButton type="button" href={buttonLink} target="_blank">
						{buttonText}
					</IonButton>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user.userDetails,
});

export default connect(mapStateToProps, {})(Promotion);
