import {
	IonLabel,
	IonItem,
	IonList,
	IonIcon,
	IonButton,
	IonContent,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { getActivations } from "../../../../services/activations";

import { add } from "ionicons/icons";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import DashboardActivationCard from "./DashboardActivationCard";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";

interface Props {
	activations: any;
	getActivations: any;
}

const DashboardActivationsView: React.FC<Props> = ({
	activations,
	getActivations,
}) => {
	const history = useHistory();
	const [aLength, setALength] = useState<any>(0);

	useEffect(() => {
		getActivations();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setALength(Object.keys(activations).length);
		//eslint-disable-next-line
	}, [activations]);

	return (
		<>
			<AppHeader title="Activations" />
			<IonContent className="ion-padding contentPane admin">
				<IonList>
					{aLength > 0 ? (
						<Fragment>
							{Object.values(activations)
								.filter((act: any) => act.status !== "draft")
								.map((activation: any, idx: any) => (
									<DashboardActivationCard key={idx} activation={activation} />
								))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">
							No activations available
						</IonLabel>
					)}
					<IonItem>
						<IonButton
							onClick={() => history.push("/admin/activation/new")}
							color="primary"
						>
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>{" "}
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, { getActivations })(
	DashboardActivationsView
);
