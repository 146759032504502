// Import Ionic Components
import {
	IonItem,
	IonCard,
	IonCardContent,
	IonCardTitle,
	IonLabel,
	IonText,
	IonList,
	IonListHeader,
} from "@ionic/react";

// Import React Libraries
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

interface Props {
	products: any;
	currentOrder: any;
	orders: any;
}

const OrderSummary: React.FC<Props> = ({ products, currentOrder, orders }) => {
	const [order, setOrder] = useState<any>({});
	const [cartTotal, setCartTotal] = useState<any>(0);

	const { cart } = order;

	const _cartTotal = () => {
		if (
			products &&
			Object.values(products).length > 0 &&
			cart &&
			Object.values(cart).length > 0
		) {
			return Object.entries(cart).reduce((accu: any, curr: any) => {
				if (products[curr[0]]) {
					let price = parseFloat(products[curr[0]].price);
					let qty = curr[1];
					return accu + price * qty;
				} else {
					return accu + 0;
				}
			}, 0);
		} else return 0;
	};

	useEffect(() => {
		if (products && orders[currentOrder]) {
			setOrder(orders[currentOrder]);
		}
		//eslint-disable-next-line
	}, [products, currentOrder, orders]);

	useEffect(() => {
		if (products && order) {
			setCartTotal(_cartTotal());
		}
		//eslint-disable-next-line
	}, [products, order]);

	return (
		<Fragment>
			<div className="order-summary">
				<IonCard>
					<IonCardContent>
						<IonCardTitle>Order Summary</IonCardTitle>
						<IonList lines="none">
							<IonItem className="cartRow">
								<IonList>
									<IonListHeader>Products</IonListHeader>
									{cart &&
										Object.entries(cart)
											.filter((item: any) => item[1] > 0)
											.map((order: any, idx: any) => (
												<IonItem key={idx} className="cartRow">
													{products && products[order[0]] && (
														<Fragment>
															<IonLabel>
																{order[1]} {" x "}
																{products[order[0]].name} @ $
																{parseFloat(products[order[0]].price).toFixed(
																	2
																)}
															</IonLabel>
															<IonText slot="end" className="ion-text-right">
																$
																{(
																	parseFloat(products[order[0]].price) *
																	parseFloat(order[1])
																).toFixed(2)}
															</IonText>
														</Fragment>
													)}
												</IonItem>
											))}
								</IonList>
							</IonItem>
							<IonItem className="cartRow">
								<IonLabel>
									<p>Sub Total</p>
								</IonLabel>
								<IonText className="ion-text-right">
									<p>${cartTotal.toFixed(2)}</p>
								</IonText>
							</IonItem>
							<IonItem className="cartRow">
								<IonLabel>
									<p>Delivery Fee</p>
								</IonLabel>
								<IonText className="ion-text-right">
									<p>$0.00</p>
								</IonText>
							</IonItem>
							<IonItem className="cartRow">
								<IonLabel>
									<p>GST (included)</p>
								</IonLabel>
								<IonText className="ion-text-right">
									<p>${((cartTotal / 115) * 15).toFixed(2)}</p>
								</IonText>
							</IonItem>
							<IonItem className="cartRow">
								<IonLabel>
									<p>
										<b>Total</b>
									</p>
								</IonLabel>
								<IonText className="ion-text-right">
									<p>
										<b>${cartTotal.toFixed(2)}</b>
									</p>
								</IonText>
							</IonItem>
						</IonList>
					</IonCardContent>
				</IonCard>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
	currentOrder: state.orders.order,
	orders: state.orders.orders,
});

export default connect(mapStateToProps, {})(OrderSummary);
