import {
	IonButton,
	IonInput,
	IonList,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardTitle,
	IonCardContent,
	IonContent,
} from "@ionic/react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import {
	processNewCardPayment,
	processExistingCardPayment,
	setCart,
	setCurrentOrder,
} from "../../../../services/orders";
import { getPaymentMethods } from "../../../../services/users";
import { toast } from "../../../../components/Toast/toast";
import { useHistory } from "react-router-dom";
import { setLoading } from "../../../../services/loading";

const useOptions = () => {
	const options = useMemo(
		() => ({
			style: {
				base: {
					fontSize: "18px",
					color: "#888888",
					letterSpacing: "0.025em",
					fontFamily: "Montserrat",
					fontWeight: 300,
					"::placeholder": {
						color: "#aab7c4",
					},
				},
				invalid: {
					color: "#9e2146",
				},
			},
		}),
		[]
	);

	return options;
};

interface Props {
	user: any;
	order: any;
	orders: any;
	getPaymentMethods: any;
	payment_methods: any;
	checkoutSubmit: any;
	checkoutSubmitDisabled: any;
	payments: any;
	setCart: any;
	setCurrentOrder: any;
	setLoading: any;
}

const CardForm: React.FC<Props> = ({
	user,
	order,
	orders,
	getPaymentMethods,
	payment_methods,
	checkoutSubmit,
	checkoutSubmitDisabled,
	payments,
	setCart,
	setCurrentOrder,
	setLoading,
}) => {
	const history = useHistory();
	const stripe = useStripe();
	const elements = useElements();
	const options = useOptions();

	const [cardName, setCardName] = useState<any>("");
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<any>("");
	const [paymentMethod, setPaymentMethod] = useState<any>("new");

	useEffect(() => {
		if (user && user.uid) {
			getPaymentMethods(user.uid);
		}

		//eslint-disable-next-line
	}, [user.uid]);

	// useEffect(() => {
	// 	console.log(order);

	// 	//eslint-disable-next-line
	// }, [order]);

	useEffect(() => {
		//Set first payment method as default
		if (payment_methods && Object.keys(payment_methods).length > 0) {
			var first_method = payment_methods[Object.keys(payment_methods)[0]];
			setPaymentMethod(first_method.id);
		}
	}, [payment_methods]);

	const _submit = async (e: any) => {
		e.preventDefault();

		setSubmitDisabled(true);
		setLoading(true, "Processing...");

		if (!stripe || !elements) {
			return;
		}
		checkoutSubmit();

		const cardElement = elements.getElement(CardElement);
		if (paymentMethod === "new" && cardElement) {
			const { setupIntent, error } = await stripe.confirmCardSetup(
				user.setup_secret,
				{
					payment_method: {
						card: cardElement,
						billing_details: {
							name: cardName,
						},
					},
				}
			);

			if (setupIntent) {
				// console.log(order);
				processNewCardPayment({
					uid: user.uid,
					setupIntent,
					order: orders[order],
				});
			} else if (error) {
				processErrorMessage(error);
				setLoading(false);
				setSubmitDisabled(false);
			}
		} else {
			processExistingCardPayment({
				uid: user.uid,
				payment_method: paymentMethod,
				order: orders[order],
			});
		}

		//setTimeout to set payment status as failed if X time passes and tidy up
		//
	};

	const _onChange = (e: any) => {
		setCardName(e.target.value);
	};

	const processErrorMessage = (error: any) => {
		//Stripe testing docs https://stripe.com/docs/testing
		// console.log(error);
		let message = error.message;
		console.log(error);
		switch (error.code) {
			case "card_declined":
				message =
					"Sorry! Your card has been declined. Please try another card.";
				if (error.decline_code === "generic_decline") {
					message =
						"Sorry! Your card appears to have declined. Please contact your bank.";
				} else if (error.decline_code === "insufficient_funds") {
					message =
						"Sorry! You appear to have insufficient funds. Please try another card.";
				} else if (error.decline_code === "insufficient_funds") {
					message =
						"Sorry! You appear to have insufficient funds. Please try another card.";
				}
				break;
			case "invalid_expiry_year_past":
			case "invalid_expiry_month_past":
				message =
					"Your card's expiration date is in the past. Please try another card.";
				break;
			case "expired_card":
				message = "Your card has expired. Please try another card.";
				break;
			case "resource_missing":
				if (error.param === "intent") {
					message =
						"Sorry, there has been a error while processing your payment. Please contact system admin and report error code SI1337";
					/**
					 * Setup intent is for the testing key, not the live key
					 * TODO remove setup intent from user and recreate.
					 * Should use a cloud function to automatically create a new
					 * setup_secret when it's removed from the user
					 **/
				}

				break;
			default:
				break;
		}
		setErrorMessage(message);
	};

	const _onCardElementChange = () => {
		setErrorMessage("");
	};

	useEffect(() => {
		if (orders && orders[order]) {
			if (orders[order].status === "processing") {
				setCart({});
				toast("Payment successful. Your order has been sent.", 3000);
				history.push(`/shop/order/${order}`);
				setCurrentOrder("");
				setLoading(false);
			}
		}

		//eslint-disable-next-line
	}, [orders]);

	return (
		<form onSubmit={_submit}>
			<IonCard id="cardForm">
				<IonCardContent>
					<IonCardTitle>Payment Details</IonCardTitle>
					{user.setup_secret ? (
						<IonGrid>
							<IonRow>
								<IonCol>
									{payment_methods &&
										Object.values(payment_methods).length > 0 && (
											<IonList lines="none">
												<IonRadioGroup
													value={paymentMethod}
													onIonChange={(e) => setPaymentMethod(e.detail.value)}
												>
													{Object.values(payment_methods).map(
														(elem: any, idx: any) => (
															<IonItem key={idx}>
																<IonRadio
																	slot="start"
																	value={elem.id}
																></IonRadio>
																<IonLabel className="payment-method">
																	<span className="card-brand">
																		{elem.card.brand}
																	</span>{" "}
																	ending in {elem.card.last4} (expires{" "}
																	{elem.card.exp_month}/{elem.card.exp_year})
																</IonLabel>
															</IonItem>
														)
													)}
													<IonItem>
														<IonLabel>Use a new payment method</IonLabel>
														<IonRadio slot="start" value="new"></IonRadio>
													</IonItem>
												</IonRadioGroup>
											</IonList>
										)}
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									{paymentMethod === "new" && (
										<Fragment>
											<IonList lines="none">
												<IonItem>
													<IonLabel position="floating">Name on Card</IonLabel>
													<IonInput
														type="text"
														id="cardName"
														name="cardName"
														value={cardName}
														onIonChange={_onChange}
													></IonInput>
												</IonItem>

												<IonItem>
													<IonContent>
														<div className="spacer15" />
														<CardElement
															options={options}
															onChange={() => _onCardElementChange()}
														/>
													</IonContent>
												</IonItem>
											</IonList>

											<div className="spacer30" />
											<div className="error-message" role="alert">
												{errorMessage}
											</div>
										</Fragment>
									)}
								</IonCol>
							</IonRow>
						</IonGrid>
					) : (
						<p>
							Sorry, something has gone wrong. Please kill the app and try
							logging in again.
						</p>
					)}
					<div className="space15" />
				</IonCardContent>
			</IonCard>
			<div className="cart-proceed-button">
				<IonButton
					type="submit"
					disabled={submitDisabled || checkoutSubmitDisabled}
				>
					Complete Order
				</IonButton>
			</div>
		</form>
	);
};

const mapStateToProps = (state: any) => ({
	orders: state.orders.orders,
	user: state.user.userDetails,
	order: state.orders.order,
	payment_methods: state.user.payment_methods,
	payments: state.user.payments,
});

export default connect(mapStateToProps, {
	getPaymentMethods,
	setCart,
	setCurrentOrder,
	setLoading,
})(CardForm);
