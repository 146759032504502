import {
  IonImg,
  IonBadge,
  IonIcon,
  IonLabel,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonItem,
  IonText,
  IonActionSheet,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { close, star, starOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "../../../../components/Toast/toast";
import {
  addActivation,
  setFeaturedActivation,
  updateActivation,
} from "../../../../services/activations";
import { pushToScreen } from "../../../../services/settings";
import { getImageURL } from "../../../../services/util";

interface Props {
  activation: any;
  subs: any;
}

const ActivationCard: React.FC<Props> = ({ activation, subs }) => {
  const history = useHistory();
  const [cImg, setCImg] = useState<any>("");
  const [imageURL, setImageURL] = useState<any>("");
  const [submissions, setSubmissions] = useState<any>([]);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const { id, img, featured } = activation;

  useEffect(() => {
    if (img !== "" && img !== cImg) {
      setCImg(img);
      getImageURL(img, "200").then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [activation]);

  useEffect(() => {
    if (subs) {
      setSubmissions(
        Object.values(subs).filter((doc: any) => doc.activation === id)
      );
    }
    //eslint-disable-next-line
  }, [activation, subs]);

  const _toggleFeatured = () => {
    if (featured === false) {
      setFeaturedActivation(id);
    } else {
      setFeaturedActivation();
    }
  };

  // const _compareStatus = (o1: any, o2: any) => {
  // 	return o1 === activation.status;
  // };

  // const _onSelect = (e: any) => {
  // 	let status = e.target.value;
  // 	updateActivation({ ...activation, status }, id);
  // 	//setActivation({ ...activation, status: e.target.value });
  // };

  const _setStatus = (status: any) => {
    updateActivation({ ...activation, status }, id);
    setTimeout(() => {
      setShowPopover({ showPopover: false, event: undefined });
    }, 50);
  };

  const _openStatusPopover = (e: any) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };

  const _duplicate = () => {
    addActivation({
      ...activation,
      title: `${activation.title} Copy`,
      status: "draft",
    }).then((res: any) => {
      if (res !== false) {
        history.push(`/admin/activation/${res}`);
        toast("Activation copied");
      }
    });
  };

  const _push = (type: any) => {
    pushToScreen({ type, id });
  };

  return (
    <IonCard>
      <IonActionSheet
        cssClass="my-custom-class ion-padding"
        // event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        buttons={[
          {
            text: "Draft",
            handler: () => _setStatus("draft"),
          },
          {
            text: "Inactive",
            handler: () => _setStatus("inactive"),
          },
          {
            text: "Active",
            handler: () => _setStatus("active"),
          },
          {
            text: "Expired",
            handler: () => _setStatus("expired"),
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="2" size="6">
              {imageURL && <IonImg alt="" src={imageURL} />}
            </IonCol>

            <IonCol>
              <IonRow>
                <IonCol>
                  <IonText>
                    <h1>{activation.title}</h1>
                  </IonText>
                </IonCol>
                <IonCol sizeSm="2" size="6">
                  <IonIcon
                    onClick={_toggleFeatured}
                    color="danger"
                    icon={featured ? star : starOutline}
                    className={"featuredToggle"}
                  />
                </IonCol>
                <IonCol
                  sizeSm="1"
                  size="6"
                  onClick={_openStatusPopover}
                  className="linked"
                >
                  {activation.status === "draft" && (
                    <IonBadge color="disabled">Draft</IonBadge>
                  )}
                  {activation.status === "inactive" && (
                    <IonBadge color="warning">Inactive</IonBadge>
                  )}
                  {activation.status === "active" && (
                    <IonBadge color="success">Active</IonBadge>
                  )}
                  {activation.status === "expired" && (
                    <IonBadge color="light">Expired</IonBadge>
                  )}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  {submissions && <div>Submissions: {submissions.length}</div>}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      <IonRow>
        <IonCol sizeSm="11" size="12">
          <IonButtons>
            <IonButton
              onClick={() => history.push(`/admin/activation/${activation.id}`)}
            >
              Edit
            </IonButton>
            <IonButton
              onClick={() =>
                history.push(`/admin/activation/${activation.id}/submissions`)
              }
            >
              Submissions
            </IonButton>
            <IonButton
              onClick={() =>
                history.push(`/admin/activation/${activation.id}/results`)
              }
            >
              Result
            </IonButton>
            <IonButton onClick={_duplicate}>Duplicate</IonButton>
            <IonButton onClick={() => _push("results")}>Push Results</IonButton>
            <IonButton onClick={() => _push("winner")}>Push Winner</IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

const mapStateToProps = (state: any) => ({
  subs: state.activations.submissions,
});

export default connect(mapStateToProps, {})(ActivationCard);
