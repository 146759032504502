import React from "react";
//import {
// IonToolbar,
// IonFooter,
// IonIcon,
// IonButtons,
// IonButton,
//} from "@ionic/react";
// import { useHistory } from "react-router-dom";

// import { arrowBack } from "ionicons/icons";

export const AppFooter: React.FC = () => {
	// const history = useHistory();

	// const _historyBack = () => {
	// 	history.goBack();
	// };
	return (
		<div></div>
		//<IonFooter>
		// 	<IonToolbar>
		// 		<IonButtons>
		// 			<IonButton onClick={_historyBack}>
		// 				<IonIcon icon={arrowBack}></IonIcon>
		// 			</IonButton>
		// 		</IonButtons>
		// 	</IonToolbar>
		// </IonFooter>
	);
};
