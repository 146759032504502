/* Auth */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_GOOGLE_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const MONITOR_USER_SUCCESS = "MONITOR_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_VOTES_SUCCESS = "GET_VOTES_SUCCESS";
export const GET_USER_NOTIFICATIONS_SUCCESS = "GET_USER_NOTIFICATIONS_SUCCESS";

/* ACTIVATIONS */
export const GET_ACTIVATIONS = "GET_ACTIVATIONS";
export const GET_SUBMISSIONS_SUCCESS = "GET_SUBMISSIONS_SUCCESS";

/* PROMOTIONS */
export const GET_PROMOS_SUCCESS = "GET_PROMOS";

/* SCHEDULE */
export const GET_SCHEDULE = "GET_SCHEDULE";
export const SET_TEAMS = "SET_TEAMS";
export const SET_GAMES = "SET_GAMES";

/* LADDERS */
export const GET_LADDERS_SUCCESS = "SET_LADDERS_SUCCESS";

/* SETTINGS */
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";

/* PRODUCTS */
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";

/* ORDERS */
export const GET_ADDTOCART_SUCCESS = "GET_ADDTOCART_SUCCESS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ADMIN_ORDERS_SUCCESS = "GET_ADMIN_ORDERS_SUCCESS";
export const SET_CURRENT_ORDER_SUCCESS = "SET_CURRENT_ORDER_SUCCESS";
export const SET_CART_SUCCESS = "SET_CART_SUCCESS";

export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";

/*** LOADING ***/
export const SET_LOADING = "SET_LOADING";

/*** CLOUD MESSAGES ***/
export const GET_CLOUDMESSAGES_SUCCESS = "GET_CLOUDMESSAGES_SUCCESS";

/*** NOTIFICATIONS ***/
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";

/*** MESSAGES ***/
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

/*** FEEDBACK ***/
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";

/*** REQUESTS ***/
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";

/*** SLIDES ***/
export const GET_SLIDES_SUCCESS = "GET_SLIDES_SUCCESS";

/*** PLAYERS ***/
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS";

/*** NEWS ***/
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_API_POSTS_SUCCESS = "GET_API_POSTS_SUCCESS";
