import { IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";
import LadderModule from "../../Ladders/components/LadderModule";
import ScheduleModule from "../../Schedules/components/ScheduleModule";

interface Props {
  settings: any;
}

const ScheduleLadderModule: React.FC<Props> = ({ settings }) => {
  const [promoImage, setPromoImage] = useState<any>("");
  const [scheduleBGImage, setScheduleBGImage] = useState<any>("");
  const [ladderBGImage, setLadderBGImage] = useState<any>("");

  useEffect(() => {
    if (settings["schedule-promo-image"]) {
      getImageURL(settings["schedule-promo-image"].value).then((url: any) => {
        setPromoImage(url);
      });
    }
    if (settings["schedule-dashboard-background-image"]) {
      getImageURL(settings["schedule-dashboard-background-image"].value).then(
        (url: any) => {
          setScheduleBGImage(url);
        }
      );
    }
    if (settings["ladder-dashboard-background-image"]) {
      getImageURL(settings["ladder-dashboard-background-image"].value).then(
        (url: any) => {
          setLadderBGImage(url);
        }
      );
    }
  }, [settings]);

  return (
    <div id="scheduleLadder">
      <div
        className="schedule-section"
        style={{ backgroundImage: `url("${scheduleBGImage}")` }}
      >
        <h1 className="section-header">Match Schedule</h1>
        <div className="spacer30" />
        <ScheduleModule format="abbrev" />
      </div>
      {promoImage && (
        <a href={settings["schedule-promo-image-URL"].value}>
          <IonImg src={promoImage} className="activation-card" />
        </a>
      )}
      <div
        className="ladder-section"
        style={{ backgroundImage: `url("${ladderBGImage}")` }}
      >
        <h1 className="section-header">Competition Ladder</h1>
        <div className="spacer30" />
        <LadderModule />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(ScheduleLadderModule);
