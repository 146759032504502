import { IonButton, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { getSchedule, getTeams } from "../../../../services/schedules";
import MatchCard from "./MatchCard";

interface Props {
	schedules: any;
	format: any;
	teams: any;
	getSchedule: any;
	getTeams: any;
}

const ScheduleModule: React.FC<Props> = ({
	schedules,
	getSchedule,
	teams,
	format,
	// getTeams,
}) => {
	const history = useHistory();
	const [results, setResults] = useState<any>({});
	const [upcoming, setUpcoming] = useState<any>({});
	const [liveNow, setLiveNow] = useState<any>({});
	const [segment, setSegment] = useState<any>("upcoming");

	function getData() {
		getSchedule();
		// getTeams();
	}

	useEffect(() => {
		getData();
		//eslint-disable-next-line
	}, []);

	function sortByDateASC(a: any, b: any) {
		if (a.matchDate < b.matchDate) return -1;
		else if (a.matchDate > b.matchDate) return 1;
		else return 0;
	}

	function sortByDateDESC(a: any, b: any) {
		if (a.matchDate > b.matchDate) return -1;
		else if (a.matchDate < b.matchDate) return 1;
		else return 0;
	}

	useEffect(() => {
		if (Object.keys(schedules).length > 0) {
			var matchResults = Object.values(schedules).filter(
				(game: any) => game.status === "post"
			);
			//TODO order by date DESC
			setResults(matchResults.sort(sortByDateDESC));

			var matchUpcoming = Object.values(schedules).filter(
				(game: any) => game.status === "pre"
			);
			//TODO order by date ASC
			setUpcoming(matchUpcoming.sort(sortByDateASC));

			var matchLiveNow = Object.values(schedules).filter(
				(game: any) => game.status === "live"
			);
			setLiveNow(matchLiveNow);
			if (matchLiveNow.length > 0) {
				setSegment("live");
			}
		}
		//eslint-disable-next-line
	}, [schedules, teams]);
	return (
		<Fragment>
			<IonSegment
				onIonChange={(e) => setSegment(e.detail.value)}
				value={segment}
				className="schedule-segment"
			>
				{liveNow.length > 0 && (
					<IonSegmentButton value="live">Live Now</IonSegmentButton>
				)}
				<IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
				<IonSegmentButton value="results">Results</IonSegmentButton>
			</IonSegment>
			{segment === "live" &&
				Object.keys(liveNow).length > 0 &&
				Object.values(liveNow).map((elem: any) => (
					<MatchCard key={elem.id} match={elem} />
				))}

			{segment === "upcoming" && (
				<Fragment>
					{format === "abbrev" ? (
						<Fragment>
							{Object.keys(upcoming).length > 0 &&
								Object.values(upcoming).map((elem: any, idx: any) => (
									<Fragment key={elem.id}>
										{idx < 3 && <MatchCard match={elem} />}
									</Fragment>
								))}
							{Object.keys(upcoming).length >= 3 && (
								<p className="center-text">
									<IonButton onClick={() => history.push("/scheduleladder")}>
										See All Matches & Results
									</IonButton>
								</p>
							)}{" "}
						</Fragment>
					) : (
						<Fragment>
							{Object.keys(upcoming).length > 0 &&
								Object.values(upcoming).map((elem: any, idx: any) => (
									<Fragment key={elem.id}>
										<MatchCard match={elem} />
									</Fragment>
								))}
						</Fragment>
					)}
				</Fragment>
			)}
			{segment === "results" &&
				Object.keys(results).length > 0 &&
				Object.values(results).map((elem: any) => (
					<MatchCard key={elem.id} match={elem} />
				))}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	schedules: state.schedules.schedule,
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, { getSchedule, getTeams })(
	ScheduleModule
);
