import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonIcon,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import {
  downloadOutline,
  logInOutline,
  star,
  starOutline,
  trash,
} from "ionicons/icons";
import { deleteSlide, setPrimarySlide } from "../../../../services/slides";
import { getImageURL } from "../../../../services/util";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { pushToScreen } from "../../../../services/settings";

interface Props {
  message: any;
  settings: any;
  format?: any;
  index?: any;
}

const AdminSlideCard: React.FC<Props> = ({
  message,
  settings,
  format = "default",
  index = "",
}) => {
  const history = useHistory();
  const [imageURL, setImageURL] = useState<any>("");
  const [downloadURL, setDownloadURL] = useState<any>("");
  // const [inPlaylist, setInPlaylist] = useState<any>(false);

  const { id, name, img, body, primary, order } = message;

  useEffect(() => {
    if (img !== "") {
      getImageURL(img, "600").then((url: any) => {
        setImageURL(url);
      });
      getImageURL(img, "full").then((url: any) => {
        setDownloadURL(url);
      });
    } else {
      setImageURL("");
      setDownloadURL("");
    }
    //eslint-disable-next-line
  }, [img, id]);

  const _delete = () => {
    deleteSlide(id);
  };

  const _edit = () => {
    history.push(`/admin/slide/${id}`);
  };

  const _push = () => {
    pushToScreen({ type: "slide", id });
  };

  const _togglePrimary = () => {
    if (primary === false) {
      setPrimarySlide(id);
    } else {
      setPrimarySlide();
    }
  };

  return (
    <IonCard className="admin-message-card" style={{ width: "100%" }}>
      <IonCardContent>
        <IonRow>
          <IonCol size="2">
            <img alt="" src={imageURL} />
          </IonCol>
          <IonCol size="2">
            <p>{body}</p>
            <h3>
              <b>{name}</b>
            </h3>
            <h3>Sort Order: {order}</h3>
          </IonCol>
          <IonCol sizeSm="1" size="6">
            <IonIcon
              onClick={_togglePrimary}
              color="danger"
              icon={primary ? star : starOutline}
              className={"featuredToggle"}
            />
          </IonCol>
        </IonRow>
      </IonCardContent>
      <IonRow>
        <IonCol size="11">
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={_edit}>EDIT</IonButton>
              {downloadURL && (
                <IonButton href={downloadURL} target="_blank" download="true">
                  <IonIcon icon={downloadOutline} />
                </IonButton>
              )}
              <IonButton onClick={_push} target="_blank" download="true">
                <IonIcon icon={logInOutline} slot="start" />
                PUSH
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCol>
        <IonCol size="1">
          <IonButtons>
            <IonButton onClick={_delete} target="_blank" download="true">
              <IonIcon icon={trash} />
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

const mapStateTopProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateTopProps, {})(AdminSlideCard);
