import { IonContent } from "@ionic/react";
import React from "react";

import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../components/Header/AppFooter";
import OrdersView from "./OrdersView";

interface Props {}

const DashboardOrdersView: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Orders" />
			<IonContent className="ion-padding contentPane admin">
				<OrdersView />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(DashboardOrdersView);
