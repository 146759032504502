import * as types from "../actionTypes";

export function setCurrentUser(payload) {
	// console.log(payload);
	return { type: types.SET_CURRENT_USER, payload };
}
export function monitorUserSuccess(payload) {
	return { type: types.MONITOR_USER_SUCCESS, payload };
}

export function addUser(payload) {
	return { type: types.ADD_NEW_USER, payload };
}

export function logUserOut() {
	return { type: types.LOGOUT_USER, payload: "" };
}

export function getPaymentMethodsSuccess(payload) {
	return { type: types.GET_PAYMENT_METHODS_SUCCESS, payload };
}

export function getPaymentsSuccess(payload) {
	return { type: types.GET_PAYMENTS_SUCCESS, payload };
}

export function getVotesSuccess(payload) {
	return { type: types.GET_VOTES_SUCCESS, payload };
}

export function setLoggedIn(payload) {
	return { type: types.SET_LOGGED_IN, payload };
}

export function getUserNotificationsSuccess(payload) {
	return { type: types.GET_USER_NOTIFICATIONS_SUCCESS, payload };
}
