import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonCol,
  IonRow,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import AdminMessageCard from "./AdminMessageCard";
import { connect } from "react-redux";
import { sortByCreated } from "../../../../services/util";
import { arrowDown, arrowUp, barChartSharp } from "ionicons/icons";
import {
  deleteAllMessages,
  hideAllMessages,
} from "../../../../services/messages";

interface Props {
  messages: any;
  settings: any;
}

const MessagesView: React.FC<Props> = ({ messages, settings }) => {
  const [segment, setSegment] = useState<any>("pending");
  const [allMessages, setMessages] = useState<any>("");
  const [pending, setPending] = useState<any>("");
  const [approved, setApproved] = useState<any>("");
  const [hidden, setHidden] = useState<any>("");
  // const [playlist, setPlaylist] = useState<any>([]);
  const [sortOrder, setSortOrder] = useState<any>("ASC");

  useEffect(() => {
    setMessages(
      Object.values(messages).sort((a, b) => sortByCreated(a, b, sortOrder))
    );
  }, [messages, sortOrder]);

  useEffect(() => {
    if (allMessages) {
      setPending(allMessages.filter((el: any) => el.status === "pending"));
      setApproved(allMessages.filter((el: any) => el.status === "approved"));
      setHidden(allMessages.filter((el: any) => el.status === "hidden"));
    }
  }, [allMessages]);

  // useEffect(() => {
  //   if (settings["admin-message-playlist-default"]) {
  //     //let playlist = settings["admin-message-playlist-default"].value;
  //     //setPlaylist(playlist);
  //   }
  //   //eslint-disable-next-line
  // }, [settings]);

  return (
    <Fragment>
      <IonRow>
        <IonCol>
          <IonButtons>
            {messages && (
              <IonButton disabled>
                <IonIcon slot="start" icon={barChartSharp} />
                Message Count: {Object.values(messages).length}
              </IonButton>
            )}
            <IonButton
              onClick={() => {
                setSortOrder("ASC");
              }}
            >
              <IonIcon slot="start" icon={arrowDown} />
              Oldest First
            </IonButton>
            <IonButton
              onClick={() => {
                setSortOrder("DESC");
              }}
            >
              <IonIcon slot="start" icon={arrowUp} />
              Latest First
            </IonButton>
          </IonButtons>
        </IonCol>
        <IonCol>
          <IonButtons>
            <IonButton onClick={hideAllMessages}>Hide All</IonButton>
            <IonButton onClick={deleteAllMessages}>Delete All</IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>

      <IonToolbar>
        <IonSegment
          onIonChange={(e) => setSegment(e.detail.value)}
          value={segment}
        >
          <IonSegmentButton value="pending">Inbox</IonSegmentButton>
          <IonSegmentButton value="approved">Approved</IonSegmentButton>

          {/* <IonSegmentButton value="all">All</IonSegmentButton> */}
          {/* <IonSegmentButton value="playlist">Playlist</IonSegmentButton> */}
          <IonSegmentButton value="hidden">Hidden</IonSegmentButton>
        </IonSegment>
      </IonToolbar>

      {segment === "pending" && (
        <Fragment>
          {pending && pending.length > 0 ? (
            pending.map((msg: any, idx: any) => (
              <AdminMessageCard key={idx} message={msg} />
            ))
          ) : (
            <IonLabel className="ion-padding">No pending messages</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "approved" && (
        <Fragment>
          {approved && approved.length > 0 ? (
            approved.map((msg: any, idx: any) => (
              <AdminMessageCard key={idx} message={msg} />
            ))
          ) : (
            <IonLabel className="ion-padding">No approved messages</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "hidden" && (
        <Fragment>
          {hidden && hidden.length > 0 ? (
            hidden.map((msg: any, idx: any) => (
              <AdminMessageCard key={idx} message={msg} />
            ))
          ) : (
            <IonLabel className="ion-padding">No hidden messages</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "all" && (
        <Fragment>
          {allMessages && allMessages.length > 0 ? (
            allMessages.map((msg: any, idx: any) => (
              <AdminMessageCard key={idx} message={msg} />
            ))
          ) : (
            <IonLabel className="ion-padding">No messages</IonLabel>
          )}
        </Fragment>
      )}
      {/* {segment === "playlist" && (
        <Fragment>
          {playlist && playlist.length > 0 ? (
            playlist.map((msg: any, idx: any) => (
              <AdminMessageCard
                key={idx}
                message={messages[msg]}
                format={"playlist"}
                index={idx}
              />
            ))
          ) : (
            <IonLabel className="ion-padding">No messages</IonLabel>
          )}
        </Fragment>
      )} */}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  messages: state.messages,
  settings: state.settings,
});

export default connect(mapStateToProps, {})(MessagesView);
