import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IonContent, IonText, IonImg } from "@ionic/react";
import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";

export interface Props {
  settings: any;
}

const About: React.FC<Props> = ({ settings }) => {
  const [headerImage, setHeaderImage] = useState<any>("");
  const [footerImage, setFooterImage] = useState<any>("");
  const [footerImageURL, setFooterImageURL] = useState<any>("");

  useEffect(() => {
    if (settings["about-header-image"]) {
      getImageURL(settings["about-header-image"].value).then((url: any) => {
        setHeaderImage(url);
      });
    }
    if (settings["about-footer-image"]) {
      getImageURL(settings["about-footer-image"].value).then((url: any) => {
        setFooterImage(url);
      });
    }
    if (settings["about-footer-image-URL"]) {
      setFooterImageURL(settings["about-footer-image-URL"].value);
    }
  }, [settings]);

  return (
    <>
      <AppHeader title="About This App" />
      <IonContent className="ion-padding about-page">
        <div className="content-wrapper">
          {headerImage && (
            <IonImg src={headerImage} className="full-width-image" />
          )}
          <div className="spacer30" />
          <IonText>
            <p>
              <b>Fanatical apps create the best fan experiences possible.</b>
            </p>

            <p>
              Our aim is to make fans feel they are more than just passive,
              ticket-buying spectators. We want to create stadium and festival
              experiences where every fan:
            </p>
            <ol>
              <li>
                Feels they are a <i>truly valued guest</i>
              </li>
              <li>
                Has the opportunity to play an active, interactive, and
                atmosphere-enhancing role in <i>their</i> event
              </li>
            </ol>
            <p>
              By delivering fans a better all-round experience, we help create
              events that are not only more enjoyable and entertaining, but that
              also offer opportunities for event organisers, sports and
              commercial partners to build far higher value connections with
              their audiences.
            </p>
            <p>
              To find out more about Fanatical apps, visit our website{" "}
              <a href="https://fanatical.app">https://fanatical.app</a>
            </p>
            <p>
              If you'd like to contact someone regarding Rams HQ please submit
              feedback via the app or use the contact details on the{" "}
              <a href="https://canterburyrams.basketball/contact">
                Canterbury Rams website
              </a>
            </p>
          </IonText>
          <IonText>
            <h3>Account Deletion</h3>
            <p>
              To initiate deletion of your account and associated data, please
              use the feedback function in-app, via email to
              lucy@canterburyrams.basketball, or using the form on our{" "}
              <a href="https://canterburyrams.basketball/ramshq-privacy/">
                privacy policy
              </a>{" "}
              page. We will initiate the account deletion process immediately
              and notify you when the process is completed.
            </p>
          </IonText>
          <div className="spacer60" />
          {footerImage && (
            <Fragment>
              {footerImageURL ? (
                <a href={footerImageURL}>
                  <IonImg
                    src={footerImage}
                    className="full-width-image footer"
                  />
                </a>
              ) : (
                <IonImg src={footerImage} className="full-width-image footer" />
              )}
            </Fragment>
          )}
        </div>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, {})(About);
