import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonToggle,
	IonItemGroup,
	IonItemDivider,
	IonSelect,
	IonSelectOption,
	IonCard,
	IonCardContent,
	IonCol,
	IonGrid,
	IonIcon,
	IonList,
	IonRow,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	addGame,
	deleteGame,
	getSchedule,
	updateGame,
} from "../../../../services/schedules";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { trash } from "ionicons/icons";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
	id: string;
}

interface Props {
	schedules: any;
	teams: any;
	getSchedule: any;
}

const AdminSchedule: React.FC<Props> = ({ schedules, teams, getSchedule }) => {
	const history = useHistory();

	const playerInit = {
		p_name: "",
		p_number: "",
		p_pos: "",
		p_pid: "_" + Math.random().toString(36).substr(2, 9),
	};

	const defaultState = {
		active: false,
		matchDate: "",
		matchTime: "",
		homeTeam: "",
		awayTeam: "",
		venue: "",
		ticketsLink: "",
		recapLink: "",
		livestreamLink: "",
		previewLink: "",
		statsLink: "",
		season: "",
		awayScore: "",
		homeScore: "",
		status: "pre",
		synopsisPre: "",
		synopsisPost: "",
		img: "",
		oppositionRoster: [],
	};

	let { id } = useParams<RouteParams>();
	const [game, setGame] = useState<any>(defaultState);
	const [homeTeam, setHomeTeam] = useState<any>(defaultState);
	const [awayTeam, setAwayTeam] = useState<any>(defaultState);
	const [status, setStatus] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const {
		active,
		matchDate,
		matchTime,
		venue,
		ticketsLink,
		recapLink,
		livestreamLink,
		previewLink,
		statsLink,
		//season,
		awayScore,
		homeScore,
		synopsisPre,
		synopsisPost,
		img,
	} = game;

	useEffect(() => {
		if (schedules[id]) {
			setGame({ ...game, ...schedules[id] });
			setHomeTeam(schedules[id].homeTeam);
			setAwayTeam(schedules[id].awayTeam);
			setStatus(schedules[id].status);
		}
		//eslint-disable-next-line
	}, [id, schedules]);

	// useEffect(() => {
	// 	console.log(game);
	// 	//eslint-disable-next-line
	// }, [game]);

	// useEffect(() => {
	// 	console.log(game);
	// }, [game]);

	const _onChange = (e: any) => {
		if (["p_name", "p_number", "p_pos"].includes(e.target.name)) {
			let oppositionRoster = game.oppositionRoster;
			oppositionRoster[e.target.dataset.id][e.target.name] = e.target.value;
			if (game.oppositionRoster !== oppositionRoster) {
				setGame({ ...game, oppositionRoster });
			}
		} else {
			setGame({ ...game, [e.target.name]: e.target.value });
		}
	};

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// TODO check all inputs are valid
		// if (game.name.trim() === "" || game.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a message and an image");
		// }
		if (id === "new") {
			addGame({ ...game, homeTeam, awayTeam, status })
				.then((res: any) => {
					if (res !== false) {
						console.log(res);
						history.push(`/admin/schedule/${res}`);
						toast("game saved");
					}
				})
				.then(() => {
					getSchedule();
				});
		} else {
			updateGame({ ...game, homeTeam, awayTeam, status }, id).then((res) => {
				if (res) {
					toast("game updated");
				}
			});
		}
		setDisableSubmit(false);
	};

	const _back = () => {
		history.push("/admin/schedules");
	};

	const _delete = () => {
		deleteGame(id);
		_back();
	};

	const _onToggle = (option: any) => {
		setGame({ ...game, [option]: !game[option] });
	};

	// useEffect(() => {
	// 	console.log(game);
	// }, [game]);

	const _onSelect = (e: any) => {
		//console.log(e.target.value);
		var name = e.target.name;
		// console.log(name);
		// console.log(e.target.value);
		if (name === "homeTeam") setHomeTeam(e.target.value);
		else if (name === "awayTeam") setAwayTeam(e.target.value);
		else if (name === "status") setStatus(e.target.value);
	};

	const _compareHomeTeam = (o1: any, o2: any) => {
		return o1 === homeTeam;
	};

	const _compareAwayTeam = (o1: any, o2: any) => {
		// console.log(o1);
		// console.log(o2);
		return o1 === awayTeam;
	};

	const _compareStatus = (o1: any, o2: any) => {
		// console.log(o1);
		// console.log(o2);
		return o1 === status;
	};

	const _addPlayer = (e: any) => {
		let oppositionRoster = game.oppositionRoster;
		oppositionRoster.push(playerInit);
		console.log(oppositionRoster);
		setGame({ ...game, oppositionRoster });
	};

	const _removePlayer = (i: number) => {
		let oppositionRoster = game.oppositionRoster;
		oppositionRoster.splice(i, 1);
		setGame({ ...game, oppositionRoster });
	};

	// useEffect(() => {
	// 	console.log(game.oppositionRoster);
	// }, [game.oppositionRoster]);

	const _onPhotoChange = (e: any) => {
		setGame({ ...game, img: e.image.fullPath });
	};

	return (
		<>
			<AppHeader title={`${id === "new" ? "Add" : "Edit"} Game`} />
			<IonContent className="ion-padding admin">
				<IonTitle>{id === "new" ? "Add" : "Edit"} Game </IonTitle>
				<form onSubmit={_submit}>
					<IonItemGroup>
						<IonItem>
							<IonLabel>Active?</IonLabel>
							<IonToggle
								checked={active}
								onClick={() => _onToggle("active")}
								name="active"
								id="active"
							/>
						</IonItem>
						<IonItemDivider>
							<IonLabel>GAME COPY</IonLabel>
						</IonItemDivider>
						<IonItem>
							<IonLabel position="stacked">Image</IonLabel>
							<div className="spacer5" />
							<PhotoUploadInput
								name="img"
								id="img"
								value={img}
								onChange={_onPhotoChange}
							/>
						</IonItem>
						<IonItem>
							<IonLabel>Game Status</IonLabel>
							<IonSelect
								compareWith={_compareStatus}
								value={status}
								name="status"
								onIonChange={_onSelect}
								okText="Okay"
								cancelText="Dismiss"
								placeholder="Select One"
							>
								<IonSelectOption value="pre">Pre-Game</IonSelectOption>
								<IonSelectOption value="live">Live</IonSelectOption>
								<IonSelectOption value="post">Post-Game</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Home Team</IonLabel>
							<IonSelect
								compareWith={_compareHomeTeam}
								value={homeTeam}
								name="homeTeam"
								onIonChange={_onSelect}
								okText="Okay"
								cancelText="Dismiss"
								placeholder="Select One"
							>
								{Object.values(teams).map((team: any) => (
									<IonSelectOption key={team.id} value={team.id}>
										{team.name}
									</IonSelectOption>
								))}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Away Team</IonLabel>
							<IonSelect
								compareWith={_compareAwayTeam}
								value={awayTeam}
								name="awayTeam"
								onIonChange={_onSelect}
								okText="Okay"
								cancelText="Dismiss"
								placeholder="Select One"
							>
								{Object.values(teams).map((team: any) => {
									if (team.id !== homeTeam)
										return (
											<IonSelectOption key={team.id} value={team.id}>
												{team.name}
											</IonSelectOption>
										);

									return false;
								})}
							</IonSelect>
						</IonItem>
						{["live", "post"].includes(status) && (
							<Fragment>
								<IonItem>
									<IonLabel position="floating">Home Score</IonLabel>
									<IonInput
										type="number"
										id="homeScore"
										name="homeScore"
										value={homeScore}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
								<IonItem>
									<IonLabel position="floating">Away Score</IonLabel>
									<IonInput
										type="number"
										id="awayScore"
										name="awayScore"
										value={awayScore}
										onIonChange={_onChange}
									></IonInput>
								</IonItem>
							</Fragment>
						)}
						<IonItem>
							<IonLabel position="floating">Match Date</IonLabel>
							<IonInput
								type="date"
								id="matchDate"
								name="matchDate"
								value={matchDate}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Match Time</IonLabel>
							<IonInput
								type="time"
								id="matchTime"
								name="matchTime"
								value={matchTime}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Venue</IonLabel>
							<IonTextarea
								id="venue"
								name="venue"
								value={venue}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						{status === "pre" && (
							<IonItem>
								<IonLabel position="floating">Synopsis Pre-Game</IonLabel>
								<IonTextarea
									id="synopsisPre"
									name="synopsisPre"
									value={synopsisPre}
									onIonChange={_onChange}
								></IonTextarea>
							</IonItem>
						)}
						{["live", "post"].includes(status) && (
							<IonItem>
								<IonLabel position="floating">Synopsis Post-Game</IonLabel>
								<IonTextarea
									id="synopsisPost"
									name="synopsisPost"
									value={synopsisPost}
									onIonChange={_onChange}
								></IonTextarea>
							</IonItem>
						)}
						<IonItemDivider>
							<IonLabel>LINKS</IonLabel>
						</IonItemDivider>
						<IonItem>
							<IonLabel position="floating">Tickets Link</IonLabel>
							<IonInput
								type="text"
								id="ticketsLink"
								name="ticketsLink"
								value={ticketsLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Preview Link</IonLabel>
							<IonInput
								type="text"
								id="previewLink"
								name="previewLink"
								value={previewLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Recap Link</IonLabel>
							<IonInput
								type="text"
								id="recapLink"
								name="recapLink"
								value={recapLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Livestream Link</IonLabel>
							<IonInput
								type="text"
								id="livestreamLink"
								name="livestreamLink"
								value={livestreamLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Live Stats Link</IonLabel>
							<IonInput
								type="text"
								id="statsLink"
								name="statsLink"
								value={statsLink}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItemDivider>
							<IonLabel>Opposition Players</IonLabel>
						</IonItemDivider>
						<IonItem>
							<IonList>
								<IonItemDivider>
									<IonLabel>OPTIONS</IonLabel>
								</IonItemDivider>

								<IonItem>
									<IonList className="full-width">
										{game.oppositionRoster &&
											Object.keys(game.oppositionRoster).map(
												(player: any, idx: any) => {
													//console.log(idx);
													let nameId = `name-${idx}`,
														numId = `num-${idx}`,
														posId = `pos-${idx}`,
														// valueId = `value-${idx}`,
														p_pid = `player-${idx}`;
													return (
														<Fragment key={idx}>
															<IonCard className="repeater-card">
																<IonCardContent>
																	<IonGrid>
																		<IonRow>
																			<IonCol>
																				<IonItem className="no-padding">
																					<IonLabel position="floating">
																						Name
																					</IonLabel>
																					<IonInput
																						type="text"
																						id={nameId}
																						name="p_name"
																						data-id={`${idx}`}
																						value={
																							game.oppositionRoster[`${idx}`]
																								.p_name
																						}
																						onIonChange={_onChange}
																					></IonInput>
																				</IonItem>
																				<IonItem className="no-padding">
																					<IonLabel position="floating">
																						Number
																					</IonLabel>
																					<IonInput
																						type="text"
																						id={numId}
																						name="p_number"
																						data-id={`${idx}`}
																						value={
																							game.oppositionRoster[`${idx}`]
																								.p_number
																						}
																						onIonChange={_onChange}
																					></IonInput>
																				</IonItem>
																				<IonItem className="no-padding">
																					<IonLabel position="floating">
																						Position
																					</IonLabel>
																					<IonInput
																						type="text"
																						id={posId}
																						name="p_pos"
																						data-id={`${idx}`}
																						value={
																							game.oppositionRoster[`${idx}`]
																								.p_pos
																						}
																						onIonChange={_onChange}
																					></IonInput>
																				</IonItem>

																				<IonInput
																					type="text"
																					id={p_pid}
																					name="p_pid"
																					data-id={`${idx}`}
																					value={
																						game.oppositionRoster[`${idx}`]
																							.p_pid
																					}
																					readonly
																					hidden
																					onIonChange={_onChange}
																				></IonInput>
																			</IonCol>
																			<IonCol size="1">
																				<IonIcon
																					onClick={() => _removePlayer(idx)}
																					icon={trash}
																					className="trash linked"
																				/>
																			</IonCol>
																		</IonRow>
																	</IonGrid>
																</IonCardContent>
															</IonCard>
														</Fragment>
													);
												}
											)}
										<IonItem>
											<IonButton onClick={_addPlayer}>Add player</IonButton>
										</IonItem>
									</IonList>
								</IonItem>
							</IonList>
						</IonItem>
					</IonItemGroup>

					<IonButton type="submit" color="primary" disabled={disableSubmit}>
						Save
					</IonButton>
					<IonButton onClick={_back} color="danger">
						Back
					</IonButton>
					<IonButton onClick={_delete} color="danger" fill="outline">
						Delete
					</IonButton>
				</form>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	schedules: state.schedules.schedule,
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, { getSchedule })(AdminSchedule);
