import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Ladder from "./Ladder";

export interface RouteParams {
  id: string;
}

interface Props {
  ladders: any;
  teams: any;
}

const LadderModule: React.FC<Props> = ({ ladders, teams }) => {
  const defaultState = {
    active: false,
    title: "",
    officialURL: "",
    data: [],
  };

  const [activeLadders, setActiveLadders] = useState<any>(defaultState);

  useEffect(() => {
    if (ladders) {
      setActiveLadders(
        Object.values(ladders).filter((ladder: any) => ladder.active)
      );
    }
  }, [ladders]);

  return (
    <div className="ladder-page">
      {activeLadders.length > 0 &&
        Object.values(activeLadders).map((ladder: any) => (
          <Ladder key={ladder.id} ladder={ladder} />
        ))}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ladders: state.ladders,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(LadderModule);
