import {
	IonImg,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	promotion: any;
}

const PromotionCard: React.FC<Props> = ({ promotion }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");

	useEffect(() => {
		if (promotion.cardImage) {
			getImageURL(promotion.cardImage).then((url: any) => {
				setImageURL(url);
			});
		} else if (promotion.img) {
			getImageURL(promotion.img).then((url: any) => {
				setImageURL(url);
			});
		}
	}, [promotion]);

	return (
		<IonCard
			button
			onClick={() => history.push(`/promotion/${promotion.id}`)}
			className="promo-card"
		>
			{imageURL && <IonImg alt="" src={imageURL} />}
			<IonCardHeader>
				<IonCardTitle>{promotion.title}</IonCardTitle>
			</IonCardHeader>
			<IonCardContent>{promotion.shortDesc}</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PromotionCard);
