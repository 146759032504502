//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
// import { addSetting, getSettingByName, updateSetting } from "../settings";
import * as actions from "./actions";

var db = firebase.firestore();

export function addSlide(payload: any) {
  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("slides")
    .add({ ...payload, created: today.toISOString() })
    .then((res) => {
      return res.id;
    })
    .catch((error) => {
      return false;
    });
}

export const getSlides = () => {
  return (dispatch: any, data: any) => {
    db.collection("slides").onSnapshot((docs) => {
      const data: any = {};
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getSlidesSuccess(data));
    });
  };
};

export const deleteSlide = (id: any) => {
  return db
    .collection("slides")
    .doc(id)
    .delete()
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(error);
      //throw error;
    });
};

export function updateSlide(payload: any, pid: string) {
  //console.log(payload);

  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("slides")
    .doc(pid)
    .update({ ...payload, updated: today.toISOString() })
    .then(() => {
      return pid;
    })
    .catch((error) => {
      return false;
    });
}

export function updateSlideOrder(pid: string, order: number) {
  return db
    .collection("slides")
    .doc(pid)
    .update({ order: order })
    .then((res) => {
      //   console.log(res);
      return pid;
    })
    .catch((error) => {
      return false;
    });
}

export const setPrimarySlide = async (id: any = null) => {
  //find post with primary status and resave as not primary
  return db
    .collection("slides")
    .where("primary", "==", true)
    .get()
    .then((docs) => {
      docs.forEach((doc: any) => {
        db.collection("slides")
          .doc(doc.id)
          .update({ primary: false })
          .then(() => {
            return true;
          });
      });
    })
    .then(() => {
      if (id) {
        db.collection("slides")
          .doc(id)
          .update({ primary: true })
          .then(() => {
            return true;
          });
      }
    });
};

export const changeStatus = (id: any, status: any) => {
  db.collection("slides").doc(id).update({ status: status });
};

// export const addToPlayList = (id: any, playlist: any = "default") => {
// 	let settingName = `admin-message-playlist-${playlist}`;

// 	getSettingByName(settingName).then((data) => {
// 		if (Object.values(data).length > 0) {
// 			let playlist = data[settingName].id;
// 			let value = data[settingName].value;
// 			if (!value.includes(id)) {
// 				value.push(id);
// 			}
// 			//console.log(value);
// 			updateSetting(playlist, value);
// 		} else {
// 			addSetting(settingName, [id]);
// 		}
// 	});
// };

// export const removeFromPlayList = (id: any, playlist: any = "default") => {
// 	let settingName = `admin-message-playlist-${playlist}`;

// 	getSettingByName(settingName).then((data) => {
// 		if (Object.values(data).length > 0) {
// 			let playlist = data[settingName].id;
// 			let value = data[settingName].value;
// 			if (value.includes(id)) {
// 				let idx = value.indexOf(id);
// 				value.splice(idx, 1);
// 			}
// 			//console.log(value);
// 			updateSetting(playlist, value);
// 		} else {
// 			addSetting(settingName, [id]);
// 		}
// 	});
// };
