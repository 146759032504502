import firebase from "firebase/app";
import "firebase/storage";
/**
 * Get URL of resized image
 *
 */
export const getImageURL = async (path: any, size: any = "600") => {
  if (path) {
    let refPath = path;
    var storage = firebase.storage();
    if (size !== "full") {
      const splitString = path.split(".");
      if (size !== "1080") {
        refPath =
          splitString[0] + "_" + size + "x" + size + "." + splitString[1];
      } else if (size === "1080") {
        refPath = splitString[0] + "_1920x1080." + splitString[1];
      }
    }

    var storageRef = storage.ref(refPath);
    if (storageRef) {
      return storageRef
        .getDownloadURL()
        .then((url: any) => {
          return url;
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  } else return "";
};

/**
 * Format a date string
 * @param date the date to be formatted
 * @param options the options object https://www.w3schools.com/jsref/jsref_tolocalestring.asp
 * @returns a formatted date string
 */
export const formatDate = (
  date: any,
  options: any = {
    formatMatcher: "basic",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
) => {
  if (date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleString("en-NZ", options);
  } else return "";
};

/**
 * Format a date string
 * @param date the date to be formatted
 * @param options the options object https://www.w3schools.com/jsref/jsref_tolocalestring.asp
 * @returns a formatted date string
 */
export const formatDateTime = (
  date: any,
  options: any = {
    formatMatcher: "basic",
    // year: "numeric",
    // month: "long",
    // day: "numeric",
    dateStyle: "medium",
    timeStyle: "short",
  }
) => {
  if (date) {
    var dateTime = new Date(date);
    return dateTime.toLocaleString("en-NZ", options);
  } else return "";
};

/**
 * Convert 24 hour time string to 12 hour
 * @param time The 24 hour time to convert
 * @returns 12 hour time as a string
 */
export const convertTimeTo12h = (time: any) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const sortByCreated = (a: any, b: any, order: any) => {
  if (order === "ASC") return sortByCreatedASC(a, b);
  else return sortByCreatedDESC(a, b);
};

export const sortByCreatedDESC = (a: any, b: any) => {
  if (a.created > b.created) return -1;
  else if (a.created < b.created) return 1;
  else return 0;
};

export const sortByCreatedASC = (a: any, b: any) => {
  if (a.created > b.created) return 1;
  else if (a.created < b.created) return -1;
  else return 0;
};

export const _sortPlayerByNumber = (a: any, b: any) => {
  return a.num - b.num;
};

export const _sortByName = (a: any, b: any) => {
  if (a.name > b.name) return 1;
  else if (a.name < b.name) return -1;
  else return 0;
};

export const colors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];

export const reds = [
  "#933A16",
  "#8D021F",
  "#A50021",
  "#DC143C",
  "#C51E4A",
  "#960018",
  "#E60026",
  "#DA2C43",
  "#58111A",
  "#65000B",
  "#ED2939",
  "#893F45",
  "#CE0029",
  "#AB4E52",
  "#C08081",
  "#BF4F51",
  "#FF7F7F",
  "#F08080",
  "#674846",
  "#E23D28",
  "#E34234",
  "#A91101",
  "#7C0902",
  "#660000",
  "#E44D2E",
  "#B31B1B",
  "#8B0000",
  "#B22222",
  "#CD5C5C",
  "#E62020",
  "#800000",
  "#841617",
  "#FF2400",
  "#D44500",
  "#D05340",
  "#905D5D",
];
