//import * as types from "./types";
import firebase from "firebase/app";
import "firebase/firestore";
// import { idText } from "typescript";
import { setSchedule, setTeams } from "./actions";

var db = firebase.firestore();

export const getSchedule = () => {
  return (dispatch: any, data: any) => {
    db.collection("schedules").onSnapshot((docs) => {
      const data: any = [];
      docs.forEach((doc) => (data[doc.id] = { ...doc.data(), id: doc.id }));
      dispatch(setSchedule(data));
    });
  };
};

export async function getGame(id: any) {
  let data;
  await db
    .collection("schedules")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        data = doc.data();
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });

  return data;
}

export async function addGame(payload: any) {
  const date = Date.now();
  const today = new Date(date);
  return db
    .collection("schedules")
    .add({ ...payload, created: today.toISOString() })
    .then(function (res) {
      return res.id;
    })
    .catch(function (error) {
      return false;
    });
}

export async function updateGame(payload: any, id: string) {
  console.log(payload);
  const date = Date.now();
  const today = new Date(date);

  await db
    .collection("schedules")
    .doc(id)
    .update({ ...payload, updated: today.toISOString() })
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });

  return true;
}

export async function deleteGame(id: any) {
  await db
    .collection("schedules")
    .doc(id)
    .delete()
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export const getTeams = () => {
  return (dispatch: any, data: any) => {
    db.collection("teams")
      .get()
      .then((docs) => {
        const data: any = {};
        docs.forEach((doc) => (data[doc.id] = { ...doc.data(), id: doc.id }));
        dispatch(setTeams(data));
      });
  };
};

export async function getTeam(id: any) {
  let data;
  await db
    .collection("teams")
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        data = doc.data();
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });

  return data;
}

export async function addTeam(payload: any) {
  const date = Date.now();
  const today = new Date(date);
  await db
    .collection("teams")
    .add({ ...payload, created: today.toISOString() })
    .then(function (res) {
      return res.id;
    })
    .catch(function (error) {
      return false;
    });
}

export async function updateTeam(payload: any, id: string) {
  const date = Date.now();
  const today = new Date(date);

  await db
    .collection("teams")
    .doc(id)
    .update({ ...payload, updated: today.toISOString() })
    .then(function () {
      return true;
    })
    .catch(function (error) {
      return false;
    });

  return true;
}

export async function deleteTeam(id: any) {
  await db
    .collection("teams")
    .doc(id)
    .delete()
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
