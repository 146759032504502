import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonItemOption,
	IonItemOptions,
	IonItemSliding,
	IonList,
	IonIcon,
	IonButton,
	IonBadge,
	IonItemDivider,
} from "@ionic/react";
import React, { Fragment } from "react";
import { getSchedule, getTeams, deleteTeam } from "../../../services/schedules";

import { trash, add } from "ionicons/icons";
import { connect } from "react-redux";
import { AppHeader } from "../../../components/Header/AppHeader";
import { useHistory } from "react-router-dom";
import TeamIcon from "./Teams/components/TeamIcon";
import ScheduleAdminCard from "./components/ScheduleAdminCard";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
	schedules: any;
	teams: any;
	getSchedule: any;
	getTeams: any;
}

const AdminSchedule: React.FC<Props> = ({ schedules, teams }) => {
	const history = useHistory();

	const _deleteTeam = (id: any) => {
		deleteTeam(id).then(() => {});
	};

	function sortByDateASC(a: any, b: any) {
		if (a.matchDate < b.matchDate) return -1;
		else if (a.matchDate > b.matchDate) return 1;
		else return 0;
	}

	return (
		<>
			<AppHeader title="Schedule" />
			<IonContent fullscreen className="ion-padding admin">
				<IonList>
					<IonItemDivider>
						<IonLabel>Games</IonLabel>
					</IonItemDivider>
					<IonItem className="ion-no-padding">
						<IonList className="full-width">
							{Object.keys(schedules).length > 0 ? (
								<Fragment>
									{Object.values(schedules)
										.sort(sortByDateASC)
										.map((match: any, idx: any) => (
											<ScheduleAdminCard key={idx} match={match} />
										))}
								</Fragment>
							) : (
								<IonLabel className="ion-padding">
									No matches schedules
								</IonLabel>
							)}
							<IonItem>
								<IonButton
									onClick={() => history.push("/admin/schedule/new")}
									color="primary"
								>
									<IonIcon icon={add} />
									Add New
								</IonButton>
							</IonItem>
						</IonList>
					</IonItem>
					<IonItemDivider>
						<IonLabel>Teams</IonLabel>
					</IonItemDivider>
					<IonItem>
						<IonList>
							{Object.keys(teams).length > 0 ? (
								<Fragment>
									{Object.values(teams).map((elem: any) => (
										<IonItemSliding key={elem.id}>
											<IonItem href={`/admin/team/${elem.id}`}>
												<TeamIcon team={elem} />
												<IonTitle>{elem.name}</IonTitle>
												{elem.active ? (
													<IonBadge color="success">Active</IonBadge>
												) : (
													<IonBadge color="light">Inactive</IonBadge>
												)}
											</IonItem>
											<IonItemOptions side="end">
												<IonItemOption
													onClick={() => _deleteTeam(elem.id)}
													color="danger"
												>
													<IonIcon icon={trash} />
												</IonItemOption>
											</IonItemOptions>
										</IonItemSliding>
									))}
								</Fragment>
							) : (
								<IonLabel className="ion-padding">No teams available</IonLabel>
							)}
							<IonItem>
								<IonButton
									onClick={() => history.push("/admin/team/new")}
									color="primary"
								>
									<IonIcon icon={add} />
									Add New
								</IonButton>
							</IonItem>
						</IonList>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	schedules: state.schedules.schedule,
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, { getSchedule, getTeams })(
	AdminSchedule
);
