import {
	IonContent,
	IonPage,
	IonButton,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonButtons,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import firebase from "firebase/app";
import { toast } from "../../../components/Toast/toast";
import "firebase/auth";
import "firebase/firestore";
import {
	loginWithGoogle,
	loginUser,
	loginAnonymously,
	loginWithFacebook,
	// loginWithApple,
	loginWithAppleWEB,
} from "../../../services/auth";
import {
	updateUser,
	loginUserUpdate,
	setUser,
	monitorUser,
} from "../../../services/users";
import { useHistory } from "react-router-dom";
//import { useDispatch } from "react-redux";
import { connect } from "react-redux";

import { logoGoogle, logoApple, logoFacebook } from "ionicons/icons";
import { setLoading } from "../../../services/loading";

import { isPlatform } from "@ionic/react";
import { getOrders } from "../../../services/orders";
const PLATFORM_ANDROID = isPlatform("android");
// const PLATFORM_IOS = isPlatform("ios");
const PLATFORM_MOBILEWEB = isPlatform("mobileweb");
// const PLATFORM_CAPACTIOR = isPlatform("capacitor");
const PLATFORM_DESKTOP = isPlatform("desktop");

interface Props {
	loggedIn: any;
	setUser: any;
	setLoading: any;
	monitorUser: any;
	getOrders: any;
}

const Home: React.FC<Props> = ({
	loggedIn,
	setUser,
	setLoading,
	monitorUser,
	getOrders,
}) => {
	const history = useHistory();

	const defaultState = {
		email: "",
		password: "",
	};

	const [credentials, setCredentials] = useState<any>(defaultState);

	// useEffect(() => {
	// 	if (loggedIn) {
	// 		history.replace("/dashboard");
	// 	}
	// 	//eslint-disable-next-line
	// }, [loggedIn]);

	const _googleLogin = () => {
		setLoading(true, "Logging in");
		loginWithGoogle()
			.then((res: any) => {
				if (res && res.user) {
					//TODO Refactor
					updateUser(res);
					setUser(res.user);
					monitorUser(res.user.uid);
					getOrders(res.user.uid);
					history.replace("/dashboard");
					toast("You are logged in!");
					setLoading(false);
				}
				// console.log(res);
			})
			.catch((error) => {
				console.log(error);
				toast("Login Failed");
				setLoading(false);
			});
	};

	const _appleLogin = () => {
		if (PLATFORM_MOBILEWEB || PLATFORM_DESKTOP) {
			setLoading(true, "Logging in");
			loginWithAppleWEB()
				.then((res: any) => {
					// console.log(res);
					if (res && res.user) {
						//TODO Refactor
						updateUser(res);
						setUser(res.user);
						//not having monitorUser and getOrders here caused issues with content loading
						monitorUser(res.user.uid);
						getOrders(res.user.uid);
						history.replace("/dashboard");
						toast("You are logged in!");
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					toast("Login Failed");
					setLoading(false);
				});
		}
	};

	const _facebookLogin = () => {
		setLoading(true, "Logging in");
		loginWithFacebook()
			.then((res: any) => {
				if (res && res.user) {
					//TODO Refactor
					setUser(res.user);
					updateUser(res);
					//not having monitorUser and getOrders here caused issues with content loading
					monitorUser(res.user.uid);
					getOrders(res.user.uid);
					toast("You are logged in!");
					setLoading(false);
				}
			})
			.catch((error) => {
				console.error(error);
				toast("Login Failed");
				setLoading(false);
			});
	};

	const _loginAnonymously = () => {
		setLoading(true, "Logging in");
		loginAnonymously()
			.then((res: any) => {
				if (res && res.user) {
					//TODO Refactor
					setUser(res.user);
					updateUser(res);
					//not having monitorUser and getOrders here caused issues with content loading
					monitorUser(res.user.uid);
					getOrders(res.user.uid);
					toast("You are logged in!");
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
				toast("Login Failed. Please try again.");
				setLoading(false);
			});
	};

	firebase
		.auth()
		.getRedirectResult()
		.then((result) => {
			//I'm not sure these actually get used anymore
			//console.log("getting redirect result");
			if (result.additionalUserInfo) {
				console.log("user additional info");
				updateUser(result);
			}
			if (result.credential) {
				console.log("result credential");
				var credential: any = result.credential;
				var token = credential.accessToken;
				console.log(token);
			}
			if (result.user) {
				console.log("result user");
				setUser(result.user);
				history.replace("/dashboard");
			}
		})
		.catch((error) => {
			console.log(error);
		});

	const _onChange = (e: any) => {
		e.preventDefault();
		setCredentials({ ...credentials, [e.target.name]: e.target.value });
	};

	const _submit = async (e: any) => {
		e.preventDefault();
		setLoading(true, "Logging in");
		const res: any = await loginUser(credentials);
		if (res) {
			//console.log(res.user);
			setUser(res.user);
			loginUserUpdate(res.user);
			history.replace("/dashboard");
			toast("You are logged in!");
		}
		setLoading(false);
	};

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				history.replace("/dashboard");
			}
		});
		//eslint-disable-next-line
	}, []);

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding auth-screen">
				<div id="app-logo" />
				<div id="content-box">
					<form onSubmit={_submit}>
						<IonItem>
							<IonLabel position="floating">Your Email</IonLabel>
							<IonInput
								type="email"
								placeholder="Email"
								name="email"
								autocomplete="email"
								onIonChange={_onChange}
							/>
						</IonItem>
						<div className="spacer5" />
						<IonItem>
							<IonLabel position="floating">Your Password</IonLabel>
							<IonInput
								type="password"
								placeholder="Password"
								name="password"
								autocomplete="current-password"
								onIonChange={_onChange}
							/>
						</IonItem>
						<div className="spacer5" />
						<div
							onClick={() => history.push("/forgotPassword")}
							className="linked instructions small"
						>
							Forgot your password?
						</div>
						<div className="spacer15" />
						<IonButton expand="full" size="large" type="submit">
							Login
						</IonButton>
					</form>
					<div className="spacer15" />
					{(PLATFORM_ANDROID || PLATFORM_MOBILEWEB || PLATFORM_DESKTOP) && (
						<Fragment>
							<p className="instructions faded bold center-text">
								Or login with
							</p>
							<div style={{ textAlign: "center" }}>
								<IonButtons className="social-icons">
									<IonButton onClick={_googleLogin} className="SSO-icons">
										<IonIcon slot="start" icon={logoGoogle}></IonIcon>
									</IonButton>
									{!(PLATFORM_ANDROID && !PLATFORM_MOBILEWEB) && (
										<IonButton onClick={_appleLogin} className="SSO-icons">
											<IonIcon slot="start" icon={logoApple}></IonIcon>
										</IonButton>
									)}
									<IonButton onClick={_facebookLogin} className="SSO-icons">
										<IonIcon slot="start" icon={logoFacebook}></IonIcon>
									</IonButton>
								</IonButtons>
							</div>
							<div className="spacer15" />
						</Fragment>
					)}
					<p
						onClick={() => history.push("/register")}
						className="linked instructions small center-text"
					>
						Don't have an account? Register now.
					</p>
					<div className="spacer15" />
					<IonButton
						fill="clear"
						onClick={_loginAnonymously}
						className="guest-button"
					>
						Continue as guest
					</IonButton>
				</div>
				<div id="app-background" />
			</IonContent>
		</IonPage>
	);
};

// const mapDispatchToProps = () => {
// 	return {
// 		setUser,
// 	};
// };

const mapStateToProps = (state: any) => ({
	loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps, {
	setUser,
	setLoading,
	monitorUser,
	getOrders,
})(Home);
