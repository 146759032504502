import { IonContent } from "@ionic/react";
import React from "react";

import { AppHeader } from "../../../components/Header/AppHeader";
import { connect } from "react-redux";
import AdminFooter from "../../../components/Header/AdminFooter";
import MessagesView from "./components/MessagesView";

interface Props {}

const Messages: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Messages" />
			<IonContent fullscreen className="ion-padding">
				<MessagesView />
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(Messages);
