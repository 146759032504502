import { GET_ACTIVATIONS, GET_SUBMISSIONS_SUCCESS } from "../actionTypes";
const defaultState = { activations: {}, submissions: {} };

const activations = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_ACTIVATIONS:
			return { ...state, activations: payload };

		case GET_SUBMISSIONS_SUCCESS:
			return { ...state, submissions: payload };
		default:
			return state;
	}
};

export default activations;
