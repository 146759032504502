import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
  IonSearchbar,
  IonButton,
  IonButtons,
  IonCol,
  IonIcon,
  IonRow,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import FuzzySearch from "fuzzy-search";
import { sortByCreated } from "../../../../services/util";
import AdminRequestCard from "./AdminRequestCard";
import {
  getRequests,
  hideAllRequests,
  deleteAllRequests,
} from "../../../../services/requests";
import { barChartSharp } from "ionicons/icons";

interface Props {
  requests: any;
  getRequests: any;
}

const Requests: React.FC<Props> = ({ requests, getRequests }) => {
  const [segment, setSegment] = useState<any>("unapproved");
  const [searchText, setSearchText] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any>([]);

  const allRequests: Array<any> = Object.values(requests).sort((a, b) =>
    sortByCreated(a, b, "ASC")
  );
  const unapproved = searchResults.filter(
    (el: any) => el.status === "unapproved"
  );
  const approved = searchResults.filter((el: any) => el.status === "approved");
  const hidden = searchResults.filter((el: any) => el.status === "hidden");

  useEffect(() => {
    getRequests();
    //eslint-disable-next-line
  }, []);

  const filterRequests = () => {
    //console.log(orders);
    const searcher = new FuzzySearch(
      allRequests,
      ["uid", "name", "song", "body"],
      { caseSensitive: false }
    );
    // console.log("checking searchText");
    if (searchText !== "") {
      // console.log("search text is NOT empty: ", searchText);
      setSearchResults(searcher.search(searchText));
    } else {
      // console.log("search text is empty: ", searchText);
      setSearchResults(allRequests);
    }
  };

  useEffect(() => {
    filterRequests();
    //eslint-disable-next-line
  }, [searchText, requests]);

  return (
    <Fragment>
      <IonRow>
        <IonCol>
          <IonButtons>
            {allRequests && (
              <IonButton disabled>
                <IonIcon slot="start" icon={barChartSharp} />
                Message Count: {allRequests.length}
              </IonButton>
            )}
          </IonButtons>
        </IonCol>
        <IonCol>
          <IonButtons>
            <IonButton onClick={hideAllRequests}>Hide All</IonButton>
            <IonButton onClick={deleteAllRequests}>Delete All</IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
      <IonToolbar>
        <IonSegment
          onIonChange={(e) => setSegment(e.detail.value)}
          value={segment}
        >
          <IonSegmentButton value="unapproved">Unapproved</IonSegmentButton>
          <IonSegmentButton value="approved">Approved</IonSegmentButton>
          <IonSegmentButton value="hidden">Hidden</IonSegmentButton>
          <IonSegmentButton value="all">All</IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <IonSearchbar
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value!)}
      ></IonSearchbar>
      {segment === "unapproved" && (
        <Fragment>
          {unapproved && unapproved.length > 0 ? (
            unapproved.map((rqst: any, idx: any) => (
              <AdminRequestCard key={idx} request={rqst} />
            ))
          ) : (
            <IonLabel className="ion-padding">No unapproved requests</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "approved" && (
        <Fragment>
          {approved && approved.length > 0 ? (
            approved.map((rqst: any, idx: any) => (
              <AdminRequestCard key={idx} request={rqst} />
            ))
          ) : (
            <IonLabel className="ion-padding">No approved requests</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "hidden" && (
        <Fragment>
          {hidden && hidden.length > 0 ? (
            hidden.map((rqst: any, idx: any) => (
              <AdminRequestCard key={idx} request={rqst} />
            ))
          ) : (
            <IonLabel className="ion-padding">No hidden requests</IonLabel>
          )}
        </Fragment>
      )}
      {segment === "all" && (
        <Fragment>
          {allRequests && allRequests.length > 0 ? (
            allRequests.map((rqst: any, idx: any) => (
              <AdminRequestCard key={idx} request={rqst} />
            ))
          ) : (
            <IonLabel className="ion-padding">No requests</IonLabel>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  requests: state.requests,
});

export default connect(mapStateToProps, { getRequests })(Requests);
