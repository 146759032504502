import {
	SET_CURRENT_USER,
	MONITOR_USER_SUCCESS,
	ADD_NEW_USER,
	LOGOUT_USER,
	SET_LOGGED_IN,
	ADD_PAYMENT_METHOD_SUCCESS,
	GET_PAYMENT_METHODS_SUCCESS,
	GET_PAYMENTS_SUCCESS,
	GET_VOTES_SUCCESS,
	GET_USER_NOTIFICATIONS_SUCCESS,
} from "../actionTypes";
const defaultState = {
	userDetails: {
		displayName: "",
		email: "",
		avatar: "",
		uid: "",
		phoneNumber: "",
		fname: "",
		lname: "",
		isAdmin: false,
		isAnonymous: true,
		emailVerified: false,
		customer_id: "",
		setup_secret: "",
		payments: {},
		payment_methods: {},
	},
	loggedIn: false,
};

const user = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case ADD_NEW_USER:
			return state;
		case SET_CURRENT_USER:
			return {
				...state,
				userDetails: payload,
				loggedIn: true,
			};
		case MONITOR_USER_SUCCESS:
			return { ...state, userDetails: { ...payload } };
		case ADD_PAYMENT_METHOD_SUCCESS:
			return { ...state, ...payload };
		case LOGOUT_USER:
			return {
				...defaultState,
			};
		case GET_PAYMENTS_SUCCESS:
			return {
				...state,
				payments: payload,
			};
		case GET_VOTES_SUCCESS:
			return {
				...state,
				votes: payload,
			};
		case GET_PAYMENT_METHODS_SUCCESS:
			return {
				...state,
				payment_methods: payload,
			};
		case SET_LOGGED_IN:
			return {
				...state,
				loggedIn: payload,
			};

		case GET_USER_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				notifications: payload,
			};
		default:
			return state;
	}
};

export default user;
