import { SET_LOADING } from "../actionTypes";
const defaultState = {
	loading: false,
	message: "Loading...",
};

const orders = (state = defaultState, action) => {
	const { type, payload } = action;
	// console.log(payload);
	// console.log(type);
	switch (type) {
		case SET_LOADING:
			return { ...payload };
		default:
			return state;
	}
};

export default orders;
