import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
  settings: any;
}

const RequestsSettings: React.FC<Props> = ({ settings }) => {
  const [dashboardImage, setDashboardImage] = useState<any>(
    settings["request-dashboard-image"]
      ? settings["request-dashboard-image"].value
      : ""
  );
  const [dashboardBGImage, setDashboardBGImage] = useState<any>(
    settings["request-dashboard-BG-image"]
      ? settings["request-dashboard-BG-image"].value
      : ""
  );
  const [headerImage, setHeaderImage] = useState<any>(
    settings["request-header-image"]
      ? settings["request-header-image"].value
      : ""
  );
  const [footerImage, setFooterImage] = useState<any>(
    settings["request-footer-image"]
      ? settings["request-footer-image"].value
      : ""
  );
  const [footerImageURL, setFooterImageURL] = useState<any>(
    settings["request-footer-image-URL"]
      ? settings["request-footer-image-URL"].value
      : ""
  );
  const [slideBG, setSlideBG] = useState<any>(
    settings["admin-request-default-slide-image"]
      ? settings["admin-request-default-slide-image"].value
      : ""
  );
  const [requestActive, setRequestActive] = useState<any>(
    settings["request-active"] ? settings["request-active"].value : false
  );

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  useEffect(() => {
    _save("request-dashboard-image", dashboardImage);
    //eslint-disable-next-line
  }, [dashboardImage]);

  useEffect(() => {
    _save("request-dashboard-BG-image", dashboardBGImage);
    //eslint-disable-next-line
  }, [dashboardBGImage]);

  useEffect(() => {
    _save("request-header-image", headerImage);
    //eslint-disable-next-line
  }, [headerImage]);

  useEffect(() => {
    _save("request-footer-image", footerImage);
    //eslint-disable-next-line
  }, [footerImage]);

  useEffect(() => {
    _save("request-footer-image-URL", footerImageURL);
    //eslint-disable-next-line
  }, [footerImageURL]);

  useEffect(() => {
    _save("admin-request-default-slide-image", slideBG);
    //eslint-disable-next-line
  }, [slideBG]);

  useEffect(() => {
    _save("request-active", requestActive);
    //eslint-disable-next-line
  }, [requestActive]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
    else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
    else if (e.name === "dashboardImage") setDashboardImage(e.image.fullPath);
    else if (e.name === "dashboardBGImage")
      setDashboardBGImage(e.image.fullPath);
    else if (e.name === "slideBG") setSlideBG(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "footerImageURL") {
      setFooterImageURL(e.target.value);
    }
  };

  const _onToggle = (section: any) => {
    if (section === "requestActive") {
      setRequestActive(!requestActive);
    }
  };

  return (
    <Fragment>
      <IonTitle className="section-header">Requests Page Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>General Request Settings</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonLabel>Request Active?</IonLabel>
              <IonToggle
                checked={requestActive}
                onClick={() => _onToggle("requestActive")}
                name="requestActive"
                id="requestActive"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Requests Page Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Dashboard Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="dashboardImage"
              id="dashboardImage"
              value={dashboardImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Dashboard Background Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="dashboardBGImage"
              id="dashboardBGImage"
              value={dashboardBGImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Header Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="headerImage"
              id="headerImage"
              value={headerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Promo Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="footerImage"
              id="footerImage"
              value={footerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Promo Image Link URL</IonLabel>
            <IonInput
              type="text"
              name="footerImageURL"
              id="footerImageURL"
              value={footerImageURL}
              onIonChange={_onChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Requests Slide Settings</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Slide Background</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="slideBG"
              id="slideBG"
              value={slideBG}
              onChange={_onPhotoChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(RequestsSettings);
