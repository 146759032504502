import {
	IonContent,
	IonButtons,
	IonCardContent,
	IonCard,
	IonFooter,
	IonButton,
	IonText,
	IonCardTitle,
	IonIcon,
	IonItem,
	IonList,
} from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";

import { connect } from "react-redux";
import AdminFooter from "../../../components/Header/AdminFooter";
import { AppHeader } from "../../../components/Header/AppHeader";

interface Props {
	ladders: any;
}

const AdminLadders: React.FC<Props> = ({ ladders }) => {
	const getDateString = (d: any) => {
		var date = new Date(d);
		const options: any = {
			weekday: "short",
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return date.toLocaleString(undefined, options);
	};

	return (
		<>
			<AppHeader title="Ladders" />
			<IonContent fullscreen className="ion-padding admin">
				{Object.values(ladders).map((elem: any, id: any) => (
					<IonCard key={id}>
						<IonCardContent>
							<IonCardTitle>{elem.title}</IonCardTitle>
							<IonText>Last updated {getDateString(elem.updated)}</IonText>
						</IonCardContent>
						<IonFooter className="ion-padding">
							<IonButtons>
								<IonButton href={`admin/ladder/${elem.id}`}>Edit</IonButton>
							</IonButtons>
						</IonFooter>
					</IonCard>
				))}
				<IonList>
					<IonItem>
						<IonButton href={"/admin/ladder/new"} color="primary">
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	ladders: state.ladders,
});

export default connect(mapStateToProps, {})(AdminLadders);
