import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonInput,
	IonItem,
	IonLabel,
	IonTitle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
	settings: any;
}

const DashboardSettings: React.FC<Props> = ({ settings }) => {
	const [headerImage, setHeaderImage] = useState<any>(
		settings["dashboard-header-image"]
			? settings["dashboard-header-image"].value
			: ""
	);
	const [footerImage, setFooterImage] = useState<any>(
		settings["dashboard-footer-image"]
			? settings["dashboard-footer-image"].value
			: ""
	);
	const [footerImageURL, setFooterImageURL] = useState<any>(
		settings["dashboard-footer-image-URL"]
			? settings["dashboard-footer-image-URL"].value
			: ""
	);

	const _save = (name: any, data: any) => {
		if (settings[name]) {
			updateSetting(settings[name].id, data);
		} else {
			addSetting(name, "");
		}
	};

	useEffect(() => {
		_save("dashboard-header-image", headerImage);
		//eslint-disable-next-line
	}, [headerImage]);

	useEffect(() => {
		_save("dashboard-footer-image", footerImage);
		//eslint-disable-next-line
	}, [footerImage]);

	useEffect(() => {
		_save("dashboard-footer-image-URL", footerImageURL);
		//eslint-disable-next-line
	}, [footerImageURL]);

	const _onPhotoChange = (e: any) => {
		if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
		else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
	};

	useEffect(() => {}, [footerImage, headerImage, footerImageURL]);

	const _onChange = (e: any) => {
		if (e.target.name === "footerImageURL") {
			setFooterImageURL(e.target.value);
		}
	};

	return (
		<Fragment>
			<IonTitle className="section-header">Dashboard Settings</IonTitle>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Dashboard Page Images</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonItem>
						<IonLabel position="stacked">Header Image</IonLabel>
						<div className="spacer5" />
						<PhotoUploadInput
							name="headerImage"
							id="headerImage"
							value={headerImage}
							onChange={_onPhotoChange}
						/>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">Footer Image</IonLabel>
						<div className="spacer5" />
						<PhotoUploadInput
							name="footerImage"
							id="footerImage"
							value={footerImage}
							onChange={_onPhotoChange}
						/>
					</IonItem>
					<IonItem>
						<IonLabel position="floating">Footer Image Link URL</IonLabel>
						<IonInput
							type="text"
							name="footerImageURL"
							id="footerImageURL"
							value={footerImageURL}
							onIonChange={_onChange}
						/>
					</IonItem>
				</IonCardContent>
			</IonCard>
			<div className="spacer30" />
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(DashboardSettings);
