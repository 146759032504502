import { IonLabel, IonItem, IonListHeader, IonNote } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

interface Props {
	type: string;
	submissions: any;
	activations: any;
}

const SubmissionResults: React.FC<Props> = ({
	type,
	submissions,
	activations,
}) => {
	const [results, setResults] = useState<any>([]);

	function onlyUnique(value: any, index: any, self: any) {
		return self.indexOf(value) === index;
	}

	function compareByCount(a: any, b: any) {
		if (a.count > b.count) {
			return -1;
		}
		if (a.count < b.count) {
			return 1;
		}
		return 0;
	}

	const _processData = (submissions: any) => {
		// console.log(submissions);
		// console.log(Object.values(submissions).length);
		let subData: any = Object.values(submissions);
		if (subData.length > 0) {
			// console.log(Object.values(submissions)[0].activation);
			let actID = subData[0].activation;
			let actOptions = activations[actID].options;

			// reduce submissions to only selected ids
			let arr = subData.map((sub: any) => sub.data.selected);

			//count occurences of each option
			const counted: any = {};
			arr.forEach((elem: any) => {
				counted[elem] = counted[elem] ? counted[elem] : {};
				counted[elem].count = counted[elem].count ? counted[elem].count + 1 : 1;
			});

			//find unique option ids
			let uniqueIds = arr.filter(onlyUnique);

			// for each unique option id collate data
			const data: any = [];
			for (var i = 0; i < uniqueIds.length; i++) {
				const found = actOptions.find(
					// eslint-disable-next-line
					(element: any) => element.oid === uniqueIds[i]
				);
				if (found) {
					data[i] = {
						id: uniqueIds[i],
						label: found.olabel,
						count: counted[uniqueIds[i]].count,
					};
				}
			}

			//sort by count
			setResults(data.sort(compareByCount));
		}
	};

	useEffect(() => {
		_processData(submissions);
		//eslint-disable-next-line
	}, [submissions]);

	return (
		<Fragment>
			{results && <IonListHeader>Results</IonListHeader>}
			{results &&
				results.map((elem: any) => (
					<IonItem key={elem.id} lines="none">
						<IonLabel>{elem.label}</IonLabel>
						<IonNote slot="start" color="success">
							{elem.count}
						</IonNote>
					</IonItem>
				))}
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, {})(SubmissionResults);
