import { IonContent } from "@ionic/react";
import React from "react";
import { getAdminOrders } from "../../../services/orders";
import { connect } from "react-redux";
import { AppHeader } from "../../../components/Header/AppHeader";
import AdminFooter from "../../../components/Header/AdminFooter";
import OrdersView from "./components/OrdersView";

interface Props {}

const AdminOrders: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Orders" />
			<IonContent fullscreen className="ion-padding admin">
				<OrdersView />
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	adminOrders: state.orders.adminOrders,
	products: state.products,
});

export default connect(mapStateToProps, { getAdminOrders })(AdminOrders);
