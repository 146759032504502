import {
	IonContent,
	IonLabel,
	IonList,
	IonItem,
	IonButton,
	IonIcon,
} from "@ionic/react";
import React from "react";

import PostCard from "./components/PostCard";
import { connect } from "react-redux";
import { add } from "ionicons/icons";
import { AppHeader } from "../../../components/Header/AppHeader";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
	posts: any;
}

const AdminPosts: React.FC<Props> = ({ posts }) => {
	return (
		<>
			<AppHeader title="Posts" />
			<IonContent fullscreen className="ion-padding admin">
				<IonList>
					{posts ? (
						Object.values(posts).map((post: any) => (
							<PostCard key={post.id} post={post} />
						))
					) : (
						<IonLabel className="ion-padding">No users available</IonLabel>
					)}
					<IonItem>
						<IonButton href={"/admin/post/new"} color="primary">
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({ posts: state.news.posts });

export default connect(mapStateToProps, {})(AdminPosts);
