import {
	IonContent,
	IonPage,
	IonButton,
	IonInput,
	IonItem,
	IonLabel,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { registerUser, loginUser } from "../../../../services/auth";
import { monitorUser, setUser } from "../../../../services/users";
import { updateUser } from "../../../../services/users";
import { connect } from "react-redux";

import { toast } from "../../../../components/Toast/toast";
import { setLoading } from "../../../../services/loading";
import { getOrders } from "../../../../services/orders";

interface RegisterProps {
	loggedIn: any;
	setUser: any;
	setLoading: any;
	monitorUser: any;
	getOrders: any;
}

const Register: React.FC<RegisterProps> = ({
	loggedIn,
	setUser,
	setLoading,
	monitorUser,
	getOrders,
}) => {
	const history = useHistory();

	const emailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [cPassword, setCPassword] = useState("");

	// useEffect(() => {
	// 	if (loggedIn) {
	// 		history.replace("/dashboard");
	// 	}
	// 	//eslint-disable-next-line
	// }, [loggedIn]);

	async function register(e: any) {
		e.preventDefault();
		if (password !== cPassword) {
			return toast("Passwords do not match");
		}
		if (email.trim() === "" || password.trim() === "") {
			return toast("Email and/or password are empty");
		}
		if (!emailTest.test(String(email).toLowerCase())) {
			return toast("Email address is incorrect format");
		}
		setLoading(true, "Registering new user");
		registerUser(email, password).then((res) => {
			if (res) {
				updateUser(res);
				setLoading(false);
				toast("You have registered successfully!");
				setLoading(true, "Logging in");
				loginUser({ email, password }).then((res: any) => {
					if (res) {
						setUser(res.user);
						monitorUser(res.user.uid);
						getOrders(res.user.uid);
						history.replace("/dashboard");
						toast("You are logged in!");
					}
					setLoading(false);
				});
			}
		});
	}

	return (
		<IonPage>
			<IonContent fullscreen className="ion-padding auth-screen">
				<div id="app-logo" />
				<div id="content-box">
					<form onSubmit={register}>
						<IonItem>
							<IonLabel position="floating">Your Email</IonLabel>
							<IonInput
								type="email"
								onIonChange={(e: any) => setEmail(e.target.value)}
							/>
						</IonItem>
						<div className="spacer15" />
						<IonItem>
							<IonLabel position="floating">Your Password</IonLabel>
							<IonInput
								type="password"
								onIonChange={(e: any) => setPassword(e.target.value)}
							/>
						</IonItem>
						<div className="spacer15" />
						<IonItem>
							<IonLabel position="floating">Confirm Password</IonLabel>
							<IonInput
								type="password"
								onIonChange={(e: any) => setCPassword(e.target.value)}
							/>
						</IonItem>
						<div className="spacer15" />
						<IonButton expand="full" size="large" type="submit">
							Register
						</IonButton>
					</form>
					<p
						onClick={() => history.push("/")}
						className="linked instructions small center-text"
					>
						Already have an account? Login
					</p>
				</div>
				<div id="app-background" />
			</IonContent>
		</IonPage>
	);
};

const mapStateToProps = (state: any) => ({
	loggedIn: state.user.loggedIn,
});

export default connect(mapStateToProps, {
	setUser,
	setLoading,
	monitorUser,
	getOrders,
})(Register);
