import { IonContent } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

import { AppHeader } from "../../../components/Header/AppHeader";

import ScheduleSettings from "./components/ScheduleSettings";
import DashboardSettings from "./components/DashboardSettings";
import ShopSettings from "./components/ShopSettings";
import MessagesSettings from "./components/MessagesSettings";
import ActivationSettings from "./components/ActivationSettings";
import PlayersSettings from "./components/PlayersSettings";
import PromotionsSettings from "./components/PromotionsSettings";
import FeedbackSettings from "./components/FeedbackSettings";
import NewsSettings from "./components/NewsSettings";
import AboutSettings from "./components/AboutSettings";
import RequestSettings from "./components/RequestSettings";
import MessageSlideshowSettings from "./components/MessageSlideshowSettings";
import AdminFooter from "../../../components/Header/AdminFooter";
import GeneralSettings from "./components/GeneralSettings";

interface Props {
  settings: any;
}

const AdminSettings: React.FC<Props> = ({ settings }) => {
  return (
    <>
      <AppHeader title="Settings" />
      <IonContent className="ion-padding admin">
        <GeneralSettings />
        <DashboardSettings />
        <ShopSettings />
        <ScheduleSettings />
        <MessagesSettings />
        <MessageSlideshowSettings />
        <RequestSettings />
        <ActivationSettings />
        <PlayersSettings />
        <PromotionsSettings />
        <FeedbackSettings />
        <NewsSettings />
        <AboutSettings />
      </IonContent>
      <AdminFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, {})(AdminSettings);
