import {
	IonContent,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonList,
	IonIcon,
	IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	addPost,
	updatePost,
	deletePost,
	getPosts,
	setFeaturedPost,
} from "../../../../services/news";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { AppHeader } from "../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../components/Header/AppFooter";

import { trash } from "ionicons/icons";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
	id: string;
}

interface Props {
	posts: any;
	getPosts: any;
}

const AdminPost: React.FC<Props> = ({ posts, getPosts }) => {
	const history = useHistory();

	const defaultState = {
		title: "",
		img: "",
		content: "",
		date: "",
		status: false,
		featured: false,
		link: "",
		excerpt: "",
	};

	let { id } = useParams<RouteParams>();
	const [post, setPost] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const { title, img, date, excerpt, content, status, featured } = post;

	useEffect(() => {
		if (posts && posts[id]) {
			setPost({ ...posts[id] });
		}
		//eslint-disable-next-line
	}, [id, posts]);

	const _onChange = (e: any) =>
		setPost({ ...post, [e.target.name]: e.target.value });

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		// if (post.name.trim() === "" || post.pos.trim() === "") {
		// 	setDisableSubmit(false);
		// 	return toast("You must include a position and a name");
		// }
		if (featured) {
			setFeaturedPost(id);
		}
		if (id === "new") {
			addPost(post).then((res: any) => {
				if (res !== false) {
					console.log(res);
					history.push(`/admin/post/${res}`);
					//console.log(res);
					toast("post saved");
				}
			});
		} else {
			updatePost(post, id).then((res) => {
				if (res) {
					toast("post updated");
				}
			});
		}
		getPosts();
		setDisableSubmit(false);
	};

	const _back = () => {
		history.push("/admin/posts");
	};

	const _delete = () => {
		deletePost(id).then(() => {
			getPosts();
			history.push(`/admin/posts`);
		});
	};

	const _onToggle = (option: any) => {
		setPost({ ...post, [option]: !post[option] });
	};

	const _onPhotoChange = (e: any) => {
		setPost({ ...post, img: e.image.fullPath });
	};

	return (
		<>
			<AppHeader title={`${id === "new" ? "Add" : "Edit"} Post`} />
			<IonContent className="ion-padding admin">
				<form onSubmit={_submit}>
					<IonList>
						<IonItem>
							<IonLabel>Active?</IonLabel>
							<IonToggle
								checked={status}
								onClick={() => _onToggle("status")}
								name="status"
								id="status"
							/>
						</IonItem>
						<IonItem>
							<IonLabel>Featured?</IonLabel>
							<IonToggle
								checked={featured}
								onClick={() => _onToggle("featured")}
								name="featured"
								id="featured"
							/>
						</IonItem>
						<IonItem>
							<IonLabel position="stacked">Image</IonLabel>
							<div className="spacer5" />
							<PhotoUploadInput
								name="img"
								id="img"
								value={img}
								onChange={_onPhotoChange}
							/>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Title</IonLabel>
							<IonInput
								type="text"
								id="title"
								name="title"
								value={title}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Excerpt</IonLabel>
							<IonTextarea
								id="excerpt"
								name="excerpt"
								value={excerpt}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Content</IonLabel>
							<IonTextarea
								id="content"
								name="content"
								value={content}
								onIonChange={_onChange}
								rows={24}
								auto-grow={true}
							></IonTextarea>
						</IonItem>
						{/* <IonItem>
							<IonLabel position="floating">Link</IonLabel>
							<IonInput
								type="text"
								id="link"
								name="link"
								value={link}
								onIonChange={_onChange}
							></IonInput>
						</IonItem> */}
						<IonItem>
							<IonLabel position="floating">Publish Date</IonLabel>
							<IonInput
								type="date"
								id="date"
								name="date"
								value={date}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
					</IonList>

					<IonButton type="submit" color="primary" disabled={disableSubmit}>
						Save
					</IonButton>
					<IonButton onClick={_back} color="warning">
						Cancel
					</IonButton>
					<IonButton onClick={_delete} color="danger">
						<IonIcon icon={trash} slot="start"></IonIcon>
						Delete
					</IonButton>
				</form>
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	posts: state.news.posts,
});

export default connect(mapStateToProps, { getPosts })(AdminPost);
