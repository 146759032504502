import {
  IonImg,
  IonBadge,
  IonIcon,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonActionSheet,
} from "@ionic/react";
import {
  star,
  starOutline,
  close,
  pencil,
  ellipsisHorizontal,
} from "ionicons/icons";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setFeaturedActivation,
  updateActivation,
} from "../../../../services/activations";
import { pushToScreen } from "../../../../services/settings";
import { getImageURL } from "../../../../services/util";

interface Props {
  activation: any;
  subs: any;
}

const DashboardActivationCard: React.FC<Props> = ({ activation, subs }) => {
  const history = useHistory();
  const [cImg, setCImg] = useState<any>("");
  const [imageURL, setImageURL] = useState<any>("");
  const [submissions, setSubmissions] = useState<any>([]);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [popoverState, setShowPopover] = useState(false);

  const { id, img, featured } = activation;

  useEffect(() => {
    if (img !== "" && img !== cImg) {
      setCImg(img);
      setImageURL("");
      getImageURL(img, "200").then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [activation]);

  useEffect(() => {
    if (subs) {
      setSubmissions(
        Object.values(subs).filter((doc: any) => doc.activation === id)
      );
    }
    //eslint-disable-next-line
  }, [activation, subs]);

  const _toggleFeatured = () => {
    if (featured === false) {
      setFeaturedActivation(id);
    } else {
      setFeaturedActivation();
    }
  };

  // const _onRadioChange = (e: any) => {
  // 	let status = e.detail.value;
  // 	//_setStatus(status);

  // 	//setActivation({ ...activation, status: e.target.value });
  // };

  const _setStatus = (status: any) => {
    updateActivation({ ...activation, status }, id);
    setTimeout(() => {
      setShowPopover(false);
    }, 50);
  };

  const _push = (type: any) => {
    pushToScreen({ type, id });
  };

  const _openStatusPopover = (e: any) => {
    e.persist();
    setShowPopover(true);
  };

  return (
    <IonCard>
      <IonActionSheet
        cssClass="my-custom-class ion-padding"
        isOpen={popoverState}
        onDidDismiss={() => setShowPopover(false)}
        buttons={[
          {
            text: "Draft",
            handler: () => _setStatus("draft"),
          },
          {
            text: "Inactive",
            handler: () => _setStatus("inactive"),
          },
          {
            text: "Active",
            handler: () => _setStatus("active"),
          },
          {
            text: "Expired",
            handler: () => _setStatus("expired"),
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Edit",
            icon: pencil,
            handler: () => history.push(`/admin/activation/${activation.id}`),
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
          },
        ]}
      ></IonActionSheet>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="2" size="6">
              {imageURL && <IonImg alt="" src={imageURL} />}
            </IonCol>

            <IonCol>
              <IonRow>
                <IonCol>
                  <h1>
                    {activation.title}
                    <IonBadge color="dark">{submissions.length}</IonBadge>
                  </h1>
                </IonCol>
                <IonCol sizeSm="2" size="6">
                  <IonIcon
                    onClick={_toggleFeatured}
                    color="danger"
                    icon={featured ? star : starOutline}
                    className={"featuredToggle"}
                  />
                </IonCol>
                <IonCol
                  sizeSm="1"
                  size="6"
                  onClick={_openStatusPopover}
                  className="linked"
                >
                  {activation.status === "draft" && (
                    <IonBadge color="disabled">Draft</IonBadge>
                  )}
                  {activation.status === "inactive" && (
                    <IonBadge color="warning">Inactive</IonBadge>
                  )}
                  {activation.status === "active" && (
                    <IonBadge color="success">Active</IonBadge>
                  )}
                  {activation.status === "expired" && (
                    <IonBadge color="light">Expired</IonBadge>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
      <IonRow>
        <IonCol sizeSm="11" size="12">
          <IonButtons>
            <IonButton
              onClick={() =>
                history.push(`/admin/activation/${activation.id}/submissions`)
              }
            >
              Submissions
            </IonButton>
            <IonButton onClick={() => _push("activationPromo")}>
              Push Promo
            </IonButton>
            <IonButton onClick={() => _push("results")}>Push Results</IonButton>
            <IonButton onClick={() => _push("winner")}>Push Winner</IonButton>
            <IonButton onClick={() => setShowActionSheet(true)}>
              <IonIcon icon={ellipsisHorizontal} />
            </IonButton>
          </IonButtons>
        </IonCol>
      </IonRow>
    </IonCard>
  );
};

const mapStateToProps = (state: any) => ({
  subs: state.activations.submissions,
});

export default connect(mapStateToProps, {})(DashboardActivationCard);
