import { IonItem, IonImg, IonThumbnail, IonIcon, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";
import { star, starOutline } from "ionicons/icons";
import { setFeaturedPost } from "../../../../services/news";

interface Props {
	post: any;
}

const PostCard: React.FC<Props> = ({ post }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const { id, title, img, featured } = post;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	const _toggleFeatured = (featured: boolean) => {
		if (featured) {
			setFeaturedPost(id);
		} else {
			setFeaturedPost();
		}
	};

	return (
		<IonItem href={`/admin/post/${post.id}`}>
			<IonThumbnail slot="start">
				<IonImg alt="" src={imageURL} />
			</IonThumbnail>
			<IonLabel>
				<h2>{title}</h2>
			</IonLabel>
			{featured ? (
				<IonIcon
					onClick={() => _toggleFeatured(false)}
					slot="end"
					color="danger"
					icon={star}
				></IonIcon>
			) : (
				<IonIcon
					onClick={() => _toggleFeatured(true)}
					slot="end"
					color="danger"
					icon={starOutline}
				></IonIcon>
			)}
		</IonItem>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PostCard);
