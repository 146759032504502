import * as types from "../actionTypes";

export function getPostsSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_POSTS_SUCCESS, payload };
}
export function getAPIPostsSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_API_POSTS_SUCCESS, payload };
}
