import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteActivationSubmissions,
  downloadSubmitters,
  saveWinners,
} from "../../../../../services/activations";
import { pushToScreen } from "../../../../../services/settings";

interface Props {
  submissions: any;
  activation: any;
}

const PickWinner: React.FC<Props> = ({ submissions, activation }) => {
  const history = useHistory();
  const [subs, setSubs] = useState<any>([]);
  const [winners, setWinners] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const { id } = activation;

  useEffect(() => {
    if (submissions) {
      setSubs(
        Object.values(submissions).filter((doc: any) => doc.activation === id)
      );
    }
    //eslint-disable-next-line
  }, [id, submissions]);

  const _chooseWinner = () => {
    const length = Object.values(subs).length;
    const position = Math.floor(Math.random() * length);
    const submission: any = Object.values(subs)[position];
    let inWinners = winners.find((winner: any) => winner.id === submission.id);
    if (!inWinners) {
      setWinners([...winners, submission]);
    } else if (Object.values(subs).length !== Object.values(winners).length) {
      _chooseWinner();
    }
  };

  useEffect(() => {
    if (activation) {
      if (activation.options) {
        let data: any = {};
        activation.options.forEach((option: any) => {
          data[option.oid] = option;
        });
        setOptions(data);
      }
      if (activation.winners) {
        setWinners(activation.winners);
      }
    }
  }, [activation]);

  const _remove = (idx: any) => {
    let data = winners;
    data.splice(idx, 1);
    setWinners([...data]);
  };

  const _saveWinners = () => {
    saveWinners(winners, activation.id);
  };

  const _push = (type: any) => {
    pushToScreen({ type, id });
  };

  const _export = () => {
    downloadSubmitters(activation, subs);
  };

  const _deleteSubmissions = () => {
    deleteActivationSubmissions(activation.id);
  };

  return (
    <Fragment>
      <IonCard className="winners-wrapper">
        <IonCardContent>
          <h1>Winners</h1>{" "}
          <IonButtons>
            <IonButton
              onClick={_chooseWinner}
              disabled={
                Object.values(subs).length === Object.values(winners).length
              }
            >
              Pick Winner
            </IonButton>
            <IonButton
              fill="outline"
              onClick={() =>
                history.push(`/admin/activation/${activation.id}/results`)
              }
            >
              Results Views
            </IonButton>

            <IonButton onClick={() => _push("results")}>Push Results</IonButton>
            <IonButton onClick={() => _push("winner")}>Push Winner</IonButton>
            <IonButton onClick={_export}>Export Entries</IonButton>
            <IonButton onClick={_deleteSubmissions}>Delete Entries</IonButton>
          </IonButtons>
          {winners.map((winner: any, idx: any) => (
            <IonCard key={idx}>
              <IonCardContent>
                <IonItem lines="none">
                  <IonLabel>
                    <h2>
                      <b>
                        {winner.submitter.fname} {winner.submitter.lname}
                      </b>
                    </h2>
                    <span>
                      {winner.submitter.email} {winner.submitter.phoneNumber}
                    </span>
                    {options &&
                      winner.data &&
                      Object.values(winner.data.selected).length > 0 && (
                        <h2>
                          Selection: {options[winner.data.selected].olabel}
                        </h2>
                      )}
                  </IonLabel>
                  <IonButton
                    slot="end"
                    onClick={() => _remove(idx)}
                    fill="outline"
                  >
                    <IonIcon icon={close} />
                  </IonButton>
                </IonItem>
              </IonCardContent>
            </IonCard>
          ))}
          {winners !== activation.winners && (
            <IonButton onClick={_saveWinners}>Save Winners</IonButton>
          )}
        </IonCardContent>
      </IonCard>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  submissions: state.activations.submissions,
});

export default connect(mapStateToProps, {})(PickWinner);
