import { GET_NOTIFICATIONS_SUCCESS } from "../actionTypes";
const defaultState = {};

const notifications = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_NOTIFICATIONS_SUCCESS:
			//console.log(payload);
			return payload;

		default:
			return state;
	}
};

export default notifications;
