import {
	IonItem,
	IonImg,
	IonThumbnail,
	IonBadge,
	IonLabel,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	product: any;
}

const ProductCard: React.FC<Props> = ({ product }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");
	const { img, id, name, active, category } = product;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}

		//eslint-disable-next-line
	}, [img]);

	useEffect(() => {
		console.log(category);
	}, [category]);

	return (
		<IonItem button onClick={() => history.push(`/admin/product/${id}`)}>
			{imageURL && (
				<IonThumbnail slot="start">
					<IonImg alt="" src={imageURL} />
				</IonThumbnail>
			)}
			<IonLabel>
				<h2>{name}</h2>
				{category && <p>{category}</p>}
			</IonLabel>
			{active ? (
				<IonBadge slot="end" color="success">
					Active
				</IonBadge>
			) : (
				<IonBadge slot="end" color="light">
					Inactive
				</IonBadge>
			)}
		</IonItem>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(ProductCard);
