import {
	IonBadge,
	IonCard,
	IonCardContent,
	IonButton,
	IonButtons,
	IonCol,
	IonGrid,
	IonRow,
	IonToolbar,
	IonList,
	IonItem,
} from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAdminOrders, updateOrderStatus } from "../../../../services/orders";
import { formatDateTime } from "../../../../services/util";

interface Props {
	products: any;
	order: any;
}

const AdminOrderCard: React.FC<Props> = ({ order, products }) => {
	const history = useHistory();
	const {
		userDetails,
		orderNumber,
		cartTotal,
		cart,
		status,
		created,
		id,
		notes,
	} = order;

	const _changeStatus = (status: string) => {
		updateOrderStatus(id, status);
	};

	return (
		<IonCard button className="orderCard">
			<IonCardContent onClick={() => history.push(`/admin/order/${id}`)}>
				<IonGrid>
					<IonRow>
						<IonCol sizeSm="1" size="6">
							Order#: {orderNumber}
						</IonCol>
						<IonCol sizeSm="1" size="6">
							{status === "processing" && (
								<IonBadge color="warning">Processing</IonBadge>
							)}
							{status === "confirmed" && (
								<IonBadge color="tertiary">Confirmed</IonBadge>
							)}
							{status === "complete" && (
								<IonBadge color="success">Complete</IonBadge>
							)}
							{status === "pending" && (
								<IonBadge color="danger">Pending</IonBadge>
							)}
						</IonCol>
						<IonCol sizeSm="2" size="6">
							Date: {formatDateTime(created)}
						</IonCol>
						<IonCol sizeSm="1" size="6">
							Name: {userDetails.fname} {userDetails.lname}
						</IonCol>
						<IonCol sizeSm="1" size="6">
							Seat: {userDetails.seat.section}
							{userDetails.seat.row}
							{userDetails.seat.number}
						</IonCol>
						<IonCol sizeSm="1" size="6">
							Total: ${cartTotal.toFixed(2)}
						</IonCol>

						<IonCol sizeSm="3" size="12">
							<IonList lines="none" className="cartItemsList">
								{Object.entries(cart).map((order: any, idx: any) => (
									<IonItem key={idx} className="cartItem">
										{products[order[0]].name} x {order[1]}
									</IonItem>
								))}
							</IonList>
						</IonCol>
						<IonCol sizeSm="2" size="12">
							Notes: {notes}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
			<IonRow>
				<IonCol>
					<IonToolbar>
						<IonButtons>
							{status === "processing" && (
								<IonButton onClick={() => _changeStatus("confirmed")}>
									Confirm
								</IonButton>
							)}
							{status === "confirmed" && (
								<IonButton onClick={() => _changeStatus("complete")}>
									Complete
								</IonButton>
							)}
							{status === "complete" && (
								<IonButton onClick={() => _changeStatus("confirmed")}>
									Set Confirmed
								</IonButton>
							)}
							<IonButton onClick={() => history.push(`/admin/order/${id}`)}>
								View
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonCol>
			</IonRow>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
});

export default connect(mapStateToProps, { getAdminOrders })(AdminOrderCard);
