import * as types from "../actionTypes";

export function addToCartSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_ADDTOCART_SUCCESS, payload };
}

export function setCartSuccess(payload) {
	//console.log(payload);
	return { type: types.SET_CART_SUCCESS, payload };
}

export function getOrdersSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_ORDERS_SUCCESS, payload };
}
export function getAdminOrdersSuccess(payload) {
	//console.log(payload);
	return { type: types.GET_ADMIN_ORDERS_SUCCESS, payload };
}
export function setCurrentOrderSuccess(payload) {
	//console.log(payload);
	return { type: types.SET_CURRENT_ORDER_SUCCESS, payload };
}

export function addPaymentMethodSuccess(payload) {
	//console.log(payload);
	return { type: types.ADD_PAYMENT_METHOD_SUCCESS, payload };
}

export function updateOrderStatusSuccess(payload) {
	//console.log(payload);
	return { type: types.UPDATE_ORDER_STATUS_SUCCESS, payload };
}
