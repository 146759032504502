import {} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivationCard from "./ActivationCard";

interface Props {
	activations: any;
	featured: any;
}

const ActivationsModule: React.FC<Props> = ({
	activations,
	featured = false,
}) => {
	const [activationList, setActivationList] = useState<any>([]);
	const [expired, setExpired] = useState<any>([]);
	const [active, setActive] = useState<any>([]);
	const [inactive, setInactive] = useState<any>([]);

	useEffect(() => {
		if (Object.keys(activations).length > 0) {
			const visible = Object.values(activations).filter(
				(activation: any) =>
					activation.status !== "draft" &&
					((featured === false && activation.featured === false) || featured)
			);
			setActivationList(visible);
			const expiredList = visible.filter(
				(activation: any) => activation.status === "expired"
			);
			setExpired(expiredList);
			const inactiveList = visible.filter(
				(activation: any) => activation.status === "inactive"
			);
			setInactive(inactiveList);
			const activeList = visible.filter(
				(activation: any) => activation.status === "active"
			);
			setActive(activeList);
		} //eslint-disable-next-line
	}, [activations]);

	return (
		<div id="activations-module-wrapper">
			{activationList.length > 0 && (
				<Fragment>
					<h1 className="section-header">Competitions</h1>
					{active.length > 0 &&
						Object.values(active).map((elem: any, idx: any) => (
							<ActivationCard key={idx} activation={elem} />
						))}
					{inactive.length > 0 &&
						Object.values(inactive).map((elem: any, idx: any) => (
							<ActivationCard key={idx} activation={elem} />
						))}
					{expired.length > 0 &&
						Object.values(expired).map((elem: any, idx: any) => (
							<ActivationCard key={idx} activation={elem} />
						))}
					<div className="spacer30" />
				</Fragment>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
});

export default connect(mapStateToProps, {})(ActivationsModule);
