import { IonContent } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { AppHeader } from "../../../components/Header/AppHeader";
// import ActivationsView from "../Activations/components/ActivationsView";
// import FeedbackView from "../Feedback/components/FeedbackView";
// import MessagesView from "../Messages/components/MessagesView";
// import OrdersView from "../Orders/components/OrdersView";
// import RequestsView from "../Requests/components/RequestsView";
// import SlidesView from "../Slides/components/SlidesView";
import DashboardActivations from "./components/DashboardActivations";
import DashboardFeedback from "./components/DashboardFeedback";
import DashboardMessages from "./components/DashboardMessages";
import DashboardOrders from "./components/DashboardOrders";
import DashboardRequests from "./components/DashboardRequests";
import DashboardSlides from "./components/DashboardSlides";

const AdminDashboard: React.FC = () => {
	const history = useHistory();
	// const [view, setView] = useState("messages");

	const _pushHistory = (url: any) => {
		history.push(url);
	};
	return (
		<>
			<AppHeader title="Admin Dashboard" />
			<IonContent fullscreen className="ion-padding admin">
				{/* {view !== "dashboard" && (
					<IonButton onClick={() => _pushHistory("/admin/dashboard")}>
						Back to Dashboard
					</IonButton>
				)} */}
				{/* {view === "dashboard" && ( */}
				<>
					<DashboardMessages
						active={false}
						onClick={() => _pushHistory("/admin/dashboardmessages")}
					/>
					<DashboardRequests
						active={false}
						onClick={() => _pushHistory("/admin/dashboardrequests")}
					/>
					<DashboardOrders
						active={false}
						onClick={() => _pushHistory("/admin/dashboardorders")}
					/>
					<DashboardFeedback
						active={false}
						onClick={() => _pushHistory("/admin/dashboardfeedback")}
					/>
					<DashboardActivations
						active={false}
						onClick={() => _pushHistory("/admin/dashboardactivations")}
					/>
					<DashboardSlides
						active={false}
						onClick={() => _pushHistory("/admin/dashboardslides")}
					/>
				</>
				{/* )} */}
				{/* {view === "messages" && (
					<>
						<h1>Messages</h1>
						<MessagesView />
					</>
				)}
				{view === "requests" && (
					<>
						<h1>Requests</h1>
						<RequestsView />
					</>
				)}
				{view === "orders" && (
					<>
						<h1>Orders</h1>
						<OrdersView />
					</>
				)}
				{view === "slides" && (
					<>
						<h1>Collateral</h1>
						<SlidesView />
					</>
				)}
				{view === "activations" && (
					<>
						<h1>Activations</h1>
						<ActivationsView />
					</>
				)}
				{view === "feedback" && (
					<>
						<h1>Feedback</h1>
						<FeedbackView />
					</>
				)} */}
			</IonContent>
			{/* <AdminFooter /> */}
		</>
	);
};

export default AdminDashboard;
