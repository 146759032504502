import { combineReducers } from "redux";
//import { routerReducer } from 'react-router-redux';

//import authReducer from "./auth/reducer";
import user from "./users/reducer";
import activations from "./activations/reducer";
import promotions from "./promotions/reducer";
import schedules from "./schedules/reducer";
import ladders from "./ladders/reducer";
import settings from "./settings/reducer";
import products from "./products/reducer";
import orders from "./orders/reducer";
import loading from "./loading/reducer";
import cloudMessages from "./cloudMessages/reducer";
import notifications from "./notifications/reducer";
import players from "./players/reducer";
import news from "./news/reducer";
import messages from "./messages/reducer";
import feedback from "./feedback/reducer";
import requests from "./requests/reducer";
import slides from "./slides/reducer";

// export const history = createBrowserHistory();
export default combineReducers({
	//auth: authReducer,
	user,
	activations,
	cloudMessages,
	feedback,
	ladders,
	loading,
	messages,
	news,
	notifications,
	orders,
	players,
	products,
	promotions,
	requests,
	schedules,
	settings,
	slides,
	//routing: routerReducer,
});
