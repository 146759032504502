import { IonCard, IonCardContent } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

interface Props {
	onClick: any;
	active: any;
}

const DashboardActivations: React.FC<Props> = ({ onClick, active }) => {
	return (
		<IonCard button color={active ? "primary" : "default"} onClick={onClick}>
			<IonCardContent>
				<h1>Activations</h1>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(DashboardActivations);
