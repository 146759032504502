import firebase from "firebase/app";
import "firebase/firestore";
import { addUserNotifications } from "../users";
import * as actions from "./actions";

var db = firebase.firestore();

export const getNotifications = (uid: string) => {
	return (dispatch: any, data: any) => {
		db.collection("notifications").onSnapshot((docs) => {
			const data: any = {};
			docs.forEach(
				(doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
			);
			dispatch(actions.getNotificationsSuccess(data));
		});
	};
};

export function addNotification(payload: any) {
	const date = Date.now();
	const today = new Date(date);
	return db
		.collection("notifications")
		.add({ ...payload, created: today.toISOString() })
		.then(function (res) {
			//TODO this should be done with a cloud function when notification is added
			if (payload.status === "approved") {
				addUserNotifications(res.id, payload);
			}
			return res.id;
		})
		.catch(function (error) {
			return false;
		});
}

export async function updateNotification(payload: any, id: string) {
	const date = Date.now();
	const today = new Date(date);

	return db
		.collection("notifications")
		.doc(id)
		.update({ ...payload, updated: today.toISOString() })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});
}

export async function deleteNotification(id: any) {
	await db
		.collection("notifications")
		.doc(id)
		.delete()
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}

export const getUserNotifications = (uid: string) => {
	return (dispatch: any, data: any) => {
		db.collection("users")
			.doc(uid)
			.collection("notifications")
			.onSnapshot((docs) => {
				const data: any = {};
				docs.forEach(
					(doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
				);
				dispatch(actions.getNotificationsSuccess(data));
			});
	};
};

// export async function getNotification(id: any) {
// 	let data;
// 	await db
// 		.collection("notifications")
// 		.doc(id)
// 		.get()
// 		.then((doc) => {
// 			if (doc.exists) {
// 				data = doc.data();
// 			}
// 		})
// 		.catch((error) => {
// 			console.log(error);
// 			return false;
// 		});

// 	return data;
// }

// export function addNotification(users: any, payload: any) {
// 	// if (users === "all") {
// 	// 	console.log;
// 	// }
// 	const date = Date.now();
// 	const today = new Date(date);
// 	return db
// 		.collection("users")
// 		.get()
// 		.then((users) => {
// 			users.forEach((user: any) => {
// 				console.log(user.id);
// 			});
// 			//   .add({ ...payload, created: today.toISOString() })
// 			// .then(function (res) {
// 			// 	return res.id;
// 			// })
// 			// .catch(function (error) {
// 			// 	return false;
// 			// });
// 		});
// }
