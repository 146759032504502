import {
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonButton,
  IonInput,
  IonTextarea,
  IonList,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
  addPlayer,
  updatePlayer,
  getPlayers,
  deletePlayer,
} from "../../../../services/players";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { AppHeader } from "../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../components/Header/AppFooter";

import { trash } from "ionicons/icons";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
  id: string;
}

interface Props {
  players: any;
  getPlayers: any;
  teams: AnalyserNode;
}

const AdminPlayer: React.FC<Props> = ({ players, teams }) => {
  const history = useHistory();

  const defaultState = {
    name: "",
    pos: "",
    num: "",
    ht: "",
    wt: "",
    dob: "",
    coll: "",
    nickname: "",
    hometown: "",
    ff1: "",
    ff2: "",
    ff3: "",
    img: "",
    team: "",
  };

  let { id } = useParams<RouteParams>();
  const [player, setPlayer] = useState<any>(defaultState);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [team, setTeam] = useState<any>(defaultState);

  const { name, img } = player;

  useEffect(() => {
    // console.log(id);
    if (players && players[id]) {
      setTeam(players[id].team);
      setPlayer({ ...players[id] });
    }
    //eslint-disable-next-line
  }, [id, players]);

  const _onChange = (e: any) =>
    setPlayer({ ...player, [e.target.name]: e.target.value });

  const _onSelect = (e: any) => {
    var name = e.target.name;
    if (name === "team") setTeam(e.target.value);
  };

  const _compareTeam = (o1: any, o2: any) => {
    return o1 === team;
  };

  const _submit = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();
    if (player.name.trim() === "" || player.pos.trim() === "") {
      setDisableSubmit(false);
      return toast("You must include a position and a name");
    }
    if (id === "new") {
      addPlayer({ ...player, team }).then((res: any) => {
        if (res !== false) {
          history.push(`/admin/player/${res}`);
          //console.log(res);
          toast("Player saved");
        }
      });
    } else {
      updatePlayer({ ...player, team }, id).then((res) => {
        if (res) {
          // console.log(id);
          toast("Player updated");
        }
      });
    }
    getPlayers();
    setDisableSubmit(false);
  };

  const _back = () => {
    setPlayer(defaultState);
    history.push("/admin/players");
  };

  const _delete = () => {
    deletePlayer(id).then(() => {
      // getPlayers();
      history.push(`/admin/players`);
    });
  };

  const _onPhotoChange = (e: any) => {
    setPlayer({ ...player, img: e.image.fullPath });
  };

  return (
    <>
      <AppHeader title={name} />
      <IonContent className="ion-padding admin">
        <IonTitle>Add Player</IonTitle>
        <form onSubmit={_submit}>
          <IonList>
            <IonItem>
              <IonLabel>Team</IonLabel>
              <IonSelect
                compareWith={_compareTeam}
                value={team}
                name="team"
                onIonChange={_onSelect}
                okText="Okay"
                cancelText="Dismiss"
                placeholder="Select One"
              >
                {Object.values(teams).map((team: any) => (
                  <IonSelectOption key={team.id} value={team.id}>
                    {team.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="img"
                id="img"
                value={img}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                type="text"
                id="name"
                name="name"
                value={player.name}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Position</IonLabel>
              <IonInput
                type="text"
                id="pos"
                name="pos"
                value={player.pos}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Number</IonLabel>
              <IonInput
                type="number"
                id="num"
                name="num"
                value={player.num}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">DOB</IonLabel>
              <IonInput
                type="date"
                id="dob"
                name="dob"
                value={player.dob}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Height (cm)</IonLabel>
              <IonInput
                type="number"
                id="ht"
                name="ht"
                value={player.ht}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Weight (kg)</IonLabel>
              <IonInput
                type="number"
                id="wt"
                name="wt"
                value={player.wt}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">College</IonLabel>
              <IonInput
                type="text"
                id="coll"
                name="coll"
                value={player.coll}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Nickname</IonLabel>
              <IonInput
                type="text"
                id="nickname"
                name="nickname"
                value={player.nickname}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Hometown</IonLabel>
              <IonInput
                type="text"
                id="hometown"
                name="hometown"
                value={player.hometown}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Fun Fact 1</IonLabel>
              <IonTextarea
                id="ff1"
                name="ff1"
                value={player.ff1}
                onIonChange={_onChange}
              ></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Fun Fact 2</IonLabel>
              <IonTextarea
                id="ff2"
                name="ff2"
                value={player.ff2}
                onIonChange={_onChange}
              ></IonTextarea>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Fun Fact 3</IonLabel>
              <IonTextarea
                id="ff3"
                name="ff3"
                value={player.ff3}
                onIonChange={_onChange}
              ></IonTextarea>
            </IonItem>
          </IonList>

          <IonButton type="submit" color="primary" disabled={disableSubmit}>
            Save
          </IonButton>
          <IonButton onClick={_back} color="warning">
            Cancel
          </IonButton>
          <IonButton onClick={_delete} color="danger">
            <IonIcon icon={trash} slot="start"></IonIcon>
            Delete
          </IonButton>
        </form>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  players: state.players,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, { getPlayers })(AdminPlayer);
