import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

interface Props {
	orders: any;
	onClick: any;
	active: any;
}

const DashboardOrders: React.FC<Props> = ({ orders, onClick, active }) => {
	return (
		<IonCard button color={active ? "primary" : "default"} onClick={onClick}>
			<IonCardContent>
				<h1>Orders</h1>
				<IonRow>
					<IonCol>Count: {Object.values(orders).length}</IonCol>
				</IonRow>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	orders: state.orders.adminOrders,
});

export default connect(mapStateToProps, {})(DashboardOrders);
