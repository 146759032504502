import {
	IonBadge,
	IonButton,
	IonCard,
	IonCardContent,
	IonCol,
	IonGrid,
	IonIcon,
	IonRow,
} from "@ionic/react";
import { trash } from "ionicons/icons";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import {
	deleteSubmission,
	saveWinners,
	setSubmissionStatus,
} from "../../../../../services/activations";
import { pushToScreen } from "../../../../../services/settings";

interface Props {
	submission: any;
	activation: any;
}

const SubmissionCard: React.FC<Props> = ({ submission, activation }) => {
	const { id, submitter, data, status } = submission;
	const { fname, lname, email, phoneNumber } = submitter;

	const [answer, setAnswer] = useState<any>("");
	const [winners, setWinners] = useState<any>([]);
	const [inWinners, setInWinners] = useState<any>("");
	const [scoreDifference, setScoreDifference] = useState<any>({});

	useEffect(() => {
		if (activation) {
			if (activation.options && data.selected) {
				let options = activation.options;
				//console.log(options);
				let chosen: any = options.find((option: any) => {
					return option.oid === data.selected;
				});
				if (chosen) {
					//console.log(chosen);
					setAnswer(chosen.olabel);
				}
			}
			if (activation.winners) {
				setWinners(activation.winners);
			}
		}
		//eslint-disable-next-line
	}, [activation]);

	useEffect(() => {
		setInWinners(winners.find((winner: any) => winner.id === id));
	}, [winners, id]);

	const _addAsWinner = () => {
		if (!inWinners) {
			saveWinners([...winners, submission], activation.id);
		}
	};

	const _delete = () => {
		if (inWinners) {
			let data = winners;
			data.splice(id, 1);
			saveWinners([...data], activation.id);
		}
		deleteSubmission(id);
	};

	useEffect(() => {
		if (activation.type === "score") {
			let homeDiff = data.score.homeScore - activation.finalScoreHome;
			let awayDiff = data.score.awayScore - activation.finalScoreAway;
			let differences = {
				home: homeDiff,
				away: awayDiff,
				total: Math.abs(homeDiff) + Math.abs(awayDiff),
			};
			setScoreDifference(differences);
		}

		//eslint-disable-next-line
	}, [submission]);

	const _push = () => {
		setSubmissionStatus({ id, status: "played" });
		pushToScreen({ type: "submission", id });
	};

	return (
		<IonCard>
			<IonCardContent>
				<IonGrid>
					<IonRow>
						{activation.canPush && (
							<IonCol sizeSm="1" size="6">
								{status === "played" && (
									<IonBadge color="success" onClick={_push} className="linked">
										Played
									</IonBadge>
								)}
								{status !== "played" && (
									<IonButton onClick={_push}>
										<span className="buttonText hide960">Push</span>
									</IonButton>
								)}
							</IonCol>
						)}
						{answer && (
							<IonCol sizeSm="2" size="6">
								{answer}
							</IonCol>
						)}
						<IonCol sizeSm="3" size="6">
							<IonRow>
								<IonCol>
									{fname} {lname}
								</IonCol>
								<IonCol>{email}</IonCol>
								<IonCol>{phoneNumber}</IonCol>
							</IonRow>
						</IonCol>
						{activation.type === "score" && (
							<IonCol sizeSm="2" size="6">
								<p>
									<b>Home:</b>
									<span>
										{data.score.homeScore}{" "}
										{activation.finalScoreHome && (
											<>
												{scoreDifference.home >= 0 && "+"}
												{scoreDifference.home})
											</>
										)}
									</span>
								</p>

								<p>
									<b>Away:</b>
									<span>
										{data.score.awayScore}
										{activation.finalScoreAway && (
											<>
												({scoreDifference.away >= 0 && "+"}
												{scoreDifference.away})
											</>
										)}
									</span>
								</p>
								{activation.finalScoreHome && activation.finalScoreAway && (
									<p>
										<b>Total:</b>
										<span>{scoreDifference.total}</span>
									</p>
								)}
							</IonCol>
						)}
						{data.additionalFields && (
							<IonCol>
								{data.additionalFields.text && (
									<div>{data.additionalFields.text}</div>
								)}
								{data.additionalFields.textarea && (
									<div>{data.additionalFields.textarea}</div>
								)}
								{data.additionalFields.number && (
									<div>{data.additionalFields.number}</div>
								)}
							</IonCol>
						)}
						<IonCol sizeSm="2" size="6">
							<IonButton
								disabled={inWinners}
								onClick={_addAsWinner}
								fill="outline"
								size="small"
							>
								Select as winner
							</IonButton>
							<IonButton onClick={_delete} fill="outline" size="small">
								<IonIcon icon={trash} />
							</IonButton>
							{/* <IonButton
								onClick={_edit}
								color="success"
								fill="outline"
								size="small"
							>
								<IonIcon icon={pencil} />
							</IonButton> */}
							{/* {formatDateTime(created)} */}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(SubmissionCard);
