import {
	IonCard,
	IonCardContent,
	IonGrid,
	IonRow,
	IonCol,
	IonCardSubtitle,
	IonInput,
	IonIcon,
	IonButtons,
	IonItem,
	IonLabel,
	IonThumbnail,
	IonButton,
	IonCardTitle,
	IonText,
} from "@ionic/react";
import { addOutline, removeOutline } from "ionicons/icons";
// import { removeOutline, addOutline } from "ionicons/icons";

// Import React Libraries
import React, { Fragment, useEffect, useState } from "react";
import { getImageURL } from "../../../../services/util";

interface Props {
	type: any;
	item: any;
	setQuantity: any;
	qty: any;
}

const ProductCard: React.FC<Props> = ({ type, item, setQuantity, qty }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const { img } = item;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	const _addToCart = () => {
		var currQty = qty ? qty : 0;
		setQuantity(item.id, currQty + 1);
	};

	const _removeFromCart = () => {
		var currQty = qty >= 0 ? qty : 0;
		if (currQty > 0) {
			setQuantity(item.id, currQty - 1);
		}
	};

	const _onChange = (e: any) => {
		var newQty = e.target.value;
		setQuantity(item.id, parseInt(newQty));
	};

	return (
		<IonCard className="productCard">
			{type !== "list" ? (
				<Fragment>
					<img id="image" alt={item.name} src={imageURL} />
					<IonCardContent className="ion-no-padding">
						<IonGrid className="ion-no-padding">
							<IonRow className="details-row ion-padding">
								<IonCol className="">
									<IonCardTitle className="ellipsis name">
										{item.name}
									</IonCardTitle>
									<IonCardSubtitle className="ellipsis price">
										${parseFloat(item.price).toFixed(2)}
									</IonCardSubtitle>
								</IonCol>
							</IonRow>
							{type === "simple" && (
								<IonRow className="action-row">
									<IonCol className="qty-col" size="7">
										<IonItem>
											<IonLabel>QTY:</IonLabel>
											<IonInput
												type="number"
												id={`qty-${item.id}`}
												name={item.id}
												value={qty >= 0 ? qty : 0}
												onIonChange={_onChange}
											/>
										</IonItem>
									</IonCol>
									<IonCol className="ion-no-padding button-col" size="5">
										<IonButton
											className="ion-no-margin add-button"
											size="small"
											onClick={_addToCart}
										>
											Add
										</IonButton>
									</IonCol>
								</IonRow>
							)}
							{type === "cart" && (
								<IonRow>
									<IonButtons>
										{qty > 0 && (
											<IonButton onClick={_removeFromCart}>
												<IonIcon icon={removeOutline} />
											</IonButton>
										)}
										<IonInput
											type="number"
											id={`qty-${item.id}`}
											name={item.id}
											value={qty >= 0 ? qty : 0}
											onIonChange={_onChange}
										/>

										<IonButton onClick={_addToCart}>
											<IonIcon icon={addOutline} />
										</IonButton>
									</IonButtons>
								</IonRow>
							)}
						</IonGrid>
					</IonCardContent>
				</Fragment>
			) : (
				<IonItem>
					<IonThumbnail slot="start">
						<img alt="" src={imageURL} />
					</IonThumbnail>
					<IonGrid>
						<IonRow>
							<IonCol>
								<IonText>
									<h6>{item.name}</h6>
								</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<div className="div-price">
									{item.dPrice && (
										<p className="para-d-price">
											<del>$ {item.dPrice}</del>
										</p>
									)}
									<p className="para-price">
										${parseFloat(item.price).toFixed(2)}
									</p>
								</div>
							</IonCol>

							<IonCol size="6" className="product-actions-wrapper">
								<IonButtons className="div-btn product-actions">
									<IonButton
										className="btn-minus"
										fill="clear"
										onClick={_removeFromCart}
									>
										<p>-</p>
									</IonButton>

									<p className="para-count">{qty ? qty : 0}</p>
									<IonButton
										fill="clear"
										className="btn-add"
										onClick={_addToCart}
									>
										<p>+</p>
									</IonButton>
								</IonButtons>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			)}
		</IonCard>
	);
};

export default ProductCard;
