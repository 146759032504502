import {
	IonCol,
	IonGrid,
	IonRow,
	IonItem,
	IonLabel,
	IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";
import TeamIcon from "../../../Admin/Schedules/Teams/components/TeamIcon";

export interface RouteParams {
	id: string;
}

interface Props {
	match: any;
	teams: any;
}

const TeamSummary: React.FC<Props> = ({ match, teams }) => {
	const defaultState = {
		active: false,
		matchDate: "",
		matchTime: "",
		homeTeam: "",
		awayTeam: "",
		venue: "",
		ticketsLink: "",
		recapLink: "",
		livestreamLink: "",
		previewLink: "",
		statsLink: "",
		awayScore: "",
		homeScore: "",
		status: "",
		synopsisPre: "",
		synopsisPost: "",
		img: "",
	};

	const [game, setGame] = useState<any>(defaultState);
	const [imageURL, setImageURL] = useState<any>("");

	const { homeTeam, awayTeam, homeScore, awayScore, status, img } = game;

	useEffect(() => {
		if (match) {
			setGame({ ...defaultState, ...match });
		}
		//eslint-disable-next-line
	}, [match]);

	useEffect(() => {
		//console.log(img);
		if (img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	return (
		<div className="teamDetails">
			{teams && teams[awayTeam] && (
				<IonItem className="teamDetailsItem" lines="none">
					<div className="teamIcon" slot="start">
						<TeamIcon team={teams[awayTeam]} />
					</div>
					<IonText className="teamName">
						<b>{teams[awayTeam].fullname}</b>
					</IonText>
					<IonGrid slot="end" className="teamStats">
						<IonRow>
							<IonCol>
								<span className="teamRecord">{teams[awayTeam].record}</span>
							</IonCol>
							<IonCol>
								{awayScore && status !== "pre" && (
									<IonLabel className="matchScore">{awayScore}</IonLabel>
								)}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			)}
			{teams && teams[homeTeam] && (
				<IonItem className="teamDetailsItem" lines="none">
					<div className="teamIcon" slot="start">
						<TeamIcon team={teams[homeTeam]} />
					</div>
					<IonText className="teamName">
						<b>{teams[homeTeam].fullname}</b>
					</IonText>
					<IonGrid slot="end" className="teamStats">
						<IonRow>
							<IonCol>
								<span className="teamRecord">{teams[homeTeam].record}</span>
							</IonCol>
							<IonCol>
								{awayScore && status !== "pre" && (
									<IonLabel className="matchScore">{homeScore}</IonLabel>
								)}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonItem>
			)}
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(TeamSummary);
