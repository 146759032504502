// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../services/util";

interface Props {
	message: any;
	settings: any;
}

const MessageSlide: React.FC<Props> = ({ message, settings }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const [defaultImage, setDefaultImage] = useState<any>("");

	const { id, name, img, body, votes } = message;

	useEffect(() => {
		if (img !== "") {
			getImageURL(img, "1080").then((url: any) => {
				setImageURL(url);
			});
		} else {
			setImageURL("");
		}
		//eslint-disable-next-line
	}, [img, id]);

	useEffect(() => {
		if (settings["admin-message-default-slide-image"]) {
			let image = settings["admin-message-default-slide-image"].value;
			getImageURL(image, "1920").then((url: any) => {
				setDefaultImage(url);
			});
		}
		//eslint-disable-next-line
	}, [settings, img]);

	return (
		<Fragment>
			{img ? (
				<div className="slide-wrapper customImage">
					<div
						style={{ backgroundImage: `url(${imageURL})` }}
						className="bg-image"
					>
						<div className="bg-overlay"></div>
					</div>
					<div
						style={{ backgroundImage: `url(${imageURL})` }}
						className="slide"
					>
						{(name !== "" || body !== "" || votes > 0) && (
							<div className="content-wrapper">
								<div className="content">
									{body !== "" && <span className="message">{body}</span>}
									<div className="row name votes">
										<div className="name-wrapper">
											{name !== "" && <span className="name">{name}</span>}
										</div>
										{/* {votes > 0 && (
										<div className="votes-wrapper">
											<IonIcon icon={star} className="icon" />
											<span className="votes">{votes}</span>
										</div>
									)} */}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			) : (
				<div
					style={{ backgroundImage: `url(${defaultImage})` }}
					className="slide-wrapper defaultImage"
				>
					<div className="slide">
						<div className="content-wrapper">
							<div className="content">
								<span className="message">{body}</span>
								<div className="row name votes">
									<div className="name-wrapper">
										<span className="name">{name}</span>
									</div>
									{/* <div className="votes-wrapper">
									<IonIcon icon={star} className="icon" />
									<span className="votes">{votes}</span>
								</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

const mapStateTopProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateTopProps, {})(MessageSlide);
