import firebase from "firebase/app";
import "firebase/firestore";
import * as actions from "./actions";

var db = firebase.firestore();

export function addPlayer(payload: any) {
	const date = Date.now();
	const today = new Date(date);
	return db
		.collection("players")
		.add({ ...payload, created: today.toISOString() })
		.then(function (res) {
			return res.id;
		})
		.catch(function (error) {
			return false;
		});
}

export async function updatePlayer(payload: any, pid: string) {
	//console.log(payload);

	const date = Date.now();
	const today = new Date(date);

	await db
		.collection("players")
		.doc(pid)
		.update({ ...payload, updated: today.toISOString() })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});

	return true;
}

export const getPlayers = () => {
	return (dispatch: any, data: any) => {
		let playersRef = db.collection("players");
		playersRef.onSnapshot((docs) => {
			const data: any = [];
			docs.forEach(
				(doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
			);
			dispatch(actions.getPlayersSuccess(data));
		});

		//return { type: types.ADD_NEW_GOOGLE_USER, payload }
	};
};

export async function getPlayer(pid: any) {
	let playerData;
	await db
		.collection("players")
		.doc(pid)
		.get()
		.then((doc) => {
			if (doc.exists) {
				//console.log(doc);
				playerData = doc.data();
			}
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
	return playerData;
	//return { type: types.ADD_NEW_GOOGLE_USER, payload }
}

export async function deletePlayer(pid: any) {
	await db
		.collection("players")
		.doc(pid)
		.delete()
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}
