import { IonContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";
import MessageFeed from "./components/MessageFeed";
import SendMessage from "./components/SendMessage";

interface Props {
	settings: any;
}

const Messages: React.FC<Props> = ({ settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");
	const [active, setActive] = useState<any>("");

	useEffect(() => {
		if (settings["message-header-image"]) {
			getImageURL(settings["message-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
		if (settings["message-active"]) {
			setActive(settings["message-active"].value);
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="Messages" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<h1 className="section-header">Send a Message</h1>
				{active ? (
					<SendMessage />
				) : (
					<p className="center-text">
						Sorry, sending messages to the big screen is currently unavailable.
						Please check back again soon.
					</p>
				)}
				<div className="spacer30" />
				<h1 className="section-header">Fan Messages</h1>
				<MessageFeed />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(Messages);
