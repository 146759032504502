import {
	IonLabel,
	IonList,
	IonCard,
	IonCardContent,
	IonBadge,
	IonItem,
	IonListHeader,
	IonText,
} from "@ionic/react";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { formatDateTime } from "../../../../services/util";

interface Props {
	order: any;
	products: any;
}

const OrderCard: React.FC<Props> = ({ order, products }) => {
	const { userDetails, orderNumber, cartTotal, status, cart, created, notes } =
		order;

	return (
		<IonCard className="orderCard">
			<IonCardContent>
				<IonList lines="none">
					<IonItem className="orderCardRow">
						<IonLabel slot="start">#{orderNumber}</IonLabel>
						{status === "pending" && (
							<IonBadge slot="end" color="danger">
								Pending
							</IonBadge>
						)}
						{status === "processing" && (
							<IonBadge slot="end" color="warning">
								Processing
							</IonBadge>
						)}
						{status === "complete" && (
							<IonBadge slot="end" color="success">
								Complete
							</IonBadge>
						)}
					</IonItem>
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Date</IonLabel>
						<IonText>{formatDateTime(created)}</IonText>
					</IonItem>
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Name: </IonLabel>
						<IonText>
							{userDetails.fname} {userDetails.lname}
						</IonText>
					</IonItem>
					{userDetails.seat && (
						<IonItem className="orderCardRow">
							<IonLabel slot="start">Seat</IonLabel>
							<IonText>
								{userDetails.seat.section}
								{userDetails.seat.row}
								{userDetails.seat.number}
							</IonText>
						</IonItem>
					)}
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Phone:</IonLabel>
						<IonText>{userDetails.phoneNumber}</IonText>
					</IonItem>
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Email:</IonLabel>
						<IonText>{userDetails.email}</IonText>
					</IonItem>

					<IonListHeader>Products</IonListHeader>
					<IonItem className="orderCardRow">
						<IonList lines="none" className="full-width">
							{Object.entries(cart)
								.filter((item: any) => item[1] > 0)
								.map((order: any, idx: any) => (
									<IonItem key={idx} className="orderCardRow">
										{products && products[order[0]] && (
											<Fragment>
												<IonText>
													{order[1]} {" x "}
													{products[order[0]].name} @ $
													{parseFloat(products[order[0]].price).toFixed(2)}
												</IonText>
												<IonText slot="end" className="ion-text-right">
													$
													{(
														parseFloat(products[order[0]].price) *
														parseFloat(order[1])
													).toFixed(2)}
												</IonText>
											</Fragment>
										)}
									</IonItem>
								))}
						</IonList>
					</IonItem>
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Total:</IonLabel>
						<IonText>${cartTotal.toFixed(2)}</IonText>
					</IonItem>
					<IonItem className="orderCardRow">
						<IonLabel slot="start">Notes:</IonLabel>
						<IonText>{notes}</IonText>
					</IonItem>
				</IonList>
			</IonCardContent>
		</IonCard>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
});

export default connect(mapStateToProps, {})(OrderCard);
