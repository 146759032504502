import firebase from "firebase/app";
import "firebase/firestore";
import { getProductsSuccess } from "./actions";

var db = firebase.firestore();

export const getProducts = () => {
	return (dispatch: any, data: any) => {
		db.collection("products")
			.get()
			.then((docs) => {
				const data: any = {};
				docs.forEach((doc) => (data[doc.id] = { ...doc.data(), id: doc.id }));
				dispatch(getProductsSuccess(data));
			});
	};
};

export async function getProduct(id: any) {
	let data;
	await db
		.collection("products")
		.doc(id)
		.get()
		.then((doc) => {
			if (doc.exists) {
				data = doc.data();
			}
		})
		.catch((error) => {
			console.log(error);
			return false;
		});

	return data;
}

export async function addProduct(payload: any) {
	const date = Date.now();
	const today = new Date(date);
	return db
		.collection("products")
		.add({ ...payload, created: today.toISOString() })
		.then(function (res) {
			return res.id;
		})
		.catch(function (error) {
			return false;
		});
}

export async function updateProduct(payload: any, id: string) {
	const date = Date.now();
	const today = new Date(date);

	await db
		.collection("products")
		.doc(id)
		.update({ ...payload, updated: today.toISOString() })
		.then(function () {
			return true;
		})
		.catch(function (error) {
			return false;
		});

	return true;
}

export async function deleteProduct(id: any) {
	await db
		.collection("products")
		.doc(id)
		.delete()
		.then(() => {
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
}
