import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { Fragment } from "react";

interface Props {
	match: any;
}

const AwayTeamRoster: React.FC<Props> = ({ match }) => {
	const { oppositionRoster } = match;

	return (
		<IonGrid id="awayTeamRoster">
			{oppositionRoster && (
				<Fragment>
					<IonRow>
						<IonCol size="2" className="header">
							#
						</IonCol>
						<IonCol size="8" className="header">
							Name
						</IonCol>
						<IonCol size="2" className="header">
							POS
						</IonCol>
					</IonRow>

					{Object.values(oppositionRoster).length > 0 &&
						Object.values(oppositionRoster).map((player: any, idx: any) => (
							<IonRow key={idx}>
								<IonCol size="2" className="number">
									{player.p_number}
								</IonCol>
								<IonCol size="8" className="name">
									{player.p_name}
								</IonCol>
								<IonCol size="2" className="pos">
									{player.p_pos}
								</IonCol>
							</IonRow>
						))}
				</Fragment>
			)}
		</IonGrid>
	);
};

export default AwayTeamRoster;
