import React, { useEffect, useState } from "react";
import { IonItem, IonIcon, IonLabel } from "@ionic/react";
import { menuController } from "@ionic/core";
import { getCurrentUser } from "../../services/auth";
import { useHistory } from "react-router";

import {
  fastFood,
  musicalNotes,
  newspaper,
  tv,
  people,
  send,
  statsChart,
  ticket,
  trophy,
  help,
  home,
} from "ionicons/icons";
import { connect } from "react-redux";

import firebase from "firebase/app";
import "firebase/auth";

interface Props {
  user: any;
  settings: any;
}

const FrontendMenu: React.FC<Props> = ({ user, settings }) => {
  const history = useHistory();

  const [shopVisible, setShopVisible] = useState<any>(false);
  const [promotionsVisible, setPromotionsVisible] = useState<any>(false);
  const [newsVisible, setNewsVisible] = useState<any>(false);

  useEffect(() => {
    getCurrentUser().then((userData: any) => {
      if (!userData) {
        history.replace("/");
      }
      firebase.auth().onAuthStateChanged(function (userData: any) {
        if (!userData) {
          history.replace("/");
        }
      });
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settings["shop-visible"]) {
      setShopVisible(settings["shop-visible"].value);
    }
    if (settings["promomotions-visible"]) {
      setPromotionsVisible(settings["promomotions-visible"].value);
    }
    if (settings["news-visible"]) {
      setNewsVisible(settings["news-visible"].value);
    }
  }, [settings]);

  const closeMenu = async () => {
    await menuController.toggle();
  };

  return (
    <>
      <IonItem routerLink="/dashboard" onClick={closeMenu}>
        <IonIcon slot="start" icon={home}></IonIcon>
        <IonLabel>Dashboard</IonLabel>
      </IonItem>
      {shopVisible && (
        <IonItem routerLink="/shop" onClick={closeMenu}>
          <IonIcon slot="start" icon={fastFood}></IonIcon>
          <IonLabel>Food Delivery</IonLabel>
        </IonItem>
      )}
      <IonItem routerLink="/messages" onClick={closeMenu}>
        <IonIcon slot="start" icon={tv}></IonIcon>
        <IonLabel>Get on the Big Screen</IonLabel>
      </IonItem>
      <IonItem routerLink="/requests" onClick={closeMenu}>
        <IonIcon slot="start" icon={musicalNotes}></IonIcon>
        <IonLabel>Request a Song</IonLabel>
      </IonItem>
      <IonItem routerLink="/activations" onClick={closeMenu}>
        <IonIcon slot="start" icon={trophy}></IonIcon>
        <IonLabel>Competitions</IonLabel>
      </IonItem>
      {promotionsVisible && (
        <IonItem routerLink="/promotions" onClick={closeMenu}>
          <IonIcon slot="start" icon={ticket}></IonIcon>
          <IonLabel>Fan Deals</IonLabel>
        </IonItem>
      )}
      <IonItem routerLink="/players" onClick={closeMenu}>
        <IonIcon slot="start" icon={people}></IonIcon>
        <IonLabel>Our Team</IonLabel>
      </IonItem>
      <IonItem routerLink="/scheduleladder" onClick={closeMenu}>
        <IonIcon slot="start" icon={statsChart}></IonIcon>
        <IonLabel>Schedule & Ladder</IonLabel>
      </IonItem>
      <IonItem routerLink="/feedback" onClick={closeMenu}>
        <IonIcon slot="start" icon={send}></IonIcon>
        <IonLabel>Send Us Your Feedback</IonLabel>
      </IonItem>
      {newsVisible && (
        <IonItem routerLink="/news" onClick={closeMenu}>
          <IonIcon slot="start" icon={newspaper}></IonIcon>
          <IonLabel>News</IonLabel>
        </IonItem>
      )}
      <IonItem routerLink="/about" onClick={closeMenu}>
        <IonIcon slot="start" icon={help}></IonIcon>
        <IonLabel>About</IonLabel>
      </IonItem>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
  settings: state.settings,
});

export default connect(mapStateToProps, {})(FrontendMenu);
