import {
	IonContent,
	IonLabel,
	IonItem,
	IonImg,
	IonItemGroup,
	IonItemDivider,
	IonCol,
	IonGrid,
	IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { getImageURL } from "../../../../services/util";
import { connect } from "react-redux";

export interface RouteParams {
	id: string;
}

interface Props {
	players: any;
}

const Player: React.FC<Props> = ({ players }) => {
	const defaultAvatar =
		"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/default-profile.jpg?alt=media&token=b1fd87b3-ccb9-436c-9061-ca95d80281d6";

	const defaultState = { img: "" };

	let { id } = useParams<RouteParams>();
	const [player, setPlayer] = useState<any>(defaultState);
	const [dob, setDob] = useState<any>("");
	const [age, setAge] = useState<any>("");
	const [imageURL, setImageURL] = useState<any>("");
	const { img } = player;

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	useEffect(() => {
		setPlayer({ ...players[id] });
	}, [id, players]);

	useEffect(() => {
		if (player.dob) {
			var options: any = { year: "numeric", month: "long", day: "numeric" };
			var date = new Date(player.dob.replace(/-/g, "/"));
			setDob(date.toLocaleDateString("en-NZ", options));

			var bday = new Date(player.dob).getTime();
			var diff = Date.now() - bday;
			var age = Math.floor(diff / 31536000000);
			setAge(age);
		}
	}, [player]);

	return (
		<>
			<AppHeader title={player.name} />
			<IonContent
				fullscreen
				className="ion-padding playerProfile playerProfilePage"
			>
				<IonImg
					alt=""
					src={imageURL ? imageURL : defaultAvatar}
					className="playerImage full-width-image"
				/>
				<IonGrid className="playerInfoBlock">
					<IonRow>
						{player.pos && (
							<IonCol>
								<span>{player.pos}</span>
							</IonCol>
						)}
						{player.num && (
							<IonCol>
								<sup># </sup>
								<span>{player.num}</span>
							</IonCol>
						)}
					</IonRow>
				</IonGrid>
				<h1 className="playerName">{player.name}</h1>
				<h3 className="playerStats">
					{player.ht && <span>{player.ht}cm</span>}
					{player.ht && player.wt && <span className="spacer">•</span>}
					{player.wt && <span>{player.wt}kg</span>}
				</h3>

				<IonItemGroup className="playerDetails">
					{dob && (
						<IonItem lines="none">
							<IonLabel>DoB:</IonLabel>
							<p>{dob}</p>
						</IonItem>
					)}
					{age && (
						<IonItem lines="none">
							<IonLabel>Age:</IonLabel>
							<p>{age} years</p>
						</IonItem>
					)}
					{player.coll && (
						<IonItem lines="none">
							<IonLabel>College:</IonLabel>
							<p>{player.coll}</p>
						</IonItem>
					)}
					{player.nickname && (
						<IonItem lines="none">
							<IonLabel>Nickname:</IonLabel>
							<p>{player.nickname}</p>
						</IonItem>
					)}
					{player.hometown && (
						<IonItem lines="none">
							<IonLabel>Hometown:</IonLabel>
							<p>{player.hometown}</p>
						</IonItem>
					)}
				</IonItemGroup>
				{(player.ff1 || player.ff2 || player.ff3) && (
					<IonItemGroup className="fun-facts">
						<IonItemDivider>
							<IonLabel>Fun Facts:</IonLabel>
						</IonItemDivider>
						{player.ff1 && (
							<IonItem lines="none">
								<p>{player.ff1}</p>
							</IonItem>
						)}
						{player.ff2 && (
							<IonItem lines="none">
								<p>{player.ff2}</p>
							</IonItem>
						)}
						{player.ff3 && (
							<IonItem lines="none">
								<p>{player.ff3}</p>
							</IonItem>
						)}
					</IonItemGroup>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({ players: state.players });

export default connect(mapStateToProps, {})(Player);
