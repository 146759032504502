import {
  IonButton,
  IonInput,
  IonLabel,
  IonItem,
  IonList,
  IonTextarea,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { toast } from "../../../../components/Toast/toast";

import { getCurrentUser } from "../../../../services/auth";

import { addMessage } from "../../../../services/messages";
import { getImageURL } from "../../../../services/util";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";

const SendMessage: React.FC = () => {
  const defaultState = {
    name: "",
    uid: "guest",
    body: "",
    img: "",
    status: "pending",
    played: false,
    votes: 0,
  };
  const [message, setMessage] = useState<any>(defaultState);
  const [imageURL, setImageURL] = useState<any>("");
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState<boolean>(false);

  const { name, body, img } = message;

  const _onChange = (e: any) =>
    setMessage({ ...message, [e.target.name]: e.target.value });

  useEffect(() => {
    getCurrentUser().then((user: any) => {
      setMessage({ ...message, uid: user.uid });
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    //console.log(img);
    if (img !== "" && imageURL === "") {
      getImageURL(img, "600").then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [img]);

  const submitMessage = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();
    if ((!body || body.trim() === "") && (!img || img.trim() === "")) {
      setDisableSubmit(false);
      return toast("You must include a message or an image");
    } else if (!name || name.trim() === "") {
      setDisableSubmit(false);
      return toast("You must include your name");
    } else if (body.trim().length > 140) {
      setDisableSubmit(false);
      return toast("Your message is too long");
    }
    addMessage(message).then((res: any) => {
      if (res) {
        toast("Your message has been sent");
        setMessageSuccess(true);
        setDisableSubmit(false);
      }
    });
  };

  const _onPhotoChange = (e: any) => {
    setMessage({ ...message, [e.name]: e.image.fullPath });
  };

  const _reset = () => {
    setMessage(defaultState);
    setImageURL("");
    setMessageSuccess(false);
  };

  return (
    <Fragment>
      {!messageSuccess ? (
        <Fragment>
          <p className="center-text">
            Send us a photo or a message to put up on the bigscreen! We’ll play
            them throughout the game.
          </p>
          <form onSubmit={submitMessage}>
            <IonList>
              <PhotoUploadInput
                name="img"
                id="img"
                value={img}
                onChange={_onPhotoChange}
              />

              <IonItem>
                <IonLabel position="floating">Your Name</IonLabel>
                <IonInput
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onIonChange={_onChange}
                  maxlength={30}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Your Message</IonLabel>
                <IonTextarea
                  id="body"
                  name="body"
                  value={body}
                  onIonChange={_onChange}
                  maxlength={140}
                />
              </IonItem>
              <div className="spacer30" />
              <IonItem lines="none">
                <IonButton
                  disabled={disableSubmit}
                  className="ion-margin-top"
                  type="submit"
                  size="large"
                >
                  Send
                </IonButton>
              </IonItem>
            </IonList>
          </form>
        </Fragment>
      ) : (
        <div style={{ minHeight: "362px" }}>
          <p className="center-text">Thanks for sending in your message</p>
          <p className="center-text">
            <IonButton fill="outline" onClick={_reset}>
              Send Another Message
            </IonButton>
          </p>
        </div>
      )}
    </Fragment>
  );
};

export default SendMessage;
