import { IonContent, IonLabel, IonList, IonButton, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { AppFooter } from "../../../components/Header/AppFooter";
import { connect } from "react-redux";
import PostCard from "./components/PostCard";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";

interface Props {
	posts: any;
	settings: any;
}

const News: React.FC<Props> = ({ posts, settings }) => {
	const _sortByDate = (a: any, b: any) => {
		if (a.date > b.date) return -1;
		else if (a.date < b.date) return 1;
		else return 0;
	};

	const [headerImage, setHeaderImage] = useState<any>("");
	const [footerImage, setFooterImage] = useState<any>("");
	const [footerImageURL, setFooterImageURL] = useState<any>("");

	useEffect(() => {
		if (settings["news-header-image"]) {
			getImageURL(settings["news-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
		if (settings["news-footer-image"]) {
			getImageURL(settings["news-footer-image"].value).then((url: any) => {
				setFooterImage(url);
			});
		}
		if (settings["news-footer-image-URL"]) {
			setFooterImageURL(settings["news-footer-image-URL"].value);
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="News" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<IonList lines="none">
					{posts ? (
						Object.values(posts)
							.sort(_sortByDate)
							.filter((post: any) => post.status === true)
							.map((post: any, idx: any) => <PostCard key={idx} post={post} />)
					) : (
						<IonLabel className="ion-padding">No posts available</IonLabel>
					)}
				</IonList>
				<IonButton href="https://canterburyrams.basketball/news/">
					Keep Reading
				</IonButton>
				<div className="spacer60" />
				{footerImage && (
					<Fragment>
						{footerImageURL ? (
							<a href={footerImageURL}>
								<IonImg src={footerImage} className="full-width-image footer" />
							</a>
						) : (
							<IonImg src={footerImage} className="full-width-image footer" />
						)}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	posts: state.news.posts,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(News);
