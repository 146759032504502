import { IonContent } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import Hotkeys from "react-hot-keys";

import { getImageURL } from "../../../../services/util";
import ResultsView from "./components/ResultsView";
import WinnersView from "./components/WinnersView";

export interface RouteParams {
	id: string;
}

interface Props {
	activations: any;
	settings: any;
}

const AdminActivationResults: React.FC<Props> = ({ activations, settings }) => {
	let { id } = useParams<RouteParams>();
	const history = useHistory();
	const [activation, setActivation] = useState<any>("");
	const [BGimage, setBGimage] = useState<any>("");

	useEffect(() => {
		if (activation) {
			if (activation.resultsImg) {
				getImageURL(activation.resultsImg, "1920").then((url: any) => {
					setBGimage(url);
				});
			} else if (settings["activation-results-bg-image"]) {
				getImageURL(settings["activation-results-bg-image"].value, "1920").then(
					(url: any) => {
						setBGimage(url);
					}
				);
			}
		}
	}, [settings, activation]);

	useEffect(() => {
		if (activations && activations[id]) {
			setActivation(activations[id]);
		}
	}, [id, activations]);

	const _changeSlide = (e: any) => {
		console.log(activations[id].type);
		if (
			(e === "z" || e === "x" || e === "space") &&
			!["simple", "score"].includes(activations[id].type)
		) {
			if (!history.location.pathname.includes("/results")) {
				history.push(`/admin/activation/${id}/results`);
			} else {
				history.push(`/admin/activation/${id}/winners`);
			}
		} else if (e === "esc") history.push(`/admin/activation/${id}/submissions`);
		else if (e === "w" && !["simple", "score"].includes(activations[id].type))
			history.push(`/admin/activation/${id}/winners`);
		else if (e === "r" && !["simple", "score"].includes(activations[id].type))
			history.push(`/admin/activation/${id}/results`);
	};

	return (
		<>
			<Hotkeys keyName="z,x,space,esc, w, r" onKeyDown={_changeSlide} />
			<IonContent
				className="ion-padding results-page"
				style={{ backgroundImage: `url(${BGimage})` }}
			>
				{activations &&
					activations[id] &&
					activations[id].type !== "simple" &&
					activations[id].type !== "score" && (
						<ResultsView activation={activations[id]} />
					)}
				{activations &&
					activations[id] &&
					["simple", "score"].includes(activations[id].type) && (
						<WinnersView activation={activations[id]} />
					)}
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
	submissions: state.activations.submissions,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(AdminActivationResults);
