import { IonButton, IonCard, IonCardContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
  settings: any;
}

const RequestsDashboardModule: React.FC<Props> = ({ settings }) => {
  const history = useHistory();
  const [requestsActive, setRequestsActive] = useState<any>("");
  const [dashboardImage, setDashboardImage] = useState<any>("");
  const [dashboardBGImage, setDashboardBGImage] = useState<any>("");

  useEffect(() => {
    if (settings["request-dashboard-image"]) {
      getImageURL(settings["request-dashboard-image"].value).then(
        (url: any) => {
          setDashboardImage(url);
        }
      );
    }
    if (settings["request-dashboard-BG-image"]) {
      getImageURL(settings["request-dashboard-BG-image"].value).then(
        (url: any) => {
          setDashboardBGImage(url);
        }
      );
    }
    if (settings["request-active"]) {
      setRequestsActive(settings["request-active"].value);
    }
  }, [settings]);

  // useEffect(() => {
  // 	console.log(requestsActive);
  // }, [requestsActive]);

  const _linkClick = () => {
    if (requestsActive) history.push("/requests");
  };

  return (
    <div
      className="requests-dashboard-wrapper"
      style={{ backgroundImage: `url("${dashboardBGImage}")` }}
    >
      <h1 className="section-header">Request a Song</h1>
      <div className="card-wrapper">
        <IonCard
          onClick={() => _linkClick()}
          className={`requestsCard ${requestsActive} image-only`}
          button={requestsActive}
        >
          {dashboardImage && (
            <IonImg
              src={dashboardImage}
              alt="request a song"
              onClick={() => _linkClick()}
            />
          )}
          <IonCardContent></IonCardContent>
          {/* <IonCardContent>
					<p>
						Request a song for the DJ to play. Include your name and a message,
						and you just might get a shout-out!
					</p>
					{requestsActive && (
						<>
							<div className="spacer15" />
							<IonButton onClick={() => history.push("/requests")}>
								Send your request now!
							</IonButton>
						</>
					)}
				</IonCardContent> */}
        </IonCard>
        {requestsActive && (
          <div className="card-button-wrapper">
            <IonButton onClick={() => history.push("/shop")}>
              Send Yours Now
            </IonButton>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
});

export default connect(mapStateToProps, {})(RequestsDashboardModule);
