import React, { Fragment } from "react";
import { connect } from "react-redux";

export interface RouteParams {
	id: string;
}

interface Props {
	activation: any;
}

const WinnersView2: React.FC<Props> = ({ activation }) => {
	const { resultsLayout, winners } = activation;

	return (
		<Fragment>
			{/* <div className="spacer60" /> */}
			<div className={`results-wrapper vote layout-${resultsLayout}`}>
				<div className="results">
					{Object.values(winners).length > 0 && (
						<div className="winners-wrapper">
							<div className="winnersTitle">Congratulations</div>
							{Object.values(winners).map((winner: any, idx: any) => (
								<div className="winner" key={idx}>
									<div className="name">
										{winner.submitter.fname} {winner.submitter.lname}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(WinnersView2);
