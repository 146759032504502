import { IonContent } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ActivationPromoSlide from "../Activations/Activation/components/PromoSlide";
import ResultsSlide from "../Activations/Activation/components/ResultsSlide";
import WinnerSlide from "../Activations/Activation/components/WinnerSlide";
import OutputSlide from "../Slides/components/OutputSlide";
import DefaultScreen from "./Slides/DefaultScreen";

import MessageSlide from "./Slides/MessageSlide";
import RequestSlide from "./Slides/RequestSlide";
import SubmissionSlide from "./Slides/SubmissionSlide";

import { getAdminSubmissions } from "../../../services/activations";
import { getRequests } from "../../../services/requests";

interface Props {
	messages: any;
	slides: any;
	requests: any;
	activations: any;
	submissions: any;
	settings: any;
	getAdminSubmissions: any;
	getRequests: any;
}

const OutputScreen: React.FC<Props> = ({
	messages,
	slides,
	requests,
	activations,
	submissions,
	settings,
	getAdminSubmissions,
	getRequests,
}) => {
	const [output, setOutput] = useState<any>("");
	const [display, setDisplay] = useState<any>([]);
	const { type, id } = display;

	useEffect(() => {
		getAdminSubmissions();
		getRequests();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (
			settings["admin-output-default"] &&
			settings["admin-output-default"].value !== display
		) {
			let screenToShow = settings["admin-output-default"].value;
			setDisplay(screenToShow);
		}
		//eslint-disable-next-line
	}, [settings]);

	useEffect(() => {
		switch (type) {
			case "message":
				if (messages[id]) {
					setOutput(<MessageSlide message={messages[id]} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "slide":
				if (slides[id]) {
					setOutput(<OutputSlide slide={slides[id]} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "activationPromo":
				console.log(id);
				console.log(activations[id]);
				if (activations[id]) {
					setOutput(<ActivationPromoSlide id={id} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "request":
				if (requests[id]) {
					setOutput(<RequestSlide request={requests[id]} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "results":
				if (activations[id]) {
					setOutput(<ResultsSlide id={id} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "winner":
				if (activations[id]) {
					setOutput(<WinnerSlide id={id} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			case "submission":
				console.log(id);
				console.log(submissions[id]);
				if (submissions[id]) {
					setOutput(<SubmissionSlide submission={submissions[id]} />);
				} else {
					setOutput(<DefaultScreen />);
				}
				break;
			default:
				setOutput(<DefaultScreen />);
				break;
		}
		//eslint-disable-next-line
	}, [display]);

	return (
		<>
			<IonContent>
				<div className="outputScreen">
					{display && type && <div className="slider-container">{output}</div>}
				</div>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	messages: state.messages,
	slides: state.slides,
	requests: state.requests,
	activations: state.activations.activations,
	submissions: state.activations.submissions,
	settings: state.settings,
});

export default connect(mapStateToProps, { getAdminSubmissions, getRequests })(
	OutputScreen
);
