// Import Ionic Components
import {
	IonButton,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
} from "@ionic/react";

// Import React Libraries
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
	addOrder,
	setCart,
	setCurrentOrder,
	updateOrder,
} from "../../../../services/orders";

import { useHistory } from "react-router-dom";

interface Props {
	cart: any;
	products: any;
	user: any;
	setCurrentOrder: any;
	setCart: any;
	currentOrder: any;
}

const Cart: React.FC<Props> = ({
	cart,
	products,
	user,
	setCurrentOrder,
	setCart,
	currentOrder,
}) => {
	const history = useHistory();

	const defaultProfile = {
		fname: "",
		lname: "",
		email: "",
		phoneNumber: "",
		uid: "",
		seat: "",
	};

	const [userDetails, setUserDetails] = useState<any>(defaultProfile);
	const [cartTotal, setCartTotal] = useState<any>(0);

	useEffect(() => {
		if (user.uid !== "") {
			setUserDetails({
				email: user.email ? user.email : "",
				fname: user.fname ? user.fname : "",
				lname: user.lname ? user.lname : "",
				phoneNumber: user.phoneNumber ? user.phoneNumber : "",
				uid: user.uid,
				seat: "",
			});
		}
		//eslint-disable-next-line
	}, [user]);

	const _cartTotal = () => {
		if (
			products &&
			Object.values(products).length > 0 &&
			cart &&
			Object.values(cart).length > 0
		) {
			return Object.entries(cart).reduce((accu: any, curr: any) => {
				if (products[curr[0]]) {
					let price = parseFloat(products[curr[0]].price);
					let qty = curr[1];
					return accu + price * qty;
				} else {
					return accu + 0;
				}
			}, 0);
		} else return 0;
	};

	useEffect(() => {
		if (products && cart) {
			setCartTotal(_cartTotal());
		}
		//eslint-disable-next-line
	}, [products, cart]);

	const _submit = async (e: any) => {
		e.preventDefault();
		console.log(currentOrder);
		if (currentOrder) {
			updateOrder(currentOrder, {
				cart,
				userDetails,
				cartTotal: cartTotal,
			}).then((res: any) => {
				history.push("/shop/checkout");
			});
		} else {
			addOrder({
				cart,
				userDetails,
				cartTotal: cartTotal,
				status: "pending",
				orderNumber: Math.floor(Math.random() * 899999 + 100000),
			}).then((order) => {
				setCurrentOrder(order);
				history.push("/shop/checkout");
			});
		}
	};

	const _empty = () => {
		setCart({});
	};

	return (
		<Fragment>
			<div className="cart-content">
				<IonCard className="price-card card">
					<IonCardContent>
						<IonRow>
							<IonCol size="6">
								<p>Sub Total</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>${cartTotal.toFixed(2)}</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>Delivery Fee</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>$0.00</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>GST (included)</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>${((cartTotal / 115) * 15).toFixed(2)}</p>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6">
								<p>
									<b>Total</b>
								</p>
							</IonCol>
							<IonCol className="ion-text-right" size="6">
								<p>
									<b>${cartTotal.toFixed(2)}</b>
								</p>
							</IonCol>
						</IonRow>
					</IonCardContent>
				</IonCard>
				<div className="cart-proceed-button">
					<IonButton disabled={cartTotal === 0} onClick={_submit}>
						Proceed To Checkout
					</IonButton>
				</div>
				<p className="center-text">
					<IonButton size="small" fill="outline" color="dark" onClick={_empty}>
						Empty Cart
					</IonButton>
				</p>
				<div className="spacer30" />
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	cart: state.orders.cart,
	products: state.products,
	user: state.user.userDetails,
	payments: state.user.payments,
	currentOrder: state.orders.order,
});

export default connect(mapStateToProps, {
	setCurrentOrder,
	setCart,
})(Cart);
