import { IonContent, IonLabel, IonList, IonSearchbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import FuzzySearch from "fuzzy-search";

import { getUsers } from "../../../services/users";

import { AppHeader } from "../../../components/Header/AppHeader";
import AdminUserCard from "./components/UserCard";
import AdminFooter from "../../../components/Header/AdminFooter";

const Users: React.FC = () => {
	const [users, setUsers] = useState<any>([]);
	const [searchText, setSearchText] = useState<any>("");
	const [searchResults, setSearchResults] = useState<any>([]);

	useEffect(() => {
		getUsers().then((res) => {
			let userData = Object.values(res).filter(
				(user: any) => user.provider !== "anon"
			);
			setUsers(userData);
		});
	}, []);

	const filterUsers = () => {
		//console.log(orders);
		const searcher = new FuzzySearch(
			users,
			["id", "fname", "lname", "displayName", "phoneNumber", "email"],
			{ caseSensitive: false }
		);
		//console.log("checking searchText");
		if (searchText !== "") {
			//console.log("search text is NOT empty: ", searchText);
			setSearchResults(searcher.search(searchText));
		} else {
			//console.log("search text is empty: ", searchText);
			setSearchResults(users);
		}
	};

	useEffect(() => {
		filterUsers();
		//eslint-disable-next-line
	}, [searchText, users]);

	return (
		<>
			<AppHeader title="Users" />
			<IonContent fullscreen className="ion-padding admin">
				<IonSearchbar
					value={searchText}
					onIonChange={(e) => setSearchText(e.detail.value!)}
				></IonSearchbar>
				<IonList>
					{searchResults ? (
						searchResults.map((user: any, idx: any) => (
							<AdminUserCard key={idx} user={user} />
						))
					) : (
						<IonLabel className="ion-padding">No users available</IonLabel>
					)}
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

export default Users;
