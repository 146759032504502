import { IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";
import { getImageURL } from "../../../../services/util";
import LadderModule from "../../Ladders/components/LadderModule";
import ScheduleModule from "../../Schedules/components/ScheduleModule";

interface Props {
	settings: any;
}

const ScheduleLadderModule: React.FC<Props> = ({ settings }) => {
	const [promoImage, setPromoImage] = useState<any>("");

	useEffect(() => {
		if (settings["schedule-promo-image"]) {
			getImageURL(settings["schedule-promo-image"].value).then((url: any) => {
				setPromoImage(url);
			});
		}
	}, [settings]);

	return (
		<Fragment>
			<h1 className="section-header">Match Schedule</h1>
			<ScheduleModule format="full" />
			<div className="spacer30" />
			{promoImage && (
				<a href={settings["schedule-promo-image-URL"].value}>
					<IonImg src={promoImage} className="full-width-image" />
				</a>
			)}
			<h1 className="section-header">Competition Ladder</h1>
			<div className="spacer15" />
			<LadderModule />
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(ScheduleLadderModule);
