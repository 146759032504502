//import * as types from "../actionTypes";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

import "@codetrix-studio/capacitor-google-auth";
import { Plugins, registerWebPlugin } from "@capacitor/core";
import {
	FacebookLogin,
	FacebookLoginResponse,
} from "@capacitor-community/facebook-login";
import store from "../../store";

import { toast } from "../../components/Toast/toast";
import { logoutUser } from "../users/index";

var db = firebase.firestore();

registerWebPlugin(FacebookLogin);

export function loginUser(credentials: any) {
	const { email, password } = credentials;
	return firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			toast(error.message, 4000);
			return false;
		});
}

export function registerUser(email: string, password: string) {
	return firebase
		.auth()
		.createUserWithEmailAndPassword(email, password)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			toast(error.message, 4000);
			return false;
		});
}

export async function loginWithFacebook() {
	const FACEBOOK_PERMISSIONS = [
		"email",
		"public_profile",
		// "user_birthday",
		// "user_photos",
		// "user_gender",
	];
	const result = (await Plugins.FacebookLogin.login({
		permissions: FACEBOOK_PERMISSIONS,
	})) as FacebookLoginResponse;

	if (result.accessToken) {
		var credential = firebase.auth.FacebookAuthProvider.credential(
			result.accessToken.token
		);

		return firebase
			.auth()
			.signInWithCredential(credential)
			.then((res: any) => {
				console.log(res);
				return res;
			});
	} else {
		return false;
	}
}

export async function loginWithGoogle() {
	const googleUser = (await Plugins.GoogleAuth.signIn()) as any;
	const credential = firebase.auth.GoogleAuthProvider.credential(
		googleUser.authentication.idToken
	);

	return firebase
		.auth()
		.signInWithCredential(credential)
		.then((res) => {
			// console.log(res);
			return res;
		})
		.catch((error) => {
			console.log(error);
			throw error;
		});
}

export async function loginWithApple() {
	return Plugins.SignInWithApple.Authorize()
		.then((response: any) => {
			console.log(response);
		})
		.catch((error: any) => {
			console.error(error);
		});
}

export async function loginWithAppleWEB() {
	var provider = new firebase.auth.OAuthProvider("apple.com");

	try {
		const res = firebase.auth().signInWithPopup(provider);
		// 	firebase
		// .auth()
		// .signInWithPopup(provider)
		// .then((result) => {
		//   /** @type {firebase.auth.OAuthCredential} */
		//   var credential = result.credential;

		//   // The signed-in user info.
		//   var user = result.user;

		//   // You can also get the Apple OAuth Access and ID Tokens.
		//   var accessToken = credential.accessToken;
		//   var idToken = credential.idToken;

		//   // ...
		// })
		// .catch((error) => {
		//   // Handle Errors here.
		//   var errorCode = error.code;
		//   var errorMessage = error.message;
		//   // The email of the user's account used.
		//   var email = error.email;
		//   // The firebase.auth.AuthCredential type that was used.
		//   var credential = error.credential;

		//   // ...
		// });
		// console.log(res);
		return res;
	} catch (error) {
		console.log(error);
	}
}

export const loginAnonymously = () => {
	return firebase
		.auth()
		.signInAnonymously()
		.then((res) => {
			// Signed in..
			// console.log("logged in");
			return res;
		})
		.catch((error) => {
			console.error("An error occured");
			console.log("An error occured");
			console.error(error);
			throw error;
		});
};

export function getCurrentUser() {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				resolve(user);
			} else {
				resolve(null);
			}
			unsubscribe();
		});
	});
}

export const logout = () => {
	firebase
		.auth()
		.signOut()
		.then(() => {
			store.dispatch(logoutUser());
		});
};

export const sendPasswordReset = (email: any) => {
	return firebase.auth().sendPasswordResetEmail(email);
};

export const saveToken = (id: any) => {
	if (id) {
		firebase
			.messaging()
			.getToken()
			.then((currentToken) => {
				if (currentToken) {
					db.collection("users")
						.doc(id)
						.collection("notificationTokens")
						.doc(currentToken)
						.set({ status: true });
				} else {
					requestPermission(id);
				}
			})
			.catch((error) => {
				console.log(
					"The notification permission was not granted and blocked instead"
				);
				console.log(error);
			});
	}
	// .bind(this)).catch(function(err) {
	//   console.error('Unable to get messaging token.', err);
	//   if (err.code === 'messaging/permission-default') {
	//     this.fcmErrorContainer.innerText = 'You have not enabled notifications on this browser. To enable notifications reload the page and allow notifications using the permission dialog.';
	//   } else if (err.code === 'messaging/notifications-blocked') {
	//     this.fcmErrorContainer.innerHTML = 'You have blocked notifications on this browser. To enable notifications follow these instructions: <a href="https://support.google.com/chrome/answer/114662?visit_id=1-636150657126357237-2267048771&rd=1&co=GENIE.Platform%3DAndroid&oco=1">Android Chrome Instructions</a><a href="https://support.google.com/chrome/answer/6148059">Desktop Chrome Instructions</a>';
	//   }
	// }.bind(this));
};

export const savePushToken = (user: any, token: any) => {
	db.collection("users")
		.doc(user.uid)
		.collection("notificationTokens")
		.doc(token)
		.set({ status: true });
};

const requestPermission = (id: any) => {
	//console.log("Requesting permission...");
	firebase
		.messaging()
		.getToken()
		.then(function () {
			//console.log("Notification permission granted.");
			saveToken(id);
		});
	// .bind(this)).catch(function(err) {
	//   console.error('Unable to get permission to notify.', err);
	// });
};
