import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getImageURL } from "../../../../../services/util";
import ScoreWinnersView from "./ScoreWinnersView";
import WinnersView2 from "./WinnersView2";

export interface RouteParams {
	id: string;
}

interface Props {
	id: any;
	activations: any;
	settings: any;
}

const WinnerSlide: React.FC<Props> = ({ id, settings, activations }) => {
	const [cID, setID] = useState<any>("");
	const [BG, setBG] = useState<any>("");
	const [header, setHeader] = useState<any>("");
	const [footer, setFooter] = useState<any>("");

	useEffect(() => {
		if (activations[id] && id !== cID) {
			setID(id);
			//Slide background

			if (activations[id].winnersBGImg) {
				getImageURL(activations[id].winnersBGImg, "1920").then((url: any) => {
					setBG(url);
				});
			} else if (activations[id].resultsBGImg) {
				getImageURL(activations[id].resultsBGImg, "1920").then((url: any) => {
					setBG(url);
				});
			} else {
				setBG("");
			}

			//slide header image
			if (activations[id].winnersHeaderImg) {
				getImageURL(activations[id].winnersHeaderImg, "1920").then(
					(url: any) => {
						setHeader(url);
					}
				);
			} else if (activations[id].resultsHeaderImg) {
				getImageURL(activations[id].resultsHeaderImg, "1920").then(
					(url: any) => {
						setHeader(url);
					}
				);
			} else {
				console.log("no header image");
				setHeader("");
			}

			//slide footer image
			if (activations[id].winnersFooterImg) {
				getImageURL(activations[id].winnersFooterImg, "1920").then(
					(url: any) => {
						setFooter(url);
					}
				);
			} else if (activations[id].resultsFooterImg) {
				getImageURL(activations[id].resultsFooterImg, "1920").then(
					(url: any) => {
						setFooter(url);
					}
				);
			} else {
				console.log("no footer image");
				setFooter("");
			}
		}
		//eslint-disable-next-line
	}, [id, activations, settings]);

	useEffect(() => {
		console.log(header);
	}, [header]);

	useEffect(() => {
		console.log(footer);
	}, [footer]);

	return (
		<div
			style={{ backgroundImage: `url(${BG})` }}
			className="slide-wrapper results"
		>
			<div className="slide">
				<div className="content-wrapper">
					<div className="content">
						<div className="ion-padding results-page">
							<div
								className="headerImage"
								style={{ backgroundImage: `url(${header})` }}
							/>
							{activations[id] &&
								["vote", "select", "simple"].includes(activations[id].type) && (
									<WinnersView2 activation={activations[id]} />
								)}
							{activations[id] && activations[id].type === "score" && (
								<ScoreWinnersView activation={activations[id]} />
							)}
							<div
								className="footerImage"
								style={{ backgroundImage: `url(${footer})` }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
	submissions: state.activations.submissions,
	settings: state.settings,
});

export default connect(mapStateToProps, {})(WinnerSlide);
