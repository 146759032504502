import { IonButton, IonCard, IonCardContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	settings: any;
}

const ShopModule: React.FC<Props> = ({ settings }) => {
	const history = useHistory();
	const [dashboardImage, setDashboardImage] = useState<any>("");
	//const [allowedSections, setAllowedSections] = useState<any>([]);

	useEffect(() => {
		if (settings["shop-dashboard-image"]) {
			getImageURL(settings["shop-dashboard-image"].value).then((url: any) => {
				setDashboardImage(url);
			});
		}
		// if (settings["shop-allowed-sections"]) {
		// 	let sections = Object.values(settings["shop-allowed-sections"].value)
		// 		.map((section: any, idx: any) => {
		// 			if (section === true) {
		// 				return idx + 1;
		// 			} else return "";
		// 		})
		// 		.filter((section: any) => {
		// 			return section !== "";
		// 		});
		// 	//setAllowedSections(sections.join(", "));
		// }
	}, [settings]);

	// useEffect(() => {
	// 	console.log(allowedSections);
	// }, [allowedSections]);

	return (
		<div className="shop-dashboard-wrapper">
			<h1 className="section-header">Food & Drink Delivery</h1>
			<div className="card-wrapper">
				<IonCard
					className="shopDashboardCard image-only"
					onClick={() => history.push("/shop")}
				>
					{dashboardImage && (
						<IonImg src={dashboardImage} alt="food & drink delivery" />
					)}
					{/* <IonCardHeader>
					<IonCardTitle>Get food & drink delivered to your seat!</IonCardTitle>
				</IonCardHeader> */}
					<IonCardContent></IonCardContent>
					{/* <IonCardContent>
					<p>
						Our fans can now get food and drinks delivered to their seat*
						throughout the game!
					</p>
					<div className="spacer15" />
					<p style={{ fontSize: "0.9em", lineHeight: "1.3em" }}>
						*Currently available for section {allowedSections}
					</p>
					<div className="spacer30" />
					<p>
						<IonButton onClick={() => history.push("/shop")}>
							Order Now
						</IonButton>
					</p>
				</IonCardContent> */}
				</IonCard>
				<div className="card-button-wrapper">
					<IonButton onClick={() => history.push("/shop")}>Order Now</IonButton>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	settings: state.settings,
});

export default connect(mapStateToProps, {})(ShopModule);
