import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonItem,
	IonLabel,
	IonList,
	IonTitle,
	IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
	settings: any;
}

const MessagesSettings: React.FC<Props> = ({ settings }) => {
	const [dashboardImage, setDashboardImage] = useState<any>(
		settings["message-dashboard-image"]
			? settings["message-dashboard-image"].value
			: ""
	);
	const [headerImage, setHeaderImage] = useState<any>(
		settings["message-header-image"]
			? settings["message-header-image"].value
			: ""
	);
	const [active, setActive] = useState<any>(
		settings["message-active"] ? settings["message-active"].value : false
	);

	const _save = (name: any, data: any) => {
		if (settings[name]) {
			updateSetting(settings[name].id, data);
		} else {
			addSetting(name, "");
		}
	};

	useEffect(() => {
		_save("message-dashboard-image", dashboardImage);
		//eslint-disable-next-line
	}, [dashboardImage]);

	useEffect(() => {
		_save("message-header-image", headerImage);
		//eslint-disable-next-line
	}, [headerImage]);

	useEffect(() => {
		_save("message-active", active);
		//eslint-disable-next-line
	}, [active]);

	const _onPhotoChange = (e: any) => {
		if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
		else if (e.name === "dashboardImage") setDashboardImage(e.image.fullPath);
	};

	const _onToggle = (section: any) => {
		if (section === "active") {
			setActive(!active);
		}
	};

	return (
		<Fragment>
			<IonTitle className="section-header">Send a Message Settings</IonTitle>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>General Messages Settings</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonList lines="none">
						<IonItem>
							<IonLabel>Messages Active?</IonLabel>
							<IonToggle
								checked={active}
								onClick={() => _onToggle("active")}
								name="active"
								id="active"
							/>
						</IonItem>
					</IonList>
				</IonCardContent>
			</IonCard>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Message Page Images</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonItem>
						<IonLabel position="stacked">Dashboard Image</IonLabel>
						<div className="spacer5" />
						<PhotoUploadInput
							name="dashboardImage"
							id="dashboardImage"
							value={dashboardImage}
							onChange={_onPhotoChange}
						/>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">Header Image</IonLabel>
						<div className="spacer5" />
						<PhotoUploadInput
							name="headerImage"
							id="headerImage"
							value={headerImage}
							onChange={_onPhotoChange}
						/>
					</IonItem>
				</IonCardContent>
			</IonCard>
			<div className="spacer30" />
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(MessagesSettings);
