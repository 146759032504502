import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
  settings: any;
}

const NewsSettings: React.FC<Props> = ({ settings }) => {
  const [headerImage, setHeaderImage] = useState<any>(
    settings["news-header-image"] ? settings["news-header-image"].value : ""
  );
  const [footerImage, setFooterImage] = useState<any>(
    settings["news-footer-image"] ? settings["news-footer-image"].value : ""
  );
  const [footerImageURL, setFooterImageURL] = useState<any>(
    settings["news-footer-image-URL"]
      ? settings["news-footer-image-URL"].value
      : ""
  );
  const [newsVisible, setNewsVisible] = useState<any>(
    settings["news-visible"] ? settings["news-visible"].value : false
  );

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  useEffect(() => {
    _save("news-header-image", headerImage);
    //eslint-disable-next-line
  }, [headerImage]);

  useEffect(() => {
    _save("news-footer-image", footerImage);
    //eslint-disable-next-line
  }, [footerImage]);

  useEffect(() => {
    _save("news-footer-image-URL", footerImageURL);
    //eslint-disable-next-line
  }, [footerImageURL]);

  useEffect(() => {
    _save("news-visible", newsVisible);
    //eslint-disable-next-line
  }, [newsVisible]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
    else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "footerImageURL") {
      setFooterImageURL(e.target.value);
    }
  };

  const _onToggle = (section: any) => {
    if (section === "newsVisible") {
      setNewsVisible(!newsVisible);
    }
  };

  return (
    <Fragment>
      <IonTitle className="section-header">News Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>General News Settings</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonLabel>News Visible?</IonLabel>
              <IonToggle
                checked={newsVisible}
                onClick={() => _onToggle("newsVisible")}
                name="newsVisible"
                id="newsVisible"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>News Page Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Header Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="headerImage"
              id="headerImage"
              value={headerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Promo Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="footerImage"
              id="footerImage"
              value={footerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Promo Image Link URL</IonLabel>
            <IonInput
              type="text"
              name="footerImageURL"
              id="footerImageURL"
              value={footerImageURL}
              onIonChange={_onChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(NewsSettings);
