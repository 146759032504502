import { IonItem, IonImg, IonThumbnail, IonTitle, IonText } from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
  player: any;
  teams: any;
}

const AdminPlayerCard: React.FC<Props> = ({ player, teams }) => {
  const history = useHistory();
  const [imageURL, setImageURL] = useState<any>("");
  const { img } = player;
  const defaultAvatar =
    "https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/default-profile.jpg?alt=media&token=b1fd87b3-ccb9-436c-9061-ca95d80281d6";

  useEffect(() => {
    if (img !== "" && imageURL === "") {
      getImageURL(img, "200").then((url: any) => {
        setImageURL(url);
      });
    }
    //eslint-disable-next-line
  }, [img]);

  return (
    <IonItem button onClick={() => history.push(`/admin/player/${player.id}`)}>
      <IonThumbnail>
        <IonImg alt="" src={imageURL ? imageURL : defaultAvatar} />
      </IonThumbnail>
      <IonTitle>{player.name}</IonTitle>
      <IonText style={{ width: "50px" }}>
        <div>{player.pos}</div>
      </IonText>
      <IonText style={{ width: "50px" }}>
        <div>{player.num}</div>
      </IonText>
      <IonText style={{ width: "100px" }}>
        <div>{teams[player.team]?.name}</div>
      </IonText>
    </IonItem>
  );
};

const mapStateToProps = (state: any) => ({ teams: state.schedules.teams });

export default connect(mapStateToProps, {})(AdminPlayerCard);
