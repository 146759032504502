import { IonContent, IonSearchbar } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import FuzzySearch from "fuzzy-search";

import { getAdminSubmissions } from "../../../../services/activations";

import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";
import SubmissionCard from "./components/SubmissionCard";
import PickWinner from "./components/PickWinner";
import WinnerScore from "./components/WinnerScore";

export interface RouteParams {
	id: string;
}

interface Props {
	activations: any;
	getAdminSubmissions: any;
	subs: any;
}

const AdminSubmissions: React.FC<Props> = ({
	activations,
	subs,
	getAdminSubmissions,
}) => {
	let { id } = useParams<RouteParams>();
	const [activation, setActivation] = useState<any>({});
	const [submissions, setSubmissions] = useState<any>([]);
	const [searchText, setSearchText] = useState<any>("");
	const [searchResults, setSearchResults] = useState<any>([]);

	const { title } = activation;

	useEffect(() => {
		if (activations[id]) {
			setActivation({ ...activation, ...activations[id] });
		}
		//eslint-disable-next-line
	}, [id, activations]);

	useEffect(() => {
		if (activation) {
			getAdminSubmissions();
		}
		//eslint-disable-next-line
	}, [id, activation]);

	useEffect(() => {
		if (subs) {
			setSubmissions(
				Object.values(subs).filter((doc: any) => doc.activation === id)
			);
		}
		//eslint-disable-next-line
	}, [id, subs]);

	const filter = () => {
		//console.log(orders);
		const searcher = new FuzzySearch(
			submissions,
			[
				"submitter.id",
				"submitter.fname",
				"submitter.lname",
				"submitter.phoneNumber",
				"submitter.email",
			],
			{ caseSensitive: false }
		);
		//console.log("checking searchText");
		if (searchText !== "") {
			//console.log("search text is NOT empty: ", searchText);
			setSearchResults(searcher.search(searchText));
		} else {
			//console.log("search text is empty: ", searchText);
			setSearchResults(submissions);
		}
	};

	useEffect(() => {
		filter();
		//eslint-disable-next-line
	}, [searchText, submissions]);

	return (
		<>
			<AppHeader title={title} />
			<IonContent className="ion-padding admin">
				{submissions && <div>Count: {submissions.length}</div>}
				{activation.type === "score" ? (
					<WinnerScore activation={activation} />
				) : (
					<PickWinner activation={activation} />
				)}
				<div className="spacer60" />
				<IonSearchbar
					value={searchText}
					onIonChange={(e) => setSearchText(e.detail.value!)}
				></IonSearchbar>
				{searchResults && searchResults.length > 0 && (
					<Fragment>
						{searchResults.map((submission: any, idx: any) => (
							<SubmissionCard
								key={idx}
								submission={submission}
								activation={activation}
							/>
						))}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	activations: state.activations.activations,
	subs: state.activations.submissions,
});

export default connect(mapStateToProps, { getAdminSubmissions })(
	AdminSubmissions
);
