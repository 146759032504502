import {
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonButton,
  IonInput,
  IonIcon,
  IonNote,
  IonItemGroup,
  IonItemDivider,
  IonSelect,
  IonSelectOption,
  IonToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import { addLadder, updateLadder } from "../../../../services/ladders";
import { trash } from "ionicons/icons";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { AppHeader } from "../../../../components/Header/AppHeader";

export interface RouteParams {
  id: string;
}

interface Props {
  ladders: any;
  teams: any;
}

const AdminLadder: React.FC<Props> = ({ ladders, teams }) => {
  const history = useHistory();

  const defaultState = {
    active: false,
    title: "",
    officialURL: "",
    data: [],
  };

  const dataInit = {
    teamID: "0",
    played: "0",
    won: "0",
    lost: "0",
    for: "0",
    against: "0",
    points: "0",
    dataID: "_" + Math.random().toString(36).substr(2, 9),
  };

  let { id } = useParams<RouteParams>();
  const [ladder, setLadder] = useState<any>(defaultState);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const { title, officialURL, active } = ladder;

  useEffect(() => {
    if (ladders[id]) {
      setLadder({
        ...ladders[id],
        data: Object.values(ladders[id].data).sort(sortByPoints),
      });
    }
    //eslint-disable-next-line
  }, [id, ladders]);

  // useEffect(() => {
  // 	console.table(ladder.data);
  // }, [ladder]);

  const _onChange = (e: any) => {
    /**
     * TODO Bug when removing a line from middle of array. Team is reset to default, all other data get rerendered.
     * Appears to have something to do with the timing of the onChange and the updating of the ladder state.
     * Changing points and refreshing page sees some buggy behaviour when the teams are reordered.
     * */
    if (
      [
        "teamID",
        "played",
        "won",
        "lost",
        "for",
        "against",
        "points",
        "dataID",
      ].includes(e.target.name)
    ) {
      let data = ladder.data;
      data[e.target.dataset.id][e.target.name] = e.target.value;
      if (ladder.data !== data) {
        setLadder({ ...ladder, data });
      }
    } else {
      setLadder({ ...ladder, [e.target.name]: e.target.value });
    }
  };

  const sortByPoints = (a: any, b: any) => {
    return b.points - a.points;
  };

  const _onToggle = (option: any) => {
    setLadder({ ...ladder, [option]: !ladder[option] });
  };

  const _submit = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();
    // if (activation.name.trim() === "" || activation.pos.trim() === "") {
    // 	setDisableSubmit(false);
    // 	return toast("You must include a message and an image");
    // }
    if (id === "new") {
      addLadder(ladder).then((res: any) => {
        if (res !== false) {
          toast("Ladder saved");
        }
      });
    } else {
      updateLadder(ladder, id).then((res) => {
        if (res) {
          toast("Ladder updated");
        }
      });
    }
    setDisableSubmit(false);
  };

  const _back = () => {
    history.push("/admin/ladders");
  };

  const _addOption = (e: any) => {
    let data = Object.values(ladder.data);
    data.push(dataInit);
    setLadder({ ...ladder, data });
  };

  const _removeOption = (i: number) => {
    let data = Object.values(ladder.data);
    data.splice(i, 1);
    setLadder({ ...ladder, data });
  };

  const _compareTeam = (o1: any, o2: any) => {
    return o1 === o2;
  };

  const _onSelect = (e: any) => {
    e.preventDefault();
    var value = e.target.value;
    var dataID = e.target.dataset.id;
    setLadder({
      ...ladder,
      data: {
        ...ladder.data,
        [dataID]: { ...ladder.data[dataID], teamID: value },
      },
    });
  };

  return (
    <>
      <AppHeader title="Ladder" />
      <IonContent className="ion-padding admin">
        <IonTitle>{id === "new" ? "Add" : "Edit"} Ladder </IonTitle>
        <form onSubmit={_submit}>
          <IonItemGroup>
            <IonItem>
              <IonLabel>Active?</IonLabel>
              <IonToggle
                checked={active}
                onClick={() => _onToggle("active")}
                name="active"
                id="active"
              />
            </IonItem>
            <IonItemDivider>
              <IonLabel>LADDER DATA</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel position="floating">Title</IonLabel>
              <IonInput
                type="text"
                id="title"
                name="title"
                value={title}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Official Ladder URL</IonLabel>
              <IonInput
                type="text"
                id="officialURL"
                name="officialURL"
                value={officialURL}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>

            <IonItemDivider>
              <IonLabel>OPTIONS</IonLabel>
            </IonItemDivider>

            <IonItem>
              {ladder && Object.keys(ladder.data).length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Team</th>
                      <th>
                        <div>P</div>
                        <div className="table-header-subtitle">Played</div>
                      </th>
                      <th>
                        <div>W</div>
                        <div className="table-header-subtitle">Won</div>
                      </th>
                      <th>
                        <div>L</div>
                        <div className="table-header-subtitle">Lost</div>
                      </th>
                      <th>
                        <div>F</div>
                        <div className="table-header-subtitle">For</div>
                      </th>
                      <th>
                        <div>A</div>
                        <div className="table-header-subtitle">Against</div>
                      </th>
                      <th>
                        <div>P</div>
                        <div className="table-header-subtitle">Points</div>
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(ladder.data)
                      .sort(sortByPoints)
                      .map((data: any, idx: any) => {
                        //console.log(idx);
                        let teamIDId = `team-${idx}`,
                          playedId = `played-${idx}`,
                          wonId = `won-${idx}`,
                          lostId = `lost-${idx}`,
                          forId = `for-${idx}`,
                          againstId = `against-${idx}`,
                          pointsId = `points-${idx}`,
                          dataId = `data-${idx}`;

                        return (
                          <tr key={idx}>
                            <td>
                              {/* {console.log(ladderTeams[`${idx}`].teamID)} */}
                              <IonItem>
                                <IonSelect
                                  compareWith={_compareTeam}
                                  id={teamIDId}
                                  value={ladder.data[`${idx}`].teamID}
                                  name="teamID"
                                  onIonChange={_onSelect}
                                  data-id={`${idx}`}
                                  okText="Okay"
                                  cancelText="Dismiss"
                                  placeholder="Select One"
                                >
                                  {Object.values(teams).map((t: any) => (
                                    <IonSelectOption key={t.id} value={t.id}>
                                      {t.name}
                                    </IonSelectOption>
                                  ))}
                                </IonSelect>
                              </IonItem>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={playedId}
                                name="played"
                                data-id={`${idx}`}
                                value={data.played}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={wonId}
                                name="won"
                                data-id={`${idx}`}
                                value={data.won}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={lostId}
                                name="lost"
                                data-id={`${idx}`}
                                value={data.lost}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={forId}
                                name="for"
                                data-id={`${idx}`}
                                value={data.for}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={againstId}
                                name="against"
                                data-id={`${idx}`}
                                value={data.against}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="number"
                                id={pointsId}
                                name="points"
                                data-id={`${idx}`}
                                value={data.points}
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonInput
                                type="text"
                                id={dataId}
                                name="dataID"
                                data-id={`${idx}`}
                                value={data.dataID}
                                readonly
                                hidden
                                onIonChange={_onChange}
                              ></IonInput>
                            </td>
                            <td>
                              <IonNote>
                                <IonIcon
                                  onClick={() => _removeOption(idx)}
                                  icon={trash}
                                />
                              </IonNote>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </IonItem>
            <IonItem>
              <IonButton onClick={_addOption}>Add option</IonButton>
            </IonItem>
          </IonItemGroup>

          <IonButton type="submit" color="primary" disabled={disableSubmit}>
            Save
          </IonButton>
          <IonButton onClick={_back} color="danger">
            Back
          </IonButton>
        </form>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  ladders: state.ladders,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(AdminLadder);
