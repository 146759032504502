import firebase from "firebase/app";
import "firebase/firestore";
import * as actions from "./actions";

var db = firebase.firestore();

export function addPost(payload: any) {
  const date = Date.now();
  const today = new Date(date);
  return db
    .collection("posts")
    .add({ ...payload, created: today.toISOString() })
    .then(function (res: any) {
      return res.id;
    })
    .catch(function (error: any) {
      return false;
    });
}

export async function updatePost(payload: any, pid: string) {
  //console.log(payload);

  const date = Date.now();
  const today = new Date(date);

  return db
    .collection("posts")
    .doc(pid)
    .update({ ...payload, updated: today.toISOString() })
    .then(function () {
      return true;
    })
    .catch(function (error: any) {
      return false;
    });
}

export const setFeaturedPost = async (id: any = null) => {
  //find post with featured status and resave as not featured

  return db
    .collection("posts")
    .where("featured", "==", true)
    .get()
    .then((docs: any) => {
      docs.forEach((doc: any) => {
        db.collection("posts")
          .doc(doc.id)
          .update({ featured: false })
          .then(() => {
            return true;
          });
        // console.log("setting false: ", doc.id);
      });
    })
    .then(() => {
      if (id) {
        db.collection("posts")
          .doc(id)
          .update({ featured: true })
          .then(() => {
            return true;
          });
        // console.log("setting true: ", id);
      }
    });
};

export const getPosts = () => {
  return (dispatch: any, data: any) => {
    db.collection("posts").onSnapshot((docs: any) => {
      const data: any = [];
      docs.forEach(
        (doc: any) => (data[doc.id] = { ...doc.data(), id: doc.id })
      );
      dispatch(actions.getPostsSuccess(data));
    });

    //return { type: types.ADD_NEW_GOOGLE_USER, payload }
  };
};

export async function getPost(pid: any) {
  let data;
  await db
    .collection("posts")
    .doc(pid)
    .get()
    .then((doc: any) => {
      if (doc.exists) {
        //console.log(doc);
        data = doc.data();
      }
    })
    .catch((error: any) => {
      console.log(error);
      return false;
    });
  return data;
  //return { type: types.ADD_NEW_GOOGLE_USER, payload }
}

export async function deletePost(pid: any) {
  await db
    .collection("posts")
    .doc(pid)
    .delete()
    .then(() => {
      return true;
    })
    .catch((error: any) => {
      console.log(error);
      return false;
    });
}

export const getAPIPosts = () => {
  return (dispatch: any, data: any) => {
    async function loadPosts() {
      const response = await fetch(
        "https://canterburyrams.basketball/wp-json/wp/v2/posts?per_page=20"
      );
      if (!response.ok) {
        // oops! something went wrong
        return;
      }

      const posts = await response.json();

      const postData: any = {};
      posts.forEach(
        (post: any) => (postData[post.id] = { ...post, id: post.id })
      );

      var sorted = Object.values(postData).sort((a: any, b: any) => {
        var keyA = new Date(a.date),
          keyB = new Date(b.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        else return 0;
      });

      dispatch(
        actions.getAPIPostsSuccess({ postOrder: sorted, posts: postData })
      );
    }
    loadPosts();
  };
};
