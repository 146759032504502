import { IonContent, IonImg, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";

import PlayersModule from "./components/PlayersModule";

interface Props {
  settings: any;
  teams: any;
}

const Players: React.FC<Props> = ({ settings, teams }) => {
  const [headerImage, setHeaderImage] = useState<any>("");
  const [footerImage, setFooterImage] = useState<any>("");
  const [footerImageURL, setFooterImageURL] = useState<any>("");
  const [filteredTeams, setFilteredTeams] = useState<any>([]);
  const [segment, setSegment] = useState<any>("");

  useEffect(() => {
    if (settings["players-header-image"]) {
      getImageURL(settings["players-header-image"].value).then((url: any) => {
        setHeaderImage(url);
      });
    }
    if (settings["players-footer-image"]) {
      getImageURL(settings["players-footer-image"].value).then((url: any) => {
        setFooterImage(url);
      });
    }
    if (settings["players-footer-image-URL"]) {
      setFooterImageURL(settings["players-footer-image-URL"].value);
    }
  }, [settings]);

  useEffect(() => {
    if (teams) {
      setFilteredTeams(
        Object.values(teams).filter((team: any) => team.includeSquad === true)
      );
    }
  }, [teams]);

  useEffect(() => {
    if (filteredTeams.length > 0) {
      setSegment(filteredTeams[0].id);
    }
  }, [filteredTeams]);

  return (
    <>
      <AppHeader title="Players" />
      <IonContent fullscreen className="ion-padding">
        {headerImage && (
          <IonImg src={headerImage} className="full-width-image" />
        )}
        <IonSegment
          onIonChange={(e) => setSegment(e.detail.value)}
          value={segment}
        >
          {filteredTeams &&
            filteredTeams.map((team: any) => (
              <IonSegmentButton key={team.id} value={team.id}>
                {team.name} Roster
              </IonSegmentButton>
            ))}
        </IonSegment>
        {filteredTeams &&
          filteredTeams.map((team: any) => (
            <div key={team.id}>
              {segment === team.id && <PlayersModule team={team.id} />}
            </div>
          ))}
        <div className="spacer60" />
        {footerImage && (
          <Fragment>
            {footerImageURL ? (
              <a href={footerImageURL}>
                <IonImg src={footerImage} className="full-width-image footer" />
              </a>
            ) : (
              <IonImg src={footerImage} className="full-width-image footer" />
            )}
          </Fragment>
        )}
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  settings: state.settings,
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(Players);
