import React, { Fragment, useEffect } from "react";
import {
	IonItem,
	IonIcon,
	IonLabel,
	IonItemGroup,
	IonNote,
	IonTitle,
} from "@ionic/react";

import { menuController } from "@ionic/core";
import { getCurrentUser } from "../../services/auth";
import { useHistory } from "react-router";

import {
	fastFood,
	musicalNotes,
	newspaper,
	tv,
	people,
	send,
	statsChart,
	ticket,
	trophy,
	settings,
	radioOutline,
} from "ionicons/icons";
import { connect } from "react-redux";
import firebase from "firebase/app";

import "firebase/auth";
import DashboardSettingsView from "../../pages/Admin/Settings/components/DashboardSettingsView";

interface Props {
	user: any;
	messages: any;
	feedback: any;
	requests: any;
	orders: any;
}

const AppMenu: React.FC<Props> = ({
	user,
	messages,
	feedback,
	requests,
	orders,
}) => {
	const history = useHistory();

	useEffect(() => {
		getCurrentUser().then((userData: any) => {
			if (!userData) {
				history.replace("/");
			}
			firebase.auth().onAuthStateChanged(function (userData) {
				if (!userData) {
					history.replace("/");
				}
			});
		});
		//eslint-disable-next-line
	}, []);

	const closeMenu = async () => {
		await menuController.toggle();
	};

	return (
		<Fragment>
			<IonItem routerLink="/admin/">
				<IonTitle>Dashboard</IonTitle>
			</IonItem>
			<IonItem routerLink="/admin/dashboardslides">
				<IonTitle>Collateral</IonTitle>
			</IonItem>
			<IonItem routerLink="/admin/dashboardactivations">
				<IonTitle>Activations</IonTitle>
			</IonItem>
			<IonItem routerLink="/admin/dashboardmessages">
				<IonTitle>Messages</IonTitle>
				<IonNote slot="end">
					{
						Object.values(messages).filter(
							(msg: any) => msg.status === "pending"
						).length
					}
				</IonNote>
			</IonItem>
			<IonItem routerLink="/admin/dashboardorders">
				<IonTitle>Orders</IonTitle>
				<IonNote slot="end">
					{
						Object.values(orders).filter(
							(order: any) => order.status === "processing"
						).length
					}
				</IonNote>
			</IonItem>
			<IonItem routerLink="/admin/dashboardrequests">
				<IonTitle>Requests</IonTitle>
				<IonNote slot="end">
					{
						Object.values(requests).filter(
							(rqst: any) => rqst.status === "unapproved"
						).length
					}
				</IonNote>
			</IonItem>
			<IonItem routerLink="/admin/dashboardfeedback">
				<IonTitle>Feedback</IonTitle>
				<IonNote slot="end">
					{
						Object.values(feedback).filter((msg: any) => msg.complete === false)
							.length
					}
				</IonNote>
			</IonItem>
			<IonItem routerLink="/admin/output">
				<IonTitle>Output</IonTitle>
			</IonItem>

			<DashboardSettingsView />
			<IonItemGroup>
				<IonItem routerLink="/admin/settings" onClick={closeMenu}>
					<IonIcon slot="start" icon={settings}></IonIcon>
					<IonLabel>Settings</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/messages" onClick={closeMenu}>
					<IonIcon slot="start" icon={tv}></IonIcon>
					<IonLabel>Messages</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/requests" onClick={closeMenu}>
					<IonIcon slot="start" icon={musicalNotes}></IonIcon>
					<IonLabel>Requests</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/feedback" onClick={closeMenu}>
					<IonIcon slot="start" icon={send}></IonIcon>
					<IonLabel>Feedback</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/orders" onClick={closeMenu}>
					<IonIcon slot="start" icon={fastFood}></IonIcon>
					<IonLabel>Orders</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/slides" onClick={closeMenu}>
					<IonIcon slot="start" icon={tv}></IonIcon>
					<IonLabel>Collateral</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/users" onClick={closeMenu}>
					<IonIcon slot="start" icon={people}></IonIcon>
					<IonLabel>Users</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/activations" onClick={closeMenu}>
					<IonIcon slot="start" icon={trophy}></IonIcon>
					<IonLabel>Activations</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/schedules" onClick={closeMenu}>
					<IonIcon slot="start" icon={statsChart}></IonIcon>
					<IonLabel>Schedules</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/ladders" onClick={closeMenu}>
					<IonIcon slot="start" icon={statsChart}></IonIcon>
					<IonLabel>Ladders</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/products" onClick={closeMenu}>
					<IonIcon slot="start" icon={fastFood}></IonIcon>
					<IonLabel>Products</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/notifications" onClick={closeMenu}>
					<IonIcon slot="start" icon={radioOutline}></IonIcon>
					<IonLabel>Notifications</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/posts" onClick={closeMenu}>
					<IonIcon slot="start" icon={newspaper}></IonIcon>
					<IonLabel>Posts</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/players" onClick={closeMenu}>
					<IonIcon slot="start" icon={people}></IonIcon>
					<IonLabel>Players</IonLabel>
				</IonItem>
				<IonItem routerLink="/admin/promotions" onClick={closeMenu}>
					<IonIcon slot="start" icon={ticket}></IonIcon>
					<IonLabel>Promotions</IonLabel>
				</IonItem>
			</IonItemGroup>
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user.userDetails,
	messages: state.messages,
	feedback: state.feedback,
	orders: state.orders.orders,
	requests: state.requests,
});

export default connect(mapStateToProps, {})(AppMenu);
