import {
  IonContent,
  IonLabel,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";

import AdminPlayerCard from "./components/AdminPlayerCard";
import { connect } from "react-redux";
import { add } from "ionicons/icons";
import { AppHeader } from "../../../components/Header/AppHeader";
import { _sortPlayerByNumber } from "../../../services/util";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
  players: any;
}

const AdminPlayers: React.FC<Props> = ({ players }) => {
  return (
    <>
      <AppHeader title="Players" />
      <IonContent fullscreen className="ion-padding admin">
        <IonList style={{ maxWidth: "900px" }}>
          {players ? (
            Object.values(players)
              .sort(_sortPlayerByNumber)
              .map((player: any) => (
                <AdminPlayerCard key={player.id} player={player} />
              ))
          ) : (
            <IonLabel className="ion-padding">No users available</IonLabel>
          )}
          <IonItem>
            <IonButton href={"/admin/player/new"} color="primary">
              <IonIcon icon={add} />
              Add New
            </IonButton>
          </IonItem>
        </IonList>
      </IonContent>
      <AdminFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({ players: state.players });

export default connect(mapStateToProps, {})(AdminPlayers);
