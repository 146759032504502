import { IonContent } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import ScheduleModule from "./components/ScheduleModule";

interface Props {}

const Schedule: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Schedule" />
			<IonContent fullscreen className="ion-padding">
				<ScheduleModule format={"full"} />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(Schedule);
