import { IonContent, IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { connect } from "react-redux";
import { formatDateTime, getImageURL } from "../../../../services/util";
import { AppHeader } from "../../../../components/Header/AppHeader";

export interface RouteParams {
	id: string;
}

interface Props {
	posts: any;
}

const Post: React.FC<Props> = ({ posts }) => {
	let { id } = useParams<RouteParams>();

	const defaultState = {
		date: "",
		title: "",
		content: "",
		img: "",
		excerpt: "",
	};

	const [post, setPost] = useState<any>(defaultState);
	const [imageURL, setImageURL] = useState("");
	const { date, title, content, img, excerpt } = post;

	useEffect(() => {
		if (posts && id && posts[id]) {
			setPost(posts[id]);
		}

		//eslint-disable-next-line
	}, [id, posts]);

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "600").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	return (
		<>
			<AppHeader title={title} />
			<IonContent fullscreen className="ion-padding single-post-page">
				{imageURL && (
					<IonImg alt={title} src={imageURL} className="full-width-image" />
				)}
				{title && <h2>{title}</h2>}
				{date && <h6>{formatDateTime(date)}</h6>}
				<div className="spacer30" />
				{excerpt && (
					<p>
						<b>
							<i>{excerpt}</i>
						</b>
					</p>
				)}
				{content && (
					<div
						dangerouslySetInnerHTML={{
							__html: content,
						}}
					/>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	posts: state.news.posts,
});

export default connect(mapStateToProps, {})(Post);
