import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
  settings: any;
}

const PromotionsSettings: React.FC<Props> = ({ settings }) => {
  const [headerImage, setHeaderImage] = useState<any>(
    settings["promotions-header-image"]
      ? settings["promotions-header-image"].value
      : ""
  );
  const [footerImage, setFooterImage] = useState<any>(
    settings["promotions-footer-image"]
      ? settings["promotions-footer-image"].value
      : ""
  );
  const [footerImageURL, setFooterImageURL] = useState<any>(
    settings["promotions-footer-image-URL"]
      ? settings["promotions-footer-image-URL"].value
      : ""
  );

  const [promotionsVisible, setPromotionsVisible] = useState<any>(
    settings["promomotions-visible"]
      ? settings["promomotions-visible"].value
      : false
  );

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  useEffect(() => {
    _save("promotions-header-image", headerImage);
    //eslint-disable-next-line
  }, [headerImage]);

  useEffect(() => {
    _save("promotions-footer-image", footerImage);
    //eslint-disable-next-line
  }, [footerImage]);

  useEffect(() => {
    _save("promotions-footer-image-URL", footerImageURL);
    //eslint-disable-next-line
  }, [footerImageURL]);

  useEffect(() => {
    _save("promomotions-visible", promotionsVisible);
    //eslint-disable-next-line
  }, [promotionsVisible]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
    else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "footerImageURL") {
      setFooterImageURL(e.target.value);
    }
  };

  const _onToggle = (section: any) => {
    if (section === "promotionsVisible") {
      setPromotionsVisible(!promotionsVisible);
    }
  };

  return (
    <Fragment>
      <IonTitle className="section-header">Promotions Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>General Promotions Settings</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonLabel>Promotions Visible?</IonLabel>
              <IonToggle
                checked={promotionsVisible}
                onClick={() => _onToggle("promotionsVisible")}
                name="promotionsVisible"
                id="promotionsVisible"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Promotions Page Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Header Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="headerImage"
              id="headerImage"
              value={headerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Promo Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="footerImage"
              id="footerImage"
              value={footerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Promo Image Link URL</IonLabel>
            <IonInput
              type="text"
              name="footerImageURL"
              id="footerImageURL"
              value={footerImageURL}
              onIonChange={_onChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(PromotionsSettings);
