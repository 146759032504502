import {
	IonContent,
	IonLabel,
	IonItem,
	IonItemGroup,
	IonList,
	IonListHeader,
	IonText,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppFooter } from "../../../../components/Header/AppFooter";
import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { formatDateTime } from "../../../../services/util";

export interface RouteParams {
	id: string;
}

interface Props {
	orders: any;
	products: any;
}

const AdminOrder: React.FC<Props> = ({ products, orders }) => {
	const defaultState = {
		orderNumber: "",
		cartTotal: "",
		cart: {},
		status: "",
		created: "",
		userDetails: {
			fname: "",
			lname: "",
			email: "",
			phoneNumber: "",
			seat: { section: "", row: "", number: "" },
		},
	};
	let { id } = useParams<RouteParams>();
	const [order, setOrder] = useState<any>(defaultState);

	const {
		orderNumber,
		cartTotal,
		cart,
		status,
		created,
		userDetails,
		userDetails: { fname, lname, email, phoneNumber, seat },
		notes,
	} = order;

	useEffect(() => {
		if (orders && id) {
			setOrder(orders[id]);
			// console.log(orders[id]);
		}
	}, [id, orders]);

	return (
		<>
			{order && userDetails && (
				<Fragment>
					<AppHeader title={`Order ${orderNumber}`} />
					<IonContent fullscreen className="ion-padding admin">
						<IonItemGroup>
							<IonItem>
								<IonLabel>Order #</IonLabel>
								<p>{orderNumber}</p>
							</IonItem>
							<IonItem>
								<IonLabel>Order ID:</IonLabel>
								<p>{id}</p>
							</IonItem>
							{fname && (
								<IonItem>
									<IonLabel>First Name</IonLabel>
									<p>{fname}</p>
								</IonItem>
							)}
							{lname && (
								<IonItem>
									<IonLabel>Last Name</IonLabel>
									<p>{lname}</p>
								</IonItem>
							)}
							{seat && (
								<IonItem>
									<IonLabel>Seat</IonLabel>
									<p>
										{seat.section}
										{seat.row}
										{seat.number}
									</p>
								</IonItem>
							)}
							{phoneNumber && (
								<IonItem>
									<IonLabel>Phone Number</IonLabel>
									<p>{phoneNumber}</p>
								</IonItem>
							)}
							{email && (
								<IonItem>
									<IonLabel>Email</IonLabel>
									<p>{email}</p>
								</IonItem>
							)}
							{cartTotal && (
								<IonItem>
									<IonLabel>Cart Total</IonLabel>
									<p>{cartTotal}</p>
								</IonItem>
							)}
							<IonItem>
								<IonList>
									<IonListHeader>Items</IonListHeader>
									{Object.entries(cart).map((order: any, idx: any) => (
										<IonItem key={idx}>
											{products[order[0]].name} x {order[1]}
										</IonItem>
									))}
								</IonList>
							</IonItem>
							{status && (
								<IonItem>
									<IonLabel>Status</IonLabel>
									<p>{status}</p>
								</IonItem>
							)}
							{created && (
								<IonItem>
									<IonLabel>Date</IonLabel>
									<p>{formatDateTime(created)}</p>
								</IonItem>
							)}
							<IonItem className="orderCardRow">
								<IonLabel slot="start">Notes:</IonLabel>
								<IonText>{notes}</IonText>
							</IonItem>
						</IonItemGroup>
					</IonContent>
				</Fragment>
			)}
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	products: state.products,
	orders: state.orders.adminOrders,
});

export default connect(mapStateToProps, {})(AdminOrder);
