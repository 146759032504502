import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import rootReducer from "./services/rootReducer";

const initialState = {};
const middleware = [thunk];
const persistConfig = {
  key: "default",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//import thunkMiddleware from 'redux-thunk'
//import { composeWithDevTools } from 'redux-devtools-extension'

//import monitorReducersEnhancer from './enhancers/monitorReducers'
//import loggerMiddleware from './middleware/logger'

// export default function configureStore() {
//   //const middlewares = [loggerMiddleware, thunkMiddleware]
//   //const middlewareEnhancer = applyMiddleware(...middlewares)

//   //const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
//   //const composedEnhancers = composeWithDevTools(...enhancers)

//   const store = createStore(rootReducer, composeWithDevTools(
//     applyMiddleware(...middleware),
//     // other store enhancers if any
//   ));

//   return store
// }

// const persistConfig = {
//   key: 'root',
//   storage
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

//persistor.purge();

export default store;
