import {
	IonButton,
	IonInput,
	IonLabel,
	IonItem,
	IonList,
	IonTextarea,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";

import { getCurrentUser } from "../../../../services/auth";
import { addRequest } from "../../../../services/requests";

const SendRequest: React.FC = () => {
	const defaultState = {
		name: "",
		uid: "guest",
		song: "",
		body: "",
		played: false,
		status: "unapproved",
	};
	const [request, setRequest] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
	const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

	const { name, body, song } = request;

	const _onChange = (e: any) =>
		setRequest({ ...request, [e.target.name]: e.target.value });

	useEffect(() => {
		getCurrentUser().then((user: any) => {
			setRequest({ ...request, uid: user.uid });
		});
		//eslint-disable-next-line
	}, []);

	const submitRequest = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();
		if (!song || song.trim() === "") {
			setDisableSubmit(false);
			return toast("You must include a song request");
		}
		addRequest(request).then((res) => {
			if (res) {
				toast("Your request has been sent");
				setRequestSuccess(true);
				setDisableSubmit(false);
			}
		});
		//console.log(Request);
	};

	const _reset = () => {
		setRequest(defaultState);
		setRequestSuccess(false);
	};

	return (
		<Fragment>
			{!requestSuccess ? (
				<Fragment>
					<p className="center-text">
						Request a song for the DJ to play. Include your name and a message,
						and you just might get a shout-out!
					</p>
					<form onSubmit={submitRequest}>
						<IonList>
							<IonItem>
								<IonLabel position="floating">Your Name</IonLabel>
								<IonInput
									id="name"
									name="name"
									type="text"
									value={name}
									onIonChange={_onChange}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Your Request</IonLabel>
								<IonTextarea
									id="song"
									name="song"
									value={song}
									onIonChange={_onChange}
								/>
							</IonItem>
							<IonItem>
								<IonLabel position="floating">Your Message</IonLabel>
								<IonTextarea
									id="body"
									name="body"
									value={body}
									onIonChange={_onChange}
								/>
							</IonItem>
							<div className="spacer30" />
							<IonItem lines="none">
								<IonButton
									disabled={disableSubmit}
									className="ion-margin-top"
									type="submit"
									size="large"
								>
									Send
								</IonButton>
							</IonItem>
						</IonList>
					</form>
				</Fragment>
			) : (
				<Fragment>
					<p className="center-text">Thanks for sending in your request</p>
					<p className="center-text">
						<IonButton fill="outline" onClick={_reset}>
							Send Another Request
						</IonButton>
					</p>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SendRequest;
