import { IonContent } from "@ionic/react";
import React from "react";
import { connect } from "react-redux";
import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import LadderModule from "./components/LadderModule";

export interface RouteParams {
	id: string;
}

interface Props {
	ladders: any;
	teams: any;
}

const Ladders: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Competition Ladder" />
			<IonContent className="ion-padding ladder-page">
				<LadderModule />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	ladders: state.ladders,
	teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(Ladders);
