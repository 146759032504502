// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../services/util";

interface Props {
	slide: any;
	settings: any;
}

const OutputSlide: React.FC<Props> = ({ slide, settings }) => {
	const [imageURL, setImageURL] = useState<any>("");
	const [overlayURL, setOverlayURL] = useState<any>("");
	const [defaultImage, setDefaultImage] = useState<any>("");

	const { id, name, img, body, type, embed, overlay } = slide;

	useEffect(() => {
		if (img !== "") {
			getImageURL(img, "1920").then((url: any) => {
				setImageURL(url);
			});
		} else {
			setImageURL("");
		}
		if (overlay !== "") {
			getImageURL(overlay, "1920").then((url: any) => {
				setOverlayURL(url);
			});
		} else {
			setImageURL("");
		}
		//eslint-disable-next-line
	}, [img, id]);

	useEffect(() => {
		if (settings["admin-message-default-slide-image"]) {
			let image = settings["admin-message-default-slide-image"].value;
			getImageURL(image, "full").then((url: any) => {
				setDefaultImage(url);
			});
		}
		//eslint-disable-next-line
	}, [settings, img]);

	// useEffect(() => {
	// 	console.log(slide);
	// }, [slide]);

	return (
		<Fragment>
			{type === "image" && (
				<>
					{img ? (
						<div className="slide-wrapper customImage">
							<div
								style={{ backgroundImage: `url(${imageURL})` }}
								className="bg-image"
							>
								<div className="bg-overlay"></div>
							</div>
							<div
								style={{ backgroundImage: `url(${imageURL})` }}
								className="slide"
							>
								{body !== "" && (
									<div className="content-wrapper">
										<div className="content">
											{body !== "" && <span className="message">{body}</span>}
										</div>
									</div>
								)}
							</div>
						</div>
					) : (
						<div
							style={{ backgroundImage: `url(${defaultImage})` }}
							className="slide-wrapper defaultImage"
						>
							<div className="slide">
								<div className="content-wrapper">
									<div className="content">
										<span className="message">{body}</span>
										<div className="row name votes">
											<div className="name-wrapper">
												<span className="name">{name}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
			{type === "video" && (
				<>
					<div
						className="video-wrapper"
						dangerouslySetInnerHTML={{
							__html: embed,
						}}
					/>
					{overlayURL && (
						<img className="overlay" src={overlayURL} alt="overlay" />
					)}
				</>
			)}
		</Fragment>
	);
};

const mapStateTopProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateTopProps, {})(OutputSlide);
