import { GET_REQUESTS_SUCCESS } from "../actionTypes";
const defaultState = {};

const requests = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_REQUESTS_SUCCESS:
			return payload;
		default:
			return state;
	}
};

export default requests;
