import { GET_SCHEDULE, SET_TEAMS } from "../actionTypes";
const defaultState = { schedule: {}, teams: {} };

const schedules = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_SCHEDULE:
			//console.log(payload);
			return { ...state, schedule: payload };
		case SET_TEAMS:
			//console.log(payload);
			return { ...state, teams: payload };
		default:
			return state;
	}
};

export default schedules;
