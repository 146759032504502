import { IonContent, IonImg } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import { AppFooter } from "../../../components/Header/AppFooter";
import { AppHeader } from "../../../components/Header/AppHeader";
import { getImageURL } from "../../../services/util";
import SendFeedback from "./components/SendFeedback";

interface Props {
	settings: any;
}

const Feedback: React.FC<Props> = ({ settings }) => {
	const [headerImage, setHeaderImage] = useState<any>("");
	const [footerImage, setFooterImage] = useState<any>("");
	const [footerImageURL, setFooterImageURL] = useState<any>("");

	useEffect(() => {
		if (settings["feedback-header-image"]) {
			getImageURL(settings["feedback-header-image"].value).then((url: any) => {
				setHeaderImage(url);
			});
		}
		if (settings["feedback-footer-image"]) {
			getImageURL(settings["feedback-footer-image"].value).then((url: any) => {
				setFooterImage(url);
			});
		}
		if (settings["feedback-footer-image-URL"]) {
			setFooterImageURL(settings["feedback-footer-image-URL"].value);
		}
	}, [settings]);

	return (
		<>
			<AppHeader title="Feedback" />
			<IonContent fullscreen className="ion-padding">
				{headerImage && (
					<IonImg src={headerImage} className="full-width-image" />
				)}
				<SendFeedback />
				<div className="spacer60" />
				{footerImage && (
					<Fragment>
						{footerImageURL ? (
							<a href={footerImageURL}>
								<IonImg src={footerImage} className="full-width-image footer" />
							</a>
						) : (
							<IonImg src={footerImage} className="full-width-image footer" />
						)}
					</Fragment>
				)}
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	settings: state.settings,
});

export default connect(mapStateToProps, {})(Feedback);
