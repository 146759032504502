import React, { useEffect, useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonLoading,
  IonFooter,
  IonButton,
  IonItemGroup,
  IonItemDivider,
  IonIcon,
} from "@ionic/react";

import { menuController } from "@ionic/core";
import { getCurrentUser, logout } from "../../services/auth";
import { useHistory } from "react-router";

import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";

import AdminMenu from "./AdminMenu";
import FrontendMenu from "./FrontendMenu";
import { warningOutline } from "ionicons/icons";
import { pushToScreen } from "../../services/settings";

interface Props {
  user: any;
  slides: any;
}

const AppMenu: React.FC<Props> = ({ user, slides }) => {
  const history = useHistory();

  const [busy, setBusy] = useState<boolean>(false);
  const [primarySlide, setPrimarySlide] = useState<any>("");

  useEffect(() => {
    getCurrentUser().then((userData: any) => {
      if (!userData) {
        history.replace("/");
      }
      firebase.auth().onAuthStateChanged(function (userData) {
        if (!userData) {
          history.replace("/");
        }
      });
    });
    //eslint-disable-next-line
  }, []);

  const closeMenu = async () => {
    await menuController.toggle();
  };

  async function _logOut() {
    // console.log("logging out");
    closeMenu();
    setBusy(true);
    logout();
    setBusy(false);
    history.push("/");
  }

  const _pushToScreen = () => {
    pushToScreen({ type: "slide", id: primarySlide });
  };

  useEffect(() => {
    if (slides) {
      let slide: any = Object.values(slides).filter(
        (slide: any) => slide.primary === true
      );
      if (slide[0]) {
        setPrimarySlide(slide[0].id);
      }
    }
  }, [slides]);

  return (
    <IonMenu contentId="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonLoading message="Logging out..." duration={0} isOpen={busy} />
      <IonContent>
        <IonItemGroup>
          {user.isAdmin && <AdminMenu />}
          {user.isAdmin && (
            <IonItemDivider>
              <IonLabel>Fans</IonLabel>
            </IonItemDivider>
          )}
          <FrontendMenu />
        </IonItemGroup>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonToolbar>
          {!user.isAdmin && <IonButton onClick={_logOut}>Logout</IonButton>}
          {user.isAdmin && (
            <IonButton onClick={_pushToScreen} fill="solid" color="danger">
              <IonIcon slot="start" icon={warningOutline}></IonIcon>
              <IonLabel>Push Default</IonLabel>
            </IonButton>
          )}
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user.userDetails,
  slides: state.slides,
});

export default connect(mapStateToProps, {})(AppMenu);
