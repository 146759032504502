import {
	IonItem,
	IonImg,
	IonLabel,
	IonCol,
	IonGrid,
	IonRow,
	IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getImageURL } from "../../../../services/util";

interface Props {
	player: any;
}

const PlayerCard: React.FC<Props> = ({ player }) => {
	const history = useHistory();
	const [imageURL, setImageURL] = useState<any>("");
	const { img } = player;
	const defaultAvatar =
		"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/default-profile.jpg?alt=media&token=b1fd87b3-ccb9-436c-9061-ca95d80281d6";

	useEffect(() => {
		if (img !== "" && imageURL === "") {
			getImageURL(img, "200").then((url: any) => {
				setImageURL(url);
			});
		}
		//eslint-disable-next-line
	}, [img]);

	return (
		<IonItem
			onClick={() => history.push(`/player/${player.id}`)}
			className="playerProfile playerProfileCard linked"
		>
			<IonImg
				slot="start"
				alt=""
				src={imageURL ? imageURL : defaultAvatar}
				className="avatar"
			/>
			<div className="content_wrapper">
				<IonLabel>
					<IonText className="playerName">{player.name}</IonText>
					<h3 className="playerStats">
						{player.ht && <span>{player.ht}cm</span>}
						{player.ht && player.wt && <span className="spacer">•</span>}
						{player.wt && <span>{player.wt}kg</span>}
					</h3>
					<h3 className="playerStats">
						{player.hometown && <span>{player.hometown}</span>}
					</h3>
				</IonLabel>
				<div className="spacer15" />
				<IonGrid className="playerInfoBlock">
					<IonRow>
						{player.pos && (
							<IonCol>
								<span>{player.pos}</span>
							</IonCol>
						)}
						{player.num && (
							<IonCol>
								<sup># </sup>
								<span>{player.num}</span>
							</IonCol>
						)}
					</IonRow>
				</IonGrid>
			</div>
		</IonItem>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PlayerCard);
