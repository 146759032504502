import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToggle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";
// import { toast } from "../../../components/Toast/toast";
// import { updateSetting } from "../../../services/settings";

interface Props {
  settings: any;
}

const ShopSettings: React.FC<Props> = ({ settings }) => {
  const defaultAllowedSections = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
  };
  const [allowedSections, setAllowedSections] = useState<any>(
    settings["shop-allowed-sections"]
      ? settings["shop-allowed-sections"].value
      : defaultAllowedSections
  );

  const [dashboardImage, setDashboardImage] = useState<any>(
    settings["shop-dashboard-image"]
      ? settings["shop-dashboard-image"].value
      : ""
  );
  const [headerImage, setHeaderImage] = useState<any>(
    settings["shop-header-image"] ? settings["shop-header-image"].value : ""
  );
  const [footerImage, setFooterImage] = useState<any>(
    settings["shop-footer-image"] ? settings["shop-footer-image"].value : ""
  );
  const [footerImageURL, setFooterImageURL] = useState<any>(
    settings["shop-footer-image-URL"]
      ? settings["shop-footer-image-URL"].value
      : ""
  );
  const [stripePublicKey, setStripePublicKey] = useState<any>(
    settings["shop-stripe-public-key"]
      ? settings["shop-stripe-public-key"].value
      : ""
  );
  const [shopActive, setShopActive] = useState<any>(
    settings["shop-active"] ? settings["shop-active"].value : false
  );
  const [shopVisible, setShopVisible] = useState<any>(
    settings["shop-visible"] ? settings["shop-visible"].value : false
  );

  const _onToggle = (section: any) => {
    console.log("toggled ", section);
    if (section === "shopActive") {
      setShopActive(!shopActive);
    } else if (section === "shopVisible") {
      console.log("shop visible");
      setShopVisible(!shopVisible);
    } else {
      setAllowedSections({
        ...allowedSections,
        [section]: !allowedSections[section],
      });
    }
  };

  const _save = (name: any, data: any) => {
    if (settings[name]) {
      updateSetting(settings[name].id, data);
    } else {
      addSetting(name, "");
    }
  };

  useEffect(() => {
    _save("shop-allowed-sections", allowedSections);
    //eslint-disable-next-line
  }, [allowedSections]);

  useEffect(() => {
    _save("shop-dashboard-image", dashboardImage);
    //eslint-disable-next-line
  }, [dashboardImage]);

  useEffect(() => {
    _save("shop-header-image", headerImage);
    //eslint-disable-next-line
  }, [headerImage]);

  useEffect(() => {
    _save("shop-footer-image", footerImage);
    //eslint-disable-next-line
  }, [footerImage]);

  useEffect(() => {
    _save("shop-footer-image-URL", footerImageURL);
    //eslint-disable-next-line
  }, [footerImageURL]);

  useEffect(() => {
    _save("shop-stripe-public-key", stripePublicKey);
    //eslint-disable-next-line
  }, [stripePublicKey]);

  useEffect(() => {
    _save("shop-active", shopActive);
    //eslint-disable-next-line
  }, [shopActive]);

  useEffect(() => {
    _save("shop-visible", shopVisible);
    //eslint-disable-next-line
  }, [shopVisible]);

  const _onPhotoChange = (e: any) => {
    if (e.name === "headerImage") setHeaderImage(e.image.fullPath);
    else if (e.name === "footerImage") setFooterImage(e.image.fullPath);
    else if (e.name === "dashboardImage") setDashboardImage(e.image.fullPath);
  };

  const _onChange = (e: any) => {
    if (e.target.name === "footerImageURL") {
      setFooterImageURL(e.target.value);
    } else if (e.target.name === "stripePublicKey") {
      setStripePublicKey(e.target.value);
    }
  };

  return (
    <Fragment>
      <IonTitle className="section-header">Shop Settings</IonTitle>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>General Shop Settings</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonLabel>Shop Active?</IonLabel>
              <IonToggle
                checked={shopActive}
                onClick={() => _onToggle("shopActive")}
                name="shopActive"
                id="shopActive"
              />
            </IonItem>
            <IonItem>
              <IonLabel>Shop Section Visible?</IonLabel>
              <IonToggle
                checked={shopVisible}
                onClick={() => _onToggle("shopVisible")}
                name="shopVisible"
                id="shopVisible"
              />
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Restrict shop purchases</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>Toggle section on to allow purchases</p>
          <IonList lines="none">
            {Object.values(allowedSections).map((section: any, idx: any) => (
              <IonItem key={idx}>
                <IonLabel>Section {idx + 1}</IonLabel>
                <IonToggle
                  checked={allowedSections[idx + 1]}
                  onClick={() => _onToggle(idx + 1)}
                  name="section"
                  id={`section-${idx}`}
                />
              </IonItem>
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Stripe API</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonLabel position="floating">Stripe Public Key</IonLabel>
          <IonInput
            type="text"
            name="stripePublicKey"
            id="stripePublicKey"
            value={stripePublicKey}
            onIonChange={_onChange}
          />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Shop Page Images</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked">Dashboard Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="dashboardImage"
              id="dashboardImage"
              value={dashboardImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Header Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="headerImage"
              id="headerImage"
              value={headerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Footer Image</IonLabel>
            <div className="spacer5" />
            <PhotoUploadInput
              name="footerImage"
              id="footerImage"
              value={footerImage}
              onChange={_onPhotoChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Footer Image Link URL</IonLabel>
            <IonInput
              type="text"
              name="footerImageURL"
              id="footerImageURL"
              value={footerImageURL}
              onIonChange={_onChange}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <div className="spacer30" />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(ShopSettings);
