import {
	IonAvatar,
	IonCol,
	IonIcon,
	IonItem,
	IonItemOption,
	IonItemOptions,
	IonItemSliding,
	IonLabel,
	IonRow,
} from "@ionic/react";
import { star } from "ionicons/icons";
import React from "react";
import { setAdminUser } from "../../../../services/users";

interface Props {
	user: any;
}

const AdminUserCard: React.FC<Props> = ({ user }) => {
	const { id, avatar, fname, lname, email, phoneNumber, isAdmin } = user;

	const defaultAvatar =
		"https://firebasestorage.googleapis.com/v0/b/rams-app-22bcc.appspot.com/o/default-profile.jpg?alt=media&token=b1fd87b3-ccb9-436c-9061-ca95d80281d6";

	const toggleAdmin = async (uid: any) => {
		setAdminUser(id, !user.isAdmin);
	};

	//TODO Add sorting segments here
	//TODO Add ability for admin to delete a request
	return (
		<IonItemSliding key={id}>
			<IonItem>
				<IonAvatar slot="start">
					<img alt="" src={avatar ? avatar : defaultAvatar} />
				</IonAvatar>
				<IonLabel className="ion-padding">
					<IonRow>
						<IonCol>
							<h2>
								{fname} {lname}
							</h2>
						</IonCol>
						<IonCol>
							<h3>{email}</h3>
						</IonCol>
						<IonCol>
							<>{phoneNumber}</>
						</IonCol>
						<IonCol>
							<h4>{id}</h4>
						</IonCol>
					</IonRow>
				</IonLabel>
				{isAdmin && <IonIcon slot="end" icon={star}></IonIcon>}
			</IonItem>
			<IonItemOptions side="end">
				<IonItemOption onClick={() => toggleAdmin(id)}>
					Toggle Admin
				</IonItemOption>
			</IonItemOptions>
		</IonItemSliding>
	);
};

export default AdminUserCard;
