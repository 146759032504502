import { IonContent } from "@ionic/react";
import React from "react";

import { connect } from "react-redux";
import { AppHeader } from "../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../components/Header/AppFooter";
import RequestsView from "./RequestsView";

interface Props {}

const DashboardRequestsView: React.FC<Props> = () => {
	return (
		<>
			<AppHeader title="Requests" />
			<IonContent className="ion-padding contentPane admin">
				<RequestsView />
			</IonContent>
			<AppFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(DashboardRequestsView);
