import { GET_SETTINGS_SUCCESS } from "../actionTypes";
const defaultState = {};

const settings = (state = defaultState, action) => {
	const { type, payload } = action;
	//console.log(payload);
	switch (type) {
		case GET_SETTINGS_SUCCESS:
			//console.log(payload);
			return payload;
		default:
			return state;
	}
};

export default settings;
