import {
	IonContent,
	IonLabel,
	IonItem,
	IonList,
	IonIcon,
	IonButton,
} from "@ionic/react";
import React, { Fragment } from "react";

import { add } from "ionicons/icons";
import { connect } from "react-redux";
import NotificationCard from "./components/NotificationCard";
import { AppHeader } from "../../../components/Header/AppHeader";
import { useHistory } from "react-router-dom";
import AdminFooter from "../../../components/Header/AdminFooter";

interface Props {
	notifications: any;
}

const AdminNotifications: React.FC<Props> = ({ notifications }) => {
	const history = useHistory();
	return (
		<>
			<AppHeader title="User Notifications" />
			<IonContent fullscreen className="ion-padding admin">
				<IonList>
					{Object.values(notifications).length > 0 ? (
						<Fragment>
							{Object.values(notifications).map((elem: any) => (
								<NotificationCard key={elem.id} message={elem} />
							))}
						</Fragment>
					) : (
						<IonLabel className="ion-padding">
							No notifcations available
						</IonLabel>
					)}
					<IonItem>
						<IonButton
							onClick={() => history.push("/admin/notification/new")}
							color="primary"
						>
							<IonIcon icon={add} />
							Add New
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
			<AdminFooter />
		</>
	);
};

const mapStateToProps = (state: any) => ({
	notifications: state.notifications,
});

export default connect(mapStateToProps, {})(AdminNotifications);
