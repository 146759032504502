import {
	IonContent,
	IonTitle,
	IonLabel,
	IonItem,
	IonButton,
	IonInput,
	IonTextarea,
	IonList,
	IonButtons,
	IonToggle,
	IonRadio,
	IonRadioGroup,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../components/Toast/toast";
import {
	updateSlide,
	getSlides,
	addSlide,
	setPrimarySlide,
} from "../../../../services/slides";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { AppHeader } from "../../../../components/Header/AppHeader";

import PhotoUploadInput from "../../../../components/PhotoUploadInput";

export interface RouteParams {
	id: string;
}

interface Props {
	slides: any;
}

const AdminSlide: React.FC<Props> = ({ slides }) => {
	const history = useHistory();
	const defaultState = {
		name: "",
		body: "",
		img: "",
		overlay: "",
		primary: false,
		type: "image",
		embed: "",
	};

	let { id } = useParams<RouteParams>();
	const [slide, setSlide] = useState<any>(defaultState);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

	const { name, img, body, primary, type, embed, overlay } = slide;

	useEffect(() => {
		if (id && slides[id]) {
			setSlide({ ...defaultState, ...slides[id] });
		} else {
			setSlide(defaultState);
		}
		//eslint-disable-next-line
	}, [id, slides]);

	const _onChange = (e: any) => {
		setSlide({ ...slide, [e.target.name]: e.target.value });
	};

	// useEffect(() => {
	// 	console.log(slide, id);
	// }, [slide]);

	const _submit = (e: any) => {
		setDisableSubmit(true);
		e.preventDefault();

		if (id === "new") {
			addSlide({ ...slide }).then((res: any) => {
				if (res !== false) {
					history.push(`/admin/slide/${res}`);
					toast("Slide saved");
					if (primary) {
						setPrimarySlide(res);
					}
				}
			});
		} else {
			updateSlide({ ...slide }, id).then((res) => {
				if (res) {
					history.push("/admin/slides");
					toast("Slide updated");
					console.log(res);
					if (primary) {
						setPrimarySlide(res);
					}
				}
			});
		}
		getSlides();
		setDisableSubmit(false);
	};

	const _back = () => {
		setSlide(defaultState);
		history.push("/admin/slides");
	};

	const _onPhotoChange = (e: any) => {
		setSlide({ ...slide, [e.name]: e.image.fullPath });
	};

	const _onToggle = (option: any) => {
		setSlide({ ...slide, [option]: !slide[option] });
	};

	const _onRadioChange = (e: any) => {
		let type = e.detail.value;
		console.log(type);
		setSlide({ ...slide, type });
		// updateSlide({ ...slide, type }, id);

		//setActivation({ ...activation, status: e.target.value });
	};

	return (
		<>
			<AppHeader title={name} />
			<IonContent className="ion-padding admin">
				<IonTitle>Slide</IonTitle>
				<form onSubmit={_submit}>
					<IonList>
						<IonItem>
							<IonLabel>Primary screen?</IonLabel>
							<IonToggle
								checked={primary}
								onClick={() => _onToggle("primary")}
								name="primary"
								id="primary"
							/>
						</IonItem>
						<IonItem>
							<IonRadioGroup
								// compareWith={_compareStatus}
								value={type}
								name="type"
								onIonChange={_onRadioChange}
								// okText="Okay"
								// cancelText="Dismiss"
								// placeholder="Select One"
							>
								<IonItem lines="none">
									<IonLabel>Image</IonLabel>
									<IonRadio slot="start" value="image" />
								</IonItem>
								<IonItem lines="none">
									<IonLabel>Video</IonLabel>
									<IonRadio slot="start" value="video" />
								</IonItem>
							</IonRadioGroup>
						</IonItem>
						{type === "video" && (
							<>
								<IonItem>
									<IonLabel position="floating">Embed Code</IonLabel>
									<IonTextarea
										id="embed"
										name="embed"
										value={embed}
										onIonChange={_onChange}
									></IonTextarea>
								</IonItem>
								<IonItem>
									<IonLabel position="stacked">Overlay Image</IonLabel>
									<div className="spacer5" />
									<PhotoUploadInput
										name="overlay"
										id="overlay"
										value={overlay}
										onChange={_onPhotoChange}
									/>
								</IonItem>
							</>
						)}
						{type === "image" && (
							<IonItem>
								<IonLabel position="stacked">Image</IonLabel>
								<div className="spacer5" />
								<PhotoUploadInput
									name="img"
									id="img"
									value={img}
									onChange={_onPhotoChange}
								/>
							</IonItem>
						)}
						<IonItem>
							<IonLabel position="floating">Name</IonLabel>
							<IonInput
								type="text"
								id="name"
								name="name"
								value={slide.name}
								onIonChange={_onChange}
							></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Body</IonLabel>
							<IonTextarea
								id="body"
								name="body"
								value={body}
								onIonChange={_onChange}
							></IonTextarea>
						</IonItem>
					</IonList>
					<IonButtons>
						<IonButton type="submit" color="success" disabled={disableSubmit}>
							Save
						</IonButton>
						<IonButton onClick={_back} color="warning">
							Cancel
						</IonButton>
					</IonButtons>
				</form>
			</IonContent>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	slides: state.slides,
});

export default connect(mapStateToProps, { getSlides })(AdminSlide);
