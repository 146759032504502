import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonItem,
	IonLabel,
	IonTitle,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PhotoUploadInput from "../../../../components/PhotoUploadInput";
import { addSetting, updateSetting } from "../../../../services/settings";

interface Props {
	settings: any;
}

const MessageSlideshowSettings: React.FC<Props> = ({ settings }) => {
	const [defaultImage, setDefaultImage] = useState<any>(
		settings["admin-message-default-slide-image"]
			? settings["admin-message-default-slide-image"].value
			: ""
	);

	const _save = (name: any, data: any) => {
		if (settings[name]) {
			updateSetting(settings[name].id, data);
		} else {
			addSetting(name, "");
		}
	};

	useEffect(() => {
		_save("admin-message-default-slide-image", defaultImage);
		//eslint-disable-next-line
	}, [defaultImage]);

	const _onPhotoChange = (e: any) => {
		if (e.name === "defaultImage") setDefaultImage(e.image.fullPath);
	};

	return (
		<Fragment>
			<IonTitle className="section-header">Message Slideshow Settings</IonTitle>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Message Slideshow Images</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonItem>
						<IonLabel position="stacked">Default Image</IonLabel>
						<div className="spacer5" />
						<PhotoUploadInput
							name="defaultImage"
							id="defaultImage"
							value={defaultImage}
							onChange={_onPhotoChange}
						/>
					</IonItem>
				</IonCardContent>
			</IonCard>
			<div className="spacer30" />
		</Fragment>
	);
};

const mapStateToProps = (state: any) => ({ settings: state.settings });

export default connect(mapStateToProps, {})(MessageSlideshowSettings);
