import {
  IonContent,
  IonTitle,
  IonLabel,
  IonItem,
  IonButton,
  IonInput,
  IonTextarea,
  IonToggle,
  IonItemGroup,
  IonItemDivider,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { toast } from "../../../../../components/Toast/toast";
import {
  addTeam,
  deleteTeam,
  updateTeam,
} from "../../../../../services/schedules";

// custom hook that will upload to firebase
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { AppHeader } from "../../../../../components/Header/AppHeader";
import { AppFooter } from "../../../../../components/Header/AppFooter";
import PhotoUploadInput from "../../../../../components/PhotoUploadInput";

export interface RouteParams {
  id: string;
}

interface Props {
  teams: any;
}

const AdminTeam: React.FC<Props> = ({ teams }) => {
  const history = useHistory();

  const defaultState = {
    active: false,
    name: "",
    fullname: "",
    record: "",
    img: "",
    includeSquad: false,
  };

  let { id } = useParams<RouteParams>();
  const [team, setTeam] = useState<any>(defaultState);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);

  const { active, name, fullname, img, record, includeSquad } = team;

  useEffect(() => {
    if (teams[id]) {
      setTeam(teams[id]);
    }
    //eslint-disable-next-line
  }, [id, teams]);

  const _onChange = (e: any) => {
    setTeam({ ...team, [e.target.name]: e.target.value });
  };

  const _submit = (e: any) => {
    setDisableSubmit(true);
    e.preventDefault();
    // if (team.name.trim() === "" || team.pos.trim() === "") {
    // 	setDisableSubmit(false);
    // 	return toast("You must include a message and an image");
    // }
    if (id === "new") {
      addTeam(team).then((res: any) => {
        if (res !== false) {
          toast("team saved");
        }
      });
    } else {
      updateTeam(team, id).then((res) => {
        if (res) {
          toast("team updated");
        }
      });
    }
    setDisableSubmit(false);
  };

  const _back = () => {
    setTeam(defaultState);
    history.push("/admin/schedules");
  };
  const _delete = () => {
    deleteTeam(id).then((res) => {
      history.push("/admin/schedules");
    });
  };

  const _onToggle = (option: any) => {
    setTeam({ ...team, [option]: !team[option] });
  };

  const _onPhotoChange = (e: any) => {
    setTeam({ ...team, img: e.image.fullPath });
  };

  return (
    <>
      <AppHeader title={`${id === "new" ? "Add" : "Edit"} team `} />
      <IonContent className="ion-padding admin">
        <IonTitle>{id === "new" ? "Add" : "Edit"} team </IonTitle>

        <form onSubmit={_submit}>
          <IonItemGroup>
            <IonItem>
              <IonLabel>Active?</IonLabel>
              <IonToggle
                checked={active}
                onClick={() => _onToggle("active")}
                name="active"
                id="active"
              />
            </IonItem>
            <IonItem>
              <IonLabel>Include Squad?</IonLabel>
              <IonToggle
                checked={includeSquad}
                onClick={() => _onToggle("includeSquad")}
                name="includeSquad"
                id="includeSquad"
              />
            </IonItem>
            <IonItemDivider>
              <IonLabel>Team Details</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <IonInput
                type="text"
                id="name"
                name="name"
                value={name}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Full Name</IonLabel>
              <IonInput
                type="text"
                id="fullname"
                name="fullname"
                value={fullname}
                onIonChange={_onChange}
              ></IonInput>
            </IonItem>

            <IonItem>
              <IonLabel position="stacked">Image</IonLabel>
              <div className="spacer5" />
              <PhotoUploadInput
                name="img"
                id="img"
                value={img}
                onChange={_onPhotoChange}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Record e.g. 11-17</IonLabel>
              <IonTextarea
                id="record"
                name="record"
                value={record}
                onIonChange={_onChange}
              ></IonTextarea>
            </IonItem>
          </IonItemGroup>

          <IonButton type="submit" color="primary" disabled={disableSubmit}>
            Save
          </IonButton>
          <IonButton onClick={_back} color="danger">
            Back
          </IonButton>
          <IonButton onClick={_delete} color="danger">
            Delete
          </IonButton>
        </form>
      </IonContent>
      <AppFooter />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  teams: state.schedules.teams,
});

export default connect(mapStateToProps, {})(AdminTeam);
