// import { IonIcon } from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

// import { star } from "ionicons/icons";

import { getImageURL } from "../../../../services/util";

interface Props {
	submission: any;
	activations: any;
	settings: any;
}

const SubmissionSlide: React.FC<Props> = ({
	submission,
	activations,
	settings,
}) => {
	//const [imageURL, setImageURL] = useState<any>("");
	const [bgURL, setBgURL] = useState<any>("");
	//const [defaultImage, setDefaultImage] = useState<any>("");
	const [theActivation, setActivation] = useState<any>({});

	const { pushSlideBG } = theActivation;
	const { id, activation, data } = submission;

	// useEffect(() => {
	// 	if (activation) {
	// 		setActivation(activations[submission.activation]);
	// 	}
	// 	if (img !== "") {
	// 		getImageURL(img, "1920").then((url: any) => {
	// 			setImageURL(url);
	// 		});
	// 	} else {
	// 		setImageURL("");
	// 	}
	// 	//eslint-disable-next-line
	// }, [img, id]);

	useEffect(() => {
		if (activation) {
			setActivation(activations[submission.activation]);
		}
		//eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		if (pushSlideBG !== "") {
			getImageURL(pushSlideBG, "1920").then((url: any) => {
				setBgURL(url);
			});
		} else {
			setBgURL("");
		}
		//eslint-disable-next-line
	}, [theActivation]);

	// useEffect(() => {
	// 	if (settings["admin-message-default-slide-image"]) {
	// 		let image = settings["admin-message-default-slide-image"].value;
	// 		getImageURL(image, "1920").then((url: any) => {
	// 			setDefaultImage(url);
	// 		});
	// 	}
	// 	//eslint-disable-next-line
	// }, [settings, img]);

	return (
		<Fragment>
			{/* {img ? (
				<div className="slide-wrapper customImage">
					<div
						style={{ backgroundImage: `url(${imageURL})` }}
						className="bg-image"
					>
						<div className="bg-overlay"></div>
					</div>
					<div
						style={{ backgroundImage: `url(${imageURL})` }}
						className="slide"
					>
						{(name !== "" || body !== "" || votes > 0) && (
							<div className="content-wrapper">
								<div className="content">
									{body !== "" && <span className="message">{body}</span>}
									<div className="row name votes">
										<div className="name-wrapper">
											{name !== "" && <span className="name">{name}</span>}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			) : ( */}
			<div
				style={{ backgroundImage: `url(${bgURL})` }}
				className="slide-wrapper submission defaultImage"
			>
				<div className="slide">
					<div className="content-wrapper">
						<div className="content">
							{data.additionalFields && (
								<>
									{data.additionalFields.text && (
										<span className="message">
											{data.additionalFields.text}
										</span>
									)}
									{data.additionalFields.textarea && (
										<span className="message">
											{data.additionalFields.textarea}
										</span>
									)}
									{data.additionalFields.number && (
										<span className="message">
											{data.additionalFields.number}
										</span>
									)}
								</>
							)}
							{/* <div className="row name votes">
									<div className="name-wrapper">
										<span className="name">{name}</span>
									</div>
								</div> */}
						</div>
					</div>
				</div>
			</div>
			{/* )} */}
		</Fragment>
	);
};

const mapStateTopProps = (state: any) => ({
	settings: state.settings,
	activations: state.activations.activations,
});

export default connect(mapStateTopProps, {})(SubmissionSlide);
