import {
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
} from "@ionic/react";
import React, { Fragment, useEffect, useState } from "react";

import FeedbackCard from "./FeedbackCard";
import { connect } from "react-redux";
import { getFeedback } from "../../../../services/feedback";
import { sortByCreated } from "../../../../services/util";

interface Props {
	feedback: any;
	getFeedback: any;
}

const FeedbackView: React.FC<Props> = ({ feedback, getFeedback }) => {
	const [segment, setSegment] = useState<any>("incomplete");

	useEffect(() => {
		getFeedback();
		//eslint-disable-next-line
	}, []);

	const allMessages = Object.values(feedback).sort((a, b) =>
		sortByCreated(a, b, "ASC")
	);
	const incomplete = allMessages.filter((el: any) => el.complete === false);
	const completed = allMessages.filter((el: any) => el.complete === true);

	return (
		<>
			<IonToolbar>
				<IonSegment
					onIonChange={(e) => setSegment(e.detail.value)}
					value={segment}
				>
					<IonSegmentButton value="incomplete">Incomplete</IonSegmentButton>
					<IonSegmentButton value="complete">Completed</IonSegmentButton>
					<IonSegmentButton value="all">All</IonSegmentButton>
				</IonSegment>
			</IonToolbar>
			{segment === "incomplete" && (
				<Fragment>
					{incomplete && incomplete.length > 0 ? (
						incomplete.map((msg: any, idx: any) => (
							<FeedbackCard key={idx} feedback={msg} />
						))
					) : (
						<IonLabel className="ion-padding">
							No incomplete feedback available
						</IonLabel>
					)}
				</Fragment>
			)}
			{segment === "complete" && (
				<Fragment>
					{completed && completed.length > 0 ? (
						completed.map((msg: any, idx: any) => (
							<FeedbackCard key={idx} feedback={msg} />
						))
					) : (
						<IonLabel className="ion-padding">
							No completed feedback available
						</IonLabel>
					)}
				</Fragment>
			)}
			{segment === "all" && (
				<Fragment>
					{allMessages && allMessages.length > 0 ? (
						allMessages.map((msg: any, idx: any) => (
							<FeedbackCard key={idx} feedback={msg} />
						))
					) : (
						<IonLabel className="ion-padding">No feedback available</IonLabel>
					)}
				</Fragment>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	feedback: state.feedback,
});

export default connect(mapStateToProps, { getFeedback })(FeedbackView);
